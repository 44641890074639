import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import {
  NewPhotoDialogComponent,
} from '@shared/modules/side-dialog/components/new-photo-dialog/new-photo-dialog.component';
import { PhotoDialogComponent } from '@shared/modules/side-dialog/components/photo-dialog/photo-dialog.component';

import { Files } from '../interfaces/claims/files.interface';

@Injectable({
  providedIn: 'root',
})
export class PhotoDialogService {
  constructor(public matDialog: MatDialog) {}

  openPhotoDialog(file: Files, files: Files[], enableDelete: boolean = true) {
    return this.matDialog.open(PhotoDialogComponent, {
      closeOnNavigation: true,
      disableClose: true,
      autoFocus: false,
      panelClass: 'photo-dialog',
      backdropClass: 'photo-dialog-backdrop',
      data: {
        file,
        files,
        enableDelete,
      },
    });
  }

  openNewPhotoDialog(file: Files, files$: Observable<Files[]>, enableDelete: boolean = true) {
    return this.matDialog.open(NewPhotoDialogComponent, {
      closeOnNavigation: true,
      disableClose: true,
      autoFocus: false,
      panelClass: 'photo-dialog',
      backdropClass: 'photo-dialog-backdrop',
      data: {
        file,
        files$,
        enableDelete,
      },
    });
  }
}
