import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { Subject } from 'rxjs';

import { Pagination } from '@core/interfaces/ui/pagination.interface';

import { GridColumnModel } from '../../models/grid/grid-column.model';
import { GridOrderByModel } from '../../models/grid/grid-order-by.model';

interface GridState {
  items: any[];
  allChecked: boolean;
}

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent {
  @Input() set items(items: any[]) {
    this.state.set({
      items,
      allChecked: this.selectable ? items.length > 0 && items.filter(item => !item.isChecked).length === 0 : false,
    });
  }
  @Input() pageSize: number;
  @Input() columns: GridColumnModel[];
  @Input() orderBy: GridOrderByModel;
  @Input() pagination: Pagination;
  @Input() selectable: boolean;
  @Input() loading = false;
  @Input() loadingFixed = false;
  @Input() hidePages = false;
  @Input() rowClickable = false;
  @Input() expandableCellTemplate: TemplateRef<any>;
  @Input() expandableRowTemplate: TemplateRef<any>;
  @Input() expandedCellColspan = 0;

  @Output() sort = new EventEmitter<GridOrderByModel[]>();
  @Output() toggleAll = new EventEmitter<boolean>();
  @Output() checked = new EventEmitter<any>();
  @Output() toggleExpand = new EventEmitter<any>();
  @Output() rowClicked = new EventEmitter<any>();
  @Output() changePage = new EventEmitter<number>();

  items$ = this.state.select('items');
  itemsLength$ = this.state.select('items', 'length');
  allChecked$ = this.state.select('allChecked');
  allCheckedToggle$ = new Subject<void>();

  constructor(private readonly state: RxState<GridState>) {
    this.state.connect('allChecked', this.allCheckedToggle$, ({ allChecked }) => {
      this.toggleAll.emit(!allChecked);
      return !allChecked;
    });

    this.state.connect('allChecked', this.state.select('items'), ({ allChecked }, items) => {
      if (items.length === 0) {
        this.toggleAll.emit(false);
        return false;
      } else {
        return allChecked;
      }
    });
  }

  itemChecked(item: any) {
    this.checked.emit(item);
  }

  toggleItemExpand(item: any, $event: MouseEvent) {
    $event.stopPropagation();
    this.toggleExpand.emit(item);
  }

  trackByRow(index: number, itemRow: any) {
    return `${index}${Object.values(itemRow).join('')}`;
  }

  trackByFnColumn(index: number, column: GridColumnModel) {
    return `${column.fieldName}${column.title}`;
  }

  changeOrderBy(fieldName: string, sortable: boolean) {
    if (!sortable) {
      return;
    }

    if (!this.orderBy) {
      this.orderBy = {
        fieldName: null,
      };
    }

    if (this.orderBy.fieldName !== fieldName || this.orderBy.ascending === undefined) {
      this.orderBy.fieldName = fieldName;
      this.orderBy.ascending = false;
    } else {
      if (this.orderBy.ascending) {
        this.orderBy.ascending = undefined;
      } else {
        this.orderBy.ascending = !this.orderBy.ascending;
      }
    }
    if (this.orderBy.ascending !== undefined) {
      this.sort.emit([this.orderBy]);
    } else {
      this.sort.emit([]);
    }
  }

  checkAll() {
    this.allCheckedToggle$.next();
  }
}
