import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancellation-status',
  templateUrl: './cancellation-status.component.html',
  styleUrls: ['./cancellation-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancellationStatusComponent implements OnInit {
  @Input() cancellationStatus: string;
  @Input() refundAmount: number;

  constructor() {}

  ngOnInit() {}
}
