import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { RxState } from '@rx-angular/state';
import { finalize } from 'rxjs/operators';

import { ServiceActionApi } from '@core/api/service-action-api';
import { APP_CONFIG } from '@core/constants/app-config.constants';
import { TRetailerAction } from '@core/enums/claim/retailer-action.enum';
import { TDateFieldType } from '@core/enums/date/date-field-type.enum';
import { TReplacementType } from '@core/enums/replacement-type.enum';
import { ClaimServiceAction, ServerPartOrder } from '@core/interfaces/claims/claimDetails.interface';
import { ClaimItemStore } from '@core/store/claim-item/claim-item.store';
import { UserStore } from '@core/store/user/user.store';
import { FormDateComponent } from '@shared/components/form-date/form-date.component';
import { FormTextareaComponent } from '@shared/components/form-textarea/form-textarea.component';
import { DialogCoreModule } from '@shared/modules/dialog-core/dialog-core.module';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';
import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';

interface IAvailabilityOrderingState {
  parts: ServerPartOrder[];
  partsLoading: boolean;
  showConfirmAvailability: boolean;
}

@Component({
  selector: 'app-authorization-availability-ordering-dialog',
  standalone: true,
  templateUrl: './authorization-availability-ordering-dialog.component.html',
  styleUrls: ['./authorization-availability-ordering-dialog.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatLegacyRadioModule,
    FormDateComponent,
    LoaderModule,
    NgIf,
    MatButtonModule,
    ReactiveFormsModule,
    AsyncPipe,
    NgForOf,
    MatCheckboxModule,
    CustomOverlayScrollbarDirective,
    DialogCoreModule,
    NgClass,
    FormTextareaComponent,
  ],
})
export class AuthorizationAvailabilityOrderingDialogComponent implements OnInit {
  showConfirmAvailability$ = this.state.select('showConfirmAvailability');
  parts$ = this.state.select('parts');
  partsLoading$ = this.state.select('partsLoading');
  form = this.fb.group({
    authorizationViewed: [false, Validators.requiredTrue],
    areAllPartsAvailable: [null, Validators.required],
    estimatedDeliveryDate: [null],
    notAvailableDescription: [null],
  });
  loadInProgress = false;
  authorizationLink = '';

  today: Date = new Date();
  TDateFieldType = TDateFieldType;
  titleText: string;
  session = this.userStore.get('session');
  itemsName = '';
  availableLabel = '';
  appConfig = inject(APP_CONFIG);
  dialogData: {
    data: {serviceAction: ClaimServiceAction; retailerAction: TRetailerAction};
  } = inject(MAT_DIALOG_DATA);

  constructor(
    public dialogRef: MatDialogRef<any>,
    public notification: NotificationService,
    protected serviceActionApi: ServiceActionApi,
    private readonly fb: UntypedFormBuilder,
    private readonly state: RxState<IAvailabilityOrderingState>,
    private readonly claimItemStore: ClaimItemStore,
    private readonly userStore: UserStore,
  ) {
    this.state.set({
      parts: [],
      partsLoading: true,
      showConfirmAvailability: true,
    });

    if (
      this.dialogData.data.retailerAction === TRetailerAction.ConfirmAvailability
      && this.dialogData.data.serviceAction.serviceActionReplacementType === TReplacementType.Reselection
    ) {
      this.state.set({
        showConfirmAvailability: false,
      });
      this.form.patchValue({
        areAllPartsAvailable: true,
      });
    }

    if (this.dialogData.data.retailerAction === TRetailerAction.ViewAuthorizationAndOrderParts) {
      this.titleText = 'Service Action Parts';
      this.availableLabel = 'Please list the part names and reasons why they are unavailable';
      this.itemsName = 'parts';
    } else {
      this.titleText = 'Service Action Replacements';
      this.availableLabel = 'Please list the product names and reasons why they are unavailable';
      this.itemsName = 'products';
    }
  }

  ngOnInit(): void {
    this.authorizationLink = this._buildPdfLink(
      `${this.appConfig.apiBaseUrl}/api/serviceaction/${this.dialogData.data.serviceAction.id}/authorization.pdf`,
    );
    this.serviceActionApi
      .getServiceActionDetails(this.dialogData.data.serviceAction.id, this.dialogData.data.serviceAction.serviceActionType)
      .pipe(
        finalize(() => {
          this.state.set({
            partsLoading: false,
          });
        }),
      )
      .subscribe(serviceAction => {
        this.state.set({
          parts: serviceAction.parts,
        });
      });
  }

  submitClaimDetailsAuthorizationAvailabilityOrdering(): void {
    if (this.loadInProgress) {
      return;
    }
    if (this.form.valid) {
      this.loadInProgress = true;

      this.serviceActionApi
        .claimDetailsAuthorizationAvailabilityOrdering(this.dialogData.data.serviceAction.id, this.form.value)
        .pipe(
          finalize(() => {
            this.loadInProgress = false;
          }),
        )
        .subscribe(serviceAction => {
          this.claimItemStore.updateServiceAction();
          if (serviceAction.errorMessage) {
            this.notification.next({
              type: NotificationType.Error,
              message: serviceAction.errorMessage,
              duration: 6000,
            });
          }
          this.dialogRef.close({
            submittedClaimDetailsAuthorizationAvailabilityOrdering: true,
          });
        });
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  partsAvailabilityChanged(): void {
    const areAllPartsAvailable = this.form.get('areAllPartsAvailable');
    if (areAllPartsAvailable.value) {
      this.form.patchValue({
        notAvailableDescription: null,
      });
    } else {
      this.form.patchValue({
        estimatedDeliveryDate: null,
      });
    }
    this.form.get('estimatedDeliveryDate').updateValueAndValidity();
    this.form.get('notAvailableDescription').updateValueAndValidity();
  }

  private _buildPdfLink(pdfLink: string): string {
    const pdfUrl = new URL(pdfLink);
    pdfUrl.searchParams.append('token', this.session.token);
    if (this.session.XTenantId) {
      pdfUrl.searchParams.append('X-TenantId', this.session.XTenantId.toString());
    }
    return pdfUrl.href;
  }
}
