import { Directive, Input, OnInit,TemplateRef, ViewContainerRef } from '@angular/core';

import { Feature } from '../../../../../core/enums/feature.enum';
import { FeatureService } from '../../../../../core/services/feature.service';

@Directive({
  selector: '[appSecureContent]',
})
export class SecureContentDirective implements OnInit {
  features: Feature[];

  @Input() set appSecureContent(features: Feature[]) {
    this.features = features;
  }
  @Input() secureContentOptions: Partial<{some: boolean}> = {};

  constructor(
    private featureService: FeatureService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) {}

  ngOnInit() {
    let isAllowed: boolean;

    if (this.secureContentOptions.some) {
      isAllowed = this.featureService.allowedSome(this.features);
    } else {
      isAllowed = this.featureService.allowedMany(this.features);
    }

    if (isAllowed) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
