import {
  IClaimDetailsModel,
  IServerSmsCommunication,
} from '../../interfaces/claims/claimDetails.interface';
import { OtherClaims } from '../../interfaces/claims/otherClaims.interface';
import { Timeline } from '../../interfaces/claims/timeline.interface';

export namespace StoreState {
  export interface IStoreState {
    claim: IClaimDetailsModel;
    smsHistoryList: IServerSmsCommunication[];
    loading: boolean;
    smsHistoryLoading: boolean;
    documentsFilterLoading: boolean;
    filesLoading: boolean;
    currentId: number;
    previousClaimsIds: number[];
    otherClaims: OtherClaims[];
    timeline: Timeline[];
    files?: any[];
  }

  export const initialState: IStoreState = {
    claim: null,
    smsHistoryList: [],
    currentId: null,
    loading: false,
    smsHistoryLoading: false,
    documentsFilterLoading: false,
    filesLoading: false,
    previousClaimsIds: [],
    otherClaims: [],
    timeline: [],
  };
}
