import { BehaviorSubject } from 'rxjs';

import { AnchorNavigation } from '../interfaces/anchor-navigation/anchor-navigation.interface';

export class AnchorNavigationClass {
  currentAnchor: string;
  currentAnchor$ = new BehaviorSubject<string>(null);
  list: AnchorNavigation[];

  constructor(navigationObject: {currentAnchor: string; list: AnchorNavigation[]}) {
    this.currentAnchor = navigationObject.currentAnchor;
    this.currentAnchor$.next(navigationObject.currentAnchor);
    this.list = navigationObject.list;
  }

  scrollToAnchor(anchor: string) {
    const anchorElement = document.getElementById(`${anchor}`) as HTMLElement;

    if (!anchorElement) {
      return;
    }

    const collapsibleBlock = anchorElement.querySelector('.collapsible-block');
    if (collapsibleBlock) {
      collapsibleBlock.classList.remove('collapsed');
    }
    this.currentAnchor = anchor;
    this.currentAnchor$.next(anchor);
    anchorElement.scrollIntoView({
      behavior: 'smooth',
      inline: 'start',
    });
  }
}
