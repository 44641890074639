import { importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { PlanDetailsDialogService } from '@th-common-retailer/shared/dialogs/plan-details/plan-details-dialog.service';
import { ClaimsPageService } from '@th-common-retailer/shared/pages/claims/claims-page.service';
import { PlansPageService } from '@th-common-retailer/shared/pages/plans/plans-page.service';

import { CoreModule } from '@core/core.module';
import { MainHub } from '@core/services/hubs/main.hub';
import { NavigationService } from '@core/services/navigation.service';
import { PlanService } from '@core/services/plan.service';
import { PipesModule } from '@shared/pipes/pipes.module';

import { RETAILER_APP_ROUTES } from './app.routes';
import { DealerNavigationService } from './core/services/dealer-navigation.service';
import { DetailedQuickSearchService } from './core/services/detailed-quick-search.service';
import { RetailerMainHub } from './core/services/retailer-main.hub';
import { RetailerClaimsPageService } from './pages/claims/retailer-claims-page.service';
import { RetailerPlanDetailsDialogService } from './pages/plans/dialogs/retailer-plan-details-dialog.service';
import { RetailerPlansPageService } from './pages/plans/retailer-plans-page.service';

export const RETAILER_APP = {
  providers: [
    importProvidersFrom(
      CoreModule,
      PipesModule,
    ),
    provideRouter(
      RETAILER_APP_ROUTES,
      // withPreloading(PreloadAllModules),
      // withDebugTracing(),
    ),
    provideAnimations(),
    {
      provide: MainHub,
      useClass: RetailerMainHub,
    },
    {
      provide: NavigationService,
      useClass: DealerNavigationService,
    },
    {
      provide: PlansPageService,
      useExisting: RetailerPlansPageService,
    },
    {
      provide: ClaimsPageService,
      useExisting: RetailerClaimsPageService,
    },
    {
      provide: PlanDetailsDialogService,
      useExisting: RetailerPlanDetailsDialogService,
    },
    DetailedQuickSearchService,
    PlanService,
  ],
};
