<mat-form-field class="form-textarea"
                [class._common-theme-field]="!!formControl.value"
                [color]="'primary'">
  <mat-label *ngIf="label">{{label}}</mat-label>
  <div [class.tooltip__input-block]="tooltipText || tooltipAttentionText">
  <textarea matInput
            [trim]="useTrim"
            [class._common-tooltipText]="tooltipText || tooltipAttentionText"
            [cdkTextareaAutosize]="matTextareaAutosize"
            [cdkAutosizeMinRows]="minRows"
            [cdkAutosizeMaxRows]="maxRows"
            [maxlength]="maxLength"
            [placeholder]="placeholder"
            [formControl]="formControl"></textarea>
  <mat-hint *ngIf="hint">{{hint}}</mat-hint>
    <div *ngIf="tooltipText" class="tooltip tooltip__textarea tooltip__input_app-form-input">
      <mat-icon class="tooltip__icon _common-icon--theme" svgIcon="help_outline"></mat-icon>
      <span class="tooltip__text">{{tooltipText}}</span>
    </div>
    <div *ngIf="tooltipAttentionText" class="tooltip tooltip__textarea tooltip__input_app-form-input">
      <mat-icon class="tooltip__icon _common-icon--theme" svgIcon="attention"></mat-icon>
      <span class="tooltip__text">{{tooltipAttentionText}}</span>
    </div>
  </div>
  <mat-icon class="form-textarea__error-icon"
            *ngIf="formControl.status === 'INVALID' && formControl.touched"
            matSuffix
            svgIcon="error-icon"></mat-icon>

  <mat-error>
    <app-form-error [showIsRequiredMessage]="showIsRequiredMessage"
                    [label]="errorMessage ? errorMessage : label"
                    [formControlRef]="formControl"></app-form-error>
  </mat-error>
</mat-form-field>
