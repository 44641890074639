import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { TDocumentType } from '@core/enums/document-type';
import { IUploadedFile } from '@core/interfaces/claims/files.interface';
import { FilesService } from '@core/services/files.service';

@Directive({
  selector: '[appFileDragAndDrop]',
  standalone: true,
})
export class FileDragAndDropDirective {
  @Input() accept: string;
  @Input() docType: TDocumentType;
  @Input() fileControlName: string;

  @Output() filesDropped = new EventEmitter<IUploadedFile[]>();

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    this.preventDefaults(event);
    this.highlightArea(event);
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event: DragEvent) {
    this.preventDefaults(event);
    this.highlightArea(event);
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
    this.preventDefaults(event);
    this.unHighlightArea();
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    this.preventDefaults(event);
    this.unHighlightArea();
    this.filesService.handleFilesInput(event, {
      docType: this.docType,
      fileControlName: this.fileControlName,
    }, this.accept).subscribe(droppedFiles => {
      this.filesDropped.emit(droppedFiles);
    });
  }

  constructor(
    private readonly el: ElementRef,
    private readonly filesService: FilesService,
  ) {
  }

  private preventDefaults(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  private highlightArea(event: DragEvent) {
    if (this.el.nativeElement.classList.contains('disabled')) {
      event.dataTransfer.dropEffect = 'none';
    }
    this.el.nativeElement.classList.add('highlight');
  }

  private unHighlightArea() {
    this.el.nativeElement.classList.remove('highlight');
  }
}
