import { AfterViewInit, Directive, EventEmitter, Input, NgZone, OnDestroy, Output, Self } from '@angular/core';
import { MatLegacySelect } from '@angular/material/legacy-select';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appMatSelectInfiniteScroll]',
  standalone: true,
})
export class MatSelectInfiniteScrollDirective implements OnDestroy, AfterViewInit {
  @Input() debounceTime = 100;
  @Input() loading = false;
  @Input() disableInfinite = false;
  @Output() scrolled = new EventEmitter<void>();

  private panel!: HTMLElement;

  private destroyed$ = new Subject<boolean>();

  constructor(@Self() private matSelect: MatLegacySelect, private ngZone: NgZone) {
  }

  ngAfterViewInit() {
    if (!this.disableInfinite) {
      this.matSelect.openedChange.pipe(
        takeUntil(this.destroyed$),
      ).subscribe((opened) => {
        if (opened) {
          this.panel = this.matSelect.panel.nativeElement;
          this.registerScrollListener();
        }
      });
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  registerScrollListener() {
    fromEvent(this.panel, 'scroll').pipe(
      debounceTime(this.debounceTime),
      takeUntil(this.destroyed$),
    ).subscribe(event => {
      this.handleScrollEvent(event);
    });
  }

  handleScrollEvent(event: any) {
    this.ngZone.runOutsideAngular(() => {
      if (this.loading) {
        return;
      }

      if (this.panel.scrollHeight - this.panel.scrollTop - 100 <= this.panel.offsetHeight) {
        this.scrolled.emit();
      }
    });
  }

}
