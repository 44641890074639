import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ClaimItemStore } from '../../../../../core/store/claim-item/claim-item.store';

@Component({
  selector: 'app-previous-claim-cell',
  templateUrl: './previous-claim-cell.component.html',
  styleUrls: ['./previous-claim-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviousClaimCellComponent {
  public data: any;
  public fieldName: string;

  constructor(private readonly claimItemStore: ClaimItemStore) {}

  openPreviousClaimDetails(id: number) {
    this.claimItemStore.openNewClaim(id);
  }
}
