import { AsyncPipe, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { FormSelectComponent } from '@shared/components/form-select/form-select.component';
import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';

import { SelectProductComponent } from './components/select-product/select-product.component';
import { SelectProductDialogComponent } from './components/select-product-dialog/select-product-dialog.component';

@NgModule({
  declarations: [
    SelectProductComponent,
    SelectProductDialogComponent,
  ],
  imports: [
    AsyncPipe,
    FormSelectComponent,
    FormsModule,
    LoadingButtonDirective,
    MatButtonModule,
    MatIconModule,
    NgIf,

  ],
  exports: [
    SelectProductComponent,
    SelectProductDialogComponent,
  ],
})
export class ClaimDialogsCoreModule {}
