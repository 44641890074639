import { Injectable } from '@angular/core';

import { ClaimType } from '../enums/claim-type.enum';
import { IRetailer } from '../interfaces/stores/retailer.interface';
import { CrudApiBaseService } from './crud-api.base';

@Injectable({ providedIn: 'root' })
export class StoresApi extends CrudApiBaseService<IRetailer> {
  endpoint = 'retailer';

  getDefaultServicingRetailer(purchaseRetailerId: number, claimType: ClaimType) {
    return this.httpClient.get<IRetailer>(this.getUrl(`${purchaseRetailerId}/defaultServicingRetailer`), {
      params: {
        claimType: claimType.toString(),
      },
    });
  }

  getCaliforniaStateLicenseData(retailerId: number) {
    return this.httpClient.get<any>(this.getUrl(`${retailerId}/fpp-license/last-acknowledged`));
  }

  postCaliforniaStateLicenseData(retailerId: number) {
    return this.httpClient.post<any>(this.getUrl(`${retailerId}/fpp-license/acknowledge-license`), {});
  }

  getValidateFppRetailers() {
    return this.httpClient.get<{allRetailersOnHold: boolean; allRetailersDontHaveFloridaLicenses: boolean}>(
      this.getUrl('validatefppretailers'),
    );
  }
}
