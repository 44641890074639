import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

import { ClaimApiService } from '../api/claim-api.service';
import { DocumentApi } from '../api/document.api';
import { ClaimServiceInterface } from '../interfaces/claims/claim-service.interface';
import { Files } from '../interfaces/claims/files.interface';

@Injectable({
  providedIn: 'root',
})
export class ClaimService implements ClaimServiceInterface {
  constructor(
    private documentsApiService: DocumentApi,
    private claimApiService: ClaimApiService,
    private notification: NotificationService,
  ) {}

  getClaimDetails(claimId: number) {
    return this.claimApiService.getClaimDetails(claimId).pipe(
      catchError(error => {
        if (error.status === 404) {
          this.notification.next({
            message: 'You do not have access to this claim.',
            type: NotificationType.Error,
          });
        }
        return throwError(error);
      }),
    );
  }

  getFiles(claimId: number) {
    return this.claimApiService.getFiles(claimId);
  }

  uploadFiles(filesToUpload: Files[], claimId: number) {
    return this.documentsApiService.uploadFiles(filesToUpload, claimId).pipe(
      tap(() => {
        this.notification.next({
          message: 'Your file(s) have been uploaded and will show up on the claim shortly.',
          type: NotificationType.Success,
        });
      }),
    );
  }

  saveNote(claimId: number, note: string) {
    return this.claimApiService.saveNote(claimId, note);
  }

  getOtherClaims(consumerId: number, exclude: number) {
    return this.claimApiService.getOtherClaims(consumerId, exclude);
  }

  resetForRetailerReview(claimId: number) {
    return this.claimApiService.resetForRetailerReview(claimId);
  }

  getSmsHistoryForClaim(claimId: number) {
    return this.claimApiService.getSmsHistoryForClaim(claimId);
  }
}
