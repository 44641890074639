import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, FormControl,ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { RxState } from '@rx-angular/state';
import { take, tap } from 'rxjs/operators';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { DateKindProtectionPlan } from '@core/enums/claim/date-kind-protection-plan.enum';
import { Feature } from '@core/enums/feature.enum';
import { TPortalType } from '@core/enums/portal-type.enum';
import { Identity } from '@core/interfaces/util/identity.interface';
import { PlansStore } from '@core/store/plans/plans.store';
import { RetailersListStore } from '@core/store/retailersList/retailersList.store';
import { DateValidation } from '@core/utils/form.util';
import { DateRangeSearchComponent } from '@shared/components/date-range-search/date-range-search.component';
import { FormSelectComponent } from '@shared/components/form-select/form-select.component';
import { RegionsSelectComponent } from '@shared/components/regions-select/regions-select.component';
import { AccessControlModule } from '@shared/modules/access-control/access-control.module';
import {
  CollapsibleBlockComponent,
} from '@shared/modules/collapsible/components/collapsible-block/collapsible-block.component';
import {
  RetailerSelectComponent,
} from '@shared/modules/store-controls/components/retailer-select/retailer-select.component';

interface IPlansAdvancedFilterState {
  dateKinds: Identity[];
}

@Component({
  selector: 'app-plans-advanced-filter',
  standalone: true,
  templateUrl: './plans-advanced-filter.component.html',
  styleUrls: ['./plans-advanced-filter.component.scss'],
  providers: [RxState, RetailersListStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CollapsibleBlockComponent,
    RetailerSelectComponent,
    FormSelectComponent,
    AsyncPipe,
    MatButtonModule,
    ReactiveFormsModule,
    RegionsSelectComponent,
    DateRangeSearchComponent,
    AccessControlModule,
    NgIf,
  ],
})
export class PlansAdvancedFilterComponent {
  form = this.fb.group({
    retailerId: new FormControl(null),
    regionId: new FormControl(null),
    fromDate: new FormControl('', DateValidation),
    toDate: new FormControl('', DateValidation),
    dateKind: new FormControl(null),
  });
  dateRange = null;
  feature = Feature;
  dateKinds$ = this.state.select('dateKinds');
  appConfig = inject(APP_CONFIG);

  protected readonly TPortalType = TPortalType;

  private _defaultFilterValues = {
    retailerId: null,
    regionId: null,
    fromDate: '',
    toDate: '',
    dateKind: null,
  };

  constructor(
    private readonly fb: FormBuilder,
    private readonly plansStore: PlansStore,
    private readonly state: RxState<IPlansAdvancedFilterState>,
    private readonly retailersListStore: RetailersListStore,
  ) {
    this.retailersListStore.loadData();
    this.plansStore
      .select$('searchRequest')
      .pipe(take(1))
      .subscribe(searchRequest => {
        this.form.patchValue(searchRequest);
      });

    this.state.set({
      dateKinds: [
        { id: DateKindProtectionPlan.RegistrationDate, name: 'Registration Date' },
        { id: DateKindProtectionPlan.PurchaseDate, name: 'Purchase Date' },
        { id: DateKindProtectionPlan.DeliveryDate, name: 'Delivery Date' },
        { id: DateKindProtectionPlan.InvoiceDate, name: 'Invoice Date' },
        { id: DateKindProtectionPlan.ReturnDate, name: 'Return Date' },
      ].filter(item => {
        if (this.appConfig.portalType === TPortalType.MJC) {
          return item.id !== DateKindProtectionPlan.DeliveryDate;
        } else {
          return true;
        }
      }),
    });

    if (this.appConfig.portalType !== TPortalType.MJC) {
      this.state.connect(
        this.form.get('regionId').valueChanges.pipe(
          tap(regionId => {
            this.form.patchValue({
              retailerId: null,
            });
            this.retailersListStore.updateSearchRequest({
              regionId,
              page: 1,
            });
            this.retailersListStore.loadData();
          }),
        ),
      );
    }
  }

  search() {
    this.plansStore.filterAdvancedSearch(this.form.value);
  }

  clear() {
    this.form.patchValue({ ...this._defaultFilterValues });
    this.dateRange = null;
  }
}
