<app-form-select [data]="dateRangeTypes"
                 [(ngModel)]="dateRangeType"
                 [ngModelOptions]="{standalone: true}"
                 [valueField]="'value'"
                 [titleField]="'title'"
                 [label]="'Date Range'"
                 (itemSelected)="dateRangeTypeChanged()"></app-form-select>
<div *ngIf="dateRangeType === rangeTypes.Custom">
  <div class="date-range-search__wrapper-from-date">
    <app-form-date formControlName="fromDate"
                   [label]="'Start'"
                   [min]="minDate"
                   [readonly]="true"
                   [max]="formGroup.value.toDate"></app-form-date>
  </div>
  <app-form-date formControlName="toDate"
                 [label]="'End'"
                 [readonly]="true"
                 [min]="formGroup.value.fromDate"></app-form-date>
</div>
