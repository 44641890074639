<div class="dialog dialog--short">
  <div class="dialog__header dialog__header--flex">
    <h3 class="dialog__title">View {{type}}</h3>
    <mat-icon class="dialog__close" svgIcon="close" (click)="close()"></mat-icon>
  </div>
  <div appCustomOverlayScrollbar>
    <div class="dialog__content">
      <table>
        <tr>
          <td><b>Subject</b></td>
          <td>{{data.subject}}</td>
        </tr>
        <tr>
          <td><b>Sent</b></td>
          <td *ngIf="!loading">{{data.createDate | appUserTimezone:'MM/DD/YYYY hh:mm A'}}</td>
        </tr>
        <tr>
          <td><b>Content</b></td>
          <td *ngIf="!loading" class="letters-modal__content" [innerHtml]="!!data.content ? data.content : 'No data.'"></td>
        </tr>
        <tr *ngIf="showAttachments">
          <td><b>Attachments</b></td>
          <td *ngIf="!loading">
            <ng-container *ngFor="let attachment of attachments; let i = index">
              <a [href]="attachment.url" target="_blank">
                {{i> 0 ? ', ': ''}}{{attachment.fileName}}
              </a>
            </ng-container>
            <p *ngIf="attachments.length === 0">No attachments</p>
          </td>
        </tr>
      </table>
    <div class="modal-loader" *ngIf="loading">
      <app-loader class="base-loader"></app-loader>
    </div>
  </div>
  </div>
</div>
