export class ScrollUtil {
  static scrollTo(scrollElement: HTMLElement, scrollTop: number) {
    if (scrollElement.scroll) {
      // IE hack
      scrollElement.scroll({
        top: scrollTop,
        behavior: 'smooth',
      });
    } else {
      scrollElement.scrollTop = scrollTop;
    }
  }
}
