import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Communication } from '../../../../../core/interfaces/claims/claimDetails.interface';
import { LettersModalComponent } from '../letters-modal/letters-modal.component';

@Component({
  selector: 'app-email-contents-grid-cell',
  templateUrl: './email-contents-grid-cell.component.html',
  styleUrls: ['./email-contents-grid-cell.component.scss'],
})
export class EmailContentsGridCellComponent implements OnInit {
  data: Communication;
  field: string;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openEmailContent(): void {
    this.dialog.open(LettersModalComponent, {
      data: this.data,
    });
  }
}
