import { NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { TTableOptionAction } from '@shared/modules/table/types/table-option-action.enum';

@Component({
  selector: 'app-table-cell-toolbar, th-table-cell-toolbar',
  standalone: true,
  templateUrl: './table-cell-toolbar.component.html',
  styleUrls: ['./table-cell-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgForOf,
    MatIconModule,
    MatMenuModule,
  ],
})
export class ThTableCellToolbarComponent {
  /**
   * List of available actions for a user
   */
  @Input() actions: TTableOptionAction[];

  /**
   * Emits an event on action click
   */
  @Output() actionClick = new EventEmitter<TTableOptionAction>();

  TTableOptionAction = TTableOptionAction;

  /**
   * Icon that is shown on the action button
   */
  iconsMap = new Map([
    [TTableOptionAction.Edit, 'edit'],
    [TTableOptionAction.Delete, 'delete'],
  ]);

  /**
   * Icon that is shown on the action button
   */
  titleMap = new Map([
    [TTableOptionAction.Edit, 'Edit'],
    [TTableOptionAction.Delete, 'Delete'],
  ]);
}
