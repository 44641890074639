import { NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyRadioChange as MatRadioChange, MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { RxState } from '@rx-angular/state';
import { PushPipe } from '@rx-angular/template/push';

import { UserStore } from '@core/store/user/user.store';
import { TCommonFieldSize } from '@shared/components/form-field/common-field-size.enum';
import { FormInputComponent } from '@shared/components/form-input/form-input.component';
import { ReadonlyControlDirective } from '@shared/directives/readonly-control.directive';
import { EmailValidationDirective } from '@shared/directives/validators/email-validation.directive';
import { ThFormFieldComponent } from '@shared/form/form-field/form-field.component';
import { ThFormFieldBlockComponent } from '@shared/form/th-form-field-block/th-form-field-block.component';
import { ClaimControlsModule } from '@shared/modules/claim-controls/claim-controls.module';
import { LabelValueItemComponent } from '@shared/standalone/label-value-item/label-value-item.component';

interface IConsumerTemplateFormState {
  emailDefault: string;
  emailCustom: string;
  activeInput: 'default' | 'custom';
}

@Component({
  selector: 'app-consumer-template-form',
  templateUrl: './consumer-template-form.component.html',
  styleUrls: ['./consumer-template-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ClaimControlsModule,
    EmailValidationDirective,
    MatIconModule,
    MatLegacyRadioModule,
    ReactiveFormsModule,
    NgIf,
    PushPipe,
    FormsModule,
    FormInputComponent,
    ReadonlyControlDirective,
    ThFormFieldComponent,
    ThFormFieldBlockComponent,
    LabelValueItemComponent,
  ],
  providers: [RxState],
})
export class ConsumerTemplateFormComponent implements OnInit, AfterViewInit {
  @Input() formGroupRef: FormGroup;
  @Input() hiddenBlocks: string[] = [];
  @Input() blocked: boolean;
  @Input() useRadio = true;
  @Input() phonesRequired = true;

  @ViewChild('customEmailInput') customEmailInput: FormInputComponent<string>;

  tooltipPhones = 'At least one number is required.';

  emailDefault$ = this.state.select('emailDefault');
  emailCustom$ = this.state.select('emailCustom');
  activeInput$ = this.state.select('activeInput');

  readonly TCommonFieldSize = TCommonFieldSize;

  constructor(private readonly userStore: UserStore, private readonly state: RxState<IConsumerTemplateFormState>) {
    this.state.set({
      emailDefault: '',
      emailCustom: '',
    });
  }

  ngOnInit(): void {
    if (this.useRadio) {
      const defaultEmail = this.userStore.get('session').username;
      let consumerEmail = this.formGroupRef.value.emailAddress;
      if (!consumerEmail) {
        consumerEmail = defaultEmail;
        this.formGroupRef.patchValue({
          emailAddress: defaultEmail,
        });
      }
      const emailCustom = defaultEmail === consumerEmail ? '' : consumerEmail;
      const activeInput = defaultEmail === consumerEmail ? 'default' : 'custom';
      this.state.set({
        emailDefault: defaultEmail,
        emailCustom,
        activeInput,
      });
    }
  }

  ngAfterViewInit() {
    if (this.useRadio) {
      const activeInput = this.state.get('activeInput');
      this.state.connect('emailCustom', this.customEmailInput.ngControl.valueChanges);
      if (activeInput === 'custom') {
        this.customEmailInput.ngControl.control.markAsTouched();
      }
    }
  }

  emailChanged($event: MatRadioChange) {
    if (this.useRadio) {
      if ($event instanceof MatRadioChange) {
        this.state.set({
          activeInput: $event.source.id as 'default' | 'custom',
        });
        if ($event.source.id === 'default') {
          this.customEmailInput.ngControl.control.markAsUntouched();
        } else {
          this.customEmailInput.ngControl.control.markAsTouched();
        }
      }
    }
  }

  customEmailChanged() {
    const activeInput = this.state.get('activeInput');
    if (!!this.customEmailInput && activeInput === 'custom') {
      this.formGroupRef.patchValue({
        emailAddress: this.customEmailInput.ngControl.value,
      });
    }
  }
}
