import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormControlState,
  FormGroup,
  UntypedFormControl,
  ValidatorFn,
} from '@angular/forms';
import moment from 'moment-timezone';

import { TContentType } from '@core/enums/content-type.enum';
import { UserScope } from '@core/enums/user-scope.enum';

export type ToFormGroup<T> = {
  [P in keyof T]: ToFormControl<T[P]>;
};
export type ToFormBuilderGroup<T> = {
  [P in keyof T]: ToFormBuilderControl<T[P]>;
};

export type ToFormControl<T> = T extends Array<infer ArrayType> ? FormArray<ToFormControl<ArrayType>> : T extends object ? FormGroup<ToFormGroup<T>> : FormControl<T | null>;
// eslint-disable-next-line max-len
export type ToFormBuilderControl<T> = T extends Array<infer ArrayType> ? ArrayType extends object ? FormArray<ToFormControl<ArrayType>> : Array<(FormControlState<ArrayType[]> | ArrayType[] | null | ValidatorFn)> : T extends object ? FormGroup<ToFormGroup<T>> : Array<(FormControlState<T> | T | null | ValidatorFn)>;


export function validateConfirmPassword(c: UntypedFormControl) {
  if (c.root && c.root.get('newPassword')) {
    const newPassword = c.root.get('newPassword');
    if (!newPassword || !c.value || newPassword.value === c.value) {
      return null;
    } else {
      return {
        matchNewPassword: {
          message: 'New password and confirmation password do not match.',
        },
      };
    }
  } else {
    return null;
  }
}

export function validateExactLength(exactLength: number) {
  return (c: UntypedFormControl) => {
    if (!!c.value && c.value.length !== exactLength) {
      return {
        exactLength: { requiredLength: exactLength, actualLength: c.value.length },
      };
    } else {
      return null;
    }
  };
}

export function validateMinLengthWithCharsAndNumbers(minLength: number) {
  return (c: UntypedFormControl) => {
    if (c.value) {
      const regexMatch = c.value.match(/[a-z0-9]+/gi);
      if (!!regexMatch && regexMatch.join().length < minLength) {
        return {
          minLengthWithoutSpace: {
            requiredLength: minLength,
            actualLength: c.value.length,
            message: 'You must enter at least five characters in order to search.',
          },
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
}

export function DateValidation(control: AbstractControl) {
  let dateValue;
  if (!control.value) {
    return null;
  }

  if (typeof control.value === 'function') {
    dateValue = control.value();
  } else {
    dateValue = control.value;
  }

  const momentDate = moment(new Date(dateValue), 'MM/DD/YYYY', true);
  if (momentDate.isValid() && momentDate.year().toString().length === 4) {
    return null;
  } else {
    return { dateIsNotValid: true };
  }
}

export function ValidateFeaturesScope(scopeControl: UntypedFormControl) {
  if (scopeControl.root && scopeControl.root.get('contentType')) {
    const contentType = scopeControl.root.get('contentType');
    if (scopeControl.value === UserScope.Retailer) {
      if (contentType.value === TContentType.MS || contentType.value === TContentType.PP || contentType.value === TContentType.Both) {
        return null;
      } else {
        return {
          isAllowedScope: true,
        };
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function handleServerErrors(form: FormGroup, httpResponse: HttpErrorResponse) {
  if (httpResponse.status === HttpStatusCode.BadRequest) {
    const validationErrors = httpResponse.error.errors;
    Object.keys(validationErrors).forEach(fieldKey => {
      const formControl = form.get(fieldKey);
      if (formControl) {
        formControl.setErrors({
          serverError: validationErrors[fieldKey],
        });
      }
    });
  }
}
