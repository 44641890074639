import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { TCommonFieldSize } from '@shared/components/form-field/common-field-size.enum';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'th-form-field',
  standalone: true,
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThFormFieldComponent {
  @Input() size: TCommonFieldSize | number = 12;

  @HostBinding('style.grid-column')
  get formFieldGridSize(): string {
    return `span ${this.size}`;
  }
}
