import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

import { TokenApi } from '../api/token.api';
import { PasswordMatchesInterface } from '../interfaces/password/password-matches.interface';
import { PasswordOptionsInterface } from '../interfaces/password/password-options.interface';
import { PasswordMatchOptionModel } from '../models/password/password-match-option.model';
import { PasswordMatchesModel } from '../models/password/password-matches.model';

@Injectable({
  providedIn: 'root',
})
export class PasswordService {
  passwordOptions: BehaviorSubject<PasswordOptionsInterface> = new BehaviorSubject<PasswordOptionsInterface>({
    requiredLength: 6,
    requiredUniqueChars: 1,
    requireNonAlphanumeric: true,
    requireLowercase: true,
    requireUppercase: true,
    requireDigit: true,
  });
  passwordMatchesSubject: BehaviorSubject<PasswordMatchesInterface> = new BehaviorSubject<PasswordMatchesInterface>(
    new PasswordMatchesModel(),
  );
  passwordMatches: PasswordMatchesInterface = new PasswordMatchesModel();

  constructor(private tokenApi: TokenApi) {}

  fetchPasswordOptions() {
    this.tokenApi.passwordOptions().subscribe((passwordOptions: PasswordOptionsInterface) => {
      this.passwordOptions.next(passwordOptions);
    });
  }

  validatePassword(password: string) {
    const passwordOptions: PasswordOptionsInterface = this.passwordOptions.getValue();
    const nonAlphanumericRegEx = /[^a-zA-Z0-9_]/g;
    const lowerCaseRegEx = /[a-z]/g;
    const upperCaseRegEx = /[A-Z]/g;
    const digitRegEx = /[0-9]/g;
    if (password) {
      const unique = password
        .split('')
        .filter((item, i, ar) => ar.indexOf(item) === i)
        .join('');
      const lengthCondition = password.length >= passwordOptions.requiredLength;
      const uniqueCondition = unique.length >= passwordOptions.requiredUniqueChars;
      this.passwordMatches.requiredLength = new PasswordMatchOptionModel(
        lengthCondition,
        passwordOptions.requiredLength > 0,
      );
      this.passwordMatches.requireNonAlphanumeric = new PasswordMatchOptionModel(
        nonAlphanumericRegEx.test(password),
        passwordOptions.requireNonAlphanumeric,
      );
      this.passwordMatches.requireLowercase = new PasswordMatchOptionModel(
        lowerCaseRegEx.test(password),
        passwordOptions.requireLowercase,
      );
      this.passwordMatches.requireUppercase = new PasswordMatchOptionModel(
        upperCaseRegEx.test(password),
        passwordOptions.requireUppercase,
      );
      this.passwordMatches.requireDigit = new PasswordMatchOptionModel(
        digitRegEx.test(password),
        passwordOptions.requireDigit,
      );
      this.passwordMatches.requiredUniqueChars = new PasswordMatchOptionModel(
        uniqueCondition,
        passwordOptions.requiredUniqueChars > 0,
      );
    } else {
      this.passwordMatches = new PasswordMatchesModel();
    }
    this.passwordMatchesSubject.next({ ...this.passwordMatches });
  }

  getPasswordMatches(): Observable<PasswordMatchesInterface> {
    return this.passwordMatchesSubject.asObservable();
  }

  getPasswordOptions(): Observable<PasswordOptionsInterface> {
    return this.passwordOptions.asObservable();
  }

  passwordMatch(controlName: string): {[key: string]: any} | null {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const confirmControl = control.root.get('confirmPassword');
      this.validatePassword(control.value);
      if (control.errors && !control.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      const matches = this.passwordMatches;
      let match = true;
      for (const key in matches) {
        if (matches.hasOwnProperty(key)) {
          if (matches[key].required && !matches[key].match) {
            match = false;
            break;
          }
        }
      }
      if (!match) {
        control.setErrors({ criteriaDoesNotMeet: true });
      } else {
        control.setErrors(null);
      }

      if (confirmControl) {
        confirmControl.updateValueAndValidity({
          onlySelf: true,
        });
      }
    };
  }
}
