import { NgIf } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { parseZone } from 'moment';
import moment from 'moment-timezone';

import { DestroyableComponent } from '@core/classes/destroyable-component';
import { AppConstants } from '@core/constants/app.constants';
import { APP_CONFIG } from '@core/constants/app-config.constants';
import { TDateRangeType } from '@core/enums/date-range-type.enum';
import { TPortalType } from '@core/enums/portal-type.enum';
import { DateRangeService } from '@core/services/date-range.service';
import { FormDateComponent } from '@shared/components/form-date/form-date.component';
import { FormSelectComponent } from '@shared/components/form-select/form-select.component';

@Component({
  selector: 'app-date-range-search',
  standalone: true,
  templateUrl: './date-range-search.component.html',
  styleUrls: ['./date-range-search.component.scss'],
  imports: [
    FormSelectComponent,
    FormDateComponent,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class DateRangeSearchComponent extends DestroyableComponent {
  minDate: any = AppConstants.minDateDatepicker;
  appConfig = inject(APP_CONFIG);

  @Input() useAdditionalDay = true;
  @Input() set dateRange(value: TDateRangeType) {
    this.dateRangeType = value;
    this.dateRangeTypeChanged();
  }
  @Input() newDesign = false;
  @Output() dateRangeTypeChange = new EventEmitter<TDateRangeType>();

  dateRangeTypes = [
    { title: 'Yesterday', value: TDateRangeType.Yesterday },
    { title: 'Last 7 days', value: TDateRangeType.LastSevenDays },
    { title: 'Last 30 days', value: TDateRangeType.LastThirtyDays },
    { title: 'Month to Date', value: TDateRangeType.LastMonth },
    { title: 'Next 30 days', value: TDateRangeType.NextThirtyDays },
    { title: 'Last 28 hours', value: TDateRangeType.Last28Hours },
    { title: 'Over 28 hours ago', value: TDateRangeType.Over28HoursAgo },
    { title: 'Custom', value: TDateRangeType.Custom },
  ].filter((item) => {
    if (this.appConfig.portalType !== TPortalType.TechPortal) {
      return item.value !== TDateRangeType.Last28Hours && item.value !== TDateRangeType.Over28HoursAgo;
    } else {
      return item;
    }
  });

  rangeTypes = TDateRangeType;

  dateRangeType: TDateRangeType = null;

  constructor(
    private readonly dateRangeService: DateRangeService,
    public readonly formGroup: ControlContainer,
  ) {
    super();
  }

  dateRangeTypeChanged(): void {
    this.dateRangeTypeChange.emit(this.dateRangeType);
    if (!this.dateRangeType) {
      this.formGroup.control.patchValue({
        fromDate: '',
        toDate: '',
      });
      return;
    }
    if (this.dateRangeType === TDateRangeType.Custom) {
      const fromDate = this.formGroup.control.value.fromDate;
      const toDate = this.formGroup.control.value.toDate;
      this.formGroup.control.patchValue({
        fromDate: this._parseFromDate(fromDate),
        toDate: this._parseToDate(toDate),
      });
    } else {
      const [fromDate, toDate] = this.dateRangeService.dateRangeToFromToDate(this.dateRangeType);
      this.formGroup.control.patchValue({
        fromDate: this._parseFromDate(fromDate),
        toDate: this._parseToDate(toDate),
      });
    }
  }

  private _parseFromDate(fromDate: string): string {
    if (fromDate) {
      return parseZone(fromDate).utc(true).startOf('day').format();
    } else {
      return moment().utc(true).startOf('day').format();
    }
  }

  private _parseToDate(toDate: string): string {
    if (toDate) {
      return parseZone(toDate).utc(true).endOf('day').format();
    } else {
      return this.useAdditionalDay
        ? moment().utc(true).add(1, 'days').endOf('day').format()
        : moment().utc(true).endOf('day').format();
    }
  }
}
