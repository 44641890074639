import { Injectable } from '@angular/core';
import moment from 'moment-timezone';

import { TDateRangeType } from '@core/enums/date-range-type.enum';

@Injectable({ providedIn: 'root' })
export class DateRangeService {

  dateRangeToFromToDate(dateRangeType: TDateRangeType): [string, string] {
    switch (dateRangeType) {
      case TDateRangeType.Yesterday:
        return [this._getStartOfLastDays(1), this._getStartOfLastDays(1)];
      case TDateRangeType.LastSevenDays:
        return [this._getLastDays(6), moment().utc(false).format()];
      case TDateRangeType.LastThirtyDays:
        return [this._getLastDays(29), moment().utc(false).format()];
      case TDateRangeType.LastMonth:
        return [this._getMonthStartDate(), this._getTodayStartDate()];
      case TDateRangeType.NextThirtyDays:
        return this._getNextThirtyDays();
      case TDateRangeType.Last28Hours:
        return [this._getLastHours(28), moment().utc(false).format()];
      case TDateRangeType.Over28HoursAgo:
        return [this._getLongTimeAgo(), this._getLastHours(28)];
      default:
        return ['', ''];
    }
  }

  private _getStartOfLastDays(daysNumber: number): string {
    return moment()
      .utc(true)
      .startOf('day')
      .add(daysNumber * -1, 'days')
      .format();
  }

  private _getLastHours(hours: number): string {
    return moment()
      .utc(false)
      .add(hours * -1, 'hours')
      .format();
  }

  private _getLastDays(daysNumber: number): string {
    return moment()
      .utc(false)
      .add(daysNumber * -1, 'days')
      .format();
  }

  private _getLongTimeAgo(): string {
    return moment('2000-01-01T00:00:00.00Z')
      .utc(false)
      .format();
  }

  private _getMonthStartDate(): string {
    return moment().utc(true).startOf('month').format();
  }

  private _getTodayStartDate() {
    return moment().utc(true).startOf('day').format();
  }

  private _getNextThirtyDays(): [string, string] {
    const today = moment().utc(false).startOf('day').format();
    const todayPlus30Days = moment().utc(false).add(30, 'days').startOf('day').format();
    return [today, todayPlus30Days];
  }
}
