export enum TDateRangeType {
  Yesterday = 1,
  LastSevenDays,
  LastThirtyDays,
  LastMonth,
  Custom,
  LastFortyFiveDays,
  PreviousMonth,
  NextThirtyDays,
  Last28Hours,
  Over28HoursAgo,
}
