import { Component, OnInit } from '@angular/core';

import { AppConstants } from '../../../../../core/constants/app.constants';

@Component({
  selector: 'app-claim-status-cell',
  templateUrl: './claim-status-cell.component.html',
  styleUrls: ['./claim-status-cell.component.scss'],
})
export class ClaimStatusCellComponent implements OnInit {
  data: any;
  fieldName: string;
  claimStatuses = AppConstants.claimStatuses;

  constructor() {}

  ngOnInit() {}
}
