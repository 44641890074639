import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';

import { EnhancementApiService } from '@core/api/enhancement-api.service';
import { IEnhancement } from '@core/interfaces/enhancement/enhancement.interface';
import { Pagination } from '@core/interfaces/ui/pagination.interface';
import { IDialogNews } from '@core/services/dialog.service';
import { UserStore } from '@core/store/user/user.store';

@Component({
  selector: 'app-news-dialog',
  templateUrl: './news-dialog.component.html',
  styleUrls: ['./news-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsDialogComponent {
  session$ = this.userStore.select$('session');

  title;
  contentText;
  buttonClose;
  closeButtonIsAvailable = false;
  enhancementsList: IEnhancement[];
  enhancementCurrent: IEnhancement;
  enhancementsIdsListMarkedAsSeen: number[] = [];
  pagination: Pagination = {
    totalCount: 1,
    page: 1,
    pageCount: 5,
  };
  pageSize = 1;
  hidePages = false;
  includeSeen = false;

  constructor(
    public dialogRef: MatDialogRef<any>,
    private readonly userStore: UserStore,
    @Inject(MAT_DIALOG_DATA) public data: {data: {enhancementsList: IEnhancement[]; dialogConfig: Partial<IDialogNews>}},
    private readonly enhancementApiService: EnhancementApiService,
  ) {
    if (data) {
      if (data.data.dialogConfig.title) {
        this.title = data.data.dialogConfig.title;
      }
      if (data.data.dialogConfig.contentText) {
        this.contentText = data.data.dialogConfig.contentText;
      }
      if (data.data.dialogConfig.buttonClose) {
        this.buttonClose = data.data.dialogConfig.buttonClose;
      }
      if (data.data.dialogConfig.closeButtonIsAvailable) {
        this.closeButtonIsAvailable = data.data.dialogConfig.closeButtonIsAvailable;
        this.includeSeen = data.data.dialogConfig.closeButtonIsAvailable;
      }
      this.enhancementsList = data.data.enhancementsList;
      this.pagination.totalCount = this.enhancementsList.length;
      this.pagination.pageCount = Math.ceil(this.enhancementsList.length / this.pageSize);
      this.enhancementCurrent = { ...this.enhancementsList[this.pagination.page - 1] };
      const impersonatedUser = Object.keys(this.userStore.get('session')).includes('XTenantId');
      if (this.includeSeen && !impersonatedUser) {
        // opened the Dialog from Dashboard-page
        // this.requestMarkOneEnhancementAsSeen(this.enhancementCurrent.id); // 500 error
      }
      if (this.pagination.pageCount === 1) {
        this.closeButtonIsAvailable = true;
      }
    }
  }

  changePage(newPage: number) {
    const pagination = { ...this.pagination };
    pagination.page = newPage;
    this.pagination = { ...pagination };
    this.enhancementCurrent = { ...this.enhancementsList[pagination.page - 1] };
    if (this.pagination.page === this.pagination.pageCount) {
      // user is at the last page
      this.closeButtonIsAvailable = true;
    }
    const impersonatedUser = Object.keys(this.userStore.get('session')).includes('XTenantId');
    if (this.includeSeen && !impersonatedUser && !this.enhancementIsSeenAndSent(this.enhancementCurrent.id)) {
      // this.requestMarkOneEnhancementAsSeen(this.enhancementCurrent.id); // 500 error
    }
  }

  closeHandler() {
    if (this.includeSeen === false) {
      // opened the Dialog from Login-page
      this.requestMarkAllEnhancementsAsSeen();
    }
    const impersonatedUser = Object.keys(this.userStore.get('session')).includes('XTenantId');
    if (this.includeSeen && !impersonatedUser) {
      // temporary solution - until BE fix (1) error
      this.requestMarkAllEnhancementsAsSeen();
    }

    this.dialogRef.close();
  }

  requestMarkOneEnhancementAsSeen(enhancementId: number) {
    this.enhancementApiService.markOneAsSeen(enhancementId).subscribe();
    this.markEnhancementAsSeenAndSent(enhancementId);
  }

  requestMarkAllEnhancementsAsSeen() {
    this.enhancementApiService.markAllAsSeen().subscribe();
  }

  enhancementIsSeenAndSent(enhancementId: number): boolean {
    return this.enhancementsIdsListMarkedAsSeen.includes(enhancementId);
  }

  markEnhancementAsSeenAndSent(enhancementId: number) {
    if (this.enhancementsIdsListMarkedAsSeen.includes(enhancementId) === false) {
      this.enhancementsIdsListMarkedAsSeen.push(enhancementId);
    }
  }
}
