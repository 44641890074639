import { Injectable } from '@angular/core';

import { Identity } from '../interfaces/util/identity.interface';
import { CrudApiBaseService } from './crud-api.base';

@Injectable({
  providedIn: 'root',
})
export class CommunicationApiService extends CrudApiBaseService<Identity> {
  endpoint = 'communication';

  getCommunicationContent(claimId: number, communicationId: string) {
    return this.httpClient.get<{content: string}>(this.getUrl(`${claimId}/${communicationId}`));
  }
}
