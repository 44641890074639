import { ChangeDetectionStrategy, Component,inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA, MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

export interface IConfirmationDialogData {
  title?: string;
  contentText?: string;
  buttonYes?: string;
  buttonNo?: string;
  disableNo?: boolean;
}

@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class ConfirmationDialogComponent {
  title = 'There are unsaved changes.';
  contentText = 'Are you sure you want to stop editing this record and leave this page?';
  buttonYes = 'DISCARD CHANGES';
  buttonNo = 'SAVE AND CONTINUE';
  disableNo = false;
  data: {data: IConfirmationDialogData} = inject(MAT_DIALOG_DATA);

  constructor(public dialogRef: MatDialogRef<any>) {
    if (this.data) {
      if (this.data.data.title) {
        this.title = this.data.data.title;
      }
      if (this.data.data.contentText) {
        this.contentText = this.data.data.contentText;
      }
      if (this.data.data.buttonNo) {
        this.buttonNo = this.data.data.buttonNo;
      }
      if (this.data.data.buttonYes) {
        this.buttonYes = this.data.data.buttonYes;
      }
      if (this.data.data.disableNo) {
        this.disableNo = this.data.data.disableNo;
      }
    }
  }
}
