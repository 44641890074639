<div class="th-table-cell-toolbar">
  <button
    *ngFor="let action of actions"
    mat-menu-item
    class="th-table-cell-toolbar__action"
    [class.th-table-cell-toolbar__action_delete]="action === TTableOptionAction.Delete"
    [title]="titleMap.get($any(action))"
    (click)="actionClick.emit(action)">
    <mat-icon class="th-table-cell-toolbar__icon">
      {{ iconsMap.get($any(action)) }}
    </mat-icon>
  </button>
</div>
