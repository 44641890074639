import { Injectable } from '@angular/core';

import { Plan } from '@core/interfaces/plan/plan.interface';
import { PlanUtil } from '@core/utils/plan.util';
import { IAppTableCol } from '@shared/modules/table/interfaces/table-col.interface';
import { IAppTableRow } from '@shared/modules/table/interfaces/table-row.interface';

@Injectable()
export abstract class PlansPageService {
  planStatuses = PlanUtil.statusesObject();

  abstract openRegisterPlan(): void;
  abstract openPlanDetailsDialog(planId: number, singleView: boolean): void;
  abstract getDefaultColumns(): IAppTableCol[];
  abstract getDefaultHiddenColumns(): string[];
  abstract buildPlanRows(plans: Plan[]): IAppTableRow[];
  abstract startClaimDialog(planId: number): void;
}
