import { ChangeDetectionStrategy, Component, Input,OnInit } from '@angular/core';

import { Address } from '../../../../../core/interfaces/address.interface';

@Component({
  selector: 'app-address-readonly',
  templateUrl: './address-readonly.component.html',
  styleUrls: ['./address-readonly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressReadonlyComponent implements OnInit {
  @Input() address: Address;

  constructor() {}

  ngOnInit(): void {}
}
