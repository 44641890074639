import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter, inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RxState } from '@rx-angular/state';
import { Options,Splide } from '@splidejs/splide';
import { finalize, map } from 'rxjs/operators';

import { DocumentApi } from '@core/api/document.api';
import { DestroyableComponent } from '@core/classes/destroyable-component';
import { AppConstants } from '@core/constants/app.constants';
import { ICarouselCustomOptions } from '@core/interfaces/carousel.interface';
import { Files } from '@core/interfaces/claims/files.interface';
import { PromptDialogService } from '@core/services/dialog/prompt-dialog.service';
import { PhotoDialogService } from '@core/services/photo-dialog.service';
import { ImageErrorDirective } from '@shared/directives/image-error.directive';
import { CarouselModule } from '@shared/modules/carousel/carousel.module';
import { PipesModule } from '@shared/pipes/pipes.module';

interface IFilesPreviewState {
  filesList: Files[];
  _disableControls: boolean;
}

@Component({
  selector: 'app-files-preview',
  standalone: true,
  templateUrl: './files-preview.component.html',
  styleUrls: ['./files-preview.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    NgIf,
    NgClass,
    AsyncPipe,
    NgForOf,
    CarouselModule,
    PipesModule,
    ImageErrorDirective,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class FilesPreviewComponent extends DestroyableComponent implements OnInit {
  filesList$ = this.state.select('filesList').pipe(
    map(filesList =>
      filesList.sort((a, b) => a.isReadOnly === b.isReadOnly ? 0 : a.isReadOnly ? 1 : -1),
    ),
  );
  _disableControls$ = this.state.select('_disableControls');
  fileTypes = AppConstants.fileTypes;
  private fileLoadedCount = 0;

  @Input() isPhotoDialog: boolean;
  @Input() showRemove: boolean;
  @Input() showDocumentName = false;
  @Input() type: string = null;
  @Input() dateRange: [string, string | undefined] = null;
  @Input() set disableControls(value: boolean) {
    this.state.set({ _disableControls: value });
  }
  @Input() set files(files: Files[]) {
    this.state.set({
      filesList: files.filter(item => !!item.thumbnailUrl),
    });
    if (files.length === 0) {
      this.allFilesLoaded.emit(true);
    }
  }
  @Input() isNavigation = false;
  @Input() customCarouselOptions: ICarouselCustomOptions = {
    arrowsPosition: 'inside',
    arrowsBackground: true,
  };

  @Output() removeFiles = new EventEmitter<number[]>();
  @Output() allFilesLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() goToFile = new EventEmitter<Files>();
  @Output() carouselMounted = new EventEmitter<Splide>();
  @ViewChild('carousel') carousel: ElementRef;
  @ViewChild('photoPreviewWrapper') photoPreviewWrapperEl: ElementRef;

  splideOptions: Options = {
    pagination: false,
    autoWidth: true,
    gap: 12,
    drag: false,
  };
  promptDialogService = inject(PromptDialogService);

  ngOnInit() {
    this.splideOptions.isNavigation = this.isNavigation;
    this.splideOptions.keyboard = this.isNavigation ? 'global' : false;
  }

  constructor(
    private photoDialogService: PhotoDialogService,
    private readonly documentApi: DocumentApi,
    private readonly state: RxState<IFilesPreviewState>,
  ) {
    super();
    this.state.set({
      filesList: [],
      _disableControls: false,
    });
  }

  onFileClick(file: Files) {
    if (this.isLoading || (!file.id && this.fileTypes.documents.indexOf(file.mimeType) !== -1)) {
      return;
    }
    const filesList = this.state.get('filesList');
    const photoFiles = filesList.filter(fileItem => this.fileTypes.documents.indexOf(fileItem.mimeType) === -1);
    this.fileClick(file, photoFiles);
  }

  fileClick(file: Files, files: Files[]) {
    if (this.isPhotoDialog) {
      this.goToFile.emit(file);
    } else {
      const isSafari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
      const isEdge = navigator.userAgent.indexOf('Edge') !== -1;
      const isTif = file.mimeType.indexOf('image/tif') !== -1;
      if (this.fileTypes.images.indexOf(file.mimeType) !== -1) {
        if (isTif) {
          if (isSafari || isEdge) {
            this.photoDialogService
              .openPhotoDialog(file, files, this.showRemove)
              .afterClosed()
              .subscribe(response => {
                if (!!response.removedIndexes && response.removedIndexes.length > 0) {
                  this.removeFiles.emit(response.removedIndexes);
                }
              });
          }
        } else {
          this.photoDialogService
            .openPhotoDialog(file, files, this.showRemove)
            .afterClosed()
            .subscribe(response => {
              if (!!response.removedIndexes && response.removedIndexes.length > 0) {
                this.removeFiles.emit(response.removedIndexes);
              }
            });
        }
      } else if (this.fileTypes.documents.indexOf(file.mimeType) !== -1) {
        window.open(file.url);
      }
    }
  }

  removeFile(e: Event, index: number): void {
    if (!this.isLoading) {
      e.preventDefault();
      e.stopPropagation();
      const filesList = this.state.get('filesList');
      const file = filesList[index];
      if (file.id) {
        this.promptDialogService.openPrompt({
          message: '<span>Are you sure you wish to delete this file?</span>',
          buttonNo: 'No',
          buttonYes: 'Yes',
        }).afterClosed().subscribe(success => {
          if (success) {
            this.removeFileRequest(file.id);
          }
        });
      } else {
        this.removeFiles.emit([file.index]);
      }
    }
  }

  fileLoaded(e: any, file: Files): boolean {
    const filesList = this.state.get('filesList');
    this.fileLoadedCount++;
    if (this.fileLoadedCount === filesList.length) {
      this.allFilesLoaded.emit(true);
    }
    file.thumbnailLoaded = true;
    return file.thumbnailLoaded;
  }

  trackByFn(index: number, item: Files) {
    return `${item.id}${item.dateCreated}${item['thumbnailLoaded']}${index}`;
  }

  carouselCreated(carousel: Splide) {
    carousel.mount();
    this.carouselMounted.emit(carousel);
  }

  removeFileRequest(fileId: number) {
    this.state.set({ _disableControls: true });
    this.documentApi
      .deleteFileRequest(fileId)
      .pipe(finalize(() => this.state.set({ _disableControls: false })))
      .subscribe(() => {
        const filesList = this.state.get('filesList').filter(file => file.id !== fileId);
        this.state.set({
          filesList,
        });
        this.removeFiles.emit([fileId]);
      });
  }

  get isLoading(): boolean {
    return this.state.get('_disableControls');
  }
}
