import { CLAIM_FILES_DROP_AREA, RECEIPT_FILES_DROP_AREA } from '@core/constants/app.constants';
import { ClaimType } from '@core/enums/claim-type.enum';
import { IDropArea } from '@core/interfaces/claims/files.interface';

interface ComparableAddress {
  streetAddress1: string;
  city: string;
  stateProvince: string;
  postalCode: string;
}

export class ClaimUtil {
  static compareAddresses(address1: ComparableAddress, address2: ComparableAddress): boolean {
    return (
      address1.streetAddress1 === address2.streetAddress1
      && address1.city === address2.city
      && address1.stateProvince === address2.stateProvince
      && address1.postalCode === address2.postalCode
    );
  }

  static getDropAreaListByClaimType(claimType: ClaimType): IDropArea[] {
    switch (claimType) {
      case ClaimType.MS:
        return [CLAIM_FILES_DROP_AREA];
      case ClaimType.PP:
        return [RECEIPT_FILES_DROP_AREA, CLAIM_FILES_DROP_AREA];
      default:
        return [];
    }
  }
}
