import moment from 'moment-timezone';

import { Address } from '../interfaces/address.interface';
import { IConsumer } from '../interfaces/consumer/consumer.interface';

export interface ConsumerForm {
  // id: number;
  // crmRefId: string;
  firstName: string;
  lastName: string;
  homePhone: string;
  mobilePhone: string;
  workPhone: string;
  emailAddress: string;
}

interface PlanInfoForm {
  clientRefObject: string;
  planId: string;
  deliveryDate: string;
  planPrice: number;
  totalCoverageAmount: number;
}

export interface ConsumerPlanForm {
  retailerId: number;
  purchaseDate: string;
  receiptNumber: string;
  planInfo: PlanInfoForm[];
}

interface RetailerPlanForm {
  consumer: Partial<ConsumerForm>;
  consumerPlan: ConsumerPlanForm;
  consumerAddress: Address;
  serviceAddress: Address;
}

export class RetailerPlanFormClass {
  oldConsumer: ConsumerForm;

  private consumer: Partial<IConsumer>;
  private consumerPlan: ConsumerPlanForm;
  private consumerAddress: Address;
  private serviceAddress: Address;

  constructor() {}

  setConsumer(consumer: IConsumer, address: Address) {
    if (!this.oldConsumer) {
      this.consumer = consumer;
    } else {
      const changedFields = this.consumerWasChanged({ ...consumer, ...address });
      this.consumer = {
        id: consumer.id,
        crmRefId: consumer.crmRefId,
        ...changedFields,
      };
    }
  }

  setConsumerPlan(consumerPlan: ConsumerPlanForm): void {
    this.consumerPlan = consumerPlan;
    this.consumerPlan.purchaseDate = this.getMomentDate(this.consumerPlan.purchaseDate);
  }

  setPlansInfo(plans: PlanInfoForm[]): void {
    plans.forEach((plan, index) => {
      plan.clientRefObject = index.toString();
      plan.deliveryDate = this.getMomentDate(plan.deliveryDate);
    });
    this.consumerPlan.planInfo = plans;
  }

  setConsumerAddress(address: Address): void {
    if (this.consumer.id || this.consumer.crmRefId) {
      return;
    } else {
      this.consumerAddress = address;
    }
  }

  setServiceAddress(address: Address): void {
    this.serviceAddress = address;
  }

  private getMomentDate(date: string): string {
    return moment(date).utc(true).format();
  }

  getValue(): RetailerPlanForm {
    return {
      consumer: this.consumer,
      consumerAddress: this.consumerAddress,
      serviceAddress: this.serviceAddress,
      consumerPlan: this.consumerPlan,
    };
  }

  private consumerWasChanged(newConsumer: IConsumer): Partial<IConsumer> {
    const changedConsumerData = {};
    const newConsumerKeys = Object.keys(newConsumer);
    newConsumerKeys.forEach(consumerKey => {
      if (this.oldConsumer.hasOwnProperty(consumerKey) && this.oldConsumer[consumerKey] !== newConsumer[consumerKey]) {
        changedConsumerData[consumerKey] = newConsumer[consumerKey];
      }
    });
    return changedConsumerData;
  }
}
