import { Directive, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NgControl,
  NgModel,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive()
export abstract class ThBaseFormControlElement<T> implements OnInit, OnChanges, OnDestroy {
  /**
   * Use it only with template-driven form `ngModel`.
   *
   * If true - set required validator to control.
   *
   * If false - remove required validator from control.
   */
  @Input() required?: boolean;

  /**
   * Use it only with template-driven form `ngModel`.
   *
   * If true - disable control.
   *
   * If false - enable control.
   */
  @Input() disabled?: boolean;

  formControl: FormControl<T>;

  /**
   * @ignore
   */
  private _controlSubscription: Subscription;

  /**
   * returns boolean if fieldControl has required validator
   */
  get isRequired(): boolean {
    return this.formControl?.hasValidator(Validators.required);
  }

  /** @ignore */
  ngControl: NgControl = inject(NgControl, { optional: true });

  /** @ignore */
  constructor() {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  /* eslint-disable */
  /** @ignore */
  // These are just to make Angular happy. Not needed since the formControl is passed to the child input
  writeValue(obj: any): void {}

  /** @ignore */
  registerOnChange(fn: (_: any) => void): void {}

  /** @ignore */
  registerOnTouched(fn: any): void {}
  /* eslint-enable */

  /** @ignore */
  ngOnInit(): void {
    if (this.ngControl instanceof FormControlName) {
      const formGroupDirective = this.ngControl.formDirective as FormGroupDirective;
      if (formGroupDirective) {
        this.formControl = formGroupDirective.form.get(this.ngControl.path) as FormControl<T>;
      }
    } else if (this.ngControl instanceof FormControlDirective) {
      this.formControl = this.ngControl.control;
    } else if (this.ngControl instanceof NgModel) {
      this.formControl = this.ngControl.control;
      this._controlSubscription = this.formControl.valueChanges
        .subscribe(() => this.ngControl.viewToModelUpdate(this.formControl.value));
    } else if (!this.ngControl) {
      this.formControl = new FormControl<T>(null);
    }

    if (this.required && !this.isRequired) {
      this._setRequiredValidator(this.required);
    }

    if (this.disabled && this.formControl.enabled) {
      this._setDisabled(this.disabled);
    }
  }

  /** @ignore */
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.formControl) {
      return;
    }

    if (changes.required && this.required !== undefined) {
      this._setRequiredValidator(this.required);
    }

    if (changes.disabled && this.disabled !== undefined) {
      this._setDisabled(this.disabled);
    }
  }

  /** @ignore */
  private _setRequiredValidator(isRequired: boolean): void {
    if (!this.formControl) {
      return;
    }

    if (!isRequired && this.isRequired) {
      this.formControl.removeValidators(Validators.required);
    } else if (isRequired && !this.isRequired) {
      this.formControl.addValidators(Validators.required);
    } else {
      return;
    }

    this.formControl.updateValueAndValidity({ emitEvent: false });
  }

  /** @ignore */
  private _setDisabled(isDisabled: boolean): void {
    if (!this.formControl) {
      return;
    }

    const disabledControl = this.formControl.disabled;

    if (!isDisabled && disabledControl) {
      this.formControl.enable();

      return;
    }

    if (isDisabled && !disabledControl) {
      this.formControl.disable();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    if (this._controlSubscription) {
      this._controlSubscription.unsubscribe();
    }
  }
}
