import { CurrencyPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter,Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

import { TooltipWithLabelComponent } from '@shared/components/tooltip-with-label/tooltip-with-label.component';
import { CurrencyDirective } from '@shared/directives/currency/currency.directive';
import { GridModule } from '@shared/modules/grid/grid.module';
import { GridColumnModel } from '@shared/modules/grid/models/grid/grid-column.model';
import { GridFieldModel } from '@shared/modules/grid/models/grid/grid-field';
import { PipesModule } from '@shared/pipes/pipes.module';

import { AppConstants } from '../../../../../core/constants/app.constants';
import { PartOrder, PartOrders } from '../../../../../core/interfaces/claims/claimDetails.interface';

@Component({
  selector: 'app-claim-invoice-grid',
  standalone: true,
  templateUrl: './claim-invoice-grid.component.html',
  styleUrls: ['./claim-invoice-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgForOf,
    NgIf,
    MatIconModule,
    MatLegacyTooltipModule,
    MatLegacyFormFieldModule,
    PipesModule,
    MatLegacyInputModule,
    FormsModule,
    CurrencyDirective,
    CurrencyPipe,
    GridModule,
    NgClass,
    TooltipWithLabelComponent,
  ],
})
export class ClaimInvoiceGridComponent implements OnInit {
  @Input() columns: GridColumnModel[];
  @Input() requiredColumnName: string;
  @Input() requiredColumnTooltip: string;
  @Input() set initialData(value: {parts: any[]; gridFields: GridFieldModel[]}) {
    this.parts = value.parts;
    this.gridFields = value.gridFields;
  }
  @Output() updatedPartsData = new EventEmitter<PartOrders>();
  @Output() tableInvalidState = new EventEmitter<boolean>();
  @Output() isRequestPaymentZeroDollarEnhancement = new EventEmitter<boolean>();

  parts: any[] = [];
  gridFields: GridFieldModel[] = [];
  fieldName = 'name';
  fieldCost = 'costAmount';
  costItem = null;
  fieldNameMaximumAuthorizedAmount = AppConstants.fieldNameMaximizedAuthorizedAmount;
  fieldNameRequestedAmount = AppConstants.fieldNameRequestedAmount;
  totalRequested = 0;
  taxAmount = null;
  deliveryAmount = null;
  requestedAmountGridFields = new GridFieldModel(AppConstants.fieldNameRequestedAmount, 0);

  constructor() {}

  ngOnInit() {
    this.calculateTotalRequested();
  }

  calculateTotalRequested() {
    if (this.parts.length) {
      let total = 0;
      this.parts.forEach(item => {
        total += +item[this.fieldCost];
      });
      this.totalRequested = total + (+this.taxAmount + +this.deliveryAmount);
      this.calculateRequestedAmount();
      this.sendUpdatedPartsData();
      this.checkTableValidationState([...this.parts]);
    }
  }

  calculateRequestedAmount() {
    if (this.gridFields.length) {
      const maximumAuthorizedAmount = +this.gridFields.filter(
        field => field.fieldName === this.fieldNameMaximumAuthorizedAmount,
      )[0].value;
      const totalRequested = this.totalRequested - (+this.taxAmount + +this.deliveryAmount);
      this.requestedAmountGridFields.value =
        Math.min(totalRequested, maximumAuthorizedAmount) + +this.taxAmount + +this.deliveryAmount;
    }
  }

  sendUpdatedPartsData() {
    const parts: PartOrder[] = [];
    this.parts.forEach(part => {
      parts.push({ id: part.id, costAmount: +part.costAmount });
    });
    const partOrders: PartOrders = {
      deliveryAmount: +this.deliveryAmount > 0 ? +this.deliveryAmount : null,
      taxAmount: +this.taxAmount > 0 ? +this.taxAmount : null,
      parts: [...parts],
    };
    this.updatedPartsData.emit(partOrders);
    this.checkForRequestPaymentZeroDollarEnhancement();
  }

  sendTableInvalidState(state: boolean) {
    this.tableInvalidState.emit(state);
  }

  private checkTableValidationState(parts: PartOrder[] = []) {
    if (parts.length) {
      const invalidPartsIndexes = [];
      parts.forEach((part, i) => {
        if (+part.costAmount < 0 || part.costAmount === null || part.costAmount + '' === '') {
          invalidPartsIndexes.push(i);
          this.sendTableInvalidState(true);
        }
      });
      if (invalidPartsIndexes.length === 0) {
        this.sendTableInvalidState(false);
      }
    }
  }

  private checkForRequestPaymentZeroDollarEnhancement() {
    const zeroDeliveryAmount = this.deliveryAmount && this.deliveryAmount.length && this.deliveryAmount == 0;
    const zeroTaxAmount = this.taxAmount && this.taxAmount.length && this.taxAmount == 0;
    const zeroCostAmount = !this.parts
      .map(part => part.costAmount && part.costAmount.length && part.costAmount !== null && +part.costAmount === 0)
      .includes(false);
    this.isRequestPaymentZeroDollarEnhancement.emit(zeroDeliveryAmount && zeroTaxAmount && zeroCostAmount);
  }
}
