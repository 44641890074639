import { Component, OnInit } from '@angular/core';

import { AppConstants } from '../../../../../core/constants/app.constants';

@Component({
  selector: 'app-claim-stages-cell',
  templateUrl: './claim-stages-cell.component.html',
  styleUrls: ['./claim-stages-cell.component.scss'],
})
export class ClaimStagesCellComponent implements OnInit {
  data: any;
  fieldName: string;
  claimStages = AppConstants.claimStages;

  constructor() {}

  ngOnInit() {}
}
