import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TDocumentType } from '@core/enums/document-type';
import { UserStore } from '@core/store/user/user.store';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

import { FileInterface } from '../interfaces/claims/claimDetails.interface';
import { Files } from '../interfaces/claims/files.interface';
import { CrudApiBaseService } from './crud-api.base';

@Injectable({ providedIn: 'root' })
export class DocumentApi extends CrudApiBaseService<any> {
  endpoint = 'document';

  constructor(
    private notificationService: NotificationService,
    private readonly userStore: UserStore,
  ) {
    super();
  }

  uploadFiles(files: Files[], claimId: number) {
    const formData = this.returnMultipleFilesFormData(files);
    return this.httpClient.post<any>(this.getUrl(`claim/${claimId}`), formData);
  }

  uploadConsumerFiles(files: Files[], token: string) {
    const formData = this.returnMultipleFilesFormData(files, false);
    formData.append('token', token);
    return this.httpClient.post<{message: string}>(this.getUrl('consumer-upload'), formData);
  }

  anonymousUploadConsumerFiles(files: Files[], token: string) {
    const formData = this.returnMultipleFilesFormData(files, false);
    formData.append('token', token);
    return this.httpClient.post<{message: string}>(this.getUrl('anonymous-upload'), formData);
  }

  uploadClaimSpecialistMultipleFilesForSystemPortal(files: Files[], claimId: string): Observable<any> {
    const formData = this.returnMultipleFilesFormData(files);
    return this.httpClient.post<any>(this.getUrl(`claim/specialist/${claimId}`), formData);
  }

  uploadPlanSpecialistMultipleFilesForSystemPortal(files: Files[], planId: string): Observable<any> {
    const formData = this.returnMultipleFilesFormData(files);
    return this.httpClient.post<any>(this.getUrl(`plan/specialist/${planId}`), formData);
  }

  uploadMyClaimMultipleFiles(files: Files[], claimId: string): Observable<any> {
    const formData = this.returnMultipleFilesFormData(files);
    return this.httpClient.post<any>(this.getUrl(`myclaim/${claimId}`), formData);
  }

  uploadMyPlanFiles(files: any, id: number) {
    const formData = this.returnMultipleFilesFormData(files);
    return this.httpClient.post<any>(this.getUrl(`myplan/${id}`), formData);
  }

  uploadPlanFiles(files: Files[], id: number): Observable<void> {
    const formData = this.returnMultipleFilesFormData(files);
    return this.httpClient.post<void>(this.getUrl(`plan/${id}`), formData);
  }

  uploadImage(file: any, url: string, id: number) {
    const formData: FormData = new FormData();
    formData.append('formfile', file);
    return this.httpClient.post<{docUrl: string}>(this.getUrl(`${url}/${id}`), formData);
  }

  getCommunicationAttachments(claimId: number, activityId: string): Observable<FileInterface[]> {
    return this.httpClient.get<any>(this.getUrl(`${claimId}/attachments/${activityId}`));
  }

  remove(id: number) {
    return this.httpClient.delete<void>(this.getUrl(`?ids=${id}`));
  }

  private returnMultipleFilesFormData(files: Files[], addToken: boolean = true): FormData {
    const receiptsUploaded = files.filter(file => file.docType === TDocumentType.Receipt);
    const claimFilesUploaded = files.filter(
      file => [TDocumentType.RetailerPortalUploadedPhoto, TDocumentType.CustPhoto].includes(file.docType),
    );
    const formData: FormData = new FormData();

    if (receiptsUploaded.length) {
      for (let i = 0; i < receiptsUploaded.length; i++) {
        formData.append('ReceiptFiles', receiptsUploaded[i].originalFile);
      }
    }
    if (claimFilesUploaded.length) {
      for (let i = 0; i < claimFilesUploaded.length; i++) {
        formData.append('ClaimFiles', claimFilesUploaded[i].originalFile);
      }
    }
    if (addToken) {
      const token = this.userStore.get('session', 'token');
      formData.append('Token', token);
    }
    return formData;
  }

  deleteFileRequest(id: number) {
    return this.remove(id).pipe(
      map(result => {
        this.notificationService.next({
          message: 'The file was deleted successfully.',
          type: NotificationType.Success,
          duration: 8000,
        });
        return result;
      }),
      // catchError(error => {
      //   // this.notificationService.next({
      //   //   message: error.error || error.Error || error.message || 'There was an error during file deletion',
      //   //   type: NotificationType.Error,
      //   //   duration: 12000,
      //   // });
      //   return throwError(error);
      // })
    );
  }
}
