export enum TServiceActionStage {
  New = 1,
  RetailerPending = 2,
  ConsumerPending = 3,
  VendorPending = 4,
  Servicing = 5,
  Assessment = 6,
  AccountingReview = 7,
  Complete = 8,
  Cancelled = 9,
  Unknown = 99,
}
