import { inject, Injectable } from '@angular/core';
import { finalize, switchMap, take } from 'rxjs/operators';

import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

import { BaseStore } from '../_base/base.store';
import { StoreState } from './regions.state';
import IStoreState = StoreState.IStoreState;
import initialState = StoreState.initialState;

import { PromptDialogService } from '@core/services/dialog/prompt-dialog.service';

import { RegionApi } from '../../api/regions.api';
import { Tree } from '../../interfaces/regions/regions.interface';
import { TreeIdentity } from '../../interfaces/util/identity.interface';

@Injectable({
  providedIn: 'root',
})
export class RegionsStore extends BaseStore<IStoreState> {
  promptDialogService = inject(PromptDialogService);

  constructor(
    private readonly regionApi: RegionApi,
    private readonly notification: NotificationService,
  ) {
    super(initialState);
  }

  init(pageSize: number = 10) {
    const searchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...searchRequest,
        pageSize,
      },
    });
  }

  loadData() {
    this.updateState({ loading: true });
    this.select$('searchRequest')
      .pipe(
        take(1),
        switchMap(searchRequest => this.regionApi.all(searchRequest)),
        finalize(() => this.updateState({ loading: false })),
      )
      .subscribe(regions => {
        this.updateState({
          regions: regions.items || [],
          pagination: {
            totalCount: regions.totalCount,
            page: regions.page,
            pageCount: regions.pageCount,
          },
        });
      });
  }

  findNode(nodes: Tree[], node: TreeIdentity): Tree {
    let result;

    const traverse = (_nodes: Tree[], _node: TreeIdentity) => {
      _nodes.forEach(n => {
        if (n.id === _node.id) {
          result = n;
        }

        if (n.children) {
          traverse(n.children, _node);
        }
      });
    };

    traverse(nodes, node);

    return result;
  }

  deleteRegion(region: Tree) {
    this.promptDialogService.openPrompt({
      message: `Region <b>${region.name}</b> and all its children will be deleted.`,
      buttonYes: 'Delete',
      buttonNo: 'Cancel',
    }).afterClosed().subscribe(result => {
      if (result) {
        this.updateState({ loading: true });
        this.regionApi
          .remove(region.id)
          .pipe(
            finalize(() => {
              this.updateState({ loading: false });
            }),
          )
          .subscribe(() => {
            let regions = this.get('regions');
            const removedRegionIndex = regions.findIndex(regionItem => regionItem.id === region.id);
            const childRegionIds = this.returnRegionChildIds(regions[removedRegionIndex].children);
            const idsToRemove = [region.id, ...childRegionIds];

            regions = regions.filter(regionItem => !idsToRemove.includes(regionItem.id));

            this.updateState({ regions: JSON.parse(JSON.stringify(regions)) });
            this.notification.next({
              message: 'Region was deleted successfully.',
              type: NotificationType.Success,
            });
          });
      }
    });
  }

  returnRegionChildIds(childRegions: Tree[]) {
    if (!childRegions) {
      return [];
    }

    return childRegions
      .map(childRegion => {
        let childIds = [childRegion.id];

        if (childRegion.children.length > 0) {
          childIds = [...childIds, ...this.returnRegionChildIds(childRegion.children)];
        }

        return childIds;
      })
      .flat();
  }

  assignRegionParent(movedRegionId: number, toRegionId: number) {
    const regions = this.get('regions');
    const movedRegion = regions.find(region => region.id === movedRegionId);
    if (this.isToRegionNotChild(movedRegionId, toRegionId)) {
      movedRegion.parentId = toRegionId;
      this.updateState({ regions: JSON.parse(JSON.stringify(regions)) });
      this.updateRegion({
        id: movedRegion.id,
        parentId: toRegionId,
        name: movedRegion.name,
      });
    } else {
      this.notification.next({
        message: 'Moving parent region to its child is not allowed.',
        type: NotificationType.Error,
      });
    }
  }

  isToRegionNotChild(movedRegionId: number, toRegionId: number) {
    const regions = this.get('regions');
    const toRegion = regions.find(region => region.id === toRegionId);
    if (toRegion.parentId === null) {
      return true;
    } else if (toRegion.parentId === movedRegionId) {
      return false;
    } else {
      return this.isToRegionNotChild(movedRegionId, toRegion.parentId);
    }
  }

  moveToRoot(movedRegionId: number) {
    const regions = this.get('regions');
    const movedRegion = regions.find(region => region.id === movedRegionId);
    movedRegion.parentId = null;
    this.updateState({ regions: JSON.parse(JSON.stringify(regions)) });
    this.updateRegion({
      id: movedRegionId,
      parentId: null,
      name: movedRegion.name,
    });
  }

  updateRegion(region: {id: number; parentId: number; name: string}) {
    this.updateState({ saveInProgress: true });
    this.regionApi
      .update(region)
      .pipe(
        finalize(() => {
          this.updateState({ saveInProgress: false });
        }),
      )
      .subscribe(result => {
        const regions = this.get('regions');
        const index = regions.findIndex(regionItem => regionItem.id === result.id);
        regions[index] = result;
        this.updateState({ regions });
      });
  }
}
