import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { RetailerPlanApiService } from '@core/api/retailer-plan-api.service';
import { MyProtectionPlanStatus } from '@core/enums/plan/my-protection-plan-status.enum';
import { Plan, PlanDetails, Refund } from '@core/interfaces/plan/plan.interface';
import { PromptDialogService } from '@core/services/dialog/prompt-dialog.service';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

@Injectable()
export class PlanService {
  cancellationInProgress$ = new BehaviorSubject<boolean>(false);
  promptDialogService = inject(PromptDialogService);

  constructor(
    private retailerPlanApiService: RetailerPlanApiService,
    private notification: NotificationService,
  ) {}

  cancelRetailerPlan(planDetails: PlanDetails) {
    if (planDetails.planInfo.protectionPlanStatus !== MyProtectionPlanStatus.Registered) {
      return;
    }
    const plan = planDetails.planInfo;
    this.openRetailerPlanCancellationModal(plan);
  }

  private openRetailerPlanCancellationModal(plan: Plan) {
    this.promptDialogService.openPrompt({
      message: `<span>Are you sure you want to cancel this plan <b>${plan.consumerPlanName}</b>?</span>`,
      buttonNo: 'No, don\'t cancel',
      buttonYes: 'Yes, cancel plan',
    }).afterClosed().subscribe(result => {
      if (result) {
        this.cancellationInProgress$.next(true);
        this.retailerPlanApiService
          .returnPlan(plan.id)
          .pipe(
            tap((refund: Refund) => {
              if (refund.errorMessage) {
                this.notifyCancellationError(refund);
              } else {
                this.notification.next({
                  message: `Plan ${plan.consumerPlanName} has been cancelled.`,
                  type: NotificationType.Success,
                  duration: 6000,
                });
              }
            }),
            finalize(() => {
              this.cancellationInProgress$.next(false);
            }),
          )
          .subscribe();
      }
    });
  }

  notifyCancellationError(refund: Refund): void {
    this.promptDialogService.openNotify({
      message: refund.errorMessage,
    });
  }
}
