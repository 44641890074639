<div class="prompt-dialog">
  <div class="prompt-close">
    <button
      (click)="close()"
      mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <p [innerHTML]="dialogData.data.message ? (dialogData.data.message | safe : 'html') : 'No data.'"></p>
  </mat-dialog-content>
  <div mat-dialog-actions class="prompt-actions">
    <button
      *ngIf="dialogData.data.buttonNo"
      [mat-dialog-close]="false"
      class="inline-buttons__button _common-button--rounded"
      mat-flat-button
      color="primary">
      {{ dialogData.data.buttonNo ? dialogData.data.buttonNo : "Cancel" }}
    </button>
    <button
      *ngIf="dialogData.data.buttonYes"
      [mat-dialog-close]="true"
      class="inline-buttons__button _common-button--rounded"
      mat-flat-button
      color="primary">
      {{ dialogData.data.buttonYes }}
    </button>
  </div>
</div>
