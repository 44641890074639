<div [formGroup]="formGroupRef">
  <th-form-field-block>
    <th-form-field [size]="TCommonFieldSize.Medium">
      <app-form-input
        *appReadonlyControl="
          formGroupRef.get('firstName').enabled;
          else labelValueItem;
          label: 'First Name';
          value: formGroupRef.get('firstName').getRawValue();
          let label = label
        "
        [disabled]="blocked"
        [autocomplete]="'section-consumer given-name'"
        [formControlName]="'firstName'"
        [label]="'First Name'"></app-form-input>
    </th-form-field>
    <th-form-field [size]="TCommonFieldSize.Medium">
      <app-form-input
        *appReadonlyControl="
          formGroupRef.get('lastName').enabled;
          else labelValueItem;
          label: 'Last Name';
          value: formGroupRef.get('lastName').getRawValue();
          let label = label
        "
        [disabled]="blocked"
        [autocomplete]="'section-consumer family-name'"
        [formControlName]="'lastName'"
        [label]="'Last Name'"></app-form-input>
    </th-form-field>
    <th-form-field [size]="TCommonFieldSize.Medium">
      <ng-container
        *appReadonlyControl="
          formGroupRef.get('emailAddress').enabled;
          else labelValueItem;
          label: 'Email';
          value: formGroupRef.get('emailAddress').getRawValue();
          let label = label
        ">
        <app-form-input
          *ngIf="!useRadio"
          [disabled]="blocked"
          [autocomplete]="'section-consumer email'"
          [formControlName]="'emailAddress'"
          [label]="'Email'"></app-form-input>
      </ng-container>
    </th-form-field>
  </th-form-field-block>
  <div
    *ngIf="useRadio && formGroupRef.get('emailAddress').enabled"
    class="consumer-form__email-section">
    <div class="fields-block">
      <h5 class="section__title"><strong>EMAIL</strong></h5>
    </div>
    <div class="fields-block">
      <mat-radio-group
        [formControlName]="'emailAddress'"
        (change)="emailChanged($event)"
        [required]="true"
        class="_common-radio-group--radio-top field field--third"
        name="emailAddressRadio">
        <div class="block__item">
          <mat-radio-button
            class="field block__subtitle"
            [id]="'default'"
            [value]="emailDefault$ | push">
            Use your portal email address
            <div class="fields-block consumer-form__email-radio-value">
              {{ emailDefault$ | push }}
            </div>
          </mat-radio-button>
        </div>
        <div class="block__item">
          <mat-radio-button
            class="field block__subtitle"
            [id]="'custom'"
            [value]="emailCustom$ | push">
            <p>Use a different email address</p>
            <div class="fields-block consumer-form__email-radio-value">
              <app-form-input
                class="field"
                #customEmailInput
                [floatLabel]="'auto'"
                [autocomplete]="'section-consumer email'"
                [ngModelOptions]="{ standalone: true }"
                [ngModel]="emailCustom$ | push"
                (ngModelChange)="customEmailChanged()"
                appEmailValidation
                [type]="'email'"
                [disabled]="(activeInput$ | push) === 'default'"
                [required]="true"
                [label]="'Email'"></app-form-input>
            </div>
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
  </div>

  <div class="consumer-form__phones">
    <h3 class="section__title">Phones</h3>
    <ng-container *ngIf="formGroupRef.get('mobilePhone').enabled && phonesRequired">
      <p class="help-text help-text--required">{{ tooltipPhones }}</p>
    </ng-container>
    <th-form-field-block>
      <th-form-field [size]="TCommonFieldSize.Medium">
        <app-form-input
          *appReadonlyControl="
            formGroupRef.get('mobilePhone').enabled;
            else labelValueItem;
            label: 'Mobile Phone #';
            value: formGroupRef.get('mobilePhone').getRawValue();
            let label = label
          "
          [disabled]="blocked"
          [autocomplete]="'section-consumer tel'"
          [formControlName]="'mobilePhone'"
          [type]="'tel'"
          [label]="'Mobile Phone #'"></app-form-input>
      </th-form-field>
      <th-form-field [size]="TCommonFieldSize.Medium">
        <app-form-input
          *appReadonlyControl="
            formGroupRef.get('homePhone').enabled;
            else labelValueItem;
            label: 'Home Phone #';
            value: formGroupRef.get('homePhone').getRawValue();
            let label = label
          "
          [disabled]="blocked"
          [autocomplete]="'section-consumer tel'"
          [formControlName]="'homePhone'"
          [type]="'tel'"
          [label]="'Home Phone #'"></app-form-input>
      </th-form-field>
      <th-form-field [size]="TCommonFieldSize.Medium">
        <app-form-input
          *appReadonlyControl="
            formGroupRef.get('workPhone').enabled;
            else labelValueItem;
            label: 'Business Phone #';
            value: formGroupRef.get('workPhone').getRawValue();
            let label = label
          "
          [disabled]="blocked"
          [autocomplete]="'section-consumer tel'"
          [formControlName]="'workPhone'"
          [type]="'tel'"
          [label]="'Business Phone #'"></app-form-input>
      </th-form-field>
    </th-form-field-block>
  </div>
  <div *ngIf="formGroupRef.get('info.preferredContactLanguage')">
    <h3 class="section__title">Contact Preferences</h3>
    <th-form-field-block>
      <th-form-field [size]="TCommonFieldSize.Medium">
        <app-language-select
          *appReadonlyControl="
            formGroupRef.get('preferredContactLanguage').enabled;
            else labelValueItem;
            label: 'Language';
            value: formGroupRef.get('preferredContactLanguage').getRawValue();
            let label = label
          "
          [formControlName]="'preferredContactLanguage'"></app-language-select>
      </th-form-field>
      <th-form-field [size]="TCommonFieldSize.Medium">
        <app-contact-method-select
          *appReadonlyControl="
            formGroupRef.get('preferredContactMethod').enabled;
            else labelValueItem;
            label: 'Contact Method';
            value: formGroupRef.get('preferredContactMethod').getRawValue();
            let label = label
          "
          [formControlName]="'preferredContactMethod'"></app-contact-method-select>
      </th-form-field>
      <th-form-field [size]="TCommonFieldSize.Medium">
        <app-contact-time-select
          *appReadonlyControl="
            formGroupRef.get('preferredContactTime').enabled;
            else labelValueItem;
            label: 'Time';
            value: formGroupRef.get('preferredContactTime').getRawValue();
            let label = label
          "
          [formControlName]="'preferredContactTime'"></app-contact-time-select>
      </th-form-field>
    </th-form-field-block>
  </div>
</div>
<ng-template
  #labelValueItem
  let-label="label"
  let-value="value">
  <app-label-value-item
    [label]="label"
    [value]="value"></app-label-value-item>
</ng-template>
