import { NgForOf, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { GridModule } from '@shared/modules/grid/grid.module';

import { ProductIncidentProblemsComponent } from './components/product-incident-problems/product-incident-problems.component';
import { ProductIncidentStatusComponent } from './components/product-incident-status/product-incident-status.component';

const components = [
  ProductIncidentStatusComponent,
  ProductIncidentProblemsComponent,
];

@NgModule({
  declarations: [...components],
  imports: [GridModule, MatTooltipModule, NgForOf, NgIf],
  exports: [...components],
})
export class ClaimDetailsCoreModule {}
