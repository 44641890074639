import { LowerCasePipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { AppConstants } from '@core/constants/app.constants';
import { ClaimStages } from '@core/enums/claim/claim-stages.enum';
import { ClaimStatuses } from '@core/enums/claim/claim-statuses.enum';
import { PipesModule } from '@shared/pipes/pipes.module';

@Component({
  selector: 'app-claim-details-header',
  standalone: true,
  templateUrl: './claim-details-header.component.html',
  styleUrls: ['./claim-details-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgClass,
    LowerCasePipe,
    PipesModule,
  ],
})
export class ClaimDetailsHeaderComponent implements OnInit {
  @Input() claimStatus: ClaimStatuses;
  @Input() claimStage: ClaimStages;
  @Input() dateOpen: string;
  @Input() dateClosed: string;

  claimStatuses = AppConstants.claimStatuses;
  claimStages = AppConstants.claimStages;

  constructor() {}

  ngOnInit() {}
}
