import { NgForOf, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import {
  CollapsibleBlockComponent,
} from '@shared/modules/collapsible/components/collapsible-block/collapsible-block.component';
import { GridModule } from '@shared/modules/grid/grid.module';
import { PipesModule } from '@shared/pipes/pipes.module';

import { GridColumnModel } from '../../../grid/models/grid/grid-column.model';

@Component({
  selector: 'app-collapsible-data-block',
  standalone: true,
  templateUrl: './collapsible-data-block.component.html',
  styleUrls: ['./collapsible-data-block.component.scss'],
  imports: [
    CollapsibleBlockComponent,
    NgIf,
    GridModule,
    PipesModule,
    NgForOf,
  ],
})
export class CollapsibleDataBlockComponent implements OnInit {
  @Input() headingTitle: string;
  @Input() grid: {source: any[]; columns: GridColumnModel[]};
  @Input() lastChild = false;
  @Input() useMobileLayout = false;

  constructor() {}

  ngOnInit() {}
}
