import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { PushPipe } from '@rx-angular/template/push';

import { BaseFormControlElement } from '@core/base/base-form-control-element';
import { IIdentityString } from '@core/interfaces/util/identity.interface';
import { FormSelectComponent } from '@shared/components/form-select/form-select.component';
import { ProblemTypeStore } from '@shared/modules/claim-controls/components/problem-type-select/store/problem-type.store';

@Component({
  selector: 'app-problem-type-select',
  standalone: true,
  templateUrl: './problem-type-select.component.html',
  styleUrls: ['./problem-type-select.component.scss'],
  providers: [ProblemTypeStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormSelectComponent,
    PushPipe,
    ReactiveFormsModule,
  ],
})
export class ProblemTypeSelectComponent extends BaseFormControlElement implements OnInit {
  @Input() required = false;
  @Input() label = 'Problem Type';
  @Output() problemTypeSelected = new EventEmitter<IIdentityString>();

  problemTypes$ = this.store.select$('items');
  loading$ = this.store.select$('loading');
  pagination$ = this.store.select$('pagination');

  constructor(@Optional() @Self() public ngControl: NgControl, private readonly store: ProblemTypeStore) {
    super(ngControl);
  }

  ngOnInit() {
    super.ngOnInit();
    this.store.loadData(true);
  }

  problemTypeSearch(searchString: string) {
    this.store.search(searchString);
  }

  typeSelected(problemType: IIdentityString) {
    this.problemTypeSelected.emit(problemType);
  }

  loadNextPage(page: number) {
    this.store.updatePagination(page);
  }
}
