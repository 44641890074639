<div class="grouped-claim-files">
  <h2 class="grouped-claim-files__title">Photos/Documents</h2>
  <ng-container *ngFor="let filteredFile of filteredFiles">
    <div class="files-for-print" *ngIf="filteredFile.files.length > 0">
      <h3>{{filteredFile.title}}</h3>
      <div class="file-thumbnail" *ngFor="let file of filteredFile.files">
        <div class="file-wrapper">
          <img [src]="file.thumbnailUrl">
        </div>
      </div>
    </div>
  </ng-container>
</div>
