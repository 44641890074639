<div *ngIf="readonly === true; else regularSelect">
  <div class="field__label">Time</div>
  <div class="field__text">{{selectedItemName$ | async | appEmptyValue}}</div>
</div>
<ng-template #regularSelect>
  <app-form-select [formControl]="formControl"
                   [data]="itemsList$ | async"
                   [label]="'Time'"></app-form-select>
</ng-template>




