export enum MyProtectionPlanStatus {
  /// <summary>
  /// Registered
  /// </summary>
  Registered = 1,

  /// <summary>
  /// Expired
  /// </summary>
  Expired = 2,

  /// <summary>
  /// Canceled
  /// </summary>
  Cancelled = 3,

  /// <summary>
  /// Cancelling
  /// </summary>
  Cancelling = 4,

  Voided = 5,
}
