import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { filesize } from 'filesize';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { TDocumentType } from '@core/enums/document-type';
import { TPortalType } from '@core/enums/portal-type.enum';
import { Files, IUploadedFile } from '@core/interfaces/claims/files.interface';
import { PromptDialogService } from '@core/services/dialog/prompt-dialog.service';
import { FilesService } from '@core/services/files.service';
import { FileDragAndDropDirective } from '@shared/modules/files/file-drag-and-drop.directive';

@Component({
  selector: 'app-form-files-drop-area',
  templateUrl: './form-files-drop-area.component.html',
  styleUrls: ['./form-files-drop-area.component.scss'],
  standalone: true,
  imports: [

    MatLegacyFormFieldModule,
    MatButtonModule,
    MatIconModule,
    FileDragAndDropDirective,
    NgIf,
    AsyncPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFilesDropAreaComponent implements OnInit {
  @Input() formFilesArray: FormArray<FormControl<Files>>;
  @Input() headerTitle = '';
  @Input() disabled = false;
  @Input() multiple = false;
  @Input() matIcon = '';
  @Input() pictureIcon = '';
  @Input() labelDescriptionForMobile = 'Drag and drop here, or';
  @Input() labelDescription = 'Drag and drop here, or';
  @Input() docType: TDocumentType;
  @Input() maxFilesNumber: number = null;
  @Input() accept = '.jpeg, .jpg, .png, .tiff, .tif, .bmp, .pdf, .doc, .docx';
  @Input() sectionData: {productIndex?: number; sectionIndex?: number; sectionName?: string} = null;
  @Input() smallDropArea = false;
  @Input() oneFileErrorMessage;

  filesSizeError$: Observable<string | null>;

  appConfig = inject(APP_CONFIG);
  promptDialogService = inject(PromptDialogService);

  get hasErrorState() {
    const isTechPortal = this.appConfig.portalType === TPortalType.TechPortal;
    return isTechPortal && this.errorStateMatcher.isErrorState(this.formFilesArray, this.ngForm || this.formGroupDirective || null);
  }

  private defaultOneFileErrorMessage = 'You only need to drop one file here. Each photo needed has it\'s own spot to drop it on. Please try again with just one file.';

  constructor(
    private readonly filesService: FilesService,
    private readonly fb: FormBuilder,
    @Optional() private readonly ngForm: NgForm,
    @Optional() private readonly formGroupDirective: FormGroupDirective,
    private readonly errorStateMatcher: ErrorStateMatcher,
  ) {}

  ngOnInit() {
    this.filesSizeError$ = this.formFilesArray.statusChanges.pipe(
      map(formStatus => {
        if (formStatus === 'INVALID' && !!this.formFilesArray.getError('sizeError')) {
          return `Total size of all files should be less than ${
            this.formFilesArray.getError('sizeError').maxFilesSize / (1000 * 1000)
          } MB.`;
        } else {
          return '';
        }
      }),
    );
  }

  handleDrop(droppedFiles: IUploadedFile[]) {
    if (!this.formFilesArray.hasError('sizeError')) {
      this.saveFiles(droppedFiles);
    }
  }

  handleInputFiles(event: Event, docType: TDocumentType) {
    if (!this.formFilesArray.hasError('sizeError')) {
      this.filesService
        .handleFilesInput(event, {
          docType,
          fileControlName: event.target['name'],
        }, this.accept)
        .subscribe(uploadedFiles => {
          this.saveFiles(uploadedFiles);
        });
    }
  }

  saveFiles(uploadedFiles: IUploadedFile[]) {
    const filesDocType = uploadedFiles[0].docType;
    const filesListByDocType = this.formFilesArray.value.filter(file => file.docType === filesDocType);
    const totalFilesLengthAfterUpload = uploadedFiles.length + filesListByDocType.length;
    if (
      (this.maxFilesNumber !== null
        && this.maxFilesNumber !== 1
        && totalFilesLengthAfterUpload > this.maxFilesNumber)
      || (this.maxFilesNumber === 1 && uploadedFiles.length > this.maxFilesNumber)
    ) {
      this.promptDialogService.openPrompt({
        message: this.oneFileErrorMessage || this.defaultOneFileErrorMessage,
        buttonNo: 'OK',
      });
    } else {
      const lastFile = this.formFilesArray.at(-1);
      let fileIndex = lastFile ? (lastFile.value.index + 1) : 0;
      const mappedFiles = uploadedFiles.map(file => this._mapFile(file, fileIndex++));
      if (this.sectionData) {
        const errorMessage = this.checkIdenticalPhotos(mappedFiles);
        if (errorMessage) {
          this.promptDialogService.openPrompt({
            message: errorMessage,
            buttonNo: 'OK',
          });
          return;
        }
      } else {
        const [currentFileNames, currentFileSizes] = this.formFilesArray.value.reduce(([fileNames, fileSizes], file) => {
          fileNames.push(file.fileName);
          fileSizes.push(file.size);
          return [fileNames, fileSizes];
        }, [[], []]);
        const filesDuplication = mappedFiles.reduce((duplications, file) => {
          const duplicationIndex = currentFileNames.findIndex(currentFileName => currentFileName === file.fileName);
          if (duplicationIndex !== -1 && currentFileSizes[duplicationIndex] === file.size) {
            return [
              ...duplications,
              {
                fileName: file.fileName,
                size: file.size,
              },
            ];
          }
          return duplications;
        }, []);

        if (filesDuplication.length > 0) {
          const filesList = filesDuplication.map(file => `${file.fileName} (${filesize(file.size)})`).join(', ');
          this.promptDialogService.openPrompt({
            // eslint-disable-next-line max-len
            message: `We see the next file(s) already exist: <b>${filesList}</b>. If these are the same file, please submit different files.`,
            buttonNo: 'OK',
          });
          return;
        }
      }

      if (this.maxFilesNumber === 1 && filesListByDocType.length > 0) {
        const filesValue = this.formFilesArray.value.filter(file => file.docType !== filesDocType).concat(mappedFiles);
        this.formFilesArray.patchValue(filesValue, {
          emitEvent: true,
        });
      } else {
        mappedFiles.forEach(mappedFile => {
          this.formFilesArray.push(this.fb.control(mappedFile), {
            emitEvent: false,
          });
        });
        this.formFilesArray.updateValueAndValidity();
      }
    }
  }

  checkIdenticalPhotos(uploadedFiles: Files[]) {
    const uploadedFilesAreReceipt = this.sectionData.sectionName === 'Receipt';
    let receiptPhotos: any[] = [];
    if (this.formFilesArray.root.value.receiptFiles) {
      receiptPhotos = this.formFilesArray.root.value.receiptFiles
        .concat(uploadedFilesAreReceipt ? uploadedFiles : [])
        .map(file => ({
          sectionName: 'Receipt',
          productIndex: null,
          fileKey: `${file.fileName}${file.size}`,
        }));
    }

    const sectionKeys = ['section1', 'section2', 'section3', 'section4'];
    const productFiles: any[] = this.formFilesArray.root.value.productFiles.reduce((files, product, productIndex) => {
      sectionKeys.forEach((sectionKey, sectionIndex) => {
        const isSameSectionWithControl =
          this.sectionData.productIndex === productIndex && this.sectionData.sectionIndex === sectionIndex;
        const sectionFiles = product[sectionKey].concat(isSameSectionWithControl ? uploadedFiles : []);
        sectionFiles.forEach(sectionFile => {
          files.push({
            sectionName: `#${sectionIndex + 1}`,
            productIndex,
            fileKey: `${sectionFile.fileName}${sectionFile.size}`,
          });
        });
      });
      return files;
    }, []);

    const identicalPhotos = receiptPhotos.concat(productFiles).reduce((identicalObj, currentFileSection) => {
      if (!identicalObj.hasOwnProperty(currentFileSection.fileKey)) {
        identicalObj[currentFileSection.fileKey] = [];
      }

      const isIdenticalInSameSection = identicalObj[currentFileSection.fileKey].find(
        item => item.sectionName === currentFileSection.sectionName,
      );

      if (!isIdenticalInSameSection) {
        identicalObj[currentFileSection.fileKey].push(currentFileSection);
      }

      return identicalObj;
    }, {});

    const photoErrors = Object.keys(identicalPhotos).reduce((errorArray, identicalPhotoKey) => {
      const value = identicalPhotos[identicalPhotoKey];
      if (value.length > 1) {
        errorArray.push(
          `photos ${value.map(section => `<strong>${section.sectionName}</strong>`).join(', ')} (product ${
            value[0].productIndex + 1
          })`,
        );
      }

      return errorArray;
    }, []);

    const errorMessage = photoErrors.join(' and ');

    if (errorMessage) {
      return (
        'We see that '
        + errorMessage
        + ' have an identical filename. If these are the same photo, please submit different photos per the instructions provided in the Photo Guide.'
      );
    } else {
      return null;
    }
  }

  private _mapFile(file: IUploadedFile, index: number): Files {
    return {
      id: null,
      index,
      fileName: file.originalFile.name,
      originalFile: file.originalFile,
      thumbnailUrl: file.thumbnailUrl,
      viewerUrl: file.thumbnailUrl,
      url: file.thumbnailUrl,
      mimeType: file.originalFile.type,
      dateCreated: new Date().toISOString(),
      thumbnailLoaded: false,
      mainLoaded: false,
      thumbnailBase64: null,
      mainBase64: null,
      size: file.originalFile.size,
      docType: file.docType || null,
      parentId: file.parentId || null,
      fileLocationInForm: file.fileLocationInForm || undefined,
    };
  }
}
