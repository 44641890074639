import { AsyncPipe, CurrencyPipe, NgClass, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { CurrencyDirective } from '@shared/directives/currency/currency.directive';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import { NewsDialogComponent } from '@shared/modules/side-dialog/components/news-dialog/news-dialog.component';
import { PipesModule } from '@shared/pipes/pipes.module';
import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';

import { CorrespondenceGridCellComponent } from './components/correspondence-grid-cell/correspondence-grid-cell.component';
import { CorrespondencePhoneCallsGridCellComponent } from './components/correspondence-phone-calls-grid-cell/correspondence-phone-calls-grid-cell.component';
import { DataGridComponent } from './components/data-grid/data-grid.component';
import { DynamicGridCellComponent } from './components/dynamic-grid-cell/dynamic-grid-cell.component';
import { EmailContentsGridCellComponent } from './components/email-contents-grid-cell/email-contents-grid-cell.component';
import { GridComponent } from './components/grid/grid.component';
import { GridAmountCellComponent } from './components/grid-amount-cell/grid-amount-cell.component';
import { GridDateCellComponent } from './components/grid-date-cell/grid-date-cell.component';
import { GridDateTimeCellComponent } from './components/grid-date-time-cell/grid-date-time-cell.component';
import { GridPriceCellComponent } from './components/grid-price-cell/grid-price-cell.component';
import { GridSearchComponent } from './components/grid-search/grid-search.component';
import { LettersModalComponent } from './components/letters-modal/letters-modal.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PreviousClaimCellComponent } from './components/previous-claim-cell/previous-claim-cell.component';
import { ServiceStatusGridCellComponent } from './components/service-status-grid-cell/service-status-grid-cell.component';
import { ServiceTypeGridCellComponent } from './components/service-type-grid-cell/service-type-grid-cell.component';

@NgModule({
  declarations: [
    CorrespondenceGridCellComponent,
    CorrespondencePhoneCallsGridCellComponent,
    DataGridComponent,
    DynamicGridCellComponent,
    EmailContentsGridCellComponent,
    GridAmountCellComponent,
    GridComponent,
    GridDateCellComponent,
    GridDateTimeCellComponent,
    GridPriceCellComponent,
    GridSearchComponent,
    LettersModalComponent,
    NewsDialogComponent,
    PaginationComponent,
    PreviousClaimCellComponent,
    ServiceStatusGridCellComponent,
    ServiceTypeGridCellComponent,
  ],
  imports: [
    AsyncPipe,
    CurrencyDirective,
    CurrencyPipe,
    CustomOverlayScrollbarDirective,
    FormsModule,
    LoaderModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatLegacyInputModule,
    NgClass,
    MatTooltipModule,
    NgForOf,
    NgIf,
    NgTemplateOutlet,
    NgxTrimDirectiveModule,
    PipesModule,

  ],
  exports: [
    CorrespondenceGridCellComponent,
    CorrespondencePhoneCallsGridCellComponent,
    DataGridComponent,
    DynamicGridCellComponent,
    EmailContentsGridCellComponent,
    GridAmountCellComponent,
    GridComponent,
    GridDateCellComponent,
    GridDateTimeCellComponent,
    GridPriceCellComponent,
    GridSearchComponent,
    LettersModalComponent,
    NewsDialogComponent,
    PaginationComponent,
    PreviousClaimCellComponent,
    ServiceStatusGridCellComponent,
    ServiceTypeGridCellComponent,
  ],
})
export class GridModule {}
