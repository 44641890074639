import { ChangeDetectionStrategy,Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';

@Component({
  selector: 'app-service-suspended-dialog',
  standalone: true,
  templateUrl: './service-suspended-dialog.component.html',
  styleUrls: ['./service-suspended-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIconModule,
    CustomOverlayScrollbarDirective,
    MatButtonModule,
  ],
})
export class ServiceSuspendedDialogComponent {
  constructor(private readonly matDialogRef: MatDialogRef<any>) {}

  close() {
    this.matDialogRef.close();
  }
}
