<ng-container *rxIf="hasRequiredValidators$">
  <ng-container *rxIf="notAllCriteriaMet$">
    <ng-container *rxLet="passwordMatches$; let passwordMatches">
      <ng-container *rxLet="passwordOptions$; let passwordOptions">
        <div class="password-matches">
          <span
            *ngIf="passwordMatches.requireUppercase.required"
            [ngClass]="{ match: passwordMatches.requireUppercase.match }">
            At least one upper case letter.
          </span>
          <span
            *ngIf="passwordMatches.requireLowercase.required"
            [ngClass]="{ match: passwordMatches.requireLowercase.match }">
            At least one lower case letter.
          </span>
          <span
            *ngIf="passwordMatches.requireDigit.required"
            [ngClass]="{ match: passwordMatches.requireDigit.match }">
            At least one digit.
          </span>
          <span
            *ngIf="passwordMatches.requireNonAlphanumeric.required"
            [ngClass]="{ match: passwordMatches.requireNonAlphanumeric.match }">
            At least one special character.
          </span>
          <span
            *ngIf="passwordMatches.requiredLength.required"
            [ngClass]="{ match: passwordMatches.requiredLength.match }">
            At least {{ passwordOptions.requiredLength }} characters long.
          </span>
          <span
            *ngIf="passwordMatches.requiredUniqueChars.required"
            [ngClass]="{ match: passwordMatches.requiredUniqueChars.match }">
            At least {{ passwordOptions.requiredUniqueChars }} unique character{{
              passwordOptions.requiredUniqueChars > 1 ? "s" : ""
            }}.
          </span>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
