import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { FormErrorComponent } from '@shared/components/form-error/form-error.component';
import { ThBaseFormControlElement } from '@shared/form/base-form-control-element.directive';


@Component({
  selector: 'app-form-textarea',
  standalone: true,
  templateUrl: './form-textarea.component.html',
  styleUrls: ['./form-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    MatLegacyInputModule,
    MatIconModule,
    NgIf,
    FormErrorComponent,
    NgxTrimDirectiveModule,
    ReactiveFormsModule,
  ],
})
export class FormTextareaComponent extends ThBaseFormControlElement<string> {
  @Input()
    label = 'Tell us more.';

  @Input()
    errorMessage = '';

  @Input() showIsRequiredMessage = true;

  @Input()
    placeholder: string;

  @Input()
    hint: string;

  @Input()
    matTextareaAutosize = true;

  @Input()
    maxLength = 32769;

  @Input()
    minRows = 1;

  @Input()
    maxRows = 8;

  @Input()
    tooltipText = '';

  @Input()
    useTrim: '' | 'blur' | false = 'blur';

  tooltipAttentionText = '';
}
