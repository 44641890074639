import { DecimalPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyInputModule } from '@angular/material/legacy-input';

@Component({
  selector: 'app-form-error',
  standalone: true,
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    MatLegacyInputModule,
    NgIf,
    DecimalPipe,
  ],
})
export class FormErrorComponent {
  @Input() label = 'This info';
  @Input() showIsRequiredMessage = true;
  @Input() dataType: string = null;
  @Input() formControlRef: FormControl;

}
