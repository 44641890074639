import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { LookupApiService } from '../api/lookup-api.service';
import { StorageItem } from '../enums/storage-item.enum';
import { Identity } from '../interfaces/util/identity.interface';
import { AppStorage } from '../utils/storage';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  cacheSubject$ = new Subject();
  lookupKeyToHttp = {
    language: 'lookupLanguage',
    contactmethod: 'lookupContactMethod',
    problemtype: 'lookupProblemType',
    escalation: 'lookupEscalation',
    contacttime: 'lookupContactTime',
    surfacetype: 'lookupSurfaceType',
    partsavailability: 'lookupPartsAvailability',
    productavailability: 'lookupProductAvailability',
    agreementtype: 'lookupAgreementType',
    serviceactiontype: 'lookupServiceActionType',
    retailerclassification: 'lookupRetailerClassification',
    technician: 'lookupTechnician',
    problemcause: 'lookupProblemCause',
    states: 'lookupStates',
  };

  constructor(private lookupApiService: LookupApiService) {
    this.cacheSubject$.pipe(mergeMap(items => of(items))).subscribe(([key, value]) => {
      const cachedLookups = AppStorage.get(StorageItem.Lookups) || {};
      cachedLookups[key] = value;
      AppStorage.set(StorageItem.Lookups, cachedLookups);
    });
  }

  getLookup(lookupKey: string): Observable<Identity[]> {
    const cachedLookups = AppStorage.get(StorageItem.Lookups) || {};
    if (cachedLookups[lookupKey]) {
      return of(cachedLookups[lookupKey]);
    } else {
      return this.lookupApiService[this.lookupKeyToHttp[lookupKey]]().pipe(
        tap(lookupData => {
          this.cacheSubject$.next([lookupKey, lookupData]);
        }),
      );
    }
  }
}
