import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CrudApiBaseService } from '@core/api/crud-api.base';
import { IEnhancement } from '@core/interfaces/enhancement/enhancement.interface';

@Injectable({ providedIn: 'root' })
export class EnhancementApiService extends CrudApiBaseService<IEnhancement> {
  endpoint = 'enhancement';

  getRecent(includeSeen: boolean): Observable<IEnhancement[]> {
    return this.httpClient.get<IEnhancement[]>(this.getUrl(`recent?includeSeen=${includeSeen}`));
  }

  markOneAsSeen(id: number): Observable<void> {
    return this.httpClient.put<void>(this.getUrl(`markasseen?enhancementId=${id}`), {});
  }

  markAllAsSeen(): Observable<void> {
    return this.httpClient.put<void>(this.getUrl('markallasseen'), {});
  }
}
