import { NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';

import { WizardStepButtonsComponent } from './wizard-step-buttons/wizard-step-buttons.component';

@NgModule({
  declarations: [WizardStepButtonsComponent],
  imports: [LoadingButtonDirective, MatButtonModule, NgIf],
  exports: [WizardStepButtonsComponent],
})
export class WizardModule {}
