import { Component, Input, OnInit } from '@angular/core';

import { GridColumnModel } from '../../models/grid/grid-column.model';

@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
})
export class DataGridComponent implements OnInit {
  _source: any;

  @Input() columns: GridColumnModel[];
  @Input()
  set source(value: any) {
    this._source = value;
  }

  constructor() {}

  ngOnInit() {}
}
