<ng-container *rxLet="vm$; let vm">
  <th-table
    *ngIf="vm.rows.length > 0; else noResultsTemplate"
    appAltTable
    [columns]="vm.columns"
    [data]="vm.rows"
    [isSortable]="false">
  </th-table>
</ng-container>
<ng-template #noResultsTemplate>
  No Repair Actions
</ng-template>
