import { inject, Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class SideDialogStateService {
  dialogClosed$ = new Subject<boolean>();
  matDialogRef = inject(MatDialogRef);

  private _isVisible$$ = new BehaviorSubject(true);

  get isVisible$(): Observable<boolean> {
    return this._isVisible$$.asObservable();
  }

  close(result: any = null): void {
    this._isVisible$$.next(false);
    this._isVisible$$.complete();
    setTimeout(() => {
      this.dialogClosed$.next(true);
      this.dialogClosed$.complete();
      this.matDialogRef.close(result);
    }, 333);
  }
}
