import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { NgForOf, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { PushPipe } from '@rx-angular/template/push';
import { combineLatestWith, map } from 'rxjs/operators';

import { BreakpointService } from '@core/services/breakpoint.service';
import { ThTableCellComponent } from '@shared/modules/table/components/table-cell/table-cell.component';
import { ThTableCellToolbarComponent } from '@shared/modules/table/components/table-cell-toolbar/table-cell-toolbar.component';
import { ThTableColComponent } from '@shared/modules/table/components/table-col/table-col.component';
import { MultiSortDirective } from '@shared/modules/table/directives/multi-sort.directive';
import { MultiSortHeaderDirective } from '@shared/modules/table/directives/multi-sort-header.directive';
import { ThTableSelection } from '@shared/modules/table/selection/table-selection';
import { TTableCell } from '@shared/modules/table/types/table-cell.enum';
import { TAppTableCol } from '@shared/modules/table/types/table-col.enum';
import { TTableOptionAction } from '@shared/modules/table/types/table-option-action.enum';

import { ITableAction } from '../../interfaces/table-action.interface';
import { IAppTableCol } from '../../interfaces/table-col.interface';
import { IAppTableRow } from '../../interfaces/table-row.interface';

interface ITableViewState {
  columns: IAppTableCol[];
  columnsMobile: IAppTableCol[];
  hiddenColumns: string[];
  displayColumns: string[];
}

@Component({
  selector: 'app-table-view, th-table-view',
  standalone: true,
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatTableModule,
    MultiSortDirective,
    NgIf,
    NgForOf,
    MatCheckboxModule,
    MultiSortHeaderDirective,
    ThTableColComponent,
    CdkOverlayOrigin,
    NgStyle,
    MatSortModule,
    ThTableCellComponent,
    CdkConnectedOverlay,
    ThTableCellToolbarComponent,
    PushPipe,
    LetDirective,
  ],
  providers: [RxState],
})
export class ThTableViewComponent {
  @Input() data: IAppTableRow[];

  @Input() columns: IAppTableCol[];

  @Input() set hiddenColumns(hiddenColumns: string[]) {
    this.state.set({ hiddenColumns });
  }

  @Input() isClickable: boolean;

  @Input() isSortable = true;

  @Input() rowHeight: string;

  @Input() mobileColumnName: boolean;
  @Input() showToolbar: boolean;

  @Input() sorting: Sort[] = [];

  @Input() selection: ThTableSelection<any, any>;
  @Input() customCellTemplate: TemplateRef<any>;
  @Output() action = new EventEmitter<ITableAction>();

  @Output() rowClicked = new EventEmitter<{index: number; data: any}>();

  @Output() sortChanged = new EventEmitter<Sort[]>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  vm$ = this.state.select();

  hoveredRow: number;
  toolbarPositions = [
    {
      overlayX: 'end',
      overlayY: 'center',
      originX: 'end',
      originY: 'top',
      offsetX: -24,
    },
  ];

  /**
   * Columns list that are merged into the first cell of the row on mobile
   */

  get showTableHeader(): boolean {
    return !(this.columns ?? []).some(col => col.type === TAppTableCol.Hidden);
  }

  constructor(
    public readonly breakpointService: BreakpointService,
    private readonly state: RxState<ITableViewState>,
  ) {
    this.state.connect('columns', this.breakpointService.isMobileOrTablet$.pipe(
      combineLatestWith(this.state.select('hiddenColumns')),
      map(([isMobile, hiddenColumns]) => {
        const columns = this.columns || [];
        return columns
          .filter(col => !isMobile || col.mobile)
          .filter(column => /*column.notHideable ||*/ !(hiddenColumns || []).includes(column.id));
      }),
    ));

    this.state.connect('displayColumns', this.state.select('columns').pipe(
      map(columns => {
        const displayColumns = columns.map(item => this.buildColKey(item));
        if (this.selection) {
          displayColumns.unshift('select');
        }

        return displayColumns;
      }),
    ));

    this.state.connect('columnsMobile', this.breakpointService.isMobileOrTablet$.pipe(
      map(isMobile => {
        const columns = this.columns || [];
        return columns.filter(col => isMobile && !col.mobile && !col.mobileHidden);
      }),
    ));
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows(): void {
    if (this.selection.isAllOnPageSelected) {
      this.selection.clear();
    } else {
      const dataModel = this.data.map(row => this._parseRowToModel(row));
      this.selection.select(...dataModel);
    }
  }

  selectRow(row: IAppTableRow): void {
    this.selection.toggle(this._parseRowToModel(row));
  }

  triggerAction(action: ITableAction): void {
    this.action.emit(action);
  }

  triggerRowClick(index: number, row: IAppTableRow): void {
    this.rowClicked.emit({ index, data: row });
  }

  buildColKey(col: IAppTableCol): string {
    return col.id;
  }

  trackById(index: number, item: IAppTableCol): string {
    return item.id;
  }

  handleSortChanged(sortData: Sort[]): void {
    this.sortChanged.emit(sortData);
  }

  handleAction(type: TTableOptionAction, data: any): void {
    this.action.emit({ type, data });
  }

  handleMouseLeave(event: MouseEvent): void {
    if (event.relatedTarget instanceof Element && !event.relatedTarget.closest('.table-toolbar-overlay')) {
      this.hoveredRow = undefined;
    }
  }

  private _parseRowToModel(row: IAppTableRow): any {
    const rowKeys = Object.keys(row);
    return rowKeys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: row[key].type === TTableCell.Custom ? row[key].data : row[key].value,
      }),
      {},
    );
  }
}
