import { Injectable } from '@angular/core';

import { StoreState } from '@shared/modules/claim-controls/components/problem-type-select/store/problem-type.state';

import { BaseStore } from '../../../../../../core/store/_base/base.store';
import IStoreState = StoreState.IStoreState;
import initialState = StoreState.initialState;
import { finalize, switchMap, take } from 'rxjs/operators';

import { ProblemTypeApi } from '../../../../../../core/api/problem-type.api';

@Injectable()
export class ProblemTypeStore extends BaseStore<IStoreState> {
  constructor(private readonly problemTypeApi: ProblemTypeApi) {
    super(initialState);
  }

  loadData(lazy: boolean = false) {
    this.updateState({ loading: true });
    this.select$('searchRequest')
      .pipe(
        take(1),
        switchMap(searchRequest => this.problemTypeApi.all(searchRequest)),
        finalize(() => this.updateState({ loading: false })),
      )
      .subscribe(({ items, totalCount, page, pageCount }) => {
        this.updateState({
          items: lazy ? this.get().items.concat(items || []) : items || [],
          pagination: {
            totalCount,
            page,
            pageCount,
          },
        });
      });
  }

  search(searchString: string) {
    const searchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...searchRequest,
        page: 1,
        searches: [searchString],
      },
    });
    this.loadData();
  }

  updatePagination(page: number, pageSize?: number) {
    const searchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...searchRequest,
        page,
        pageSize: pageSize || searchRequest.pageSize,
      },
    });
    this.loadData(true);
  }
}
