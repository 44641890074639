import { AsyncValidatorFn, FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConstants } from '@core/constants/app.constants';
import { Files, ITechPortalFile } from '@core/interfaces/claims/files.interface';

export function validateArrayMaxFilesSize(maxFilesSize: number = AppConstants.maxFilesSize) {
  return (formArray: FormArray<FormControl<Files>>) => {
    const totalFilesSize = formArray.value.reduce((filesSize, file) => {
      filesSize += file.size;
      return filesSize;
    }, 0);
    if (totalFilesSize > maxFilesSize) {
      return {
        sizeError: {
          maxFilesSize,
          totalFilesSize,
        },
      };
    } else {
      return null;
    }
  };
}

export function validateFilesGroupMaxFilesSize(
  arrayControlNames: string[],
  maxFilesSize: number = AppConstants.maxFilesSize,
) {
  return (formGroup: FormGroup<{[key: string]: FormArray<FormControl<Files>>}>) => {
    const totalFilesSize = arrayControlNames
      .map(controlName => formGroup.value[controlName])
      .flat()
      .reduce((filesSize, file) => {
        filesSize += file.size;
        return filesSize;
      }, 0);
    if (totalFilesSize > maxFilesSize) {
      return {
        sizeError: {
          maxFilesSize,
          totalFilesSize,
        },
      };
    } else {
      return null;
    }
  };
}

export function validateFilesRequiredAsync(files$: Observable<Array<Files | ITechPortalFile>>): AsyncValidatorFn {
  return (formArray: FormArray<FormControl<Files>>): Observable<ValidationErrors | null> => files$.pipe(
    map(files => {
      if (files.length === 0 && formArray.value.length === 0) {
        return {
          filesRequired: true,
        };
      } else {
        return null;
      }
    }),
  );
}
