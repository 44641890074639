import { Directive,OnInit, Optional, Self } from '@angular/core';
import { FormControl,
  FormControlDirective,
  FormControlName,
  NgControl,
  NgModel } from '@angular/forms';

import { BaseComponent } from './base.component';

@Directive({
  selector: '[appBaseFormControlElement]',
})
export abstract class BaseFormControlElement extends BaseComponent implements OnInit {
  formControl: FormControl;

  protected constructor(@Optional() @Self() public ngControl: NgControl) {
    super();
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  // These are just to make Angular happy. Not needed since the formControl is passed to the child input
  writeValue(obj: any): void {}
  registerOnChange(fn: (_: any) => void): void {}
  registerOnTouched(fn: any): void {}

  ngOnInit(): void {
    if (this.ngControl instanceof FormControlName) {
      this.formControl = this.ngControl.control;
    } else if (this.ngControl instanceof FormControlDirective) {
      this.formControl = this.ngControl.control;
    } else if (this.ngControl instanceof NgModel) {
      this.formControl = this.ngControl.control;
      this.formControl.setValue(this.ngControl.viewModel);
      this.formControl.valueChanges.subscribe(x => this.ngControl.viewToModelUpdate(this.formControl.value));
    } else if (!this.ngControl) {
      this.formControl = new FormControl();
    }
  }
}
