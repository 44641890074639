import { Component,inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA, MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { ClaimServiceAction } from '@core/interfaces/claims/claimDetails.interface';
import { ClaimItemStore } from '@core/store/claim-item/claim-item.store';

@Component({
  selector: 'app-close-settlements-dialog',
  standalone: true,
  templateUrl: './close-settlements-dialog.component.html',
  styleUrls: ['./close-settlements-dialog.component.scss'],
  imports: [
    MatLegacyInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    NgxTrimDirectiveModule,
  ],
})
export class CloseSettlementsDialogComponent {
  note = '';
  dialogData: {
    data: ClaimServiceAction;
  } = inject(MAT_DIALOG_DATA);

  constructor(
    private readonly claimItemStore: ClaimItemStore,
    public dialogRef: MatDialogRef<any>,
  ) {}

  processCashSettlement() {
    this.claimItemStore.processCashSettlement(this.dialogData.data.id, this.note).subscribe(() => {
      this.dialogRef.close();
    });
  }
}
