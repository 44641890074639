import { Component, ComponentFactoryResolver,Input, OnInit, ViewContainerRef } from '@angular/core';

import { DynamicGridCell } from '../../../../../core/interfaces/grid/dynamic-grid-cell.interface';

@Component({
  selector: 'app-dynamic-grid-cell',
  templateUrl: './dynamic-grid-cell.component.html',
  styleUrls: ['./dynamic-grid-cell.component.scss'],
})
export class DynamicGridCellComponent implements OnInit {
  @Input() component: any;
  @Input() data: any;
  @Input() fieldName: string;

  constructor(public viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    (<DynamicGridCell>componentRef.instance).data = this.data;
    (<DynamicGridCell>componentRef.instance).fieldName = this.fieldName;
  }
}
