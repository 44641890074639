<app-collapsible-block [lastChild]="lastChild">
  <h2 header class="collapsible-data-block__title">{{headingTitle}}</h2>
  <div content>
    <ng-container *ngIf="grid">
      <ng-container *ngIf="grid.source.length > 0">
        <div [class.collapsible-data-block__grid_desktop]="useMobileLayout">
          <app-data-grid [columns]="grid.columns"
                         [source]="grid.source"></app-data-grid>
        </div>
        <div *ngIf="useMobileLayout" class="collapsible-data-block__grid_mobile">
          <ul class="collapsible-data-block__mobile-items-list">
            <li *ngFor="let item of grid.columns" class="collapsible-data-block__mobile-list-item">
              <p>
                {{item.title}}:
                <app-dynamic-grid-cell *ngIf="item.component"
                                       [component]="item.component"
                                       [fieldName]="item.fieldName"
                                       [data]="grid.source[0]"></app-dynamic-grid-cell>
                <ng-container *ngIf="!item.component">{{grid.source[0][item.fieldName] | appEmptyValue}}</ng-container>
              </p>
            </li>
          </ul>
        </div>
      </ng-container>
      <div *ngIf="grid.source.length === 0">
        No {{headingTitle}}
      </div>
    </ng-container>
    <ng-container *ngIf="!grid">
      <ng-content></ng-content>
    </ng-container>
  </div>
</app-collapsible-block>
