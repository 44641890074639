import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IDataSearch } from '@core/interfaces/data-search.interface';
import { ClaimSearchRequest } from '@core/models/search/search-request.model';

import { TServiceActionStage } from '../enums/claim/service-action-stage.enum';
import { TServiceActionType } from '../enums/service-action/service-action-type.enum';
import { ServerClaimIncidentPartOrders } from '../interfaces/claims/claimDetails.interface';
import { Files } from '../interfaces/claims/files.interface';
import { CrudApiBaseService } from './crud-api.base';

interface IUpdatedServiceAction {
  serviceActionId: number;
  serviceActionType: TServiceActionType;
  serviceActionStage: TServiceActionStage;
  processStage: string;
  errorMessage: string;
}

@Injectable({ providedIn: 'root' })
export class ServiceActionApi extends CrudApiBaseService<any> {
  endpoint = 'serviceaction';

  all(searchRequest: Partial<ClaimSearchRequest>, queryParams?: {tileId?: string}) {
    return this.httpClient.post<IDataSearch<any>>(this.getUrl('all'), searchRequest, {
      params: queryParams,
    });
  }

  processExchanges(id: number, note: string): Observable<any> {
    return this.httpClient.put<any>(this.getUrl(`${id}/exchangeprocessed`), {
      note,
    });
  }

  processSettlement(id: number, note: string): Observable<any> {
    return this.httpClient.put<any>(this.getUrl(`${id}/settlementprocessed`), {
      note,
    });
  }

  processComplaint(id: number, note: string): Observable<any> {
    return this.httpClient.put<any>(this.getUrl(`${id}/complaintprocessed`), { note });
  }

  claimDetailsAuthorizationAvailabilityOrdering(id: number, data: any) {
    return this.httpClient.put<IUpdatedServiceAction>(this.getUrl(`${id}/authorize`), data);
  }

  getServiceActionDetails(id: number, type: number): Observable<ServerClaimIncidentPartOrders> {
    return this.httpClient.get<any>(this.getUrl(`${id}/details?serviceActionType=${type}`));
  }

  requestPayment(files: Files[], id: string, data: any) {
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('formfiles', files[i].originalFile);
    }
    formData.append('model', JSON.stringify(data));

    return this.httpClient.put<IUpdatedServiceAction>(this.getUrl(`${id}/request-payment`), formData);
  }

  getShippingLabel(serviceActionId: number): Observable<{href: string}> {
    return this.httpClient.get<{href: string}>(this.getUrl(`${serviceActionId}/shippinglabel`));
  }

  printPackage(serviceActionId: number): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(this.getUrl(`${serviceActionId}/packageinsert.pdf`), {
      observe: 'response',
      responseType: 'blob',
    });
  }
}
