export enum TServicerType {
  AssignedServicingRetailer = 223940001,
  Retailer = 223940000,
  ThirdPartyRepairDepot = 223940002,
}

export const SERVICER_TYPE_TO_TITLE: {[key in TServicerType]: string} = {
  [TServicerType.AssignedServicingRetailer]: 'Assigned Servicing Retailer',
  [TServicerType.Retailer]: 'Retailer',
  [TServicerType.ThirdPartyRepairDepot]: 'Third Party Repair Depot',
};
