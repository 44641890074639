<mat-form-field
  [class.disabled]="disabled"
  [class._common-mat-form-field_hide-errors]="!formFieldProvider.showErrors"
  [class._common-theme-field]="!!formControl.value">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <div
    *ngIf="loading"
    class="select__input-loader">
    <app-loader></app-loader>
  </div>
  <mat-select
    #matSelectInstance
    [formControl]="formControl"
    [multiple]="multiple"
    [disabled]="disabled"
    [disableOptionCentering]="true"
    (selectionChange)="itemSelect($event)"
    (openedChange)="openedHandler($event)"
    [panelClass]="{
      'custom-overlay-scrollbar': true,
      'select--loading': loading,
      'select--custom-search': showSearch,
      'select--search': showSearch && (dataLength$ | async) > 5,
      'select--table': templateType === 'table'
    }"
    appMatSelectInfiniteScroll
    appScrollLoadingOverlay
    [loading]="loading"
    [disableInfinite]="!loadOnScroll"
    (scrolled)="onScrollDown()">
    <mat-option
      *ngIf="showSearch"
      class="custom-option-search"
      [disabled]="true">
      <input
        data-lpignore="true"
        #searchInput
        autofocus
        (keydown)="$event.stopPropagation()"
        class="select__search-input"
        name="search"
        matInput
        trim="blur"
        type="text"
        autocomplete="off"
        (ngModelChange)="modelChanged($event)"
        [(ngModel)]="searchString"
        [ngModelOptions]="{ standalone: true }"
        (keydown.enter)="searchEvent($event)"
        placeholder="Search" />
      <mat-icon
        *ngIf="searchString.length > 0 && !loading"
        (click)="clearSearch()"
        class="select__search-reset"
        svgIcon="close"></mat-icon>
      <div
        *ngIf="loading"
        class="select__loader">
        <app-loader></app-loader>
      </div>
    </mat-option>
    <div
      class="select__option-columns mat-option"
      *ngIf="templateType === 'table' && (dataLength$ | async) > 0">
      <p
        *ngFor="let column of optionColumns"
        class="select__option--{{ column.fieldName }}">
        {{ column.title }}
      </p>
    </div>
    <mat-label
      *ngIf="optionsLabel"
      class="select__empty-option select__options-label">
      {{ optionsLabel }}
    </mat-label>
    <mat-option
      class="select__empty-option"
      [class.hidden]="(dataLength$ | async) === 0"
      [value]="null"
      *ngIf="!required && !isRequired && !multiple && showEmptyOption">
      {{ emptyLabelPrefix }} {{ label }}
    </mat-option>
    <ng-container [ngSwitch]="templateType">
      <!-- Default Template -->
      <ng-container *ngSwitchCase="'default'">
        <mat-option
          *ngIf="showOutOfScopeValue && (outOfScopeValue$ | async) as outOfScopeValue"
          [value]="outOfScopeValue">
          {{ outOfScopeValue }} (out of scope)
        </mat-option>
        <mat-option
          *ngIf="
            loadOnScroll &&
            !showOutOfScopeValue &&
            (showSelectedOutOfListItem$ | async) &&
            (selectedItem$ | async) as selectedItem
          "
          [value]="selectedItem[valueField]">
          {{ selectedItem[titleField] }}
          <div
            *ngIf="selectedItem[subTitleField]"
            class="select__sub-text">
            {{ selectedItem[subTitleField] }}
          </div>
        </mat-option>
        <mat-option
          [title]="item[titleField]"
          *ngFor="let item of data$ | async"
          [value]="item[valueField]"
          [disabled]="item.disabled">
          {{ item[titleField] }}
          <div
            *ngIf="item[subTitleField]"
            class="select__sub-text">
            {{ item[subTitleField] }}
          </div>
        </mat-option>
      </ng-container>
      <!-- Table Template -->
      <ng-container *ngSwitchCase="'table'">
        <mat-option
          *ngFor="let item of data$ | async"
          [value]="item[valueField]">
          <app-option-as-table
            [item]="item"
            [columns]="optionColumns"></app-option-as-table>
        </mat-option>
      </ng-container>
      <!-- Tree Template -->
      <ng-container *ngSwitchCase="'tree'">
        <ng-container *ngFor="let item of data$ | async">
          <div *ngTemplateOutlet="optionsTree; context: { optionItem: item, isChild: false }"></div>
        </ng-container>
        <ng-template
          #optionsTree
          let-optionItem="optionItem"
          let-isChild="isChild">
          <div [ngClass]="{ 'child-option': isChild }">
            <mat-option [value]="optionItem[valueField]">{{ optionItem[titleField] }}</mat-option>
            <ng-container *ngFor="let children of optionItem.children">
              <div *ngTemplateOutlet="optionsTree; context: { optionItem: children, isChild: true }"></div>
            </ng-container>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
    <div *ngIf="(dataLength$ | async) === 0">
      <mat-option
        disabled=""
        value="noResults">
        No results found
      </mat-option>
    </div>
  </mat-select>
  <mat-icon
    class="select__error-icon"
    *ngIf="formControl.status === 'INVALID' && formControl.touched"
    matSuffix
    svgIcon="error-icon"></mat-icon>
  <mat-error>
    <app-form-error
      [formControlRef]="formControl"
      [label]="label"></app-form-error>
  </mat-error>
</mat-form-field>
