<div class="collapsible-block"
     [class.collapsed]="collapsed"
     [class.collapsible-block_lastChild]="lastChild"
     [class.collapsible-block_shortView]="sizeVertical === 'short'">
  <div class="collapsible-block__header" (click)="toggleClass()">
    <ng-content select="[header]"></ng-content>
    <mat-icon class="collapsible-block__arrow _common-icon--theme" svgIcon="arrow-down"></mat-icon>
  </div>
  <div class="collapsible-block__content">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
