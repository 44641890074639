import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { CarouselComponent } from './carousel.component';
import { CarouselNewComponent } from './carousel-new/carousel-new.component';
import { CarouselSlideComponent } from './carousel-slide/carousel-slide.component';

@NgModule({
  declarations: [CarouselComponent, CarouselNewComponent, CarouselSlideComponent],
  imports: [CommonModule, MatIconModule, MatIconModule],
  exports: [CarouselComponent, CarouselNewComponent, CarouselSlideComponent],
})
export class CarouselModule {}
