import { Component, OnInit } from '@angular/core';

import { PlanUtil } from '@core/utils/plan.util';

@Component({
  selector: 'app-consumer-plan-status-grid-cell',
  templateUrl: './consumer-plan-status-grid-cell.component.html',
  styleUrls: ['./consumer-plan-status-grid-cell.component.scss'],
})
export class ConsumerPlanStatusGridCellComponent implements OnInit {
  data: any;
  fieldName: string;
  planStatuses = PlanUtil.statusesObject();

  constructor() {}

  ngOnInit() {}
}
