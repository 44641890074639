import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-side-dialog-content',
  standalone: true,
  imports: [],
  template: '<ng-content></ng-content>',
  styleUrls: ['./side-dialog-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideDialogContentComponent {

}
