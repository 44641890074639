import { Injectable } from '@angular/core';
import { PlanDetailsComponent } from '@th-common-retailer/dialogs/plan-details/plan-details.component';

import { DIALOG_CONFIG_CUSTOM_ANIMATION } from '@core/constants/dialog.constants';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class DealerPlanDialogsService {
  constructor(private modalDialogService: ModalDialogService) {}

  openPlanDetailsDialog(planId: number, singleView: boolean = false): void {
    this.modalDialogService.open({
      title: 'Plan Details',
      data: {
        planId,
        singleView,
      },
    }, PlanDetailsComponent, DIALOG_CONFIG_CUSTOM_ANIMATION);
  }
}
