export enum TSmsHistoryStatus {
  /* statuses for Outgoing messages */
  PendingSend = 921580001, //message has not yet been confirmed as received by the messaging service (transitional state)
  Sending = 921580002, //messaging service has received message, is in process of sending message to carrier (transitional state)
  Sent = 1, //messaging service has passed message to carrier (usually transitional state; end state only if no delivered/undelivered confirmation is received from carrier)
  Failed = 921580005, //messaging service reported an error in sending or delivering the message (end state)
  Delivered = 921580006, //carrier has confirmed delivery of the message to the subscriber (end state)
  Undelivered = 921580007, //carrier has indicated that the message has not yet been delivered to the subscriber (can be end state or transitional state; depends on reason for non-delivery)

  /* statuses for Incoming messages */
  Unread = 921580003, //inbound message has been received and is flagged as "Unread" (default for most inbound messages)
  Received = 921580004 //inbound message has been received (and is not flagged as "Unread")
}
