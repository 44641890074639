import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import { DIALOG_CONFIG_CUSTOM_ANIMATION } from '@core/constants/dialog.constants';
import { Claim } from '@core/interfaces/claims/claim.interface';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';

import { ClaimDetailsComponent } from '../../claim-details.component';

@Component({
  selector: 'app-claim-details-popup-cell',
  standalone: true,
  templateUrl: './claim-details-popup-cell.component.html',
  styleUrls: ['./claim-details-popup-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimDetailsPopupCellComponent {
  data: any;
  fieldName: string;

  constructor(
    private readonly router: Router,
    private modalDialogService: ModalDialogService,
  ) {}

  openClaimDetails(claim: Claim): void {
    if (this.router.url.includes('claims')) {
      this.router.navigate([], {
        queryParams: { id: claim.id },
      });
    } else {
      this.modalDialogService.open({
        title: 'Claim Details',
        data: {
          claimInfo: claim,
          singleView: true,
        },
      }, ClaimDetailsComponent, DIALOG_CONFIG_CUSTOM_ANIMATION);
    }
  }
}
