export enum TRetailerAction {
  Invoice = 1,
  ProcessCashSettlement,
  ProcessReplacement,
  ProcessComplaint,
  ProcessTechWorkOrder,
  ViewAuthorizationAndOrderParts,
  ConfirmAvailability,
  ReplacementEvenExchange,
  EZReplacementEvenExchange,
  ReplacementReselection,
  EZReplacementReselection,
  PartOrderEnterDeliveryAndPaymentRequest,
}
