import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { WizardNavigation, WizardNavigationItem } from '../../../../../core/classes/base-wizard';

@Component({
  selector: 'app-wizard-navigation',
  templateUrl: './wizard-navigation.component.html',
  styleUrls: ['./wizard-navigation.component.scss'],
})
export class WizardNavigationComponent implements OnInit {
  @Input() navigationList: string[];
  @Input() navigationItems: WizardNavigation;
  @Input() form: UntypedFormGroup;
  @Input() currentStep: WizardNavigationItem;
  @Input() currentSubStep: number;
  @Input() additionalValidations: {[key: string]: boolean} = {};
  @Input() displayTitles = false;

  constructor() {}

  ngOnInit() {}
}
