<table class="data-grid claim-invoice-grid" *ngIf="parts">
  <thead>
  <tr>
    <th class="data-grid__head-cell grid__th--{{column.fieldName}}"
        *ngFor="let column of columns"
        [class.hide-print]="column.hideOnPrint">
      <span [class.th--cell-required]="requiredColumnName === column.fieldName">
        <span [class.help-text--required-right]="requiredColumnName === column.fieldName">{{column.title}}</span>
        <span *ngIf="requiredColumnName === column.fieldName">
            <mat-icon svgIcon="help_outline" class="_common-icon--red-help"
                      #tooltip="matTooltip"
                      [matTooltip]=requiredColumnTooltip
                      matTooltipPosition="above"></mat-icon>
        </span>
      </span>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let partOrder of parts; let i = index" class="grid__row">
    <td *ngFor="let column of columns"
        class="grid__cell grid__cell--{{column.fieldName}}"
        [class.hide-print]="column.hideOnPrint"
        [class.claim-invoice-grid__td-cost]="column.fieldName === fieldCost">
      <app-dynamic-grid-cell *ngIf="column.component"
                             [component]="column.component"
                             [fieldName]="column.fieldName"
                             [data]="partOrder"></app-dynamic-grid-cell>
      <ng-container *ngIf="!column.component">
        <ng-container *ngIf="column.fieldName !== fieldCost; else cellCost">
          {{partOrder[column.fieldName] | appEmptyValue}}
        </ng-container>
        <ng-template #cellCost>
          <mat-form-field class="field _common-table-td--cost claim-invoice-grid__content-cost"
                          [ngClass]="{'field--price': partOrder[column.fieldName]}">
            <span class="dollar" *ngIf="partOrder[column.fieldName]">$</span>
            <input data-lpignore="true" matInput appCurrency
                   autocomplete="section-product purchase-price" type="text"
                   (input)="calculateTotalRequested()"
                   [(ngModel)]="partOrder[column.fieldName]"/>
          </mat-form-field>
        </ng-template>
      </ng-container>
  </tr>

  <tr class="claim-invoice-grid__title-row">
    <td colspan="3" class="grid__cell claim-invoice-grid__td-title">Tax</td>
    <td class="grid__cell claim-invoice-grid__td-cost">
      <mat-form-field class="field _common-table-td--cost claim-invoice-grid__content-cost field--price">
        <span class="dollar" *ngIf="taxAmount !== undefined || taxAmount !== null">$</span>
        <input data-lpignore="true" matInput appCurrency
               autocomplete="section-product purchase-price" type="text"
               (input)="calculateTotalRequested()"
               [(ngModel)]="taxAmount"/>
      </mat-form-field>
    </td>
  </tr>
  <tr class="claim-invoice-grid__title-row">
    <td colspan="3" class="grid__cell claim-invoice-grid__td-title">Delivery Amount</td>
    <td class="grid__cell claim-invoice-grid__td-cost">
      <mat-form-field class="field _common-table-td--cost claim-invoice-grid__content-cost field--price">
        <span class="dollar" *ngIf="deliveryAmount !== undefined || deliveryAmount !== null">$</span>
        <input data-lpignore="true" matInput appCurrency
               autocomplete="section-product purchase-price" type="text"
               (input)="calculateTotalRequested()"
               [(ngModel)]="deliveryAmount"/>
      </mat-form-field>
    </td>
  </tr>
  <tr class="claim-invoice-grid__title-row">
    <td colspan="3" class="grid__cell claim-invoice-grid__td-title">Total Requested</td>
    <td class="grid__cell  claim-invoice-grid__td-cost">
      <div class="claim-invoice-grid__content-cost claim-invoice-grid__content-cost--readonly">
        <span [class.invisible]="!totalRequested"><strong>{{totalRequested | currency:'$'}}</strong></span>
      </div>
    </td>
  </tr>
  <tr *ngFor="let field of gridFields" class="claim-invoice-grid__title-row">
    <ng-container *ngIf="field.fieldName === fieldNameMaximumAuthorizedAmount">
      <td colspan="3" class="grid__cell claim-invoice-grid__td-title">
        <p>{{field.title || 'Maximum Authorized Amount'}}</p>
        <p class="claim-invoice-grid__content-tooltip">
          <app-tooltip-with-label [tooltipText]="'Montage will reimburse landed wholesale costs for the replaced item(s) but will not exceed 60% of the original retail price paid by the consumer. Please refer to the purchase order for more details.'"></app-tooltip-with-label>
        </p>
      </td>
      <td class="grid__cell  claim-invoice-grid__td-cost">
        <div class="claim-invoice-grid__content-cost claim-invoice-grid__content-cost--readonly">
          <span><strong>{{field.value | currency:'$'}}</strong></span>
        </div>
      </td>
    </ng-container>
  </tr>
  <tr class="claim-invoice-grid__title-row" *ngIf="gridFields.length">
    <td colspan="3" class="grid__cell claim-invoice-grid__td-requested-amount">
      <div class="claim-invoice-grid__requested-amount-title">
        <div class="claim-invoice-grid__requested-amount-title-content">{{requestedAmountGridFields.title || 'Requested Amount'}}</div>
      </div>
    </td>
    <td class="grid__cell claim-invoice-grid__td-cost--requested-amount">
      <div class="claim-invoice-grid__content-cost  claim-invoice-grid__requested-amount-value">
        <span><strong>{{requestedAmountGridFields.value | currency:'$'}}</strong></span>
      </div>
    </td>
  </tr>
  </tbody>
</table>
