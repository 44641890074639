import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';

import { ClaimApiService } from '@core/api/claim-api.service';
import { DocumentApi } from '@core/api/document.api';
import { AppConstants } from '@core/constants/app.constants';
import { ClaimCommunicationActivityTypeEnum } from '@core/enums/claim/claim-communication-activity-type.enum';
import { Communication, FileInterface } from '@core/interfaces/claims/claimDetails.interface';
import { CommunicationService } from '@core/services/communication.service';

@Component({
  selector: 'app-letters-modal',
  templateUrl: './letters-modal.component.html',
  styleUrls: ['./letters-modal.component.scss'],
})
export class LettersModalComponent implements OnInit {
  attachments: FileInterface[] = [];
  type = '';
  loading = true;
  showAttachments = false;

  constructor(
    public claimApiService: ClaimApiService,
    public documentApi: DocumentApi,
    public cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: Communication,
    private readonly communicationService: CommunicationService,
  ) {}

  ngOnInit(): void {
    this.type = AppConstants.claimCommunicationActivityType[this.data.activityTypeCode];
    this.showAttachments = [
      ClaimCommunicationActivityTypeEnum.Email,
      ClaimCommunicationActivityTypeEnum.Letter,
    ].includes(this.data.activityTypeCode);
    if (this.showAttachments) {
      this.documentApi.getCommunicationAttachments(this.data.claimId, this.data.id).subscribe(attachments => {
        if (attachments) {
          this.attachments = attachments;
        }
      });
    }

    this.communicationService
      .getCommunicationContent(this.data.claimId, this.data.id)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe(contentResult => {
        this.data.content = contentResult.content;
      });
  }

  close() {
    this.dialogRef.close();
  }
}
