<p class="correspondence-block" *ngIf="data.emailTo">
  Sent To:
  <span class="correspondence-block__sub-text">{{data.emailTo}}</span>
</p>
<p class="correspondence-block" *ngIf="data.emailFrom">
  Sent From:
  <span class="correspondence-block__sub-text">{{data.emailFrom}}</span>
</p>
<p class="correspondence-block">
  Direction
  <span class="correspondence-block__sub-text">{{data.direction}}</span>
</p>
