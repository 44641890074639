import { Directive, Input } from '@angular/core';
import { LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';

import { ThBaseFormControlElement } from './base-form-control-element.directive';

@Directive()
export abstract class ThBaseFormFieldControlElement<T> extends ThBaseFormControlElement<T>{
  /**
   * Possible appearance styles for the form field.
   *
   * Default value is `outline`.
   */
  @Input() appearance: MatFormFieldAppearance = 'outline';

  /**
   * Field label.
   *
   * Optional parameter.
   */
  @Input() label?: string;

  /**
   * Field placeholder.
   *
   * Optional parameter.
   */
  @Input() placeholder?: string;

  /**
   * Hint text to be shown underneath the form field control.
   *
   * Optional parameter.
   */
  @Input() hint?: string;

  /**
   * Angular material theme color palette for the component.
   *
   * Default value is `outline`.
   */
  @Input() color: ThemePalette = 'primary';

  /**
   * Whether the form field should reserve space for one line by default.
   */
  // @Input() subscriptSizing: SubscriptSizing = 'fixed';

  protected constructor() {
    super();
  }
}
