<div class="modal-loader" *ngIf="loading$ | async; else smsHistoryContent">
  <app-loader class="base-loader"></app-loader>
</div>
<ng-template #smsHistoryContent>
  <div class="dialog view-message-history-dialog">
    <ng-container *ngIf="(smsHistoryList$ | async) as smsHistoryList">
      <ng-container *ngIf="smsHistoryList.length === 0; else smsHistoryContent">
        <app-base-header headerTitle="Text Messages for Claim {{claimNumber$ | async}}"
                         (closeClick)="close()"></app-base-header>
        <div class="view-message-history-dialog__content-wrapper view-message-history-dialog__content-wrapper--no-data" *ngIf="(loading$ | async) === false">
          <div class="block view-message-history-dialog__icon-wrapper-empty-inbox">
            <mat-icon class="view-message-history-dialog__icon-empty-inbox" svgIcon="img-empty-inbox"></mat-icon>
          </div>
          <div class="block">There are no text message threads for this claim.</div>
          <div class="inline-buttons inline-buttons--center">
            <button (click)="close()"
                    class="inline-buttons__button _common-button--rounded"
                    mat-flat-button color="primary">Close</button>
          </div>
        </div>
      </ng-container>

      <ng-template #smsHistoryContent>
        <div class="dialog__container dialog__container--small-gap view-message-history-dialog__content-wrapper">
          <div class="view-message-history-dialog__left-content">
            <div appCustomOverlayScrollbar class="view-message-history-dialog__scroll-left">
              <div class="view-message-history-dialog__left-message"
                   *ngFor="let smsHistory of smsHistoryList"
                   [class.view-message-history-dialog__left-message--active]="smsHistory.activityId === (activeHistoryId$ | async)"
                   (click)="setActiveSmsHistory(smsHistory)">
                <div class="view-message-history-dialog__message-top-section">
                  <div>{{smsHistory.createdOn | appUserTimezone:'ddd, MM/DD/YY'}}</div>
                  <div>
                    <mat-icon class="view-message-history-dialog__icon-sms" svgIcon="img-sms"></mat-icon>{{smsHistory.createdOn | appUserTimezone:'hh:mm a'}}</div>
                </div>
                <div class="view-message-history-dialog__message-bottom-section">
                  <div>
                    <div class="view-message-history-dialog__message-title">Montage Phone #:</div>
                    <div class="view-message-history-dialog__message-value">{{smsHistory.internalNumber}}</div>
                  </div>
                  <div>
                    <div class="view-message-history-dialog__message-title">Customer Phone #:</div>
                    <div class="view-message-history-dialog__message-value">{{smsHistory.externalNumber}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="view-message-history-dialog__right-section">
            <app-base-header headerTitle="Text Messages for Claim {{claimNumber$ | async}}" (closeClick)="close()"></app-base-header>

            <div class="view-message-history-dialog__right-content">
              <div appCustomOverlayScrollbar class="dialog__content">
                <div class="view-message-history-dialog__message-group" *ngFor="let groupedMessage of activeHistoryGroupedMessages$ | async">
                  <div class="view-message-history-dialog__day-date-section">{{groupedMessage.groupTime | appUserTimezone: 'dddd, MMMM, DD, YYYY'}}</div>
                  <app-sms-history-message class="view-message-history-dialog__message"
                                           [class.view-message-history-dialog__message--outbound]="message.isOutbound"
                                           *ngFor="let message of groupedMessage.messages"
                                           [message]="message"></app-sms-history-message>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</ng-template>
