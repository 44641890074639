import { GridOrderByModel } from '@shared/modules/grid/models/grid/grid-order-by.model';

import { Plan } from '../../interfaces/plan/plan.interface';
import { Pagination } from '../../interfaces/ui/pagination.interface';
import { ProtectionPlanRequest } from '../../models/search/search-request.model';

export namespace StoreState {
  export interface IStoreState {
    plans: Plan[];
    searchRequest: ProtectionPlanRequest;
    pagination: Pagination;
    loading: boolean;
    saveInProgress: boolean;
    exportInProgress: boolean;
  }

  export const initialState: IStoreState = {
    plans: [],
    searchRequest: {
      orderBy: [new GridOrderByModel('registrationDate', false)],
      // orderBy: [],
      page: 1,
      pageSize: 10,
      searches: [],
      noCount: false,
      noRows: false,
      regionId: null,
      retailerId: null,
      searchAsOneWord: true,
    },
    pagination: {
      totalCount: 0,
      page: 1,
      pageCount: 1,
    },
    loading: false,
    saveInProgress: false,
    exportInProgress: false,
  };
}
