import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-side-dialog-header',
  standalone: true,
  templateUrl: './side-dialog-header.component.html',
  styleUrls: ['./side-dialog-header.component.scss'],
  imports: [
    MatIconModule,
    NgIf,
    NgTemplateOutlet,
  ],
})
export class SideDialogHeaderComponent {
  @Input() dialogTitle: string;
  @Input() dialogTitleTemplate: TemplateRef<any>;
  @Input() showReturnArrow = false;
  @Input() showClose = true;
  @Input() disableClose = false;
  @Output() returnToItem: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();

  closeHandler(): void {
    if (!this.disableClose) {
      this.closeDialog.emit();
    }
  }
}
