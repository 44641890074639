export enum UserRoles {
  /// <summary>
  /// System process
  /// </summary>
  System = 100,
  /// <summary>
  /// System process
  /// </summary>
  DataImport = 105,
  /// <summary>
  /// System admin, cross tenant
  /// </summary>
  SystemAdmin = 200,
  /// <summary>
  /// Claims specialist
  /// </summary>
  ClaimsSpecialist = 205,
  OpsManagement = 206,
  /// <summary>
  /// Marketing (non admin user in system tenant)
  /// </summary>
  Marketing = 210,
  /// <summary>
  /// Finance users
  /// </summary>
  Finance = 215,
  Sales = 220,
  /// <summary>
  /// Admin for one tenant
  /// </summary>
  TenantAdmin = 300,
  /// <summary>
  /// Retailer admin for a tenant
  /// </summary>
  RetailerAdmin = 310,
  /// <summary>
  /// Corporate user
  /// </summary>
  CorporateUser = 320,
  /// <summary>
  /// Store Admin
  /// </summary>
  StoreAdmin = 330,
  /// <summary>
  /// Store User
  /// </summary>
  StoreUser = 340,
  /// <summary>
  /// User with read only access
  /// </summary>
  ReadOnlyUser = 350,
  /// <summary>
  /// Consumer
  /// </summary>
  Consumer = 400,
  /// <summary>
  /// Admin for one tenant
  /// </summary>
  JewelryTenantAdmin = 700,
  /// <summary>
  /// Retailer admin for a tenant
  /// </summary>
  JewelryRetailerAdmin = 710,
  /// <summary>
  /// Corporate user
  /// </summary>
  JewelryCorporateUser = 720,
  /// <summary>
  /// Store Admin
  /// </summary>
  JewelryStoreAdmin = 730,
  /// <summary>
  /// Store User
  /// </summary>
  JewelryStoreUser = 740,
  /// <summary>
  /// Store User
  /// </summary>
  JewelryReadOnlyUser = 750,
}
