import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-retailer-plan-actions-cell',
  standalone: true,
  templateUrl: './retailer-plan-actions-cell.component.html',
  styleUrls: ['./retailer-plan-actions-cell.component.scss'],
  imports: [
    NgIf,
    MatIconModule,
  ],
})
export class RetailerPlanActionsCellComponent implements OnInit {
  pdfLink: string;
  data: {downloadUrl: string};
  fieldName: string;

  constructor() {}

  ngOnInit(): void {}
}
