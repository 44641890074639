import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter, inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RxState } from '@rx-angular/state';
import { PushPipe } from '@rx-angular/template/push';
import { Options,Splide } from '@splidejs/splide';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { DestroyableComponent } from '@core/classes/destroyable-component';
import { AppConstants } from '@core/constants/app.constants';
import { ICarouselCustomOptions } from '@core/interfaces/carousel.interface';
import { Files } from '@core/interfaces/claims/files.interface';
import { PromptDialogService } from '@core/services/dialog/prompt-dialog.service';
import { PhotoDialogService } from '@core/services/photo-dialog.service';
import { ImageErrorDirective } from '@shared/directives/image-error.directive';
import { CarouselModule } from '@shared/modules/carousel/carousel.module';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import { PipesModule } from '@shared/pipes/pipes.module';

interface IFilesPreviewNewState {
  filesList: Files[];
  _disableControls: boolean;
  deleteFileById: number;
}

@Component({
  selector: 'app-files-carousel',
  standalone: true,
  templateUrl: './files-carousel.component.html',
  styleUrls: ['./files-carousel.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgClass,
    AsyncPipe,
    LoaderModule,
    CarouselModule,
    NgForOf,
    PushPipe,
    ImageErrorDirective,
    PipesModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class FilesCarouselComponent extends DestroyableComponent implements OnInit {
  filesList$ = this.state
    .select('filesList')
    .pipe(map(filesList => filesList.sort((a, b) => (a.isReadOnly === b.isReadOnly ? 0 : a.isReadOnly ? 1 : -1))));
  photos$ = this.filesList$.pipe(
    map(filesList => filesList.filter(fileItem => !this.fileTypes.documents.includes(fileItem.mimeType))),
  );
  _disableControls$ = this.state.select('_disableControls');
  fileTypes = AppConstants.fileTypes;
  private fileLoadedCount = 0;

  @Input() submitButtonCaption = 'Upload Files';
  @Input() loading = false;
  @Input() isPhotoDialog: boolean;
  @Input() showRemove: boolean;
  @Input() showDocumentName = false;
  @Input() type: string = null;
  @Input() dateRange: [string, string | undefined] = null;
  @Input() set disableControls(value: boolean) {
    this.state.set({ _disableControls: value });
  }
  @Input() set files(files: Files[]) {
    this.state.set({
      filesList: files.filter(item => !!item.thumbnailUrl),
    });
    if (files.length === 0) {
      this.allFilesLoaded.emit(true);
    }
  }
  @Input() isLoadingReadOnlyFiles = false;
  @Input() isNavigation = false;
  @Input() customCarouselOptions: ICarouselCustomOptions = {
    arrowsPosition: 'inside',
    arrowsBackground: true,
  };

  @Output() removeFileById = new EventEmitter<number>();
  @Output() removeFileByIndex = new EventEmitter<number>();
  @Output() updateFiles = new EventEmitter<Files[]>();
  @Output() removeFileIndex = new EventEmitter<number>();
  @Output() allFilesLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() goToFile = new EventEmitter<Files>();
  @Output() carouselMounted = new EventEmitter<Splide>();
  @ViewChild('carousel') carousel: ElementRef;
  @ViewChild('photoPreviewWrapper') photoPreviewWrapperEl: ElementRef;

  splideOptions: Options = {
    pagination: false,
    autoWidth: true,
    gap: 14,
    drag: false,
  };
  promptDialogService = inject(PromptDialogService);

  ngOnInit() {
    this.splideOptions.isNavigation = this.isNavigation;
    this.splideOptions.keyboard = this.isNavigation ? 'global' : false;
  }

  constructor(
    private photoDialogService: PhotoDialogService,
    private readonly state: RxState<IFilesPreviewNewState>,
  ) {
    super();
    this.state.set({
      filesList: [],
      _disableControls: false,
    });
  }

  onFileClick(file: Files) {
    if (this.isLoading || (!file.id && this.fileTypes.documents.indexOf(file.mimeType) !== -1)) {
      return;
    }
    this.fileClick(file);
  }

  fileClick(file: Files) {
    if (this.isPhotoDialog) {
      this.goToFile.emit(file);
    } else {
      const isSafari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
      const isEdge = navigator.userAgent.indexOf('Edge') !== -1;
      const isTif = file.mimeType.indexOf('image/tif') !== -1;
      if (this.fileTypes.images.indexOf(file.mimeType) !== -1) {
        if (isTif) {
          if (isSafari || isEdge) {
            this.openPhotoDialog(file, this.photos$, this.showRemove);
          }
        } else {
          this.openPhotoDialog(file, this.photos$, this.showRemove);
        }
      } else if (this.fileTypes.documents.includes(file.mimeType)) {
        window.open(file.url);
      }
    }
  }

  removeFile(e: Event, fileIndex: number): void {
    if (!this.isLoading) {
      e.preventDefault();
      e.stopPropagation();
      const filesList = this.state.get('filesList');
      const file = filesList.find(fileItem => fileItem.index === fileIndex);
      if (file.id) {
        this.promptDialogService.openPrompt({
          message: '<span>Are you sure you wish to delete this file?</span>',
          buttonNo: 'No',
          buttonYes: 'Yes',
        }).afterClosed().subscribe(success => {
          if (success) {
            this.removeFileById.emit(file.id);
          }
        });
      } else {
        this.removeFileByIndex.emit(fileIndex);
      }
    }
  }

  fileLoaded(e: any, file: Files): boolean {
    const filesList = this.state.get('filesList');
    this.fileLoadedCount++;
    if (this.fileLoadedCount === filesList.length) {
      this.allFilesLoaded.emit(true);
    }
    file.thumbnailLoaded = true;
    return file.thumbnailLoaded;
  }

  trackByFn(index: number, item: Files) {
    return `${item.id}${item.dateCreated}${item['thumbnailLoaded']}${index}`;
  }

  carouselCreated(carousel: Splide) {
    carousel.mount();
    this.carouselMounted.emit(carousel);
  }

  get isLoading(): boolean {
    return this.state.get('_disableControls') || this.isLoadingReadOnlyFiles;
  }

  private openPhotoDialog(file: Files, files$: Observable<Files[]>, enableDelete: boolean) {
    const dialogRef = this.photoDialogService.openNewPhotoDialog(file, files$, enableDelete);
    this.componentDestroyed$.subscribe(() => {
      dialogRef.close();
    });
    dialogRef.componentInstance.deleteFileById$.pipe(takeUntil(dialogRef.afterClosed())).subscribe(fileId => {
      this.removeFileById.next(fileId);
    });
    dialogRef.componentInstance.deleteFileByIndex$.pipe(takeUntil(dialogRef.afterClosed())).subscribe(fileIndex => {
      this.removeFileByIndex.next(fileIndex);
    });
  }
}
