<div class="sub-nav">
  <ng-container *ngFor="let nav of navigationList">
    <div
      *ngIf="!nav.show$ || nav.show$ | push"
      [class.active]="nav.anchor === currentAnchor"
      (click)="scrollToAnchor(nav.anchor)"
      class="sub-nav__item">
      <span
        class="sub-nav__title"
        [class.ellipsis]="!allowMultirowTitle"
        [title]="nav.name">
        {{ nav.name }}
      </span>
      <p
        *ngIf="nav.actions | async"
        class="sub-nav-actions">
        <span>{{ nav.actions | async }}</span>
      </p>
    </div>
  </ng-container>
  <ng-content></ng-content>
</div>
