import { Component, OnInit } from '@angular/core';

import { ProductIncidentStatus } from '@core/enums/claim/product-incident-status.enum';

@Component({
  selector: 'app-product-incident-status',
  templateUrl: './product-incident-status.component.html',
  styleUrls: ['./product-incident-status.component.scss'],
})
export class ProductIncidentStatusComponent implements OnInit {
  data: any;
  fieldName: string;

  incidentStatuses = {};
  constructor() {
    this.incidentStatuses[ProductIncidentStatus.CurrentlyInProgress] = 'In Progress';
    this.incidentStatuses[ProductIncidentStatus.Closed] = 'Closed';
    this.incidentStatuses[ProductIncidentStatus.Denied] = 'Denied';
    this.incidentStatuses[ProductIncidentStatus.ClaimCancelled] = 'Cancelled';
  }

  ngOnInit() {}
}
