export enum DateKind {
  /// <summary>
  /// Open date
  /// </summary>
  ClaimOpened = 1,
  /// <summary>
  /// Closed date
  /// </summary>
  ClaimClosed = 2,
  /// <summary>
  /// Purchase date
  /// </summary>
  PurchaseDate = 3,
  /// <summary>
  /// Service date
  /// </summary>
  ServiceDate = 4,
  /// <summary>
  /// Date tech completed work
  /// </summary>
  TechCompletionDate = 5,
  /// <summary>
  /// Retailer Expiration Date
  /// </summary>
  RetailerExpirationDate = 6,
}
