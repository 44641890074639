import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Sort } from '@angular/material/sort';
import { get } from 'lodash-es';

import { LoaderModule } from '@shared/modules/loader/loader.module';
import { ThTableEmptyComponent } from '@shared/modules/table/components/table-empty/table-empty.component';
import { ThTablePaginationComponent } from '@shared/modules/table/components/table-pagination/table-pagination.component';
import { ThTileTableLoaderComponent } from '@shared/modules/table/components/tile-loader/tile-table-loader.component';
import { ThTableViewComponent } from '@shared/modules/table/containers/table-view/table-view.component';
import { IPagination } from '@shared/modules/table/interfaces/pagination.interface';
import { ITableAction } from '@shared/modules/table/interfaces/table-action.interface';
import { ThTableSelection } from '@shared/modules/table/selection/table-selection';

import { IAppTableCol } from './interfaces/table-col.interface';
import { IAppTableRow } from './interfaces/table-row.interface';

@Component({
  selector: 'app-table, th-table',
  standalone: true,
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ThTableViewComponent,
    ThTileTableLoaderComponent,
    NgIf,
    ThTableEmptyComponent,
    ThTablePaginationComponent,
    MatProgressSpinnerModule,
    LoaderModule,
  ],
})

export class ThTableComponent {
  @Input() data: IAppTableRow[];
  @Input() columns: IAppTableCol[];
  @Input() hiddenColumns: string[] = [];
  @Input() isClickable = false;
  @Input() isSortable = true;
  @Input() rowHeight = '40px';
  @Input() mobileColumnName = false;
  @Input() loading: boolean;
  @Input() maxHeightLimitation = false;
  @Input() pagination: IPagination;
  @Input() pageSizeOptions: number[] = [5, 10, 20];
  @Input() showToolbar = false;
  @Input() sorting: Sort[];
  @Input() selection: ThTableSelection<any, any>;

  @ContentChild(TemplateRef) customCellTemplate: TemplateRef<any>;

  @Output() action = new EventEmitter<ITableAction>();
  @Output() rowClick = new EventEmitter<{index: number; data: any}>();
  @Output() sortChanged = new EventEmitter<Sort[]>();
  @Output() pageChanged = new EventEmitter<number>();
  @Output() dataChange = new EventEmitter<IAppTableRow[]>();

  get empty(): boolean {
    return !get(this.data, 'length');
  }

  ngOnChanges(): void  {
    this.dataChange.emit(this.data);
  }
}
