import { Injectable } from '@angular/core';

import { CommunicationApiService } from '@core/api/communication-api.service';

@Injectable({ providedIn: 'root' })
export class CommunicationService {
  constructor(private readonly communicationApiService: CommunicationApiService) {}

  getCommunicationContent(claimId: number, communicationId: string) {
    return this.communicationApiService.getCommunicationContent(claimId, communicationId);
  }
}
