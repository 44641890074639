<app-side-dialog-animation>
  <ng-container *ngIf="form; else initialDataLoading">
    <div class="side-dialog__header">
      <h3 class="consumer-name">
        <mat-icon
          svgIcon="big-arrow"
          class="_common-icon--theme edit-consumer__return"
          (click)="onClose()"></mat-icon>
        {{ consumerTitle }}
      </h3>
    </div>
    <div class="side-dialog__content">
      <div class="dialog__scroll-view side-dialog__content-scroll edit-consumer__content-scroll">
        <form>
          <h2 class="section__title">Consumer</h2>
          <div>
            <app-consumer-template-form
              [formGroupRef]="form.get('consumer.info')"
              [useRadio]="false"
              [blocked]="saveInProgress || addressVerificationInProgress"
              [phonesRequired]="phonesRequired"></app-consumer-template-form>
            <div>
              <h3>Consumer Address</h3>
              <app-address-form
                [form]="form.get('consumer.consumerAddress')"
                [blocked]="saveInProgress || addressVerificationInProgress"></app-address-form>
              <app-address-suggest
                [addressForm]="form.get('consumer.consumerAddress')"
                [blocked]="saveInProgress || addressVerificationInProgress"
                (isInProgress)="addressVerificationInProgressHandler($event)"></app-address-suggest>
            </div>
          </div>
          <div class="dialog__submit-button">
            <button
              (click)="submitForm()"
              appLoadingButton
              [isLoading]="saveInProgress"
              mat-flat-button
              color="primary"
              [disabled]="!form.valid || addressVerificationInProgress">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-container>
  <ng-template #initialDataLoading>
    <div class="default-loader">
      <app-loader class="base-loader"></app-loader>
    </div>
  </ng-template>
</app-side-dialog-animation>
