import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DEFAULT_DOCUMENT_FILTERS, IDocumentFilter } from '@core/constants/document-filter.constants';
import { ClaimType } from '@core/enums/claim-type.enum';
import { TDocumentType } from '@core/enums/document-type';
import { FormSelectComponent } from '@shared/components/form-select/form-select.component';
import { ClaimDocument } from '@shared/pipes/filter-documents-by-types.pipe';

@Component({
  selector: 'app-documents-filter',
  standalone: true,
  templateUrl: './documents-filter.component.html',
  styleUrls: ['./documents-filter.component.scss'],
  imports: [
    FormSelectComponent,
    NgIf,
    FormsModule,
  ],
})
export class DocumentsFilterComponent {
  docTypeFilter: any = 0;
  noFilesWithDocType = false;

  @Input() items: IDocumentFilter[] = DEFAULT_DOCUMENT_FILTERS;
  @Input() files: ClaimDocument[];
  @Input() set claimType(value: ClaimType) {
    if (value === ClaimType.MS) {
      this.items = [...this.items].filter(item => item.id === 0 || !(item.id as TDocumentType[]).includes(TDocumentType.Receipt));
    }
  }
  @Output() docTypeFilterChanged: EventEmitter<TDocumentType[]> = new EventEmitter<TDocumentType[]>();

  filterDocuments(): void {
    if (this.docTypeFilter) {
      this.noFilesWithDocType = this.files.filter(file => this.docTypeFilter.includes(file.docType)).length === 0;
    } else {
      this.noFilesWithDocType = false;
    }
    this.docTypeFilterChanged.emit(this.docTypeFilter);
  }
}
