import { Pagination } from '@core/interfaces/ui/pagination.interface';
import { Identity, IIdentityString } from '@core/interfaces/util/identity.interface';
import { ProtectionPlanRequest } from '@core/models/search/search-request.model';
import { GridOrderByModel } from '@shared/modules/grid/models/grid/grid-order-by.model';

export namespace StoreState {
  export interface IStoreState {
    plans: IIdentityString[];
    selectedPlansIdsList: string[];
    searchRequest: ProtectionPlanRequest;
    pagination: Pagination;
    loading: boolean;
    selected: Identity;
    selectedExists: boolean;
    disabled: boolean;
  }

  export const initialState: IStoreState = {
    plans: [],
    selectedPlansIdsList: [],
    searchRequest: {
      page: 1,
      retailerId: null,
      pageSize: 10,
      orderBy: [new GridOrderByModel('parentPlanName', true)],
      noCount: false,
      noRows: false,
      searchAsOneWord: true,
    },
    pagination: {
      totalCount: 0,
      page: 1,
      pageCount: 1,
    },
    loading: false,
    selected: null,
    selectedExists: false,
    disabled: false,
  };
}
