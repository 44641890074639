import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get } from 'lodash-es';
import { parseZone } from 'moment/moment';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { TDocumentType } from '@core/enums/document-type';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

import { IRegisterPlanRequest } from '../../../../th-mjc/app/dialogs/mjc-register-plan/store/plan-wizard.interface';
import { Files } from '../interfaces/claims/files.interface';
import { IDataSearch } from '../interfaces/data-search.interface';
import { Plan, PlanDetails } from '../interfaces/plan/plan.interface';
import { Identity } from '../interfaces/util/identity.interface';
import { ProtectionPlanRequest } from '../models/search/search-request.model';
import { FileService } from '../services/file.service';
import { CrudApiBaseService } from './crud-api.base';

export interface IMjcPlanRegisterResponse {
  planNumber: string;
  crmContactAccountId: string;
}

@Injectable({
  providedIn: 'root',
})
export class RetailerPlanApiService extends CrudApiBaseService<Identity> {
  endpoint = 'retailerplan';

  constructor(private notification: NotificationService, private readonly fileService: FileService) {
    super();
  }

  all(searchRequest: ProtectionPlanRequest) {
    if (searchRequest.fromDate) {
      searchRequest.fromDate = parseZone(searchRequest.fromDate).startOf('day').toISOString();
    }

    if (searchRequest.toDate) {
      searchRequest.toDate = parseZone(searchRequest.toDate).endOf('day').toISOString();
    }
    return this.httpClient.post<IDataSearch<Plan>>(this.getUrl('all'), searchRequest);
  }

  createProtectionPlan(data: any, files: Files[]) {
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('formfiles', files[i].originalFile);
    }

    formData.append('model', JSON.stringify(data));
    const plan = get(data, 'consumerPlan.planInfo.length') > 1 ? 'plans have been' : 'plan has been';

    return this.httpClient.post(this.getUrl('register'), formData).pipe(
      tap(() => {
        this.notification.next({
          message: `Your protection ${plan} submitted for processing.`,
          type: NotificationType.Success,
          duration: 6000,
        });
      }),
    );
  }

  registerMjcPlan(requestBody: IRegisterPlanRequest, files: Files[]): Observable<IMjcPlanRegisterResponse> {
    const formData = this._returnMultipleFilesFormData(files);

    formData.append('model', JSON.stringify(requestBody));

    return this.httpClient.post<IMjcPlanRegisterResponse>(this.getUrl('register'), formData).pipe(
      tap(() => {
        this.notification.next({
          // message: `Your protection ${plan} submitted for processing.`,
          message: 'Your plan submitted for processing.',
          type: NotificationType.Success,
          duration: 6000,
        });
      }),
    );
  }

  detailed(id: number): Observable<PlanDetails> {
    return this.httpClient.get<PlanDetails>(this.getUrl(`${id}/detailed`));
  }

  getFiles(id: number) {
    return this.fileService.getFileViewerWidth().pipe(
      switchMap(width =>
        this.httpClient
          .get<Omit<Files, 'index'>[]>(this.getUrl(`${id}/files`), {
          params: {
            width,
          },
        })
          .pipe(map(files => this.fileService.sortFiles(files))),
      ),
    );
  }

  returnPlan(id: number): Observable<any> {
    return this.httpClient.get(this.getUrl(`${id}/return`));
  }

  sendPdf(protectionPlanIds: string[]): Observable<any> {
    return this.httpClient.post(this.getUrl('sendPdf'), {
      protectionPlanIds,
    });
  }

  exportPlans(request: ProtectionPlanRequest): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(this.getUrl('export'), request, { observe: 'response', responseType: 'blob' });
  }

  private _returnMultipleFilesFormData(files: Files[]): FormData {
    const receiptsUploaded = files.filter(file => file.docType === TDocumentType.Receipt);
    const productFilesUploaded = files.filter(
      file => [TDocumentType.RetailerPortalUploadedPhoto, TDocumentType.CustPhoto].includes(file.docType),
    );
    const formData: FormData = new FormData();

    if (receiptsUploaded.length) {
      for (let i = 0; i < receiptsUploaded.length; i++) {
        formData.append('ReceiptFiles', receiptsUploaded[i].originalFile);
      }
    }
    if (productFilesUploaded.length) {
      for (let i = 0; i < productFilesUploaded.length; i++) {
        formData.append('ProductPhotos', productFilesUploaded[i].originalFile);
      }
    }
    return formData;
  }
}
