<div class="sub-nav wizard-navigation">
  <ng-container *ngFor="let parentNav of navigationList">
    <div class="sub-nav__item"
         [class.active]="currentStep.step === navigationItems[parentNav].step">
      <span class="sub-nav__title" [title]="displayTitles ? navigationItems[parentNav].name : ''">
        {{navigationItems[parentNav].name}}
      </span>
      <div *ngIf="form" class="sub-nav__icon-wrapper">
        <ng-container *ngIf="(navigationItems[parentNav].formGroupName && form.get(navigationItems[parentNav].formGroupName).valid)
                            || (!navigationItems[parentNav].formGroupName && (form.valid || navigationItems[parentNav].extraValidation(navigationItems[parentNav].step)))">
          <span *ngIf="(navigationItems[parentNav].extraValidation ? navigationItems[parentNav].extraValidation(navigationItems[parentNav].step, form) : true) && (additionalValidations[parentNav] !== undefined ? additionalValidations[parentNav] : true)" class="sub-nav__icon sub-nav__icon--valid">
            <mat-icon class="_common-icon--white" svgIcon="checkmark"></mat-icon>
          </span>
        </ng-container>
      </div>
    </div>
    <div class="nav-child" *ngIf="navigationItems[parentNav].child && !navigationItems[parentNav].hideChildrenInNavbar">
      <div *ngFor="let childNav of navigationItems[parentNav].child; let i = index;"
           [class.active]="navigationItems[parentNav].step === currentStep.step && childNav.subStep === currentSubStep"
           class="sub-nav__item">
        <span class="sub-nav__title sub-nav__title--one-line ellipsis" [title]="displayTitles ? childNav.name : ''">{{childNav.name}}</span>
        <div class="sub-nav__icon-wrapper">
          <span *ngIf="form.get(navigationItems[parentNav].formGroupName)['controls'][childNav.subStep]?.valid" class="sub-nav__icon sub-nav__icon--valid">
            <mat-icon class="_common-icon--white" svgIcon="checkmark"></mat-icon>
          </span>
        </div>
      </div>
    </div>
  </ng-container>
</div>
