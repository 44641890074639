import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-base-header',
  templateUrl: './base-header.component.html',
  styleUrls: ['./base-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseHeaderComponent {
  @Input() headerTitle: string;
  @Input() disableClose = false;
  @Input() subtitle = '';
  @Input() noBorder = false;
  @Input() contentRight = false;
  @Output() closeClick = new EventEmitter<void>();

  close(): void {
    if (!this.disableClose) {
      this.closeClick.emit();
    }
  }
}
