import { GridOrderByModel } from '@shared/modules/grid/models/grid/grid-order-by.model';

import { IRetailer } from '../../interfaces/stores/retailer.interface';
import { Pagination } from '../../interfaces/ui/pagination.interface';
import { StoreSearchRequest } from '../../models/search/search-request.model';

export namespace StoreState {
  export interface IStoreState {
    retailers: IRetailer[];
    searchRequest: StoreSearchRequest;
    pagination: Pagination;
    loading: boolean;
    saveInProgress: boolean;
    allChecked?: boolean;
  }

  export const initialState: IStoreState = {
    retailers: [],
    searchRequest: {
      orderBy: [new GridOrderByModel('name', true)],
      page: 1,
      pageSize: 10,
      searches: [],
      noCount: false,
      noRows: false,
      retailerFilter: null,
      regionId: null,
      regionIds: [],
    },
    pagination: {
      totalCount: 0,
      page: 1,
      pageCount: 1,
    },
    loading: false,
    saveInProgress: false,
  };
}
