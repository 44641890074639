<div class="dialog__header dialog__header--flex"
     [class.dialog__header--no-border]="noBorder"
     [class.dialog__header--no-pb]="noBorder">
  <div class="dialog-header" [class.dialog-header--content-right]="contentRight">
    <h3 class="dialog__title dialog-header__title" [class.dialog__title--with-subtitle]="subtitle">
      <h2 class="dialog__main-title">{{headerTitle}}</h2>
      <div *ngIf="subtitle" class="dialog__subtitle">{{subtitle}}</div>
    </h3>
    <ng-content></ng-content>
  </div>
  <button class="dialog__close dialog-header__close"
          [class.dialog-header__close-disabled]="disableClose"
          mat-icon-button (click)="close()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
