import { NgForOf, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-files-for-print',
  standalone: true,
  templateUrl: './files-for-print.component.html',
  styleUrls: ['./files-for-print.component.scss'],
  imports: [
    NgIf,
    NgForOf,
  ],
})
export class FilesForPrintComponent {
  filesList: any[];
  @Input() dateRange: [string, string | undefined] = null;
  @Input() hideTitle = false;
  @Input() set files(files: any[]) {
    if (this.dateRange) {
      files = files
        .filter(file => (this.dateRange[0] ? new Date(file.dateCreated) > new Date(this.dateRange[0]) : true))
        .filter(file => (this.dateRange[1] ? new Date(file.dateCreated) < new Date(this.dateRange[1]) : true));
    }

    this.filesList = files;
  }
  constructor() {}
}
