<div class="claims-header-paginator">
  <ng-container *ngIf="showCount; else newPaginationTemplate">
    <mat-icon
      svgIcon="big-arrow"
      class="_common-icon--theme prev"
      (click)="setPrevPage()"
      [class.disabled]="(currentPage$ | async) === 1"></mat-icon>
    <div>
      <p
        class="claims-header-paginator__item-title"
        *ngIf="itemsTitle">
        {{ itemsTitle }}
      </p>
      <span class="claim-current-index">{{ currentPage$ | async }} of {{ totalCount$ | async }}</span>
    </div>
    <mat-icon
      svgIcon="big-arrow"
      class="_common-icon--theme next"
      (click)="setNextPage()"
      [class.disabled]="(currentPage$ | async) === (totalCount$ | async)"></mat-icon>
  </ng-container>
</div>
<ng-template #newPaginationTemplate>
  <button
    class="_common-button--rounded"
    mat-stroked-button
    (click)="setPrevPage()"
    [disabled]="(currentPage$ | async) === 1">
    <mat-icon svgIcon="big-arrow"></mat-icon>
    <span *ngIf="!showCount">Previous</span>
  </button>
  <button
    class="_common-button--rounded claims-header-paginator__next"
    mat-stroked-button
    (click)="setNextPage()"
    [disabled]="(currentPage$ | async) === (totalCount$ | async)">
    <span *ngIf="!showCount">Next</span>
    <mat-icon
      svgIcon="big-arrow"
      class="next-icon"></mat-icon>
  </button>
</ng-template>
