import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-table-empty, th-table-empty',
  standalone: true,
  templateUrl: './table-empty.component.html',
  styleUrls: ['./table-empty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIconModule,
    NgIf,
  ],
})
export class ThTableEmptyComponent {
  @Input() icon: string;
  @Input() header: string;
}
