<div class="splide__wrapper">
  <div
    class="splide"
    #carousel>
    <div
      class="splide__arrows"
      [class.splide__arrows--hidden]="(showArrows$ | async) === false">
      <button
        class="splide__arrow splide__arrow--prev"
        type="button"
        [class.splide__arrow--hidden]="(currentIndex$ | async) === 0 || (allSlidesVisible$ | async)"
        [class.splide__arrow--no-background]="!customOptions.arrowsBackground"
        [class.splide__arrow--outside]="customOptions.arrowsPosition === 'outside'">
        <mat-icon
          svgIcon="ic-chevron-right"
          class="_common-icon--theme prev"></mat-icon>
      </button>
      <button
        class="splide__arrow splide__arrow--next"
        type="button"
        [class.splide__arrow--hidden]="(lastSlideVisible$ | async) || (allSlidesVisible$ | async)"
        [class.splide__arrow--no-background]="!customOptions.arrowsBackground"
        [class.splide__arrow--outside]="customOptions.arrowsPosition === 'outside'">
        <mat-icon
          svgIcon="ic-chevron-right"
          class="_common-icon--theme next"></mat-icon>
      </button>
    </div>
    <div class="splide__track">
      <div class="splide__list">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
