export enum ProductIncidentStatus {
  /// <summary>
  /// Currently in Progress
  /// </summary>
  CurrentlyInProgress = 1,
  /// <summary>
  /// Closed
  /// </summary>
  Closed = 2,
  /// <summary>
  /// Denied
  /// </summary>
  Denied = 3,
  /// <summary>
  /// Claim Cancelled
  /// </summary>
  ClaimCancelled = 4,
}
