<div class="document-type">
  <app-form-select [label]="'Document Type'"
                   [data]="items"
                   [showEmptyOption]="false"
                   [(ngModel)]="docTypeFilter"
                   (ngModelChange)="filterDocuments()"></app-form-select>
</div>
<div *ngIf="noFilesWithDocType">
  No files with selected type
</div>
