import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA, MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { RxState } from '@rx-angular/state';
import { finalize } from 'rxjs/operators';

import { RetailerPlanApiService } from '@core/api/retailer-plan-api.service';
import { ServerSendPdfResponse } from '@core/interfaces/send-pdf-response.interface';
import { Pagination } from '@core/interfaces/ui/pagination.interface';
import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';
import { DialogCoreModule } from '@shared/modules/dialog-core/dialog-core.module';
import { GridModule } from '@shared/modules/grid/grid.module';
import { GridColumnModel } from '@shared/modules/grid/models/grid/grid-column.model';
import { GridOrderByModel } from '@shared/modules/grid/models/grid/grid-order-by.model';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';
import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';

import { RetailerPlanActionsCellComponent } from '../../../pages/plans/retailer-plan-actions-cell/retailer-plan-actions-cell.component';

interface IRetailerPlanSocket {
  agreementNumber: string;
  consumerEmailAddress: string;
  downloadUrl: string;
  plan: {clientRefObject: number; crmRefId: string; id: number};
  protectionPlanName: string;
}

interface IRetailerPlanSuccessState {
  emailSendInProgress: boolean;
  emailSendMessage: string;
}

@Component({
  selector: 'app-retailer-plan-success',
  standalone: true,
  templateUrl: './retailer-plan-success.component.html',
  styleUrls: ['./retailer-plan-success.component.scss'],
  providers: [RxState],
  imports: [
    DialogCoreModule,
    NgIf,
    CustomOverlayScrollbarDirective,
    GridModule,
    MatLegacyInputModule,
    AsyncPipe,
    MatButtonModule,
    LoadingButtonDirective,
    MatDialogModule,
  ],
})
export class RetailerPlanSuccessComponent implements OnInit {
  emailSendInProgress$ = this.state.select('emailSendInProgress');
  emailSendMessage$ = this.state.select('emailSendMessage');
  gridsOrderBy = new GridOrderByModel('agreementNumber');
  createdColumns = this.getDefaultColumns();

  failedColumns = this.getDefaultFailedColumns();

  createdPlans: {
    items: any[];
    pagination: Pagination;
  };
  failedPlans: {
    items: any[];
    pagination: Pagination;
  };

  consumerEmailAddress = this.data.consumerEmailAddress;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      consumerEmailAddress: string;
      createdPlans: IRetailerPlanSocket[];
      failedPlans: IRetailerPlanSocket[];
    },
    public dialogRef: MatDialogRef<any>,
    private retailerPlanApiService: RetailerPlanApiService,
    private notificationService: NotificationService,
    public cdr: ChangeDetectorRef,
    private readonly state: RxState<IRetailerPlanSuccessState>,
  ) {
    this.state.set({
      emailSendInProgress: false,
      emailSendMessage: null,
    });
  }

  ngOnInit() {
    this.createdPlans = {
      pagination: {
        totalCount: this.data.createdPlans.length,
        page: 1,
        pageCount: Math.ceil(this.data.createdPlans.length / 5),
      },
      items: this.data.createdPlans,
    };

    this.failedPlans = {
      pagination: {
        totalCount: this.data.failedPlans.length,
        page: 1,
        pageCount: Math.ceil(this.data.failedPlans.length / 5),
      },
      items: this.data.failedPlans,
    };
  }

  close() {
    this.dialogRef.close();
  }

  changePage(page: number) {}

  private getDefaultColumns(): GridColumnModel[] {
    return [
      new GridColumnModel('protectionPlanName', 'Plan Name', null, false, false),
      new GridColumnModel('agreementNumber', 'Plan Number', null, false, false),
      new GridColumnModel('actions', 'View Plan', RetailerPlanActionsCellComponent, false, false),
    ];
  }

  private getDefaultFailedColumns(): GridColumnModel[] {
    return [
      new GridColumnModel('protectionPlanName', 'Plan Name', null, false, false),
      new GridColumnModel('error', 'Error Message', null, false, false),
      // new GridColumnModel('actions', 'View Plan', RetailerPlanRetryCellComponent, false, false),
    ];
  }

  sendPdf() {
    if (this.createdPlans && this.createdPlans.items.length) {
      this.state.set({
        emailSendInProgress: true,
      });
      this.retailerPlanApiService
        .sendPdf(this.data.createdPlans.map(plan => plan.plan.crmRefId))
        .pipe(
          finalize(() => {
            this.state.set({
              emailSendInProgress: false,
            });
            this.cdr.detectChanges();
          }),
        )
        .subscribe(
          (data: ServerSendPdfResponse) => {
            this.state.set({
              emailSendMessage: data.message,
            });
            if (data.failedToSend && data.failedToSend.length) {
              const planNames = [];
              data.failedToSend.forEach(plan => {
                const name = this.data.createdPlans.filter(createdPlan => createdPlan.plan.crmRefId === plan)[0]
                  .protectionPlanName;
                planNames.push(name);
                return name;
              });
              this.notificationService.next({
                type: NotificationType.Error,
                message: 'The following plan(s) could not be emailed: ' + planNames.join(', '),
                duration: 12000,
              });
            }
          },
          () => {
            this.notificationService.next({
              type: NotificationType.Error,
              message: 'Protection plan sending has failed.',
            });
          },
        );
    }
  }
}
