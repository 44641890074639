import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PushPipe } from '@rx-angular/template/push';
import { tap } from 'rxjs/operators';

import { IRetailer } from '@core/interfaces/stores/retailer.interface';
import { RetailersListStore } from '@core/store/retailersList/retailersList.store';
import { FormSelectComponent } from '@shared/components/form-select/form-select.component';
import { ThBaseFormControlElement } from '@shared/form/base-form-control-element.directive';

@Component({
  selector: 'app-retailer-select',
  standalone: true,
  templateUrl: './retailer-select.component.html',
  styleUrls: ['./retailer-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormSelectComponent,
    PushPipe,
    ReactiveFormsModule,
  ],
})
export class RetailerSelectComponent extends ThBaseFormControlElement<string | number> {
  @Input() label = 'Store';
  @Input() disabled = false;
  @Input() valueField = 'id';
  @Input() selectedRetailer: IRetailer;

  @Output() selectedItem = new EventEmitter<IRetailer>();

  store = inject(RetailersListStore);

  retailers$ = this.store.select$('retailers').pipe(
    tap((retailers) => {
      if (retailers.length === 1 && this.isRequired) {
        this.formControl.setValue(retailers[0][this.valueField]);
        this.selectedItem.emit(retailers[0]);
      }
    }),
  );
  loading$ = this.store.select$('loading');
  pagination$ = this.store.select$('pagination');

  loadNextPage(page: number): void {
    this.store.updateSearchRequest({
      page,
    });
    this.store.loadData(true);
  }

  retailerSelected(retailer: IRetailer): void {
    this.selectedItem.emit(retailer);
  }

  retailersSearch(searchString: string): void {
    this.store.updateSearchRequest({
      searches: [searchString],
      page: 1,
    });
    this.store.loadData();
  }
}
