import { NgIf } from '@angular/common';
import { Component,inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA, MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { IPromptDialogData } from '@core/interfaces/prompt-dialog-data.interface';
import { PipesModule } from '@shared/pipes/pipes.module';

@Component({
  selector: 'app-prompt',
  standalone: true,
  templateUrl: 'prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss'],
  imports: [
    MatIconModule,
    NgIf,
    MatDialogModule,
    MatButtonModule,
    PipesModule,
  ],
})
export class PromptDialogComponent {
  dialogData: {
    data: Partial<IPromptDialogData>;
  } = inject(MAT_DIALOG_DATA);

  constructor(
    private readonly dialogRef: MatDialogRef<any>,
  ) {}

  close(): void {
    this.dialogRef.close(null);
  }
}
