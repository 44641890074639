import { inject, Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { DIALOG_CONFIG_SHORT } from '@core/constants/dialog.constants';
import { INotifyDialogData, IPromptDialogData } from '@core/interfaces/prompt-dialog-data.interface';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import { NotifyDialogComponent } from '@shared/modules/side-dialog/components/notify-dialog/notify-dialog.component';
import { PromptDialogComponent } from '@shared/modules/side-dialog/components/prompt-dialog/prompt-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PromptDialogService {
  modalDialogService = inject(ModalDialogService);

  openPrompt(data: Partial<IPromptDialogData>): MatDialogRef<PromptDialogComponent, boolean | null> {
    return this.modalDialogService.open({
      title: 'Prompt',
      data,
    }, PromptDialogComponent, DIALOG_CONFIG_SHORT);
  }

  openNotify(data: Partial<INotifyDialogData>): MatDialogRef<NotifyDialogComponent, boolean | null> {
    return this.modalDialogService.open({
      title: 'Notify',
      data,
    }, NotifyDialogComponent, DIALOG_CONFIG_SHORT);
  }
}
