import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ICustomProperties } from '@microsoft/applicationinsights-core-js';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { buildVariables } from '@th-common/assets/config/build-variables';
import { filter } from 'rxjs/operators';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { IAppConfig } from '@core/interfaces/app-config.interface';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  private _appInsights: ApplicationInsights;

  constructor(private readonly router: Router, @Inject(APP_CONFIG) private appConfig: IAppConfig) {
    if (!isDevMode()) {
      this._appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: appConfig.appInsightsKey,
        },
      });
      this._appInsights.loadAppInsights();
      this._loadCustomTelemetryProperties();
      this._createRouterSubscription();
    }
  }

  setAIUserId(userId: string, accountId: string = '', storeInCookie: boolean = true): void {
    if (!isDevMode() && !!userId) {
      this._appInsights.setAuthenticatedUserContext(userId, accountId, storeInCookie);
    }
  }

  clearAIUserId(): void {
    if (!isDevMode()) {
      this._appInsights.clearAuthenticatedUserContext();
    }
  }

  logPageView(name?: string, uri?: string): void {
    if (!isDevMode()) {
      this._appInsights.trackPageView({
        name,
        uri,
      });
    }
  }

  logException(exception: Error, customProperties: ICustomProperties = {}, severityLevel?: SeverityLevel): void {
    if (!isDevMode()) {
      if (!!exception && !(exception instanceof HttpErrorResponse)) {
        if (exception.stack) {
          exception.name = exception.name || exception.stack.split('\n')[0];
          exception.message = exception.message || exception.stack.split('\n')[0];
        }
        this._appInsights.trackException({ exception }, customProperties);
      }
    }
  }

  logEvent(name: string, properties?: Record<string, any>): void {
    if (!isDevMode()) {
      this._appInsights.trackEvent({ name }, properties);
    }
  }

  logMetric(name: string, average: number, properties?: Record<string, any>): void {
    if (!isDevMode()) {
      this._appInsights.trackMetric({ name, average }, properties);
    }
  }

  logTrace(message: string, properties?: Record<string, any>): void {
    if (!isDevMode()) {
      this._appInsights.trackTrace({ message }, properties);
    }
  }

  private _loadCustomTelemetryProperties(): void {
    this._appInsights.addTelemetryInitializer(envelope => {
      if (!navigator.onLine) {
        return false;
      }

      const item = envelope.baseData;
      item.properties = item.properties || {};
      item.properties.PortalType = this.appConfig.portalType;
      item.properties.FEBuildNumber = buildVariables.buildNumber;
    });
  }

  private _createRouterSubscription(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.logPageView(null, event.urlAfterRedirects);
    });
  }
}
