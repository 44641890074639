import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { PushPipe } from '@rx-angular/template/push';
import moment from 'moment';
import { combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { DestroyableComponent } from '@core/classes/destroyable-component';
import { TDateFieldType } from '@core/enums/date/date-field-type.enum';
import { IIdentityString } from '@core/interfaces/util/identity.interface';
import { UserStore } from '@core/store/user/user.store';
import { AlertMessageComponent } from '@shared/components/alert-message/alert-message.component';
import { FormDateComponent } from '@shared/components/form-date/form-date.component';
import { FormInputComponent } from '@shared/components/form-input/form-input.component';
import { FormSelectComponent } from '@shared/components/form-select/form-select.component';
import { GridColumnModel } from '@shared/modules/grid/models/grid/grid-column.model';

import { RegisterRetailerPlanSteps } from '../../register-retailer-plan/register-retailer-plan.component';
import { ParentPlansListStore } from './store/parent-plans-list.store';

type TDeliveryDateValidation = 'purchaseDate' | 'registrationDate';

interface IProtectionPlanFormState {
  deliveryDateValidationType: TDeliveryDateValidation;
  deliveryDateWarningMessage: string;
}

@Component({
  selector: 'app-protection-plan-form',
  standalone: true,
  templateUrl: './protection-plan-form.component.html',
  styleUrls: ['./protection-plan-form.component.scss'],
  providers: [ParentPlansListStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormSelectComponent,
    ReactiveFormsModule,
    AlertMessageComponent,
    FormDateComponent,
    AsyncPipe,
    FormInputComponent,
    PushPipe,
  ],
})
export class ProtectionPlanFormComponent extends DestroyableComponent implements OnInit {
  today: Date = new Date();
  minDeliveryDate: Date;
  maxDeliveryDate: Date;
  plans$ = combineLatest([this.store.select$('plans'), this.store.select$('selectedPlansIdsList')]).pipe(
    map(([plans, selectedPlanIds]) => plans.map(plan => ({
      ...plan,
      disabled: selectedPlanIds.includes(plan.id),
    }))),
  );
  loading$ = this.store.select$('loading');
  pagination$ = this.store.select$('pagination');

  planColumns: GridColumnModel[] = [
    new GridColumnModel('parentPlanName', 'Plan Name'),
    new GridColumnModel('upTo', 'Up To'),
  ];

  TDateFieldType = TDateFieldType;
  clearSearch = false;

  @Input() form: UntypedFormGroup;
  @Input() set selectedPlansIdsList(ids: string[]) {
    this.store.setSelectedIds(ids);
  }
  @Input() set retailerId(retailerId: number) {
    if (retailerId) {
      this.store.setRetailerId(retailerId);
      if (this.form) {
        this.form.patchValue({
          planId: null,
        });
        this.form.updateValueAndValidity();
      }
    }
  }
  @Input() set purchaseDate(purchaseDate: string) {
    if (purchaseDate) {
      const maxSalesRegistrationDays = this.userStore.get('session', 'maxSalesRegistrationDays');
      const today = moment();
      const registrationMinDate = today.subtract(maxSalesRegistrationDays, 'days');
      const purchaseMinDate = moment(purchaseDate).subtract(5, 'days');
      const minDeliveryDate = moment.max(registrationMinDate, purchaseMinDate);
      this.minDeliveryDate = minDeliveryDate.toDate();
      this.maxDeliveryDate = moment(purchaseDate).add(12, 'months').toDate();
      if (minDeliveryDate.isSame(registrationMinDate)) {
        this.state.set({
          // eslint-disable-next-line max-len
          deliveryDateWarningMessage: `You can only enter a plan sale if the delivery date was within the past <b>${maxSalesRegistrationDays}</b> days, i.e. no earlier than <b>${registrationMinDate.format(
            'MM/DD/YYYY',
          )}</b>. Future estimated delivery dates are allowed.`,
        });
      } else {
        this.state.set({
          deliveryDateWarningMessage: `The <b>delivery date</b> cannot be more than <b>5</b> days before the purchase date, i.e. not before <b>${purchaseMinDate.format(
            'MM/DD/YYYY',
          )}</b>.`,
        });
      }
    }
  }
  @Input() set currentStep(value: any) {
    this.clearSearch = value === RegisterRetailerPlanSteps.Plan;
  }

  @Output() protectionPlanSelected = new EventEmitter<IIdentityString>();
  @Output() plans = new EventEmitter<Array<IIdentityString>>();

  deliveryDateWarningMessage$ = this.state.select('deliveryDateWarningMessage');

  constructor(
    private readonly store: ParentPlansListStore,
    private readonly userStore: UserStore,
    private readonly state: RxState<IProtectionPlanFormState>,
  ) {
    super();
  }

  ngOnInit() {
    this.plans$.pipe(takeUntil(this.componentDestroyed$)).subscribe(plans => this.plans.emit(plans));
  }

  loadNextPage(page: number) {
    this.store.updatePagination(page);
  }

  planSelected(plan: IIdentityString) {
    this.protectionPlanSelected.emit(plan);
  }

  planSearch(searchString: string) {
    this.store.search(searchString);
  }
}
