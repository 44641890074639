import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule } from '@angular/material/legacy-select';

import { FormSelectComponent } from '@shared/components/form-select/form-select.component';
import { COMPONENTS } from '@shared/modules/selects/components';


@NgModule({
  declarations: [...COMPONENTS],
  imports: [AsyncPipe, FormSelectComponent, MatLegacyRadioModule, MatLegacySelectModule, NgForOf, NgIf, ReactiveFormsModule],
  exports: [...COMPONENTS],
})
export class SelectsModule {}
