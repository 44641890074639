import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-side-dialog-container',
  standalone: true,
  imports: [MatDialogModule],
  templateUrl: './side-dialog-container.component.html',
  styleUrls: ['./side-dialog-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideDialogContainerComponent {

}
