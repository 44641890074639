<div class="section timeline">
  <h2 class="section">Timeline</h2>
  <ng-container *ngIf="timeline$ | async as timeline">
    <div class="section__title" *ngFor="let time of timeline; index as i" [attr.id]="'time-' + time.date">
      <app-collapsible-data-block [headingTitle]="time.date | date: 'M/d/yyyy'">
        <div class="timeline__item-type" *ngFor="let communication of time.items" [ngClass]="communication.type.replace(' ', '')">
          <div class="timeline__item-head">
            <span>{{communication.title}}</span><span>{{communication.created | appUserTimezone:'MM/DD/YYYY hh:mm A'}}</span>
          </div>
          <div class="timeline__item-content">
            <ng-container *ngIf="communication.type === 'Note'">
              <app-data-grid
                [columns]="timelineClaimNoteColumns"
                [source]="[communication]"></app-data-grid>
            </ng-container>
            <ng-container *ngIf="claimCommunicationActivityType.indexOf(communication.type) !== -1 && communication.type !== 'Email'">
              <app-data-grid
                [columns]="timelineClaimLettersColumns"
                [source]="[communication]"></app-data-grid>
            </ng-container>
            <ng-container *ngIf="communication.type === 'Email'">
              <app-data-grid
                [columns]="timelineClaimEmailColumns"
                [source]="[communication]"></app-data-grid>
            </ng-container>
            <ng-container *ngIf="communication.type === 'Service Taken'">
              <app-data-grid
                [columns]="timelineClaimActivityColumns"
                [source]="[communication]"></app-data-grid>
            </ng-container>
            <ng-container *ngIf="communication.type === 'Files'">
              <app-files-preview (allFilesLoaded)="itemFilesLoaded(timeline)"
                                 [files]="communication.files"
                                 [showRemove]="false"></app-files-preview>
              <app-files-for-print [files]="communication.files"></app-files-for-print>
            </ng-container>
          </div>
        </div>
      </app-collapsible-data-block>
    </div>
    <div *ngIf="(timelineLength$ | async) === 0">
      <p>No timeline events.</p>
    </div>
  </ng-container>
</div>
