import { NgForOf, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ConsumerApiService } from '@th-common/app/core/api/consumer-api.service';
import { WizardNavigation, WizardNavigationItem } from '@th-common/app/core/classes/base-wizard';
import { QuickSearchConsumer } from '@th-common/app/core/interfaces/quick-search/search-results.interface';
import {
  QuickSearchConsumerComponent,
} from '@th-common-retailer/shared/components/quick-search-consumer/quick-search-consumer.component';
import { finalize, tap } from 'rxjs/operators';

import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { AddButtonSmallComponent } from '@shared/modules/buttons/add-button-small/add-button-small.component';
import { LoaderModule } from '@shared/modules/loader/loader.module';

import { PreRegisterRetailerPlanSteps } from '../register-retailer-plan/register-retailer-plan.component';

@Component({
  selector: 'app-pre-register-retailer-plan',
  standalone: true,
  templateUrl: './pre-register-retailer-plan.component.html',
  styleUrls: ['./pre-register-retailer-plan.component.scss'],
  imports: [
    NgIf,
    SearchInputComponent,
    LoaderModule,
    NgForOf,
    AddButtonSmallComponent,
    QuickSearchConsumerComponent,
  ],
})
export class PreRegisterRetailerPlanComponent implements OnChanges {
  preSteps = PreRegisterRetailerPlanSteps;
  searchResults: QuickSearchConsumer[] = [];
  loading = false;
  searched = false;

  @Input() navigationList: string[];
  @Input() navigationItems: WizardNavigation;
  @Input() currentStep: WizardNavigationItem;
  @Input() currentSubStep: number;
  @Output() nextStep: EventEmitter<void> = new EventEmitter<void>();
  @Output() goToRegistrationStep: EventEmitter<void> = new EventEmitter<void>();
  @Output() consumerClicked: EventEmitter<QuickSearchConsumer> = new EventEmitter<QuickSearchConsumer>();

  constructor(private consumerApiService: ConsumerApiService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentSubStep && changes.currentSubStep.currentValue === PreRegisterRetailerPlanSteps.Search) {
      this.clearSearch();
    }
  }

  quickSearch(search: string): void {
    if (search) {
      this.loading = true;
      this.searchResults = [];
      this.requestSearch(search);
    }
  }

  requestSearch(query: string): void {
    this.consumerApiService
      .search(query)
      .pipe(
        tap((searchResults: any) => {
          if (searchResults.length) {
            this.searchResults = searchResults;
            this.nextStep.emit();
          }
        }),
        finalize(() => {
          this.loading = false;
          this.searched = true;
        }),
      )
      .subscribe();
  }

  clearSearch(): void {
    this.searchResults = [];
    this.loading = false;
    this.searched = false;
  }

  onConsumerClicked(consumer: QuickSearchConsumer): void {
    this.consumerClicked.emit(consumer);
    this.nextStep.emit();
  }
}
