export enum ClaimType {
  /// <summary>
  /// MS, My service portal claim (My Service portal)
  /// </summary>
  MS = 1,

  /// <summary>
  /// PP, Protection Plan claim (Consumer portal)
  /// </summary>
  PP = 2,
}
