<ng-container>
  <div class="files-preview-new__wrapper" [ngClass]="{disabled: (_disableControls$ | async)}" #photoPreviewWrapper>
    <div *ngIf="loading" class="files-preview-new__loader-wrapper">
      <app-loader class="base-loader"></app-loader>
    </div>
    <app-carousel-new [useNewDesign]="true" [options]="splideOptions" [customOptions]="customCarouselOptions"
                      (carouselCreated)="carouselCreated($event)">
      <ng-container *ngFor="let file of filesList$ | push; trackBy: trackByFn">
        <div class="splide__slide files-preview-new__splide-slide">
          <app-carousel-slide>
            <div class="files-preview-new" (click)="onFileClick(file)"
                 [ngClass]="{disabled: (_disableControls$ | async)}" >
              <div class="files-preview-new__image" [attr.id]="'thumbnailImageBlock' + file.index" >
                <div class="image-block">
                  <div class="files-preview-new__image-wrapper">
                    <div>
                      <img appImageError
                           [src]="file.thumbnailUrl"
                           (load)="fileLoaded($event, file)"
                           [ngClass]="{'hidden': !file.thumbnailLoaded}"
                      />
                    </div>
                  </div>
                  <div class="files-preview-new__label-common files-preview-new__label-file" *ngIf="showDocumentName">
                    <span class="files-preview-new__label-file--doc-type">{{file.docType | fileDoctype}}</span>
                  </div>
                  <div class="files-preview-new__label-common files-preview-new__label-file" *ngIf="showDocumentName">
                    <span class="files-preview-new__label-file--name">{{file | fileName}}</span>
                    <span class="files-preview-new__label-file--extension">{{file | fileExtension}}</span>
                  </div>
                  <mat-spinner class="files-preview-new__uploading-spinner" color="primary" [diameter]="50" *ngIf="!file.thumbnailLoaded"></mat-spinner>
                </div>
              </div>
              <button *ngIf="showRemove && !file.isReadOnly"
                      (click)="removeFile($event, file.index)"
                      mat-icon-button
                      class="files-preview-new__remove-wrapper"
                      type="button">
                <mat-icon svgIcon="delete-button" class="files-preview-new__remove-image"
                          [ngClass]="{'disabled': (_disableControls$ | async)}"></mat-icon>
              </button>
            </div>
          </app-carousel-slide>
        </div>
      </ng-container>
    </app-carousel-new>
  </div>
</ng-container>
