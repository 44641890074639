import { DatePipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
} from '@angular/material-moment-adapter';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { TDateFieldType } from '@core/enums/date/date-field-type.enum';
import { TPortalType } from '@core/enums/portal-type.enum';
import { IAppConfig } from '@core/interfaces/app-config.interface';
import { APP_FORM_FIELD_PROVIDER } from '@core/providers/form-field.provider';
import { MomentDateAdapterV2 } from '@shared/components/form-date/moment-date-adapter-v2';
import { FormErrorComponent } from '@shared/components/form-error/form-error.component';
import { ThBaseFormFieldControlElement } from '@shared/form/base-form-field-control-element.directive';
import { PipesModule } from '@shared/pipes/pipes.module';

@Component({
  selector: 'app-form-date',
  standalone: true,
  templateUrl: './form-date.component.html',
  styleUrls: ['./form-date.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapterV2, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    MatLegacyInputModule,
    NgIf,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatIconModule,
    FormErrorComponent,
    DatePipe,
    PipesModule,
  ],
})
export class FormDateComponent extends ThBaseFormFieldControlElement<string> implements OnInit {

  @ViewChild('picker') picker: MatDatepicker<any>;

  /**
   * Moment date format
   */
  @Input() outputFormat = 'YYYY-MM-DD';

  @Input() max: Date;

  @Input() min: Date;

  @Input() autocomplete: string;

  @Input() readonly = true;

  @Input() type: TDateFieldType;

  @Input() tooltipText = '';

  @Input() noPaddingBottom = false;

  @Input() icon: 'date-range' | 'schedule' | 'reschedule' = 'date-range';

  tooltipAttentionText = '';

  TDateFieldType = TDateFieldType;
  TPortalType = TPortalType;

  customMaxDateValidation = false;
  customMinDateValidation = false;

  @Input()
    datePickerFilter = (d: Date | null): boolean => true;

  constructor(
    @Inject(APP_FORM_FIELD_PROVIDER) public readonly formFieldProvider: {showErrors: boolean},
    @Inject(APP_CONFIG) public readonly appConfig: IAppConfig,
  ) {
    super();
  }

  /**
   * Format the value to the expected format right after the setup
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.customMaxDateValidation = [TDateFieldType.MinMaxDate, TDateFieldType.MyPlanPurchaseDate].includes(this.type);
    this.customMinDateValidation = [TDateFieldType.MinMaxDate, TDateFieldType.MyPlanDeliveryDate].includes(this.type);
  }

  reOpenCalendar(): void {
    if (this.readonly) {
      this.picker.open();
    }
  }
}
