import { ChangeDetectionStrategy, Component, Input,OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

@Component({
  selector: 'app-tooltip-with-label',
  standalone: true,
  templateUrl: './tooltip-with-label.component.html',
  styleUrls: ['./tooltip-with-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatLegacyTooltipModule,
    MatIconModule,
  ],
})
export class TooltipWithLabelComponent implements OnInit {
  @Input() label = 'Where does this number come from?';
  @Input() labelPosition: 'left' | 'right' = 'right';
  @Input() tooltipText = 'Where does this number come from?';
  @Input() tooltipPosition: 'left' | 'right' | 'above' | 'below' | 'before' | 'after' = 'left';

  constructor() {}

  ngOnInit(): void {}
}
