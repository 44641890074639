import { ChangeDetectionStrategy, Component, Input,Optional, Self } from '@angular/core';
import { NgControl, ReactiveFormsModule } from '@angular/forms';

import { BaseFormControlElement } from '@core/base/base-form-control-element';
import { FormSelectComponent } from '@shared/components/form-select/form-select.component';

@Component({
  selector: 'app-processing-stage-select',
  standalone: true,
  templateUrl: './processing-stage-select.component.html',
  styleUrls: ['./processing-stage-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormSelectComponent,
    ReactiveFormsModule,
  ],
})
export class ProcessingStageSelectComponent extends BaseFormControlElement {
  @Input() items;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    super(ngControl);
  }
}
