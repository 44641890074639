<div class="claim-status">
  <p *ngIf="claimStatus"
     [ngClass]="claimStatuses[claimStatus] | lowercase">
    {{claimStatuses[claimStatus]}}
  </p>
  <p *ngIf="claimStage"
     [ngClass]="claimStages[claimStage] | lowercase">
    {{claimStages[claimStage]}}
  </p>
  <span>Opened on: {{dateOpen | momentDate:'MM/DD/YYYY'}}</span>
  <span *ngIf="dateClosed">
          Closed on: {{dateClosed | momentDate:'MM/DD/YYYY'}}
        </span>
</div>
