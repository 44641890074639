import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RxState } from '@rx-angular/state';
import { map, switchMap } from 'rxjs/operators';

import { Pagination } from '@core/interfaces/ui/pagination.interface';

interface IItemDetailsPaginationState {
  currentItemId: number;
  items: any[];
  pagination: Pagination & {pageSize: number};
  itemIndex: number;
  maxPageIndex: number;
  minPageIndex: number;
  currentPage: number;
}

@Component({
  selector: 'app-item-details-pagination',
  standalone: true,
  templateUrl: './item-details-pagination.component.html',
  styleUrls: ['./item-details-pagination.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    MatIconModule,
    AsyncPipe,
    MatButtonModule,
  ],
})
export class ItemDetailsPaginationComponent {
  @Input() set currentItemIndex(itemIndex: number) {
    this.state.set({
      itemIndex,
    });
  }
  @Input() set pagination(pagination: Pagination & {pageSize: number}) {
    this.state.set({
      pagination,
      maxPageIndex: pagination.pageSize - 1,
      minPageIndex: 0,
    });
  }
  @Input() itemsTitle = '';
  @Input() showCount = true;
  @Input() newDesign = false;
  @Input() showPrevNextArrows = true;
  @Output() setPage = new EventEmitter<{page: number; itemIndex: number}>();
  @Output() changeItemIndex = new EventEmitter<number>();

  currentPage$ = this.state.select('currentPage');
  totalCount$ = this.state.select('pagination', 'totalCount');

  constructor(private readonly state: RxState<IItemDetailsPaginationState>) {
    this.state.connect(
      'currentPage',
      this.state.select('itemIndex').pipe(
        switchMap(itemIndex =>
          this.state.select('pagination').pipe(
            map(pagination => {
              const itemsExtraPages = pagination.page * pagination.pageSize - pagination.pageSize;
              return itemIndex + 1 + itemsExtraPages;
            }),
          ),
        ),
      ),
    );
  }

  setPrevPage() {
    const pagination = this.state.get('pagination');

    if (this.state.get('currentPage') === 1) {
      return;
    }

    const prevItemIndex = this.state.get('itemIndex') - 1;
    const minPageIndex = this.state.get('minPageIndex');

    if (prevItemIndex < minPageIndex) {
      this.setPage.emit({
        page: pagination.page - 1,
        itemIndex: pagination.pageSize - 1,
      });
    } else {
      this.changeItemIndex.emit(prevItemIndex);
    }
  }

  setNextPage() {
    const pagination = this.state.get('pagination');

    if (this.state.get('currentPage') === pagination.totalCount) {
      return;
    }

    const nextItemIndex = this.state.get('itemIndex') + 1;
    const maxPageIndex = this.state.get('maxPageIndex');

    if (nextItemIndex > maxPageIndex) {
      this.setPage.emit({
        page: pagination.page + 1,
        itemIndex: 0,
      });
    } else {
      this.changeItemIndex.emit(nextItemIndex);
    }
  }
}
