import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { RxState } from '@rx-angular/state';
import { Options,Splide } from '@splidejs/splide';
import { fromEvent } from 'rxjs';
import { debounceTime, takeUntil, throttleTime } from 'rxjs/operators';

import { BaseComponent } from '@core/base/base.component';
import { ICarouselCustomOptions } from '@core/interfaces/carousel.interface';
import { CarouselSlideComponent } from '@shared/modules/carousel/carousel-slide/carousel-slide.component';

interface ICarouselState {
  currentIndex: number;
  allSlidesVisible: boolean;
  lastSlideVisible: boolean;
  showArrows: boolean;
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  @Input() options: Options;
  @Input() customOptions: ICarouselCustomOptions = {
    arrowsPosition: 'inside',
    arrowsBackground: true,
  };
  @Output() carouselCreated = new EventEmitter<Splide>();
  @Output() indexChanged = new EventEmitter<number>();

  @ContentChildren(CarouselSlideComponent, { descendants: true }) slides: QueryList<CarouselSlideComponent>;

  @ViewChild('carousel') carousel: ElementRef;

  carouselInstance: Splide;
  currentIndex$ = this.state.select('currentIndex');
  allSlidesVisible$ = this.state.select('allSlidesVisible');
  lastSlideVisible$ = this.state.select('lastSlideVisible');
  showArrows$ = this.state.select('showArrows');

  constructor(private readonly state: RxState<ICarouselState>) {
    super();
    this.state.set({
      currentIndex: 0,
      allSlidesVisible: false,
      lastSlideVisible: false,
    });
  }

  ngAfterViewInit(): void {
    this.carouselInstance = new Splide(this.carousel.nativeElement, this.options);
    this._subscribeToCarouselEvents();
    this.carouselCreated.emit(this.carouselInstance);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.carouselInstance) {
      this.carouselInstance.destroy(true);
    }
  }

  private _subscribeToCarouselEvents(): void {
    this.slides.changes
      .pipe(debounceTime(0), takeUntil(this.unsubscribeOnDestroy$))
      .subscribe((list: QueryList<CarouselSlideComponent>) => {
        const lastListIndex = list.length - 1;
        if (lastListIndex !== -1 && this.carouselInstance.index > lastListIndex) {
          this.carouselInstance.go(lastListIndex);
        }
        this.carouselInstance.refresh();
      });

    this.carouselInstance.on('mounted', () => {
      this.state.set({
        currentIndex: this.carouselInstance.index,
        showArrows: this.carouselInstance.options.arrows,
      });
    });

    this.carouselInstance.on('updated', (options: Options) => {
      this.state.set({
        showArrows: options.arrows,
      });
    });

    this.state.connect(fromEvent(this.carouselInstance, 'visible').pipe(throttleTime(0), debounceTime(0)), oldState => {
      const slides = this.carouselInstance.Components.Slides.get(false);
      const visibleSlidesLength = slides.filter(slide => slide.slide.classList.contains('is-visible')).length;
      return {
        ...oldState,
        allSlidesVisible: visibleSlidesLength === this.carouselInstance.length,
        lastSlideVisible: slides[slides.length - 1].slide.classList.contains('is-visible'),
      };
    });

    this.carouselInstance.on('move', (currentIndex: number) => {
      this.state.set({
        currentIndex,
      });
      this.indexChanged.emit(currentIndex);
    });
  }
}
