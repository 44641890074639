import { GridOrderByModel } from '@shared/modules/grid/models/grid/grid-order-by.model';

import { Pagination } from '../../../../../../core/interfaces/ui/pagination.interface';
import { Identity, IIdentityString } from '../../../../../../core/interfaces/util/identity.interface';
import { BaseSearchRequest } from '../../../../../../core/models/search/search-request.model';

export namespace StoreState {
  export interface IStoreState {
    items: IIdentityString[];
    searchRequest: BaseSearchRequest;
    pagination: Pagination;
    loading: boolean;
    selected: Identity;
    selectedExists: boolean;
    disabled: boolean;
  }

  export const initialState: IStoreState = {
    items: [],
    searchRequest: {
      page: 1,
      pageSize: 10,
      orderBy: [new GridOrderByModel('name', true)],
      noCount: false,
      noRows: false,
    },
    pagination: {
      totalCount: 0,
      page: 1,
      pageCount: 1,
    },
    loading: false,
    selected: null,
    selectedExists: false,
    disabled: false,
  };
}
