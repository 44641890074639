import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { Identity } from '@core/interfaces/util/identity.interface';
import { LookupService } from '@core/services/lookup.service';

@Component({
  selector: 'app-escalation-info',
  standalone: true,
  templateUrl: './escalation-info.component.html',
  styleUrls: ['./escalation-info.component.scss'],
  imports: [
    NgIf,
  ],
})
export class EscalationInfoComponent implements OnInit {
  @Input() escalation: number;
  escalationName: string;
  constructor(private lookupService: LookupService) {}

  ngOnInit() {
    this.lookupService.getLookup('escalation').subscribe((escalations: Identity[]) => {
      this.escalationName = escalations.find(escalation => escalation.id === this.escalation).name;
    });
  }
}
