import { TDateRangeType } from '@core/enums/date-range-type.enum';
import { TClaimPriority } from '@core/enums/tech-portal/claim-priority.enum';
import { TDateKindInvoice, TDateKindWorkOrder } from '@core/enums/tech-portal/date-kind-tech.enum';
import { TInvoiceStatus, TWorkOrderStatus } from '@core/enums/tech-portal/work-order-status.enum';
import { TWorkOrderType } from '@core/enums/tech-portal/work-order-type.enum';
import { UserScope } from '@core/enums/user-scope.enum';
import { GridOrderByModel } from '@shared/modules/grid/models/grid/grid-order-by.model';

import { DataImportRowStatuses } from '../../../../../th-system/app/core/enums/data-import/data-import-row-statuses.enum';
import { ExportKinds, ExportSelections } from '../../enums/claim/claim-export.enum';
import { ClaimStages } from '../../enums/claim/claim-stages.enum';
import { ClaimStatuses } from '../../enums/claim/claim-statuses.enum';
import { DateKind } from '../../enums/claim/date-kind.enum';
import { TConsumerPlanType } from '../../enums/consumer-plan-type.enum';
import { RetailerFilter } from '../../enums/retailer-filter.enum';
import { ClaimsExportOptions, WorkOrdersExportOptions } from '../../interfaces/claims/claims-export-options.interface';
import { IServiceActionFilter } from '../../interfaces/service-action-filter.interface';

export interface BaseSearchRequest {
  orderBy?: GridOrderByModel[];
  page?: number;
  pageSize?: number;
  searches?: string[];
  noCount?: boolean;
  noRows?: boolean;
}

export interface IPurchaseSearchRequest extends BaseSearchRequest {
  receiptNumber?: string;
  consumerEmail?: string;
  consumerPhone?: string;
  retailerName?: string;
  retailerState?: string;
  purchaseDate?: string;
}

export interface TenantSearchRequest extends BaseSearchRequest {
  noPortal: boolean;
  scope?: UserScope[];
}

export interface StoreSearchRequest extends BaseSearchRequest {
  retailerFilter: RetailerFilter;
  regionId: number;
  regionIds: number[];
  tenantId?: number;
}

export interface RetailerClassificationSearchRequest extends BaseSearchRequest {
  filterByRetailerId: string;
}

export interface RootCrmAccountSearchRequest extends BaseSearchRequest {
  rootAccountsOnly: boolean;
}

export interface ProblemTypeSubSearchRequest extends BaseSearchRequest {
  parentId: string;
}

export interface ClaimSearchRequest extends BaseSearchRequest {
  regionId: number;
  retailerId: number;
  claimStatus: ClaimStatuses;
  claimStage: ClaimStages;
  dateKind: DateKind;
  fromDate: string;
  toDate: string;
  serviceActionFilters: Partial<IServiceActionFilter>[];
  consumerPlanType: TConsumerPlanType;
  isFlaggedForClientReview: boolean;
  dateRangeType?: TDateRangeType;
}

export interface DataImportSearchRequest extends BaseSearchRequest {
  exportOptions: ClaimsExportOptions;
  excludeActive: boolean;
  filterBy: any[];
  byStatus: DataImportRowStatuses;
  byErrorType: string; // radio     DataImportRowErrorType
  bySalesTypes: string[]; // multi-select
}

export interface SlumberlandDataSearchRequest extends BaseSearchRequest {
  exportOptions: ClaimsExportOptions;
  excludeActive: boolean;
  filterBy: any[];
  searchAsOneWord: boolean;
  phoneNumber?: number;
  salesOrder?: string;
  customerCode?: string;
}

export interface UserSearchRequest extends BaseSearchRequest {
  role?: number;
  specificTenantId?: number;
  excludeActive?: boolean;
}

export interface PortalFeaturesSearchRequest extends BaseSearchRequest {
  searchAsOneWord: boolean;
  excludeActive: boolean;
  scope?: number;
  filterBy?: any[];
}

export interface TechWorkOrderSearchRequest extends BaseSearchRequest {
  priority?: TClaimPriority;
  claimType?: TConsumerPlanType;
  claimNumber?: string;
  workOrderNumber?: string;
  workOrderStatusList?: TWorkOrderStatus[];
  processStage?: string;
  dateKind?: TDateKindWorkOrder | TDateKindInvoice;
  fromDate?: string;
  toDate?: string;
  authorizedWorkOrderCost?: number;
  consumerName?: string;
  consumerPhone?: string;
  consumerZipCode?: string;
  retailerName?: string;
  exportOptions?: WorkOrdersExportOptions;
  searchAsOneWord?: boolean;
  excludeActive?: boolean;
  filterBy?: any[];
  workOrderType?: TWorkOrderType;
  claimStatus?: ClaimStatuses;
  claimStatusList?: ClaimStatuses[];
  invoiceStatus?: TInvoiceStatus;
  dateRangeType?: TDateRangeType;
}

export const TECH_WORK_ORDER_SEARCH_REQUEST_LABELS = {
  priority: 'Priority',
  claimType: 'Claim Type',
  claimNumber: 'Claim #',
  workOrderNumber: 'Work Order #',
  workOrderStatusList: 'Work Order Status',
  dateKind: 'Date Kind',
  consumerName: 'Consumer Name',
  consumerPhone: 'Consumer Phone',
  consumerZipCode: 'Consumer Zip Code',
  retailerName: 'Retailer Name',
  claimStatusList: 'Claim Status',
  invoiceStatus: 'Invoice Status',
};

export interface TechInvoicesSearchRequest extends BaseSearchRequest {
  invoiceNumber?: string;
  workOrderNumber?: string;
  claimNumber?: string;
  invoiceStatusList?: TInvoiceStatus[];
  dateKind?: number;
  fromDate?: string;
  toDate?: string;
  dateRangeType?: TDateRangeType;
}

export const TECH_INVOICE_SEARCH_REQUEST_LABELS = {
  invoiceNumber: 'Invoice #',
  workOrderNumber: 'Work Order #',
  claimNumber: 'Claim #',
  invoiceStatusList: 'Invoice Status',
  dateKind: 'Date Kind',
};

export interface IExportOptions {
  ids: number[];
  selections: number;
  exportKind: number;
  exportTopN: number;
}

export interface VideosSearchRequest extends BaseSearchRequest {
  searchAsOneWord: boolean;
  excludeActive: boolean;
  scope?: number;
  filterBy?: any[];
}

export interface FAQsSearchRequest extends BaseSearchRequest {
  searchAsOneWord: boolean;
  excludeActive: boolean;
  contentType?: number;
  scope?: number;
  filterBy?: any[];
  exportOptions?: {
    ids: number[];
    selections: ExportSelections;
    exportKind: ExportKinds;
    exportTopN?: number;
  };
}

export interface ProtectionPlanRequest extends BaseSearchRequest {
  regionId?: number;
  retailerId?: number;
  mjcProductType?: number;
  fromDate?: string;
  toDate?: string;
  // protectionPlanType?: number;
  searchAsOneWord: boolean;
  exportOptions?: {
    ids: number[];
    selections: ExportSelections;
    exportKind: ExportKinds;
    exportTopN?: number;
  };
}

export class SearchRequestModel {
  params: BaseSearchRequest = {
    orderBy: [],
    page: 1,
    pageSize: 10,
    searches: [],
    noCount: false,
    noRows: false,
  };

  constructor(searchRequestData: BaseSearchRequest) {
    this.params = { ...this.params, ...searchRequestData };
  }

  getParams(): BaseSearchRequest {
    return this.params;
  }
}

export interface ClientsSearchRequest extends BaseSearchRequest {
  filterBy?: any[];
}

export interface PortalDetailsDataImportSearchRequest extends BaseSearchRequest {
  filterBy?: any[];
  showInactive?: boolean;
  tenantId: string | number;
}
