import { TTileId } from '@core/enums/claim/tile-types.enum';
import { GridOrderByModel } from '@shared/modules/grid/models/grid/grid-order-by.model';

import {
  ACTION_TILE_SEARCH_REQUEST,
  STAT_TILE_SEARCH_REQUEST,
} from '../../../../../th-retailer/app/core/constants/tile-stage-filter.constant';
import { TConsumerPlanType } from '../../enums/consumer-plan-type.enum';
import { Feature } from '../../enums/feature.enum';
import { Claim } from '../../interfaces/claims/claim.interface';
import { DashboardTileBase } from '../../interfaces/dashboard-tile.interface';
import { Pagination } from '../../interfaces/ui/pagination.interface';
import { ClaimSearchRequest } from '../../models/search/search-request.model';

export namespace StoreState {
  export interface IStoreState {
    claims: Claim[];
    searchRequest: Partial<ClaimSearchRequest>;
    pagination: Pagination;
    loading: boolean;
    saveInProgress: boolean;
    exportInProgress: boolean;
    statTiles: DashboardTileBase[];
    actionTiles: DashboardTileBase[];
    allChecked?: boolean;
  }

  export const initialState: IStoreState = {
    claims: [],
    searchRequest: {
      orderBy: [new GridOrderByModel('dateOpen', false)],
      page: 1,
      pageSize: 10,
      searches: [],
      noCount: false,
      noRows: false,
      serviceActionFilters: [],
    },
    pagination: {
      totalCount: 0,
      page: 1,
      pageCount: 1,
    },
    loading: false,
    saveInProgress: false,
    exportInProgress: false,
    actionTiles: [
      {
        id: TTileId.ClaimsToReview,
        title: 'Claims to Review',
        icon: 'flag-white',
        searchRequest: ACTION_TILE_SEARCH_REQUEST[TTileId.ClaimsToReview],
        claimType: TConsumerPlanType.MyService,
        features: [Feature.HasClaimsForClientReview],
        tileDataType: 'claims',
      },
      {
        id: TTileId.SettlementsToApprove,
        title: 'Settlements to Approve',
        icon: 'settlements',
        searchRequest: ACTION_TILE_SEARCH_REQUEST[TTileId.SettlementsToApprove],
        claimType: TConsumerPlanType.MyService,
        features: [Feature.HasActiveMyServiceSettlements],
        tileDataType: 'claims',
      },
      {
        id: TTileId.ComplaintsToReview,
        title: 'Complaints to Review',
        checkboxTitle: 'Open Complaints',
        icon: 'complaints',
        searchRequest: ACTION_TILE_SEARCH_REQUEST[TTileId.ComplaintsToReview],
        claimType: TConsumerPlanType.MyService,
        features: [Feature.HasActiveMyServiceComplaints],
        tileDataType: 'claims',
      },
      {
        id: TTileId.ExchangesToReview,
        title: 'Exchanges to Review',
        icon: 'exchanges',
        searchRequest: ACTION_TILE_SEARCH_REQUEST[TTileId.ExchangesToReview],
        claimType: TConsumerPlanType.MyService,
        features: [Feature.HasActiveMyServiceExchanges],
        tileDataType: 'claims',
      },
      {
        id: TTileId.PPClaimsToReview,
        title: 'Claims to Review',
        icon: 'flag-white',
        searchRequest: ACTION_TILE_SEARCH_REQUEST[TTileId.PPClaimsToReview],
        claimType: TConsumerPlanType.ProtectionPlan,
        features: [Feature.HasFppClaimsForClientReview],
        tileDataType: 'claims',
      },
    ],
    statTiles: [
      {
        id: TTileId.OpenClaims,
        title: 'Open Claims',
        icon: 'service-claims',
        searchRequest: STAT_TILE_SEARCH_REQUEST[TTileId.OpenClaims],
        claimType: TConsumerPlanType.MyService,
        features: [Feature.ViewClaims],
        hideCheckbox: true,
        tileDataType: 'claims',
      },
      {
        id: TTileId.OpenPartOrders,
        title: 'Open Part Orders',
        icon: 'parts',
        searchRequest: STAT_TILE_SEARCH_REQUEST[TTileId.OpenPartOrders],
        claimType: TConsumerPlanType.MyService,
        features: [Feature.HasActiveMyServiceParts],
        tileDataType: 'claims',
      },
      {
        id: TTileId.RecentTechReports,
        title: 'Recent Tech Reports',
        icon: 'work-orders',
        searchRequest: STAT_TILE_SEARCH_REQUEST[TTileId.RecentTechReports],
        claimType: TConsumerPlanType.MyService,
        features: [Feature.HasActiveMyServiceTech],
        tileDataType: 'claims',
      },
      {
        id: TTileId.PPOpenPartOrders,
        title: 'Open Part Orders',
        icon: 'parts',
        searchRequest: STAT_TILE_SEARCH_REQUEST[TTileId.PPOpenPartOrders],
        claimType: TConsumerPlanType.ProtectionPlan,
        features: [],
        tooltipText:'This count shows all in-progress part orders including ones being worked on by Montage that require no retailer action. To find all part orders requiring retailer action, please use the Part Orders tile above.',
        tileDataType: 'serviceActions',
      },
      {
        id: TTileId.PPEvenExchanges,
        title: 'Open Even Exchanges',
        icon: 'exchanges',
        searchRequest: STAT_TILE_SEARCH_REQUEST[TTileId.PPEvenExchanges],
        claimType: TConsumerPlanType.ProtectionPlan,
        tooltipText:'This count shows all in-progress even exchanges including ones being worked on by Montage that require no retailer action. To find all even exchanges requiring retailer action, please use the Replacements tile above.',
        features: [],
        tileDataType: 'serviceActions',
      },
      {
        id: TTileId.PPOpenReselections,
        title: 'Open Reselections',
        icon: 'exchanges',
        searchRequest: STAT_TILE_SEARCH_REQUEST[TTileId.PPOpenReselections],
        claimType: TConsumerPlanType.ProtectionPlan,
        tooltipText:'This count shows all in-progress reselections including ones being worked on by Montage that require no retailer action. To find all reselections requiring retailer action, please use the Replacements tile above.',
        features: [],
        tileDataType: 'serviceActions',
      },
    ],
  };
}
