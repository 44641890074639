<div class="wizard-step-buttons">
  <ng-content></ng-content>
  <div
    class="wizard-step-buttons__main"
    [class.wizard-step-buttons__main--right]="!currentStep.nextStep && submitButtonPosition === 'right'">
    <ng-container *ngIf="currentStep.prevStep || (currentStep.child && currentSubStep > 0)">
      <button
        [disabled]="submitInProgress || backButtonDisabled"
        type="button"
        id="button-back"
        (click)="prevStep.emit()"
        mat-flat-button
        color="primary">
        Back
      </button>
    </ng-container>
    <ng-container *ngIf="currentStep.nextStep">
      <button
        *ngIf="nextButtonShow"
        id="button-next"
        appLoadingButton
        [isLoading]="nextButtonInProgress"
        (click)="nextStepHandle($event)"
        [disabled]="
          nextButtonDisabled !== undefined ? nextButtonDisabled : !form.get(currentStep.formGroupName).valid
        "
        mat-flat-button
        color="primary">
        Next
      </button>
    </ng-container>
    <ng-content select="[sizeError]"></ng-content>
    <button
      *ngIf="!currentStep.nextStep"
      (click)="submitForm.emit()"
      id="button-submit"
      mat-flat-button
      color="primary"
      appLoadingButton
      [isLoading]="submitInProgress"
      [disabled]="!form.valid || disableSubmitButton">
      {{ submitButtonText }}
    </button>
  </div>
</div>
