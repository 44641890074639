import { NgIf } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { AppConstants } from '../../../core/constants/app.constants';

@Component({
  selector: 'app-email-link',
  standalone: true,
  templateUrl: './email-link.component.html',
  styleUrls: ['./email-link.component.scss'],
  imports: [
    NgIf,
  ],
})
export class EmailLinkComponent implements OnChanges {
  @Input() email: string;
  isEmailValid: boolean;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.isEmailValid = !!this.email.match(AppConstants.emailRegEx);
  }
}
