<div class="th-table-pagination">
  <div
    *ngIf="counter$ | async as counter"
    class="th-table-pagination__counter">
    {{ counter.from }}-{{ counter.to }} of {{ pagination.totalCount }}
  </div>

  <div class="th-table-pagination__controls">
    <div class="th-table-pagination__previous">
      <button
        (click)="first()"
        [disabled]="pagination.page === 1"
        class="th-table-pagination__page-button">
        <mat-icon>keyboard_double_arrow_left</mat-icon>
      </button>
      <button
        (click)="prev()"
        [disabled]="pagination.page === 1"
        class="th-table-pagination__page-button">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
    </div>
    <div class="th-table-pagination__pages">
      <button
        *ngFor="let page of pagesRange$ | async"
        [disabled]="page === '...'"
        (click)="changePage(page)"
        [class.is-active]="pagination.page === page"
        class="th-table-pagination__page-button">
        {{ page }}
      </button>
    </div>
    <div class="th-table-pagination__next">
      <button
        (click)="next()"
        [disabled]="pagination.page >= pagination.pageCount"
        class="th-table-pagination__page-button">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
      <button
        (click)="last()"
        [disabled]="pagination.page >= pagination.pageCount"
        class="th-table-pagination__page-button">
        <mat-icon>keyboard_double_arrow_right</mat-icon>
      </button>
    </div>
  </div>
</div>
