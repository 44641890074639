import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import {
  ShippingLabelActionCellComponent,
} from '@th-common-retailer/dialogs/claim-details/components/shipping-label-action-cell/shipping-label-action-cell.component';

import { IServerRepairAction } from '@core/interfaces/claims/claimDetails.interface';
import { AltTableDirective } from '@shared/directives/table/alt-table.directive';
import { IAppTableCol } from '@shared/modules/table/interfaces/table-col.interface';
import { IAppTableRow } from '@shared/modules/table/interfaces/table-row.interface';
import { ThTableComponent } from '@shared/modules/table/table.component';
import { ThTableService } from '@shared/modules/table/table.service';


interface IRepairActionsTableState {
  columns: IAppTableCol[];
  rows: IAppTableRow[];
}

@Component({
  selector: 'app-repair-actions-table',
  standalone: true,
  imports: [
    LetDirective,
    ThTableComponent,
    AltTableDirective,
    NgIf,
    ShippingLabelActionCellComponent,
  ],
  templateUrl: './repair-actions-table.component.html',
  styleUrls: ['./repair-actions-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepairActionsTableComponent {
  vm$ = this.state.select();

  @Input() set repairActions(repairActions: IServerRepairAction[]) {
    this.state.set({
      columns: this._repairActionColumns(),
      rows: this._buildRepairActionRows(repairActions || []),
    });
  }

  constructor(
    private readonly state: RxState<IRepairActionsTableState>,
  ) {
    this.state.set({
      columns: [],
      rows: [],
    });
  }

  private _repairActionColumns(): IAppTableCol[] {
    return [
      ThTableService.buildCol('repairTypeCategory', 'Repair Type Category'),
      ThTableService.buildCol('repairType', 'Repair Type'),
      ThTableService.buildCol('repairInstructions', 'Repair Instructions', {
        maxWidth: '180px',
      }),
      ThTableService.buildCol('repairAuthorizationStatus', 'Authorization Status'),
      ThTableService.buildCol('repairServicingType', 'Servicer Type'),
      ThTableService.buildCol('estimate', 'Estimate'),
      ThTableService.buildCol('amount', 'Amount'),
      ThTableService.buildCol('quantity', 'Quantity'),
    ];
  }

  private _buildRepairActionRows(repairActions: IServerRepairAction[]): IAppTableRow[] {
    return repairActions.map((repairAction: IServerRepairAction) => ({
      id: ThTableService.buildCellInline(repairAction.id),
      repairTypeCategory: ThTableService.buildCellInline(repairAction.repairTypeCategory),
      repairType: ThTableService.buildCellInline(repairAction.repairType),
      repairInstructions: ThTableService.buildCellInline(repairAction.repairInstructions || '-'),
      repairAuthorizationStatus: ThTableService.buildCellInline(repairAction.repairAuthorizationStatus),
      repairServicingType: ThTableService.buildCellInline(repairAction.repairServicingType),
      estimate: repairAction.repairEstimate === null
        ? ThTableService.buildCellInline('-')
        : ThTableService.buildCellCurrency(repairAction.repairEstimate),
      amount: repairAction.reimbursementAmount === null
        ? ThTableService.buildCellInline('-')
        : ThTableService.buildCellCurrency(repairAction.reimbursementAmount),
      quantity: ThTableService.buildCellInline((repairAction.quantity || 0).toString()),
    }));
  }

}
