import { Injectable } from '@angular/core';

import { Address } from '../interfaces/address.interface';
import { CrudApiBaseService } from './crud-api.base';

@Injectable({
  providedIn: 'root',
})
export class UtilityApiService extends CrudApiBaseService<any> {
  endpoint = 'utility';

  validateAddress(address: Address) {
    return this.httpClient.post<any>(this.getUrl('validateaddress'), address);
  }

  supportInfo(planId: number) {
    return this.httpClient.get<any>(this.getUrl(`support-info/${planId}`));
  }
}
