import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usaPhone',
})
export class UsaPhonePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    } else {
      return value;
    }
  }
}
