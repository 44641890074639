import { Pipe, PipeTransform } from '@angular/core';

import { Files } from '@core/interfaces/claims/files.interface';

@Pipe({
  name: 'fileExtension',
})
export class FileExtensionPipe implements PipeTransform {
  transform(file: Files): string {
    let fileNameArray = [];
    fileNameArray = (
      file.fileName ? file.fileName : (file.originalFileName ? file.originalFileName : '')
    ).split('.');
    if (fileNameArray.length) {
      return '.' + fileNameArray[fileNameArray.length - 1];
    } else {
      return '';
    }
  }
}
