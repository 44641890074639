import { PasswordMatchesInterface } from '../../interfaces/password/password-matches.interface';
import { PasswordMatchOptionModel } from './password-match-option.model';

export class PasswordMatchesModel implements PasswordMatchesInterface {
  requiredLength = new PasswordMatchOptionModel();
  requiredUniqueChars = new PasswordMatchOptionModel();
  requireNonAlphanumeric = new PasswordMatchOptionModel();
  requireLowercase = new PasswordMatchOptionModel();
  requireUppercase = new PasswordMatchOptionModel();
  requireDigit = new PasswordMatchOptionModel();

  constructor() {}
}
