import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { LookupApiService } from '@core/api/lookup-api.service';
import { DestroyableComponent } from '@core/classes/destroyable-component';
import { IConsumer } from '@core/interfaces/consumer/consumer.interface';

@Component({
  selector: 'app-consumer-form',
  templateUrl: './consumer-form.component.html',
  styleUrls: ['./consumer-form.component.scss'],
})
export class ConsumerFormComponent extends DestroyableComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() consumerForm: UntypedFormGroup;
  @Input() readonly = false;
  @Input() emailRequired = true;
  @Input() emailEditable = false;
  @Input() hiddenBlocks: string[] = [];
  @Input() blocked: boolean;
  @Output() emailWasChanged = new EventEmitter<boolean>();

  consumer: IConsumer;
  tooltipPhones = 'There must be at least one phone number provided.';

  constructor(
    private lookupApiService: LookupApiService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.readonly) {
      this.consumer = this.consumerForm.getRawValue();
      this.lookupApiService.getContactLanguage(this.consumer.preferredContactLanguage).subscribe(item => {
        this.consumer.contactLanguage = item.name;
        this.cdr.detectChanges();
      });
      this.lookupApiService.getContactMethod(this.consumer.preferredContactMethod).subscribe(item => {
        this.consumer.contactMethod = item.name;
        this.cdr.detectChanges();
      });
      this.lookupApiService.getContactTime(this.consumer.preferredContactTime).subscribe(item => {
        this.consumer.contactTime = item.name;
        this.cdr.detectChanges();
      });
    } else {
      if (this.form.get('consumer.consumerAddress')) {
        this.form
          .get('consumer.consumerAddress')
          .valueChanges.pipe(
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            takeUntil(this.componentDestroyed$),
          )
          .subscribe(() => {
            if (this.form.get('isServiceAddressSame') && this.form.get('consumer.serviceAddress')) {
              this._serviceAddressIsSame();
            }
          });
      }
    }

    if (this.form.get('isServiceAddressSame') && this.form.get('consumer.serviceAddress')) {
      this.form
        .get('isServiceAddressSame')
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.componentDestroyed$))
        .subscribe(() => {
          this._serviceAddressIsSame();
        });
    }
  }

  private _serviceAddressIsSame(): void {
    if (this.form.get('isServiceAddressSame').value) {
      this.form
        .get('consumer.serviceAddress')
        .patchValue({ ...(this.form.get('consumer.consumerAddress') as UntypedFormGroup).getRawValue() });
    }
  }
}
