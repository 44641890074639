import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment';
import { parseZone } from 'moment/moment';
import moment from 'moment-timezone';

@Pipe({
  name: 'dateCustomFormat',
})
export class DateCustomFormatPipe implements PipeTransform {
  public transform(initialDate: string | Moment | Date, format: string = 'MM/DD/YYYY'): string {
    if (!initialDate) {
      return '';
    }
    if (typeof initialDate === 'string' && initialDate.indexOf(' ') !== -1) {
      const notFormatted = initialDate.split(' ')[0];
      return moment(notFormatted, 'YYYY-MM-DD').format(format);
    } else {
      return parseZone(initialDate).format(format); // take clear date, without time
    }
  }
}
