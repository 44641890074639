import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { WizardNavigationItem } from '@core/classes/base-wizard';

@Component({
  selector: 'app-wizard-step-buttons',
  templateUrl: './wizard-step-buttons.component.html',
  styleUrls: ['./wizard-step-buttons.component.scss'],
})
export class WizardStepButtonsComponent {
  @Input() form: UntypedFormGroup;
  @Input() currentStep: WizardNavigationItem;
  @Input() currentSubStep: number;
  @Input() submitInProgress: boolean;
  @Input() submitButtonText = 'Submit';
  @Input() submitButtonPosition: 'default' | 'right' = 'default';
  @Input() nextButtonShow = true;
  @Input() nextButtonDisabled: boolean;
  @Input() nextButtonInProgress: boolean;
  @Input() disableSubmitButton = false;
  @Input() backButtonDisabled = false;

  @Output() prevStep: EventEmitter<void> = new EventEmitter<void>();
  @Output() nextStep: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitForm: EventEmitter<void> = new EventEmitter<void>();

  nextStepHandle($event: MouseEvent) {
    $event.preventDefault();
    this.nextStep.emit();
  }
}
