import { inject, Injectable } from '@angular/core';
import { PlanDetailsDialogService } from '@th-common-retailer/shared/dialogs/plan-details/plan-details-dialog.service';
import { Observable, of } from 'rxjs';

import { Feature } from '@core/enums/feature.enum';
import { MyProtectionPlanStatus } from '@core/enums/plan/my-protection-plan-status.enum';
import { PlanDetails } from '@core/interfaces/plan/plan.interface';
import { FeatureService } from '@core/services/feature.service';
import {
  ConsumerPlanStatusGridCellComponent,
} from '@shared/modules/base-plan-details/plan-status-grid-cell/consumer-plan-status-grid-cell.component';
import { GridDateCellComponent } from '@shared/modules/grid/components/grid-date-cell/grid-date-cell.component';
import { GridPriceCellComponent } from '@shared/modules/grid/components/grid-price-cell/grid-price-cell.component';
import { GridColumnModel } from '@shared/modules/grid/models/grid/grid-column.model';

@Injectable({
  providedIn: 'root',
})
export class RetailerPlanDetailsDialogService extends PlanDetailsDialogService {
  featureService = inject(FeatureService);

  planColumns = [
    new GridColumnModel('consumerPlanName', 'Plan Number'),
    new GridColumnModel('parentPlanName', 'Plan Description'),
    new GridColumnModel('purchaseDate', 'Purchase', GridDateCellComponent),
    new GridColumnModel('deliveryDate', 'Delivery', GridDateCellComponent),
    new GridColumnModel('planPrice', 'Plan Price', GridPriceCellComponent),
    new GridColumnModel('purchasePrice', 'Purchase Price', GridPriceCellComponent),
    new GridColumnModel('protectionPlanStatus', 'Plan Status', ConsumerPlanStatusGridCellComponent),
  ];

  coveredProductColumns = [
    new GridColumnModel('vendor', 'Vendor'),
    new GridColumnModel('name', 'Name'),
    new GridColumnModel('sku', 'SKU'),
  ];

  showCancelPlanButton(planDetails: PlanDetails): Observable<boolean> {
    const isAllowedCancelPlan = this.featureService.allowed(Feature.CancelProtectionPlan);
    return of(planDetails.planInfo.protectionPlanStatus === MyProtectionPlanStatus.Registered
      && planDetails.isPlanReturnable
      && isAllowedCancelPlan,
    );
  }

  parsePlanDetails(planDetails: PlanDetails): PlanDetails {
    return planDetails;
  }

  getCoveredProductColumns(plan: PlanDetails): GridColumnModel[] {
    return this.coveredProductColumns;
  }
}
