export enum TServiceActionType {
  /// <summary>
  /// Pre-Qualification
  /// </summary>
  PreQualification = 1,
  /// <summary>
  /// Self inspection Kit (SIP)
  /// </summary>
  Kit = 2,
  /// <summary>
  /// Cash settlement (check, gift card, discount)
  /// </summary>
  CashSettlement = 3,
  /// <summary>
  /// Technician Work Order
  /// </summary>
  TechWorkOrder = 4,
  /// <summary>
  /// Replacement
  /// </summary>
  Replacement = 5,
  /// <summary>
  /// Part order
  /// </summary>
  PartOrder = 6,
  /// <summary>
  /// Refund
  /// </summary>
  Refund = 7,
  /// <summary>
  /// Complaint
  /// </summary>
  Complaint = 8,
  /// <summary>
  /// Complaint
  /// </summary>
  Repair = 9,
  /// <summary>
  /// Repairs
  /// </summary>
  Repairs = 101,
  /// <summary>
  /// Claims to Review
  /// </summary>
  ClaimsToReview = 103,
  /// <summary>
  /// Not known
  /// </summary>
  Other = 100,
}
