import { DatePipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
// eslint-disable-next-line max-len
import {
  AuthorizationAvailabilityOrderingDialogComponent,
} from '@th-common-retailer/dialogs/claim-details/components/authorization-availability-ordering-dialog/authorization-availability-ordering-dialog.component';
import {
  RetailerActionReplacementsDialogComponent,
} from '@th-common-retailer/dialogs/claim-details/components/retailer-action-replacements-dialog/retailer-action-replacements-dialog.component';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { DIALOG_CONFIG_SERVICE_ACTION } from '@core/constants/dialog.constants';
import { TRetailerAction } from '@core/enums/claim/retailer-action.enum';
import { Feature } from '@core/enums/feature.enum';
import { IClaimServiceActionModel } from '@core/interfaces/claims/claimDetails.interface';
import { ISession } from '@core/interfaces/session/session-data.interface';
import { UserStore } from '@core/store/user/user.store';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import { CloseComplaintDialogComponent } from '@shared/modules/grid/components/close-complaint-dialog/close-complaint-dialog.component';
import { CloseExchangeDialogComponent } from '@shared/modules/grid/components/close-exchange-dialog/close-exchange-dialog.component';
import {
  CloseSettlementsDialogComponent,
} from '@shared/modules/grid/components/close-settlements-dialog/close-settlements-dialog.component';
import { PipesModule } from '@shared/pipes/pipes.module';
import { UserTimezonePipe } from '@shared/pipes/user-timezone.pipe';

@Component({
  selector: 'app-retailer-action-cell',
  standalone: true,
  templateUrl: './service-retailer-action.component.html',
  styleUrls: ['./service-retailer-action.component.scss'],
  providers: [RxState, DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    PipesModule,
    NgIf,
    NgForOf,
  ],
})
export class ServiceRetailerActionComponent implements OnInit {
  feature = Feature;
  handledText = 'Handled';
  data: IClaimServiceActionModel;
  fieldName: string;
  session: ISession = this.userStore.get('session');
  appConfig = inject(APP_CONFIG);

  constructor(
    private readonly modalDialogService: ModalDialogService,
    private readonly userTimezonePipe: UserTimezonePipe,
    private readonly datePipe: DatePipe,
    private readonly userStore: UserStore,
  ) {}

  ngOnInit(): void {
    if (this.data.enteredBy !== 'System Admin User') {
      this.handledText = `Handled on ${this.datePipe.transform(
        this.userTimezonePipe.transform(this.data.modifiedOn, 'MM/DD/YYYY hh:mm A'),
      )} by ${this.data.enteredBy}`;
    }
  }

  handleAction(retailerAction: TRetailerAction): void {
    switch (retailerAction) {
      case TRetailerAction.Invoice:
        window.open(this._buildPdfLink(`${this.appConfig.apiBaseUrl}/api/serviceaction/${this.data.id}/invoice.pdf`));
        break;
      case TRetailerAction.ProcessCashSettlement:
        this._processCashSettlement();
        break;
      case TRetailerAction.ProcessComplaint:
        this._processComplaint();
        break;
      case TRetailerAction.ProcessReplacement:
        this._processExchange();
        break;
      case TRetailerAction.ProcessTechWorkOrder:
        window.open(this._buildPdfLink(`${this.appConfig.apiBaseUrl}/api/serviceaction/${this.data.id}/techreport.pdf`));
        break;
      case TRetailerAction.ViewAuthorizationAndOrderParts:
        this._openAuthorizationAvailabilityOrderingDialog(TRetailerAction.ViewAuthorizationAndOrderParts);
        break;
      case TRetailerAction.ConfirmAvailability:
        this._openAuthorizationAvailabilityOrderingDialog(TRetailerAction.ConfirmAvailability);
        break;
      case TRetailerAction.ReplacementEvenExchange:
        this._openEvenExchangeDialog(TRetailerAction.ReplacementEvenExchange);
        break;
      case TRetailerAction.EZReplacementEvenExchange:
        this._openEZReplacementDialog(TRetailerAction.EZReplacementEvenExchange);
        break;
      case TRetailerAction.ReplacementReselection:
        this._openReselectionDialog(TRetailerAction.ReplacementReselection);
        break;
      case TRetailerAction.EZReplacementReselection:
        this._openEZReselectionDialog(TRetailerAction.EZReplacementReselection);
        break;
      case TRetailerAction.PartOrderEnterDeliveryAndPaymentRequest:
        this._openPartOrderDialog(TRetailerAction.PartOrderEnterDeliveryAndPaymentRequest);
        break;
    }
  }

  openInvoice(): void {
    window.open(this._buildPdfLink(`${this.appConfig.apiBaseUrl}/api/serviceaction/${this.data.id}/invoice.pdf`));
  }

  private _buildPdfLink(pdfLink: string): string {
    const pdfUrl = new URL(pdfLink);
    pdfUrl.searchParams.append('token', this.session.token);

    if (this.session.XTenantId) {
      pdfUrl.searchParams.append('X-TenantId', this.session.XTenantId.toString());
    }

    return pdfUrl.href;
  }

  private _processExchange(): void {
    this.modalDialogService.open({
      title: 'Close Exchange',
      data: this.data,
    }, CloseExchangeDialogComponent, {
      closeOnNavigation: true,
    });
  }

  private _processCashSettlement(): void {
    this.modalDialogService.open({
      title: 'Close Cash Settlement',
      data: this.data,
    }, CloseSettlementsDialogComponent, {
      closeOnNavigation: true,
    });
  }

  private _processComplaint(): void {
    this.modalDialogService.open({
      title: 'Close Complaint',
      data: this.data,
    }, CloseComplaintDialogComponent , {
      disableClose: true,
      closeOnNavigation: true,
    });
  }

  private _openAuthorizationAvailabilityOrderingDialog(retailerAction: TRetailerAction): void {
    this.modalDialogService.open({
      title: 'Authorization Availability Ordering',
      data: {
        serviceAction: this.data,
        retailerAction: retailerAction,
      },
    }, AuthorizationAvailabilityOrderingDialogComponent, DIALOG_CONFIG_SERVICE_ACTION);
  }

  private _openEvenExchangeDialog(retailerAction: TRetailerAction): void {
    this._openRetailerActionReplacementsDialog({
      dialogTitle: 'Even Exchange - Enter Delivery and Payment Request',
      retailerAction: retailerAction,
    }).afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
    });
  }

  private _openReselectionDialog(retailerAction: TRetailerAction): void {
    this._openRetailerActionReplacementsDialog({
      dialogTitle: 'Reselection - Enter Delivery and Payment Request',
      retailerAction: retailerAction,
    }).afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      if (result.submittedRetailerActionReplacementsDialog) {
      }
    });
  }

  private _openEZReplacementDialog(retailerAction: TRetailerAction): void {
    this._openRetailerActionReplacementsDialog({
      dialogTitle: 'Replacement',
      retailerAction: retailerAction,
    }).afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      if (result.submittedRetailerActionReplacementsDialog) {
      }
    });
  }

  private _openEZReselectionDialog(retailerAction: TRetailerAction): void {
    this._openRetailerActionReplacementsDialog({
      retailerAction: retailerAction,
      dialogTitle: 'Enter Delivery and Payment Request',
    }).afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      if (result.submittedRetailerActionReplacementsDialog) {
      }
    });
  }

  private _openPartOrderDialog(retailerAction: TRetailerAction): void {
    this._openRetailerActionReplacementsDialog({
      retailerAction: retailerAction,
      dialogTitle: 'Part Order - Enter Delivery and Payment Request',
    }).afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      if (result.submittedRetailerActionReplacementsDialog) {
      }
    });
  }

  private _openRetailerActionReplacementsDialog(
    { retailerAction, dialogTitle }: {retailerAction: TRetailerAction; dialogTitle: string},
  ): MatDialogRef<RetailerActionReplacementsDialogComponent> {
    return this.modalDialogService.open({
      title: dialogTitle,
      data: {
        dialogTitle: dialogTitle,
        retailerAction: retailerAction,
        serviceActionId: this.data.id,
        serviceActionType: this.data.serviceActionType,
      },
    }, RetailerActionReplacementsDialogComponent, DIALOG_CONFIG_SERVICE_ACTION);
  }
}
