import { Directive,
  EmbeddedViewRef,
  Input,
  ɵstringify as stringify,
  TemplateRef,
  ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appReadonlyControl]',
  standalone: true,
})
export class ReadonlyControlDirective<T = unknown> {
  private _context: ReadonlyControlContext<T> = new ReadonlyControlContext<T>();
  private _thenTemplateRef: TemplateRef<ReadonlyControlContext<T>>|null = null;
  private _elseTemplateRef: TemplateRef<ReadonlyControlContext<T>>|null = null;
  private _thenViewRef: EmbeddedViewRef<ReadonlyControlContext<T>>|null = null;
  private _elseViewRef: EmbeddedViewRef<ReadonlyControlContext<T>>|null = null;

  @Input()
  set appReadonlyControl(condition: T) {
    this._context.$implicit = this._context.appReadonlyControl = condition;
    this._updateView();
  }

  @Input()
  set appReadonlyControlThen(templateRef: TemplateRef<ReadonlyControlContext<T>>|null) {
    assertTemplate('appReadonlyControlThen', templateRef);
    this._thenTemplateRef = templateRef;
    this._thenViewRef = null;  // clear previous view if any.
    this._updateView();
  }

  @Input()
  set appReadonlyControlElse(templateRef: TemplateRef<ReadonlyControlContext<T>>|null) {
    assertTemplate('appReadonlyControlElse', templateRef);
    this._elseTemplateRef = templateRef;
    this._elseViewRef = null;  // clear previous view if any.
    this._updateView();
  }

  @Input() set appReadonlyControlLabel(label: string) {
    this._context.label = label;
  }

  @Input() set appReadonlyControlValue(value: string | number) {
    this._context.value = value;
  }

  constructor(
    private readonly viewContainer: ViewContainerRef,
    templateRef: TemplateRef<ReadonlyControlContext<T>>,
  ) {
    this._thenTemplateRef = templateRef;
  }

  private _updateView(): void {
    if (this._context.$implicit) {
      if (!this._thenViewRef) {
        this.viewContainer.clear();
        this._elseViewRef = null;
        if (this._thenTemplateRef) {
          this._thenViewRef =
            this.viewContainer.createEmbeddedView(this._thenTemplateRef, this._context);
        }
      }
    } else {
      if (!this._elseViewRef) {
        this.viewContainer.clear();
        this._thenViewRef = null;
        if (this._elseTemplateRef) {
          this._elseViewRef =
            this.viewContainer.createEmbeddedView(this._elseTemplateRef, this._context);
        }
      }
    }
  }
}

export class ReadonlyControlContext<T = unknown> {
  public $implicit: T = null;
  public label = '';
  public value: any = null;
  public appReadonlyControl: T = null;
}

function assertTemplate(property: string, templateRef: TemplateRef<any>|null): void {
  const isTemplateRefOrNull = !!(!templateRef || templateRef.createEmbeddedView);
  if (!isTemplateRefOrNull) {
    throw new Error(`${property} must be a TemplateRef, but received '${stringify(templateRef)}'.`);
  }
}
