<div class="dialog service-suspended">
  <div
    appCustomOverlayScrollbar
    class="dialog__content">
    <mat-icon
      class="service-suspended__icon"
      svgIcon="img-trafficlight"></mat-icon>
    <p class="service-suspended__header">Service Suspended</p>
    <div class="service-suspended__main-text">
      <p><b>Access to some areas of our website has been suspended because your account is PAST DUE.</b></p>
      <p>
        You are currently not authorized to sell or void any MFS furniture protection plans until your account
        is brought up to date and access to features has been restored.
      </p>
    </div>
    <div class="service-suspended__text">
      <p>
        Continuing to sell MFS furniture protection plans without authority or the ability to provide them to
        consumers is fraud and illegal. MFS has no liability to and will not service any plans sold
        fraudulently. Any customer that calls MFS for service will be referred back to your store if they are
        not registered in our system or cannot provide the necessary information to qualify for service.
      </p>
      <br />
      <p>
        Access to certain features of the Retailer Portal, such as eligible sales and claim information,
        training and other elements, will be maintained unless limited for other reasons.
        <b>
          Please contact our Accounting Department or Billing Department to discuss bringing your account
          current.
        </b>
      </p>
      <br />
      <p class="service-suspended__text-sign">MFS Accounting</p>
    </div>
    <div class="service-suspended__buttons">
      <a
        class="service-suspended__button"
        href="tel:616-226-1130"
        mat-flat-button
        color="primary">
        <mat-icon
          class="service-suspended__button-icon"
          svgIcon="ic-phone-white"></mat-icon>
        <span>616-226-1130</span>
      </a>
      <a
        class="service-suspended__button"
        href="mailto:billing@montagefs.com"
        mat-flat-button
        color="primary">
        <mat-icon
          class="service-suspended__button-icon"
          svgIcon="ic-mail-outline-white"></mat-icon>
        <span>billing@montagefs.com</span>
      </a>
    </div>
    <button
      (click)="close()"
      mat-flat-button
      color="primary">
      Close
    </button>
  </div>
</div>
