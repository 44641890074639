import { ProductIncidentStatus } from '@core/enums/claim/product-incident-status.enum';
import { ServiceStatus } from '@core/enums/claim/service-status.enum';
import { TServiceActionType } from '@core/enums/service-action/service-action-type.enum';

export const CLAIM_INCIDENT_STATUS_TO_TITLE = {
  [ProductIncidentStatus.CurrentlyInProgress]: 'In Progress',
  [ProductIncidentStatus.Closed]: 'Closed',
  [ProductIncidentStatus.Denied]: 'Denied',
  [ProductIncidentStatus.ClaimCancelled]: 'Cancelled',
};

export const SERVICE_ACTION_TYPE_TO_TITLE = {
  [TServiceActionType.PreQualification]: 'Pre Qualification',
  [TServiceActionType.Kit]: 'Kit',
  [TServiceActionType.CashSettlement]: 'Cash Settlement',
  [TServiceActionType.TechWorkOrder]: 'Tech Work Order',
  [TServiceActionType.Replacement]: 'Replacement',
  [TServiceActionType.PartOrder]: 'Part Order',
  [TServiceActionType.Refund]: 'Refund',
  [TServiceActionType.Complaint]: 'Complaint',
  [TServiceActionType.Other]: 'Other',
};

export const SERVICE_ACTION_STATUS_TO_TITLE: {[key in ServiceStatus]: string} = {
  [ServiceStatus.Open]: 'Open',
  [ServiceStatus.Cancelled]: 'Cancelled',
  [ServiceStatus.TechnicianAssigned]: 'Technician Assigned',
  [ServiceStatus.Pending]: 'Pending',
  [ServiceStatus.WaiverPending]: 'Waiver Pending',
  [ServiceStatus.TechnicianRejected]: 'Technician Rejected',
  [ServiceStatus.TechnicianScheduled]: 'Technician Scheduled',
  [ServiceStatus.TechnicianCompleted]: 'Technician Completed',
  [ServiceStatus.Waived]: 'Waived',
  [ServiceStatus.CancelledAfterAssignment]: 'Cancelled After Assignment',
  [ServiceStatus.ReAssigned]: 'Re-Assigned',
  [ServiceStatus.AuthorizedForService]: 'Authorized for Service',
  [ServiceStatus.Closed]: 'Closed',
  [ServiceStatus.Completed]: 'Completed',
};
