import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-collapsible-block',
  standalone: true,
  templateUrl: './collapsible-block.component.html',
  styleUrls: ['./collapsible-block.component.scss'],
  imports: [
    MatIconModule,
  ],
})
export class CollapsibleBlockComponent implements OnInit {
  @Input() collapsed = false;
  @Input() lastChild = false;
  @Input() sizeVertical: 'short' | 'default' = 'default';

  constructor() {}

  ngOnInit() {}

  toggleClass() {
    this.collapsed = !this.collapsed;
  }
}
