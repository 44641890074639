import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import {
  LegacyFloatLabelType as FloatLabelType,
} from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { APP_FORM_FIELD_PROVIDER } from '@core/providers/form-field.provider';
import { FormErrorComponent } from '@shared/components/form-error/form-error.component';
import { PasswordMatchesComponent } from '@shared/components/password-matches/password-matches.component';
import { CurrencyDirective } from '@shared/directives/currency/currency.directive';
import { OnlyNumberDirective } from '@shared/directives/only-number/only-number.directive';
import { ThBaseFormFieldControlElement } from '@shared/form/base-form-field-control-element.directive';
import { PipesModule } from '@shared/pipes/pipes.module';

type TReadOnlyType = 'input' | boolean;
type TInputType = 'password' | 'text' | 'tel' | 'email';
type TInputDataType = 'price' | 'only-number' | 'phone';

@Component({
  selector: 'app-form-input',
  standalone: true,
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    NgIf,
    PipesModule,
    MatIconModule,
    NgSwitch,
    CurrencyDirective,
    NgxTrimDirectiveModule,
    ReactiveFormsModule,
    NgSwitchCase,
    OnlyNumberDirective,
    FormErrorComponent,
    PasswordMatchesComponent,
    MatLegacyInputModule,
    NgSwitchDefault,
  ],
})
export class FormInputComponent<T = any> extends ThBaseFormFieldControlElement<T> {
  @Input() maxLength: number;
  @Input() minLength: number;
  @Input() readonly: TReadOnlyType = false;
  @Input() mask: Array<string | RegExp> | ((raw: string) => Array<string | RegExp>) | false = false;
  @Input() icon: string;
  @Input() type: TInputType = 'text';
  @Input() dataType: TInputDataType = null;
  @Input() autocomplete = null;
  @Input() big = false;
  @Input() currencyInput = false;
  @Input() numericInput = false;
  @Input() tooltipText = '';
  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() showPasswordCriteria = false;
  @Output() inputFocus = new EventEmitter<void>();
  @Output() inputBlur = new EventEmitter<void>();
  @Output() fieldInput = new EventEmitter<void>();

  tooltipAttentionText = '';

  constructor(
    @Inject(APP_FORM_FIELD_PROVIDER) public readonly formFieldProvider: {showErrors: boolean},
  ) {
    super();
  }

  enterClicked(): void {
    if (typeof this.formControl.value === 'string' && this.formControl.value) {
      this.formControl.patchValue(this.formControl.value.trim() as T);
    } else {
      this.formControl.patchValue(this.formControl.value as T);
    }
  }
}
