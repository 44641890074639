import { ChangeDetectionStrategy, Component, Input,OnInit } from '@angular/core';

import { TSmsHistoryStatus } from '@core/enums/sms-history-status.enum';
import { IServerSmsMessage } from '@core/interfaces/claims/claimDetails.interface';
import { PipesModule } from '@shared/pipes/pipes.module';

@Component({
  selector: 'app-sms-history-message',
  standalone: true,
  templateUrl: './sms-history-message.component.html',
  styleUrls: ['./sms-history-message.component.scss'],
  imports: [
    PipesModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmsHistoryMessageComponent implements OnInit {
  @Input() message: IServerSmsMessage;

  smsHistoryStatusToText = {
    [TSmsHistoryStatus.PendingSend]: 'Pending Send',
    [TSmsHistoryStatus.Sending]: 'Sending',
    [TSmsHistoryStatus.Sent]: 'Sent',
    [TSmsHistoryStatus.Failed]: 'Failed',
    [TSmsHistoryStatus.Delivered]: 'Delivered',
    [TSmsHistoryStatus.Undelivered]: 'Undelivered',
    [TSmsHistoryStatus.Unread]: 'Unread',
    [TSmsHistoryStatus.Received]: 'Received',
  };

  constructor() { }

  ngOnInit(): void {
  }

}
