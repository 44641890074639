import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appImageError]',
  standalone: true,
})
export class ImageErrorDirective {
  constructor(el: ElementRef) {
    el.nativeElement.onerror = event => {
      if (window.navigator.onLine) {
        event.target.src = 'assets/icons/icon-brokenimage.svg';
      }
    };
  }
}
