<ng-container>
  <div class="photo-preview-wrapper" [ngClass]="{disabled: (_disableControls$ | async)}"
       *ngIf="(filesList$ | async).length"
       #photoPreviewWrapper>
    <app-carousel [options]="splideOptions" [customOptions]="customCarouselOptions" (carouselCreated)="carouselCreated($event)">
      <ng-container *ngFor="let file of filesList$ | async | filterItems:'thumbnailUrl'; index as fileIndex; trackBy: trackByFn">
        <ng-container *ngIf="(!type || fileTypes[type].indexOf(file.mimeType) !== -1)">
          <ng-container *ngIf="!file.isReadOnly">
            <div class="splide__slide">
              <app-carousel-slide>
                <div class="photo-preview" (click)="onFileClick(file)"
                     [ngClass]="{disabled: (_disableControls$ | async)}" >
                  <div class="photo-preview__image" [attr.id]="'thumbnailImageBlock' + fileIndex" >
                    <div class="image-block" >
                      <img appImageError
                           [src]="file.thumbnailUrl"
                           (load)="fileLoaded($event, file)"
                           [ngClass]="{'hidden': !file.thumbnailLoaded}"
                      />
                      <span class="file-name"
                            *ngIf="showRemove
                           && (fileTypes.documents.indexOf(file.mimeType) !== -1
                           || file.mimeType.indexOf('image/tif') !== -1); else defaultDocumentNameView">
                      <span>{{file.fileName}}</span>
                    </span>
                      <ng-template #defaultDocumentNameView>
                      <span class="file-name" *ngIf="showDocumentName">
                        <span>{{file.id}}
                          <span *ngIf="(fileTypes.documents.indexOf(file.mimeType) !== -1
                           || file.mimeType.indexOf('image/tif') !== -1)">{{file | fileExtension }}</span>
                        </span>
                      </span>
                      </ng-template>
                      <mat-spinner color="primary" [diameter]="50" *ngIf="!file.thumbnailLoaded"></mat-spinner>
                    </div>
                  </div>
                  <button *ngIf="showRemove"
                          (click)="removeFile($event, fileIndex)"
                          mat-icon-button
                          class="photo-preview__hover-wrapper"
                          type="button">
                    <mat-icon svgIcon="delete-button" class="photo-preview__remove-image"
                              [ngClass]="{'disabled': (_disableControls$ | async)}"></mat-icon>
                  </button>
                </div>
              </app-carousel-slide>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="file.isReadOnly">
          <div class="splide__slide">
            <app-carousel-slide>
              <div class="photo-preview readonly-image" (click)="onFileClick(file)">
                <div class="photo-preview__image" [attr.id]="'thumbnailImageBlock' + fileIndex + 'r'" >
                  <div class="image-block" >
                    <img appImageError [src]="file.thumbnailUrl"/>
                    <span class="file-name">Uploaded receipt.</span>
                  </div>
                </div>
              </div>
            </app-carousel-slide>
          </div>
        </ng-container>
      </ng-container>
    </app-carousel>
  </div>
</ng-container>
