import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PIPES } from '@shared/pipes/index';
import { UserTimezonePipe } from '@shared/pipes/user-timezone.pipe';

import { ArgbToHexPipe } from './argb-to-hex.pipe';

@NgModule({
  declarations: [...PIPES],
  providers: [ArgbToHexPipe, UserTimezonePipe],
  imports: [CommonModule],
  exports: [...PIPES],
})
export class PipesModule {}
