import { Pipe, PipeTransform } from '@angular/core';

import { Files } from '@core/interfaces/claims/files.interface';

@Pipe({
  name: 'fileName',
})
export class FileNamePipe implements PipeTransform {
  transform(file: Files): string {
    const fileName = file.originalFileName || file.fileName || '';
    const fileNameArray = fileName.split('.');
    return fileNameArray[0] || '';
  }
}
