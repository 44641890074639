<div class="product-incident-cell" [matTooltip]="tooltipText">
  <ng-container *ngFor="let field of fieldName; let i = index">
    <ng-container *ngIf="i !== 2">
      <p>{{this.labels[i]}}
        <span class="general-text">{{data[field] ? data[field].substring(0, 26) + (data[field].length > 26 ? '...' : '') : '-'}}</span>
        <span class="print-text">{{data[field] ? data[field] : '-'}}</span>
      </p>
    </ng-container>
    <ng-container *ngIf="i === 2 && session.showRetailerClassification">
      <p>{{this.labels[i]}}
        <span class="general-text">{{data[field] ? data[field].substring(0, 26) + (data[field].length > 26 ? '...' : '') : '-'}}</span>
        <span class="print-text">{{data[field] ? data[field] : '-'}}</span>
      </p>
    </ng-container>
  </ng-container>
</div>
