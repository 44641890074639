<div *ngIf="readonly === true; else regularInput">
  <div class="field__label">{{ label }}</div>
  <div class="field__text">{{ ngControl.value | appEmptyValue }}</div>
</div>
<ng-template #regularInput>
  <mat-form-field
    class="form-input _common-mat-form-field"
    [class.form-input--disabled]="readonly === 'input'"
    [class._common-mat-form-field_hide-errors]="!formFieldProvider.showErrors"
    [class._common-theme-field]="!!ngControl.value"
    [floatLabel]="floatLabel"
    [color]="'primary'"
    [class.form-input_big]="big">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <ng-container matPrefix>
      <ng-content select="[prefix]"></ng-content>
      <mat-icon *ngIf="dataType === 'phone'">call</mat-icon>
      <mat-icon *ngIf="dataType === 'price'">attach_money</mat-icon>
    </ng-container>
    <div
      matSuffix
      class="form-input__suffix">
      <mat-icon
        class="form-input__error-icon"
        *ngIf="ngControl.status === 'INVALID' && ngControl.touched">
        error
      </mat-icon>
      <ng-content select="[suffix]"></ng-content>
    </div>

    <div
      [class.tooltip__input-block]="tooltipText || tooltipAttentionText"
      class="form-input__wrapper-data-type">
      <ng-container [ngSwitch]="dataType">
        <input
          *ngSwitchCase="'price'"
          matInput
          trim="blur"
          appCurrency
          [autocomplete]="autocomplete"
          [type]="type"
          (keydown.enter)="enterClicked()"
          [minlength]="minLength"
          [maxlength]="maxLength"
          [placeholder]="placeholder"
          [formControl]="formControl"
          [readonly]="readonly"
          (focus)="inputFocus.emit()"
          (blur)="inputBlur.emit()"
          (input)="fieldInput.emit()" />
        <input
          *ngSwitchCase="'only-number'"
          matInput
          trim="blur"
          [appOnlyNumber]="true"
          [autocomplete]="autocomplete"
          [type]="type"
          (keydown.enter)="enterClicked()"
          [minlength]="minLength"
          [maxlength]="maxLength"
          [placeholder]="placeholder"
          [formControl]="formControl"
          [readonly]="readonly"
          (focus)="inputFocus.emit()"
          (blur)="inputBlur.emit()"
          (input)="fieldInput.emit()" />
        <input
          *ngSwitchDefault
          matInput
          trim="blur"
          [autocomplete]="autocomplete"
          [type]="type"
          (keydown.enter)="enterClicked()"
          [minlength]="minLength"
          [maxlength]="maxLength"
          [placeholder]="placeholder"
          [formControl]="formControl"
          [readonly]="readonly"
          (focus)="inputFocus.emit()"
          (blur)="inputBlur.emit()"
          (input)="fieldInput.emit()" />
      </ng-container>

      <div
        *ngIf="tooltipText"
        class="tooltip tooltip__input tooltip__input_app-form-input">
        <mat-icon
          class="tooltip__icon _common-icon--theme"
          svgIcon="help_outline"></mat-icon>
        <span class="tooltip__text">{{ tooltipText }}</span>
      </div>
      <div
        *ngIf="tooltipAttentionText"
        class="tooltip tooltip__input tooltip__input_app-form-input">
        <mat-icon
          class="tooltip__icon _common-icon--theme"
          svgIcon="attention"></mat-icon>
        <span class="tooltip__text">{{ tooltipAttentionText }}</span>
      </div>
    </div>

    <mat-hint>
      <strong>{{ hint }}</strong>
    </mat-hint>
    <mat-error>
      <app-form-error
        [label]="label"
        [formControlRef]="ngControl.control"
        [dataType]="dataType"></app-form-error>
    </mat-error>
    <mat-error *ngIf="formControl.hasError('pattern')">{{ label }} format is invalid.</mat-error>
    <mat-error *ngIf="formControl.hasError('firstLastNameInvalid')">
      {{ label }} must start with a letter and contain at least
      {{ formControl.errors.firstLastNameInvalid.minNameLength }}
      {{ formControl.errors.firstLastNameInvalid.minNameLength === 1 ? "letter" : "letters" }}.
    </mat-error>
    <mat-error *ngIf="formControl.hasError('matchNewPassword')">
      New password and confirmation password do not match.
    </mat-error>
    <mat-error *ngIf="formControl.hasError('min')">
      Minimum value is {{ formControl.errors.min.min }}
    </mat-error>
    <mat-error *ngIf="formControl.hasError('phoneNumberInvalid')">
      Phone numbers must include at least ten digits, and they may not all be the same number.
    </mat-error>
    <app-password-matches *ngIf="showPasswordCriteria && type === 'password'"></app-password-matches>
  </mat-form-field>
</ng-template>
