import { Component, OnInit } from '@angular/core';
import moment from 'moment-timezone';

@Component({
  selector: 'app-grid-date-time-cell',
  templateUrl: './grid-date-time-cell.component.html',
  styleUrls: ['./grid-date-time-cell.component.scss'],
})
export class GridDateTimeCellComponent implements OnInit {
  data: any;
  fieldName: string;
  moment = moment;

  constructor() {}

  ngOnInit() {}
}
