import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'momentDate',
})
export class MomentDate implements PipeTransform {
  constructor() {}

  public transform(value: string, format: string = ''): string {
    return moment(value).format(format);
  }
}
