<ng-container>
  <div class="files-preview-new__wrapper" [ngClass]="{disabled: (_disableControls$ | async)}"
       *ngIf="(totalFilesLength$ | async) > 0; else noFilesToPreview"
       #photoPreviewWrapper>
    <div *ngIf="sectionTitle" class="files-preview-new__caption-section">
      <div class="files-preview-new__caption-main-text">{{sectionTitle}}</div>
      <div *ngIf="showCaptions" class="files-preview-new__caption-additional-text">
        <mat-icon class="files-preview-new__icon-doublecheck" svgIcon="img-doublecheck"></mat-icon>
        Please remember to click the “{{submitButtonCaption}}” button when done. To delete a file, click it’s trash can icon.
      </div>
    </div>
    <app-carousel-new [useNewDesign]="true" [options]="splideOptions" [customOptions]="customCarouselOptions"
                      (carouselCreated)="carouselCreated($event)">
      <ng-container *ngFor="let file of filesList$ | async; trackBy: trackByFn">
        <ng-container *ngIf="(!type || fileTypes[type].indexOf(file.mimeType) !== -1) && !file.isReadOnly">
          <div class="splide__slide files-preview-new__splide-slide">
            <app-carousel-slide>
              <div class="files-preview-new" (click)="onFileClick(file)"
                   [ngClass]="{disabled: (_disableControls$ | async)}" >
                <div class="files-preview-new__image" [attr.id]="'thumbnailImageBlock' + file.index" >
                  <div class="image-block">
                    <div class="files-preview-new__image-wrapper">
                      <div>
                        <img appImageError
                             [src]="file.thumbnailUrl"
                             (load)="fileLoaded(file)"
                             [ngClass]="{'hidden': !file.thumbnailLoaded}"
                        />
                      </div>
                    </div>
                    <div class="files-preview-new__label-common files-preview-new__label-file" *ngIf="showDocumentName">
                      <span class="files-preview-new__label-file--doc-type">{{file.docType | fileDoctype}}</span>
                    </div>
                    <div class="files-preview-new__label-common files-preview-new__label-file" *ngIf="showDocumentName">
                      <span class="files-preview-new__label-file--name">{{file | fileName}}</span>
                      <span class="files-preview-new__label-file--extension">{{file | fileExtension}}</span>
                    </div>
                    <mat-spinner class="files-preview-new__uploading-spinner" color="primary" [diameter]="50" *ngIf="!file.thumbnailLoaded"></mat-spinner>
                  </div>
                </div>
                <button *ngIf="showRemove"
                        (click)="removeFile($event, file.index)"
                        mat-icon-button
                        class="files-preview-new__remove-wrapper"
                        type="button">
                  <mat-icon svgIcon="delete-button" class="files-preview-new__remove-image"
                            [ngClass]="{'disabled': (_disableControls$ | async)}"></mat-icon>
                </button>
              </div>
            </app-carousel-slide>
          </div>
        </ng-container>
      </ng-container>
    </app-carousel-new>
    <div *ngIf="showCaptions" class="files-preview-new__caption-additional-text">
      <mat-icon class="files-preview-new__icon-doublecheck" svgIcon="img-doublecheck"></mat-icon>
      Ensure to double-check your files uploaded before clicking the ”{{submitButtonCaption}}” button below.
    </div>
  </div>

  <ng-template #noFilesToPreview>
    <div class="files-preview-new__caption-section">
      <div class="files-preview-new__caption-main-text files-preview-new__caption-main-text--no-file">File Upload Preview</div>
    </div>
    <div class="files-preview-new__no-files-wrapper">
      <div class="files-preview-new__no-files-header">No Files to Preview Yet</div>
      <div class="files-preview-new__no-files-image-section">
        <mat-icon class="files-preview-new__no-files-img-emptybox _common-icon--theme" svgIcon="img-emptybox"></mat-icon>
      </div>
      <div class="files-preview-new__no-files-main-text">
        <p>Your files will appear here when you drag them to their drop sections above.</p>
      </div>
    </div>
  </ng-template>
</ng-container>
