import { AsyncPipe, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ITableAction } from '@shared/modules/table/interfaces/table-action.interface';
import { TTableOptionAction } from '@shared/modules/table/types/table-option-action.enum';

import { IAppTableCol } from '../../interfaces/table-col.interface';
import { TAppTableCol } from '../../types/table-col.enum';

@Component({
  selector: 'app-table-col, th-table-col',
  standalone: true,
  templateUrl: './table-col.component.html',
  styleUrls: ['./table-col.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgSwitch,
    NgSwitchCase,
    NgIf,
    NgSwitchDefault,
    AsyncPipe,
  ],
})

/**
 * A table col component
 * */
export class ThTableColComponent {

  TAppTableCol = TAppTableCol;

  @Input() item: IAppTableCol;

  @Input() isMobile: boolean;

  @Output() action = new EventEmitter<ITableAction>();

  headerColClick($event: MouseEvent): void {
    $event.stopPropagation();
    this.action.emit({
      type: TTableOptionAction.HeaderClick,
      data: this.item.data,
    });
  }
}
