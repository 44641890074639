import { MatDialogConfig } from '@angular/material/dialog';

export const DIALOG_CONFIG_CUSTOM_ANIMATION: MatDialogConfig = {
  enterAnimationDuration: 0,
  exitAnimationDuration: 0,
  hasBackdrop: false,
};

export const DIALOG_CONFIG_SHORT: MatDialogConfig = {
  disableClose: true,
  width: '520px',
  autoFocus: false,
};

export const DIALOG_CONFIG_SERVICE_ACTION: MatDialogConfig = {
  disableClose: true,
  width: '800px',
  autoFocus: false,
};

export const DIALOG_CONFIG_RETAILER_SALE: MatDialogConfig = {
  disableClose: true,
  autoFocus: false,
  width: 'auto',
  maxWidth: '1028px',
  panelClass: '_common-dialog',
};
