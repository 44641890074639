<div class="user__main-info clearfix">
  <div class="user__info-block">
      <h2 class="section__title">Consumer</h2>
      <ul class="user__customer">
        <li class="user__customer-name">
          <span>{{plan.consumer.firstName}} {{plan.consumer.lastName}}</span>
        </li>
        <li>Contact Id: {{plan.consumer.contactId}}</li>
        <li><br></li>
        <li *ngIf="plan.consumer.homePhone">Home phone: {{plan.consumer.homePhone | usaPhone}}</li>
        <li *ngIf="plan.consumer.mobilePhone">Mobile phone: {{plan.consumer.mobilePhone | usaPhone}}</li>
        <li *ngIf="plan.consumer.workPhone">Business phone: {{plan.consumer.workPhone | usaPhone}}</li>
        <li>Email: <app-email-link *ngIf="plan.consumer.emailAddress" [email]="plan.consumer.emailAddress"></app-email-link>
          <span *ngIf="!plan.consumer.emailAddress">-</span>
        </li>
      </ul>
  </div>
  <div class="user__info-block">
    <h2 class="section__title">Store</h2>
    <app-retailer-information *ngIf="plan.retailer" [retailer]="plan.retailer"></app-retailer-information>
    <span *ngIf="!plan.retailer">No store available</span>
  </div>
  <div class="user__info-block">
    <h2 class="section__title">Consumer Address</h2>
    <ul *ngIf="plan.consumer.streetAddress1" class="user__customer">
      <li class="user__address" *ngIf="plan.consumer.streetAddress1">{{plan.consumer.streetAddress1}}</li>
      <li class="user__address" *ngIf="plan.consumer.streetAddress2">{{plan.consumer.streetAddress2}}</li>
      <li class="user__address" *ngIf="plan.consumer.city || plan.consumer.stateProvince || plan.consumer.postalCode">
        {{plan.consumer.city}}, {{plan.consumer.stateProvince}} {{plan.consumer.postalCode}}
      </li>
    </ul>
    <span *ngIf="!plan.consumer.streetAddress1">No consumer address available</span>
  </div>
</div>
