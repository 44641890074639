import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';

import { DialogCoreModule } from '@shared/modules/dialog-core/dialog-core.module';

@Component({
  selector: 'app-claim-success',
  standalone: true,
  templateUrl: './claim-success.component.html',
  styleUrls: ['./claim-success.component.scss'],
  imports: [
    DialogCoreModule,
  ],
})
export class ClaimSuccessComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {data: {claim: {claimNumber: string}}},
    public dialogRef: MatDialogRef<any>,
  ) {}

  close() {
    this.dialogRef.close();
  }
}
