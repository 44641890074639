import { NgIf } from '@angular/common';
import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  ShippingLabelActionCellComponent,
} from '@th-common-retailer/dialogs/claim-details/components/shipping-label-action-cell/shipping-label-action-cell.component';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { TPortalType } from '@core/enums/portal-type.enum';
import { ClaimDetails, ClaimServiceAction } from '@core/interfaces/claims/claimDetails.interface';
import { ServiceActionService } from '@core/services/service-action.service';
import { DealerClaimDetailsTablesConfig } from '@shared/modules/claim-details/classes/dealer-claim-details-tables-config';
import { GridModule } from '@shared/modules/grid/grid.module';
import { GridColumnModel } from '@shared/modules/grid/models/grid/grid-column.model';

@Component({
  selector: 'app-service-action-grid',
  standalone: true,
  templateUrl: './service-action-grid.component.html',
  styleUrls: ['./service-action-grid.component.scss'],
  imports: [
    GridModule,
    NgIf,
  ],
})
export class ServiceActionGridComponent implements OnInit, OnChanges {
  @Input() headingTitle: string;
  @Input() source: ClaimServiceAction[] = [];
  @Input() claim: ClaimDetails;

  appConfig = inject(APP_CONFIG);

  columns: GridColumnModel[] = [];

  ngOnInit(): void {
    let isInvoice = false;
    this.source.forEach(action => {
      if (ServiceActionService.isInvoice(action)) {
        isInvoice = true;
      }
    });

    const mjcColumnsExclude = ['Retailer Action', 'Details'];

    if (isInvoice) {
      this.columns = DealerClaimDetailsTablesConfig.serviceActionInvoiceColumnsWithActions.filter(
        column => this.appConfig.portalType === TPortalType.MJC ? !mjcColumnsExclude.includes(column.title) : true,
      );
    } else {
      this.columns = DealerClaimDetailsTablesConfig.serviceActionColumnsWithActions.filter(
        column => this.appConfig.portalType === TPortalType.MJC ? !mjcColumnsExclude.includes(column.title) : true,
      );
    }

    if (this.appConfig.portalType === TPortalType.MJC) {
      const hasServiceActionWithShippingLabel = this.claim.serviceActions.find(
        serviceAction => serviceAction.showShippingLabel,
      );

      if (hasServiceActionWithShippingLabel) {
        this.columns = [
          ...this.columns,
          new GridColumnModel('shippingLabel', '', ShippingLabelActionCellComponent, false, false, true, true),
        ];
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.source) {
      this.ngOnInit();
    }
  }
}
