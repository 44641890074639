import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import {
  ClaimsPageService,
  IServiceActionFilterCheckbox,
} from '@th-common-retailer/shared/pages/claims/claims-page.service';
import moment from 'moment';
import { distinctUntilChanged, map, skip, takeUntil } from 'rxjs/operators';

import { DestroyableComponent } from '@core/classes/destroyable-component';
import { AppConstants } from '@core/constants/app.constants';
import { APP_CONFIG } from '@core/constants/app-config.constants';
import { ClaimStages } from '@core/enums/claim/claim-stages.enum';
import { DateKind } from '@core/enums/claim/date-kind.enum';
import { TTileId, TTileType } from '@core/enums/claim/tile-types.enum';
import { TConsumerPlanType } from '@core/enums/consumer-plan-type.enum';
import { TDateRangeType } from '@core/enums/date-range-type.enum';
import { Feature } from '@core/enums/feature.enum';
import { TPortalType } from '@core/enums/portal-type.enum';
import { TServiceActionTileStage } from '@core/enums/service-action/service-action-tile-stage.enum';
import { TServiceActionType } from '@core/enums/service-action/service-action-type.enum';
import { IServiceActionFilter } from '@core/interfaces/service-action-filter.interface';
import { AdvancedSearchService } from '@core/services/advanced-search.service';
import { FeatureService } from '@core/services/feature.service';
import { ClaimsStore } from '@core/store/claims/claims.store';
import { RetailersListStore } from '@core/store/retailersList/retailersList.store';
import { UserStore } from '@core/store/user/user.store';
import { DateValidation } from '@core/utils/form.util';
import { DateRangeSearchComponent } from '@shared/components/date-range-search/date-range-search.component';
import { FormSelectComponent } from '@shared/components/form-select/form-select.component';
import { RegionsSelectComponent } from '@shared/components/regions-select/regions-select.component';
import { AccessControlModule } from '@shared/modules/access-control/access-control.module';
import {
  CollapsibleBlockComponent,
} from '@shared/modules/collapsible/components/collapsible-block/collapsible-block.component';
import {
  RetailerSelectComponent,
} from '@shared/modules/store-controls/components/retailer-select/retailer-select.component';

import { ProcessingStageSelectComponent } from '../../components/processing-stage-select/processing-stage-select.component';

interface IClaimsAdvancedFilterState {
  serviceActionFilters: IServiceActionFilterCheckbox[];
  showProcessStages: boolean;
}

@Component({
  selector: 'app-claims-advanced-filter',
  standalone: true,
  templateUrl: './claims-advanced-filter.component.html',
  styleUrls: ['./claims-advanced-filter.component.scss'],
  providers: [RetailersListStore, RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CollapsibleBlockComponent,
    RegionsSelectComponent,
    RetailerSelectComponent,
    DateRangeSearchComponent,
    FormSelectComponent,
    NgForOf,
    AccessControlModule,
    NgIf,
    MatButtonModule,
    MatCheckboxModule,
    AsyncPipe,
    ReactiveFormsModule,
    FormsModule,
    ProcessingStageSelectComponent,
    LetDirective,
  ],
})
export class ClaimsAdvancedFilterComponent extends DestroyableComponent implements OnInit {
  vm$ = this.state.select();
  appConfig = inject(APP_CONFIG);
  defaultClaimType$ = this.userStore.select$('session', 'defaultClaimType');
  form = this.fb.group({
    retailerId: [null],
    regionId: [null],
    fromDate: ['', DateValidation],
    toDate: ['', DateValidation],
    dateRangeType: [null],
    dateKind: [null],
    claimStatus: [null],
    claimStage: [null],
    isFlaggedForClientReview: [false],
  });
  processStages = this.claimsPageService.processStages.filter(stage => this.featureService.allowedMany(stage.features));
  serviceActionTileStage = '';
  claimStatuses = Object.keys(AppConstants.claimStatuses).map(key => ({
    id: +key,
    name: AppConstants.claimStatuses[+key],
  }));
  claimStages = Object.keys(AppConstants.claimStages).map(key => ({
    id: +key,
    name: AppConstants.claimStages[+key],
  })).filter(stage => {
    if (this.appConfig.portalType === TPortalType.MJC) {
      return ![
        ClaimStages.Pending,
        ClaimStages.DocumentationPending,
        ClaimStages.Validation,
      ].includes(stage.id);
    } else {
      return stage.id !== ClaimStages.AgreementValidation;
    }
  });
  feature = Feature;
  dateKinds = [
    { id: DateKind.ClaimOpened, name: 'Claim Opened' },
    { id: DateKind.ClaimClosed, name: 'Claim Closed' },
    { id: DateKind.PurchaseDate, name: 'Purchase Date' },
    { id: DateKind.ServiceDate, name: 'Service Date' },
    { id: DateKind.TechCompletionDate, name: 'Tech Completion Date' },
    { id: DateKind.RetailerExpirationDate, name: 'Retailer PO Expiration Date' },
  ].filter(item => {
    if (this.appConfig.portalType === TPortalType.MJC) {
      return ![
        DateKind.ServiceDate,
        DateKind.TechCompletionDate,
        DateKind.RetailerExpirationDate,
      ].includes(item.id);
    } else {
      return item;
    }
  });

  protected readonly TPortalType = TPortalType;

  constructor(
    private readonly fb: FormBuilder,
    private readonly claimsStore: ClaimsStore,
    private readonly featureService: FeatureService,
    private readonly userStore: UserStore,
    private readonly retailersListStore: RetailersListStore,
    private readonly advancedSearchService: AdvancedSearchService,
    private readonly state: RxState<IClaimsAdvancedFilterState>,
    private readonly claimsPageService: ClaimsPageService,
  ) {
    super();
    this.state.set({
      showProcessStages: false,
    });
    this.retailersListStore.loadData();

    this.state.connect('serviceActionFilters', this.claimsPageService.getAdvancedSearchActionFilters());
    this.state.connect('showProcessStages', this.userStore.select$('session', 'defaultClaimType').pipe(
      distinctUntilChanged(),
      map(defaultClaimType => {
        if (this.appConfig.portalType === TPortalType.MJC) {
          return true;
        }

        return defaultClaimType === TConsumerPlanType.ProtectionPlan;
      }),
    ));
  }

  ngOnInit(): void {
    this.defaultClaimType$.pipe(skip(1)).subscribe(() => {
      this.clear();
    });

    if (this.appConfig.portalType !== TPortalType.MJC) {
      this.form
        .get('regionId')
        .valueChanges.pipe(takeUntil(this.componentDestroyed$), distinctUntilChanged())
        .subscribe(regionId => {
          this.form.patchValue({
            retailerId: null,
          });
          this.retailersListStore.updateSearchRequest({
            regionId,
            page: 1,
          });
          this.retailersListStore.loadData();
        });
    }

    this._parseInitialSearchRequest();
  }

  search(): void {
    const serviceActionFilters = this._parseServiceActionFilters();
    this.claimsStore.filterAdvancedSearch({
      ...this.form.value,
      serviceActionFilters,
    });
  }

  clear(): void {
    this.form.reset({
      isFlaggedForClientReview: false,
    });
    const serviceActionFilters = this.state.get('serviceActionFilters');
    this.state.set({
      serviceActionFilters: serviceActionFilters.map(claimServiceFilter => ({
        ...claimServiceFilter,
        checked: false,
      })),
    });
    this.serviceActionTileStage = null;
  }

  dateRangeTypeChanged(dateRangeType: TDateRangeType): void {
    this.form.patchValue({
      dateRangeType,
    });
  }

  updateFilters(): void {
    const selectedFilters = this.state.get('serviceActionFilters').filter(claimFilter => claimFilter.checked);
    const searchRequest = selectedFilters.reduce((previousValue, currentValue) => ({
      ...previousValue,
      ...currentValue.searchRequest,
    }), {});

    this.form.patchValue(searchRequest);
  }

  filterChanged(filter: IServiceActionFilterCheckbox): void {
    if (filter.searchRequest.hasOwnProperty('isFlaggedForClientReview')) {
      this.form.patchValue({
        isFlaggedForClientReview: filter.checked,
      });
    } else if (filter.checked) {
      this.form.patchValue(filter.searchRequest);
      if (!!filter.searchRequest.fromDate || !!filter.searchRequest.toDate) {
        const searchRequestDaysDiff = moment(filter.searchRequest.toDate).diff(
          moment(filter.searchRequest.fromDate),
          'days',
        );

        if (searchRequestDaysDiff === 7) {
          this.form.patchValue({
            dateRangeType: TDateRangeType.LastSevenDays,
          });
        }
      }
    }
  }

  private _parseServiceActionFilters(): Partial<IServiceActionFilter>[] {
    const serviceActionFilters = this.state
      .get('serviceActionFilters')
      .filter(
        claimFilter =>
          claimFilter.checked
          && claimFilter.searchRequest.serviceActionFilters
          && claimFilter.searchRequest.serviceActionFilters[0],
      )
      .map(claimFilter => claimFilter.searchRequest.serviceActionFilters[0]);

    const serviceActionTileStageFilters =
      this.claimsPageService.getTileSearchRequest(TTileType.Composite, this.serviceActionTileStage)?.serviceActionFilters || [];

    return [...serviceActionFilters, ...serviceActionTileStageFilters];
  }

  private _parseInitialSearchRequest(): void {
    const tileSearch = this.advancedSearchService.tileSearch;
    const searchRequest = {
      ...this.claimsStore.get('searchRequest'),
    };

    this.form.patchValue({
      ...searchRequest,
    });

    if (tileSearch.tileType === TTileType.Stat && tileSearch.tileId === TTileId.RecentTechReports) {
      this.form.patchValue({
        dateRangeType: TDateRangeType.LastSevenDays,
      });
    }

    if (tileSearch.tileType === TTileType.Composite) {
      if (
        this.claimsPageService.tileFilter.getActionTypeTileStageKey(
          TServiceActionType.PartOrder,
          TServiceActionTileStage.ExpiringSoon) === tileSearch.tileId
      ) {
        this.serviceActionTileStage = `${TServiceActionType.PartOrder}`;
        this.form.patchValue({
          dateRangeType: TDateRangeType.NextThirtyDays,
        });
      } else if (
        this.claimsPageService.tileFilter.getActionTypeTileStageKey(
          TServiceActionType.Replacement,
          TServiceActionTileStage.ExpiringSoon) === tileSearch.tileId
      ) {
        this.serviceActionTileStage = `${TServiceActionType.Replacement}`;
        this.form.patchValue({
          dateRangeType: TDateRangeType.NextThirtyDays,
        });
      } else {
        this.serviceActionTileStage = `${tileSearch.tileId}`;
      }
    }

    this.advancedSearchService.resetTileSearch();
  }
}
