<div class="new-file-drop-area">
  <input class="file-input" #filesInputReceipt type="file"
         [multiple]="multiple"
         [accept]="accept"
         (change)="handleInputFiles($event, docType)"
         [disabled]="(filesSizeError$ | async) || disabled">
  <div class="new-file-drop-area__header new-file-drop-area__single-drop-area" *ngIf="headerTitle">{{headerTitle}}</div>
  <div appFileDragAndDrop
       [docType]="docType"
       [accept]="accept"
       (filesDropped)="handleDrop($event)"
       class="new-file-drop-area__area"
       [class.new-file-drop-area__area_small]="smallDropArea"
       [class.error]="(filesSizeError$ | async) || hasErrorState"
       [class.disabled]="(filesSizeError$ | async) || disabled">
    <mat-icon *ngIf="pictureIcon" class="new-file-drop-area__picture-icon _common-icon--theme" svgIcon="{{pictureIcon}}"></mat-icon>
    <mat-icon *ngIf="matIcon" class="new-file-drop-area__picture-icon new-file-drop-area__mat-icon _common-icon--theme material-icons-outlined">{{matIcon}}</mat-icon>
    <div class="new-file-drop-area__button-wrapper">
      <div class="new-file-drop-area__label-description">
        <span [class.new-file-drop-area__label-description_desktop]="labelDescriptionForMobile">{{labelDescription}}</span>
        <span *ngIf="labelDescriptionForMobile"
              class="new-file-drop-area__label-description_mobile">{{labelDescriptionForMobile}}</span>
      </div>
      <div class="new-file-drop-area__error-section">
        <mat-error *ngIf="filesSizeError$ | async as filesSizeError">
          {{filesSizeError}}
        </mat-error>
      </div>
      <div class="inline-buttons inline-buttons--center">
        <button [disabled]="!!(filesSizeError$ | async) || disabled"
                (click)="filesInputReceipt.click()"
                type="button"
                class="inline-buttons__button new-file-drop-area__button-browse"
                mat-stroked-button color="primary">BROWSE
        </button>
      </div>
    </div>
  </div>
</div>
