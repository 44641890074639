import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PipesModule } from '@shared/pipes/pipes.module';

@Component({
  selector: 'app-label-value-item',
  standalone: true,
  imports: [PipesModule],
  templateUrl: './label-value-item.component.html',
  styleUrls: ['./label-value-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelValueItemComponent {
  @Input() label: string;
  @Input() value: string | number;
}
