import { Injectable } from '@angular/core';
import { PlansPageService } from '@th-common-retailer/shared/pages/plans/plans-page.service';

import { DIALOG_CONFIG_CUSTOM_ANIMATION } from '@core/constants/dialog.constants';
import { Plan } from '@core/interfaces/plan/plan.interface';
import { PlanUtil } from '@core/utils/plan.util';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import { IAppTableCol } from '@shared/modules/table/interfaces/table-col.interface';
import { IAppTableRow } from '@shared/modules/table/interfaces/table-row.interface';
import { ThTableService } from '@shared/modules/table/table.service';

import { DealerPlanDialogsService } from '../../core/services/dealer-plan-dialogs.service';
import { RegisterRetailerPlanComponent } from './register-retailer-plan/register-retailer-plan.component';

@Injectable({
  providedIn: 'root',
})
export class RetailerPlansPageService extends PlansPageService {
  constructor(
    private readonly modalDialogService: ModalDialogService,
    private readonly planDialogsService: DealerPlanDialogsService,
    private readonly planUtil: PlanUtil,
  ) {
    super();
  }

  openRegisterPlan(): void {
    this.modalDialogService.open({
      title: 'Register Plan',
      data: {},
    }, RegisterRetailerPlanComponent, DIALOG_CONFIG_CUSTOM_ANIMATION);
  }

  openPlanDetailsDialog(planId: number, singleView: boolean = false): void {
    this.planDialogsService.openPlanDetailsDialog(planId, singleView);
  }

  getDefaultColumns(): IAppTableCol[] {
    return [
      ThTableService.buildCol('name', 'Plan Number', {
        notHideable: true,
      }),
      ThTableService.buildCol('consumer', 'Consumer', {
        maxWidth: '200px',
      }),
      ThTableService.buildCol('parentPlanName', 'Plan Description', {
        minWidth: '120px',
        maxWidth: '200px',
      }),
      ThTableService.buildCol('purchaseRetailerName', 'Retailer Name', {
        maxWidth: '200px',
      }),
      ThTableService.buildCol('registrationDate', 'Registration', {
        minWidth: '120px',
      }),
      ThTableService.buildCol('purchaseDate', 'Purchase', {
        minWidth: '120px',
      }),
      ThTableService.buildCol('deliveryDate', 'Delivery', {
        minWidth: '120px',
      }),
      ThTableService.buildCol('receiptNumber', 'Receipt Number'),
      ThTableService.buildCol('protectionPlanStatus', 'Plan Status'),
      ThTableService.buildCol('actions', 'Actions', {
        notHideable: true,
        disableSorting: true,
      }),
    ];
  }

  buildPlanRows(plans: Plan[]): IAppTableRow[] {
    return plans.map(item => ({
      id: ThTableService.buildCellInline(item.id.toString()),
      name: ThTableService.buildCellCustom({
        type: 'planName',
        plan: item,
      }),
      consumer: ThTableService.buildCellInline(item.consumer),
      parentPlanName: ThTableService.buildCellInline(item.parentPlanName),
      purchaseRetailerName: ThTableService.buildCellInline(item.purchaseRetailerName),
      registrationDate: ThTableService.buildCellDate(item.registrationDate),
      purchaseDate: ThTableService.buildCellDate(item.purchaseDate),
      deliveryDate: ThTableService.buildCellDate(item.deliveryDate),
      receiptNumber: ThTableService.buildCellInline(item.receiptNumber),
      protectionPlanStatus: ThTableService.buildCellInline(this.planStatuses[item.protectionPlanStatus]),
      actions: ThTableService.buildCellCustom({
        type: 'actions',
        plan: item,
      }),
    }));
  }

  startClaimDialog(planId: number): void {
    // No implementation
  }

  getDefaultHiddenColumns(): string[] {
    return [];
  }
}
