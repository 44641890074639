import { Injectable } from '@angular/core';

import { TDocumentType } from '../enums/document-type';

@Injectable({
  providedIn: 'root',
})
export class ClaimFileService {
  constructor() {}

  filterPlanFiles(files: any) {
    return files.filter(file => [TDocumentType.Receipt].includes(file.docType));
  }

  filterClaimConsumerFiles(files: any) {
    return files.filter(file => [TDocumentType.CustPhoto, TDocumentType.CustPhoto0rd].includes(file.docType));
  }

  filterClaimRetailerFiles(files: any) {
    return files.filter(file => [TDocumentType.myServPhotos, TDocumentType.RetailerPortalUploadedPhoto].includes(file.docType));
  }

  filterClaimTechFiles(files: any) {
    return files.filter(file => [TDocumentType.TechPhoto, TDocumentType.TechReport].includes(file.docType));
  }
}
