import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-claim-flagged-cell',
  standalone: true,
  templateUrl: './claim-flagged-cell.component.html',
  styleUrls: ['./claim-flagged-cell.component.scss'],
  imports: [
    MatIconModule,
    NgIf,
  ],
})
export class ClaimFlaggedCellComponent {
  data: any;
  fieldName: string;
}
