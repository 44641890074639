import { TDocumentType } from '@core/enums/document-type';

export type TDocumentFilterId = 0 | TDocumentType[];

export interface IDocumentFilter {
  id: TDocumentFilterId;
  name: string;
}

export const PLAN_DOCUMENT_FILTERS: IDocumentFilter[] = [
  { id: [TDocumentType.Receipt], name: 'Plan' },
];

export const CLAIM_CONSUMER_DOCUMENT_FILTERS: IDocumentFilter[] = [
  { id: [TDocumentType.CustPhoto, TDocumentType.CustPhoto0rd], name: 'Claim (Consumer)' },
];

export const CLAIM_RETAILER_DOCUMENT_FILTERS: IDocumentFilter[] = [
  {
    id: [
      TDocumentType.RetailerPortalUploadedPhoto,
      TDocumentType.myServPhotos,
      TDocumentType.ManufacturerInvoiceReceipt,
      TDocumentType.DeliveryReceipt,
    ],
    name: 'Claim (Retailer)',
  },
];

export const CLAIM_TECHNICIAN_DOCUMENT_FILTERS: IDocumentFilter[] = [
  {
    id: [
      TDocumentType.TechPhoto,
      TDocumentType.TechReport,
      TDocumentType.TechServicePhoto,
      TDocumentType.TechProductPhoto,
      TDocumentType.TechTagPhoto,
      TDocumentType.TechDamagePhoto,
    ],
    name: 'Claim (Technician)',
  },
];

export const DEFAULT_DOCUMENT_FILTERS: IDocumentFilter[] = [
  { id: 0, name: 'All' },
  ...PLAN_DOCUMENT_FILTERS,
  ...CLAIM_CONSUMER_DOCUMENT_FILTERS,
  ...CLAIM_RETAILER_DOCUMENT_FILTERS,
  ...CLAIM_TECHNICIAN_DOCUMENT_FILTERS,
];
