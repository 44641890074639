import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMjcClaimRequest } from '@th-common-retailer/core/interfaces/claim.interface';
import { isMoment, parseZone } from 'moment';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { TDocumentType } from '@core/enums/document-type';
import { Theme } from '@core/interfaces/ui/theme.interface';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

import { Claim } from '../interfaces/claims/claim.interface';
import { ClaimDetails, IServerSmsCommunication } from '../interfaces/claims/claimDetails.interface';
import { Files } from '../interfaces/claims/files.interface';
import { OtherClaims } from '../interfaces/claims/otherClaims.interface';
import { IDataSearch } from '../interfaces/data-search.interface';
import { QuickSearchConsumer } from '../interfaces/quick-search/search-results.interface';
import { ClaimSearchRequest } from '../models/search/search-request.model';
import { FileService } from '../services/file.service';
import { CrudApiBaseService } from './crud-api.base';

export interface ClaimApiBaseService extends CrudApiBaseService<Claim> {
  getClaimDetails(id: number): Observable<ClaimDetails>;
  getFiles(id: number): Observable<Omit<Files, 'index'>[]>;
}

@Injectable({
  providedIn: 'root',
})
export class ClaimApiService extends CrudApiBaseService<Claim> implements ClaimApiBaseService {
  endpoint = 'claim';

  constructor(private notification: NotificationService, private readonly fileService: FileService) {
    super();
  }

  all(searchRequest: Partial<ClaimSearchRequest>, queryParams?: {tileId?: string}) {
    if (searchRequest.fromDate) {
      searchRequest.fromDate = parseZone(searchRequest.fromDate).startOf('day').toISOString();
    }

    if (searchRequest.toDate) {
      searchRequest.toDate = parseZone(searchRequest.toDate).endOf('day').toISOString();
    }
    return this.httpClient.post<IDataSearch<Claim>>(this.getUrl('all'), searchRequest, {
      params: queryParams,
    });
  }

  getClaimDetails(id: number): Observable<ClaimDetails> {
    return this.httpClient.get<ClaimDetails>(this.getUrl(`${id}`));
  }

  saveNote(id: number, note: string): Observable<any> {
    return this.httpClient.post<any>(this.getUrl(`${id}/note`), {
      note,
    });
  }

  getOtherClaims(consumerId: number, exclude: number): Observable<OtherClaims[]> {
    return this.httpClient.get<any>(this.getUrl(`otherclaims/${consumerId}?excludeClaimId=${exclude}`));
  }

  getFiles(id: number) {
    return this.fileService.getFileViewerWidth().pipe(
      switchMap(width =>
        this.httpClient
          .get<Omit<Files, 'index'>[]>(this.getUrl(`${id}/files`), {
          params: {
            width,
          },
        })
          .pipe(map(files => this.fileService.sortFiles(files))),
      ),
    );
  }

  resetForRetailerReview(id: number) {
    return this.httpClient.put<Omit<Files, 'index'>[]>(this.getUrl(`${id}/resetforretailerreview`), {});
  }

  quickSearch(query: string): Observable<QuickSearchConsumer[]> {
    return this.httpClient.get<any>(this.getUrl(`quicksearch?searchString=${encodeURIComponent(query)}`));
  }

  info(token: string): Observable<ClaimDetails> {
    return this.httpClient.post<ClaimDetails>(this.getUrl('info'), {
      token,
    });
  }

  claimTenantInfo(encryptedClaimPortalToken: string) {
    return this.httpClient.get<ClaimDetails & {tenantInfo: Theme}>(this.getUrl('claimTenantInfo'), {
      params: {
        encryptedClaimPortalToken,
      },
    });
  }

  exportClaims(request: any): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(this.getUrl('export'), request, { observe: 'response', responseType: 'blob' });
  }

  createClaim(data: any, files: Files[]): Observable<{claimNumber: string}> {
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('formfiles', files[i].originalFile);
    }

    formData.append('model', JSON.stringify(data));

    return this.httpClient.post<{claimNumber: string}>(this.getUrl(''), formData).pipe(
      tap(() => {
        this.notification.next({
          message:
            'You claim has been submitted for processing. '
            + 'You will receive the Claim Number shortly, as soon as it has been assigned.',
          type: NotificationType.Success,
          duration: 6000,
        });
      }),
    );
  }

  createMjcClaim(claim: IMjcClaimRequest, files: Files[]): Observable<{claimNumber: string}> {
    const formData = this._returnMultipleFilesFormData(files);
    Object.entries(claim).forEach(([key, value]) => {
      this._convertJsonValueToFormData(formData, value, key);
    });

    return this.httpClient.post<{claimNumber: string}>(this.getUrl(''), formData).pipe(
      tap(() => {
        this.notification.next({
          message:
            'You claim has been submitted for processing. '
            + 'You will receive the Claim Number shortly, as soon as it has been assigned.',
          type: NotificationType.Success,
          duration: 6000,
        });
      }),
    );
  }

  getSmsHistoryForClaim(claimId: number): Observable<IServerSmsCommunication[]> {
    return this.httpClient.get<IServerSmsCommunication[]>(this.getUrl(`${claimId}/smshistory`));
  }

  markCompleted(claimId: number, data?: {salesTax?: number; receiptFiles: Files[]}): Observable<void> {
    let formData = new FormData();
    if (data) {
      formData = this._returnMultipleFilesFormData(data.receiptFiles);
      if (data.salesTax) {
        formData.append('salesTax', data.salesTax.toString());
      }
    }
    return this.httpClient.put<void>(this.getUrl(`${claimId}/markcompleted`), formData);
  }

  private _returnMultipleFilesFormData(files: Files[]): FormData {
    const receiptsUploaded = files.filter(file => file.docType === TDocumentType.Receipt && !file.isReadOnly);
    const productFilesUploaded = files.filter(
      file => [TDocumentType.CustPhoto, TDocumentType._DamagePhoto, TDocumentType.RetailerPortalUploadedPhoto].includes(file.docType),
    );
    const formData: FormData = new FormData();

    if (receiptsUploaded.length) {
      for (let i = 0; i < receiptsUploaded.length; i++) {
        formData.append('receiptFiles', receiptsUploaded[i].originalFile);
      }
    }
    if (productFilesUploaded.length) {
      for (let i = 0; i < productFilesUploaded.length; i++) {
        formData.append('claimFiles', productFilesUploaded[i].originalFile);
      }
    }
    return formData;
  }

  private _convertJsonValueToFormData(formData: FormData, jsonValue: any, key: string, parentKey?: string): void {
    const joinedKey = parentKey ? `${parentKey}.${key}` : key;
    if (jsonValue) {
      if (isMoment(jsonValue)) {
        formData.append(joinedKey, jsonValue.toISOString());
      } else if (Array.isArray(jsonValue)) {
        jsonValue.forEach((subValue, index) => {
          this._convertJsonValueToFormData(formData, subValue, `${joinedKey}[${index}]`);
        });
      } else if (typeof jsonValue === 'object') {
        Object.entries(jsonValue).forEach(([objectKey, objectValue]) => {
          this._convertJsonValueToFormData(formData, objectValue, objectKey, joinedKey);
        });
      } else {
        formData.append(`${joinedKey}`, jsonValue.toString());
      }
    }
  }
}
