<div
  class="splide"
  #carousel>
  <div
    class="splide__arrows"
    [class.splide__arrows--hidden]="(showArrows$ | async) === false">
    <button
      class="splide__arrow splide__arrow--prev"
      [class.splide__arrow--hidden]="(currentIndex$ | async) === 0 || (allSlidesVisible$ | async)"
      [class.splide__arrow--no-background]="!customOptions.arrowsBackground"
      [class.splide__arrow--outside]="customOptions.arrowsPosition === 'outside'">
      <mat-icon
        svgIcon="big-arrow"
        class="splide__arrow--white prev"></mat-icon>
    </button>
    <button
      class="splide__arrow splide__arrow--next"
      [class.splide__arrow--hidden]="(lastSlideVisible$ | async) || (allSlidesVisible$ | async)"
      [class.splide__arrow--no-background]="!customOptions.arrowsBackground"
      [class.splide__arrow--outside]="customOptions.arrowsPosition === 'outside'">
      <mat-icon
        svgIcon="big-arrow"
        class="splide__arrow--white next"></mat-icon>
    </button>
  </div>
  <div class="splide__track">
    <div class="splide__list">
      <ng-content></ng-content>
    </div>
  </div>
</div>
