import { Component } from '@angular/core';

import { TServiceActionType } from '@core/enums/service-action/service-action-type.enum';

@Component({
  selector: 'app-service-type-grid-cell',
  templateUrl: './service-type-grid-cell.component.html',
  styleUrls: ['./service-type-grid-cell.component.scss'],
})
export class ServiceTypeGridCellComponent {
  data: any;
  fieldName: string;

  serviceType: {[key in TServiceActionType ]: string} = {
    [TServiceActionType.PreQualification]: 'Pre Qualification',
    [TServiceActionType.Kit]: 'Kit',
    [TServiceActionType.CashSettlement]: 'Cash Settlement',
    [TServiceActionType.TechWorkOrder]: 'Tech Work Order',
    [TServiceActionType.Replacement]: 'Replacement',
    [TServiceActionType.PartOrder]: 'Part Order',
    [TServiceActionType.Refund]: 'Refund',
    [TServiceActionType.Complaint]: 'Complaint',
    [TServiceActionType.Repair]: 'Repair',
    [TServiceActionType.Repairs]: '',
    [TServiceActionType.ClaimsToReview]: '',
    [TServiceActionType.Other]: 'Other',
  };
}
