import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject } from 'rxjs';

import { IPagination } from '@shared/modules/table/interfaces/pagination.interface';


export interface IPageCounter {
  from: number;
  to: number;
}

@Component({
  selector: 'app-table-pagination, th-table-pagination',
  standalone: true,
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    NgForOf,
    MatIconModule,
    NgIf,
  ],
})
export class ThTablePaginationComponent implements OnChanges {
  @Input() pagination: IPagination;
  @Input() pageSize: number;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  pagesRange$$ = new BehaviorSubject<Array<string | number>>([]);
  pagesRange$ = this.pagesRange$$.asObservable();
  counter$$ = new BehaviorSubject<IPageCounter>(null);
  counter$ = this.counter$$.asObservable();

  ngOnChanges(): void {
    this.pagesRange$$.next(this._pageNumbers(this.pagination.pageCount, this.pagination.page));
    this.counter$$.next({
      from:
        this.pagination.totalCount === 0 ? this.pagination.totalCount : (this.pagination.page - 1) * this.pageSize + 1,
      to:
        this.pagination.page * this.pageSize > this.pagination.totalCount
          ? this.pagination.totalCount
          : this.pagination.page * this.pageSize,
    });
  }

  next(): void {
    if (this.pagination.page < this.pagination.pageCount) {
      this.pageChange.emit(this.pagination.page + 1);
    }
  }

  prev(): void {
    if (this.pagination.page > 1) {
      this.pageChange.emit(this.pagination.page - 1);
    }
  }

  first(): void {
    this.pageChange.emit(1);
  }

  last(): void {
    this.pageChange.emit(this.pagination.pageCount);
  }

  changePage(page): void {
    if (page !== this.pagination.page) {
      this.pageChange.emit(page);
    }
  }

  private _pageNumbers(count, current): Array<any> {
    const delta = 5,
      left: number = delta,
      right: number = count - delta + 1,
      range: Array<any> = [],
      rangeWithDots: Array<any> = [];
    let l: number;

    for (let i = 1; i <= count; i++) {
      if (
        i === 1
        || i === count
        || (current + delta - 1 > count && i >= count - delta + 1)
        || (current - delta < 0 && i <= delta)
        || (current >= left && current <= right && i >= current - 2 && i <= current + 2)
      ) {
        range.push(i);
      }
    }

    for (const i of range) {
      if (l) {
        if (i - l > 1) {
          rangeWithDots.push('...');
          rangeWithDots.push(i);
        } else {
          rangeWithDots.push(i);
        }
      } else {
        rangeWithDots.push(i);
      }
      l = i;
    }

    return rangeWithDots;
  }
}
