import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RxState } from '@rx-angular/state';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@core/base/base.component';
import { validateMinLengthWithCharsAndNumbers } from '@core/utils/form.util';
import { FormInputComponent } from '@shared/components/form-input/form-input.component';

interface ISearchInputState {
  canBounce: boolean;
  bounce: boolean;
}

@Component({
  selector: 'app-search-input',
  standalone: true,
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    MatIconModule,
    NgIf,
    AsyncPipe,
    FormInputComponent,
    ReactiveFormsModule,
  ],
})
export class SearchInputComponent extends BaseComponent implements OnInit {
  @Input() set canBounce(canBounce: boolean) {
    this.state.set({
      canBounce,
    });
  }
  @Output() search = new EventEmitter<string>();
  @Output() clearSearch = new EventEmitter<string>();
  @Output() queryChanged = new EventEmitter<string>();

  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() isTwoWaySearch = false;
  @Input() clearSearchValidators = false;

  @Input()
  set searchQueryValue(search: string) {
    this.form.patchValue({
      search,
    });
  }

  bounce$ = this.state.select('bounce');
  canBounce$ = this.state.select('canBounce');

  form = this.fb.group({
    search: [null, validateMinLengthWithCharsAndNumbers(5)],
  });

  constructor(private readonly fb: UntypedFormBuilder, private readonly state: RxState<ISearchInputState>) {
    super();
    this.state.set({
      canBounce: true,
      bounce: true,
    });
  }

  ngOnInit() {
    if (this.isTwoWaySearch) {
      this.form.get('search').setValidators([]);
      this.form.get('search').updateValueAndValidity();
    }

    this.form
      .get('search')
      .valueChanges.pipe(takeUntil(this.unsubscribeOnDestroy$))
      .subscribe(value => {
        this.queryChanged.emit(value);
      });

    if (this.clearSearchValidators) {
      this.form.get('search').clearValidators();
    }
  }

  quickSearch() {
    this.form.get('search').markAsTouched();
    if (this.form.valid) {
      this.search.emit(this.form.get('search').value);
    }
  }

  clear() {
    this.form.reset();
    this.clearSearch.emit();
  }

  quickSearchInputBlur() {
    this.form.get('search').markAsTouched();
    this.state.set({
      bounce: true,
    });
  }

  quickSearchInputFocus() {
    this.state.set({
      bounce: false,
    });
  }
}
