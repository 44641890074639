<div *ngIf="readonly === true; else regularSelect">
  <div class="field__label">Contact Method</div>
  <div class="field__text">{{selectedItemName$ | async | appEmptyValue}}</div>
</div>
<ng-template #regularSelect>
  <app-form-select [formControl]="formControl"
                   [data]="itemsList$ | async"
                   [label]="'Contact Method'"></app-form-select>
</ng-template>


