import { NgIf } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { ClaimDetailsType } from '@core/enums/claim/claim-details-type.enum';
import { TPortalType } from '@core/enums/portal-type.enum';
import { BaseConsumer, ClaimInfo, Retailer } from '@core/interfaces/claims/claimDetails.interface';
import { EmailLinkComponent } from '@shared/components/email-link/email-link.component';
import { EscalationInfoComponent } from '@shared/components/escalation-info/escalation-info.component';
import { RetailerInformationComponent } from '@shared/components/retailer-information/retailer-information.component';
import { PipesModule } from '@shared/pipes/pipes.module';

@Component({
  selector: 'app-consumer-information',
  standalone: true,
  templateUrl: './consumer-information.component.html',
  styleUrls: ['./consumer-information.component.scss'],
  imports: [
    NgIf,
    PipesModule,
    RetailerInformationComponent,
    EmailLinkComponent,
    EscalationInfoComponent,
  ],
})
export class ConsumerInformationComponent implements OnInit {
  @Input() consumer: BaseConsumer;
  @Input() claimInfo: ClaimInfo;
  @Input() retailer: Retailer;
  @Input() claimDetailsType: ClaimDetailsType;

  appConfig = inject(APP_CONFIG);
  showSubmitterAndEscalation: boolean;
  isSystemDetails: boolean;

  ngOnInit(): void {
    this.showSubmitterAndEscalation =
      this.claimDetailsType === ClaimDetailsType.Tenant && ![TPortalType.MJC, TPortalType.Consumer].includes(this.appConfig.portalType);
    this.isSystemDetails = this.claimDetailsType === ClaimDetailsType.System;
  }
}
