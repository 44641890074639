<div class="dialog dialog--short">
  <app-base-header
    headerTitle="Plan(s) Registered"
    (closeClick)="close()"></app-base-header>
  <section class="dialog__content">
    <h3
      class="claim-success-modal__email-success-message"
      *ngIf="
        createdPlans.items && createdPlans.items.length && !(failedPlans.items && failedPlans.items.length)
      ">
      Registration was successful.
    </h3>
    <h3
      class="claim-success-modal__error-message"
      *ngIf="
        !(createdPlans.items && createdPlans.items.length) && failedPlans.items && failedPlans.items.length
      ">
      Registration failed.
    </h3>
    <h3
      class="claim-success-modal__email-success-message"
      *ngIf="
        createdPlans.items && createdPlans.items.length && failedPlans.items && failedPlans.items.length
      ">
      Registration was partially successful.
    </h3>
  </section>
  <div
    appCustomOverlayScrollbar
    class="dialog__content claim-success-modal__scrollbar">
    <section
      *ngIf="createdPlans.items && createdPlans.items.length > 0"
      class="block">
      <h3 class="section__title--theme">Created Plan(s):</h3>
      <div>
        <app-grid
          [items]="createdPlans.items"
          [columns]="createdColumns"
          [orderBy]="gridsOrderBy"
          (changePage)="changePage($event)"></app-grid>
      </div>
    </section>
    <section
      *ngIf="failedPlans.items && failedPlans.items.length > 0"
      class="block">
      <h3 class="claim-success-modal__error-message">The following plan(s) could not be registered:</h3>
      <div>
        <app-grid
          [items]="failedPlans.items"
          [columns]="failedColumns"
          [orderBy]="gridsOrderBy"
          (changePage)="changePage($event)"></app-grid>
      </div>
    </section>
    <section *ngIf="consumerEmailAddress && createdPlans.items && createdPlans.items.length">
      <h3 class="section__title section__title--theme">Email Plan(s) to Consumer</h3>
      <div class="fields-block">
        <mat-form-field class="field field--half">
          <input
            disabled
            matInput
            [value]="consumerEmailAddress"
            type="email"
            placeholder="Email" />
        </mat-form-field>
        <div
          class="claim-success-modal__success-message"
          *ngIf="emailSendMessage$ | async as emailSendMessage; else sendToEmail">
          {{ emailSendMessage }}
        </div>
      </div>
    </section>
  </div>
  <div class="dialog__content inline-buttons inline-buttons--right claim-success-modal__button-close">
    <button
      [mat-dialog-close]="false"
      class="inline-buttons__button _common-button--rounded"
      mat-flat-button
      color="primary">
      Close
    </button>
  </div>
</div>
<ng-template #sendToEmail>
  <button
    class="claim-success-modal__send-button _common-button--rounded"
    appLoadingButton
    [disabled]="!createdPlans.items.length"
    [isLoading]="emailSendInProgress$ | async"
    (click)="sendPdf()"
    mat-flat-button
    color="primary">
    Send
  </button>
</ng-template>
