import { NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import {
  FormBuilder, FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { finalize } from 'rxjs/operators';

import { ConsumerApiService } from '@core/api/consumer-api.service';
import { QuickSearchConsumer } from '@core/interfaces/quick-search/search-results.interface';
import { ClaimFormService } from '@core/services/claim-form.service';
import { DialogService } from '@core/services/dialog.service';
import { SideDialogStateService } from '@core/services/dialog/side-dialog-state.service';
import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';
import {
  AddressSuggestComponent,
} from '@shared/modules/claim-dialogs/components/address-suggest/address-suggest.component';
import { AddressType } from '@shared/modules/claim-dialogs/enums/address-type.enum';
import { ClaimFormModule } from '@shared/modules/claim-form/claim-form.module';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import {
  ConsumerTemplateFormComponent,
} from '@shared/standalone/claim-forms/consumer-template-form/consumer-template-form.component';
import { SideDialogAnimationComponent } from '@shared/standalone/side-dialog-animation/side-dialog-animation.component';

@Component({
  selector: 'app-edit-consumer',
  standalone: true,
  templateUrl: './edit-consumer.component.html',
  styleUrls: ['./edit-consumer.component.scss'],
  imports: [
    ClaimFormModule,
    AddressSuggestComponent,
    LoadingButtonDirective,
    MatButtonModule,
    LoaderModule,
    MatIconModule,
    NgIf,
    SideDialogAnimationComponent,
    ConsumerTemplateFormComponent,
  ],
  providers: [SideDialogStateService],
})
export class EditConsumerComponent implements OnInit {
  form: FormGroup;
  saveInProgress = false;
  addressVerificationInProgress = false;
  consumerTitle = '';
  dialogData: {data: {consumer: QuickSearchConsumer}} = inject(MAT_DIALOG_DATA);
  phonesRequired = true;

  constructor(
    private fb: FormBuilder,
    private consumerApiService: ConsumerApiService,
    private claimFormService: ClaimFormService,
    private readonly dialogService: DialogService,
    private readonly sideDialogStateService: SideDialogStateService,
  ) {}

  ngOnInit(): void {
    this.consumerTitle = [this.dialogData.data.consumer.firstName, this.dialogData.data.consumer.lastName].filter(item => !!item).join(' ');
    this.consumerApiService.get(this.dialogData.data.consumer.id).subscribe(consumer => {
      this.form = this.fb.group({
        id: new FormControl(consumer.id, Validators.required),
        consumer: this.fb.group({
          info: this.claimFormService.getConsumerFields(consumer),
          consumerAddress: this.claimFormService.getAddressFields(consumer, AddressType.CustomerAddress),
        }),
      });
      if (this.form.get('consumer.info.emailAddress').invalid) {
        this.form.get('consumer.info.emailAddress').markAsTouched();
      }

      if (
        !this.dialogData.data.consumer.mobilePhone
        && !this.dialogData.data.consumer.homePhone
        && !this.dialogData.data.consumer.workPhone
      ) {
        this.phonesRequired = false;
        this.form.get('consumer.info').removeValidators(ClaimFormService.ValidateConsumerPhones);
      } else {
        this.phonesRequired = true;
        this.form.get('consumer.info').addValidators(ClaimFormService.ValidateConsumerPhones);
      }

      if (!consumer.emailAddress) {
        this.form
          .get('consumer.info.emailAddress')
          .removeValidators([Validators.required]);
        this.form.get('consumer.info.emailAddress').updateValueAndValidity();
      }
    });
  }

  submitForm(): void {
    if (this.form.valid) {
      this.saveInProgress = true;
      const formValue = {
        id: this.form.value.id,
        ...this.form.value.consumer.consumerAddress,
        ...this.form.value.consumer.info,
      };

      this.consumerApiService.update(formValue).pipe(
        finalize(() => {
          this.saveInProgress = false;
        }),
      ).subscribe(
        () => {
          this.saveInProgress = false;
          this.sideDialogStateService.close({
            edited: true,
            consumer: formValue,
          });
        },
      );
    }
  }

  onClose(): void {
    if (this.saveInProgress) {
      return;
    }
    this.dialogService.canLeaveDefaultConfirmationObservable(this.form).subscribe(canLeave => {
      if (canLeave) {
        this.sideDialogStateService.close({
          edited: false,
        });
      } else {
        return;
      }
    });
  }

  addressVerificationInProgressHandler(addressVerificationInProgress: boolean): void {
    this.addressVerificationInProgress = addressVerificationInProgress;
  }
}
