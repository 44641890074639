import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-add-button-small',
  standalone: true,
  templateUrl: './add-button-small.component.html',
  styleUrls: ['./add-button-small.component.scss'],
  imports: [
    MatButtonModule,
    MatIconModule,
    NgIf,
  ],
})
export class AddButtonSmallComponent {
  @Input() buttonText: string;
  @Input() disabled = false;
  @Input() bold = false;
  @Input() color: 'primary' = null;
}
