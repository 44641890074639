import { Injectable } from '@angular/core';

import { BaseStore } from '@core/store/_base/base.store';

import { StoreState } from './parent-plans-list.state';
import IStoreState = StoreState.IStoreState;
import initialState = StoreState.initialState;
import { finalize, switchMap, take } from 'rxjs/operators';

import { ParentPlanApiService } from '@core/api/parent-plan-api.service';

@Injectable({
  providedIn: 'root',
})
export class ParentPlansListStore extends BaseStore<IStoreState> {
  constructor(private readonly parentPlanApi: ParentPlanApiService) {
    super(initialState);
  }

  loadData(lazy: boolean = false): void {
    this.updateState({ loading: true });
    this.select$('searchRequest')
      .pipe(
        take(1),
        switchMap(searchRequest => this.parentPlanApi.all(searchRequest)),
        finalize(() => this.updateState({ loading: false })),
      )
      .subscribe(({ items, totalCount, page, pageCount }) => {
        this.updateState({
          plans: lazy ? this.get().plans.concat(items || []) : items || [],
          pagination: {
            totalCount,
            page,
            pageCount,
          },
        });
      });
  }

  setSelectedIds(selectedPlansIdsList: string[]) {
    this.updateState({ selectedPlansIdsList });
  }

  updatePagination(page: number, pageSize?: number) {
    const searchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...searchRequest,
        page,
        pageSize: pageSize || searchRequest.pageSize,
      },
    });
    this.loadData(true);
  }

  setRetailerId(retailerId: number) {
    const searchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...searchRequest,
        retailerId,
      },
    });
    this.loadData();
  }

  search(searchString: string) {
    const searchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...searchRequest,
        page: 1,
        searches: [searchString],
      },
    });
    this.loadData();
  }
}
