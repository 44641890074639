import { NgForOf, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { ClaimFileService } from '@core/services/claim-file.service';
import { ClaimDocument } from '@shared/pipes/filter-documents-by-types.pipe';

@Component({
  selector: 'app-grouped-claim-files',
  standalone: true,
  templateUrl: './grouped-claim-files.component.html',
  styleUrls: ['./grouped-claim-files.component.scss'],
  imports: [
    NgForOf,
    NgIf,
  ],
})
export class GroupedClaimFilesComponent implements OnInit {
  @Input() files;
  filteredFiles: {title: string; files: ClaimDocument[]}[] = [];

  constructor(private claimFileService: ClaimFileService) {}

  ngOnInit() {
    const planFiles = this.claimFileService.filterPlanFiles(this.files);
    const claimConsumerFiles = this.claimFileService.filterClaimConsumerFiles(this.files);
    const claimRetailerFiles = this.claimFileService.filterClaimRetailerFiles(this.files);
    const claimTechFiles = this.claimFileService.filterClaimTechFiles(this.files);

    this.filteredFiles = [
      { title: 'Plan', files: planFiles },
      { title: 'Claim (Consumer)', files: claimConsumerFiles },
      { title: 'Claim (Retailer)', files: claimRetailerFiles },
      { title: 'Claim (Technician)', files: claimTechFiles },
    ];
  }
}
