import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { TPortalType } from '@core/enums/portal-type.enum';
import { AuthService } from '@core/services/auth.service';

import { WINDOW } from '../providers/window.provider';
import { ThemingService } from '../services/theming.service';

export const tenantThemeResolver: ResolveFn<boolean> = (route: ActivatedRouteSnapshot) => {
  const authService = inject(AuthService);
  const themingService = inject(ThemingService);
  const windowProvider = inject(WINDOW);
  const appConfig = inject(APP_CONFIG);

  let { tenant } = authService.getPortalUrlData();
  const href = windowProvider.location.href;
  const tenantFromParams = route.paramMap.get('tenant');
  const page = /[^/]*$/.exec(href)[0];
  const pageDefined = !!page && tenantFromParams !== page;
  if (tenant && tenant !== tenantFromParams) {
    const replace = `/${tenant}${!pageDefined ? (page ? '/login' : 'login') : ''}`;
    windowProvider.location.href = href.replace(`/${tenantFromParams}`, `${replace}`);
  }
  if (!tenant) {
    tenant = tenantFromParams;
  }

  if (tenant === appConfig.commonTenantName && route.queryParams.tenantId) {
    tenant = route.queryParams.tenantId;
  }

  if ([TPortalType.TechPortal, TPortalType.MJC].includes(appConfig.portalType) && tenant === 'common') {
    tenant = appConfig.commonTenantName;
  }

  return themingService.for(tenant);
};
