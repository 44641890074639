<div class="side-dialog-header">
  <div class="side-dialog-header-title">
    <mat-icon
      svgIcon="big-arrow"
      class="_common-icon--white side-dialog-header__return"
      *ngIf="showReturnArrow"
      (click)="returnToItem.emit()"></mat-icon>
    <div class="side-dialog-header-title-text">
      <h2>{{ dialogTitle }}</h2>
      <ng-content select="[title-header]"></ng-content>
      <ng-container *ngIf="dialogTitleTemplate">
        <ng-container *ngTemplateOutlet="dialogTitleTemplate"></ng-container>
      </ng-container>
    </div>
  </div>
  <div class="side-dialog-header-controls">
    <ng-content select="[controls]"></ng-content>
    <div
      *ngIf="showClose"
      class="side-dialog-header-controls-close"
      [class.side-dialog-header-controls--disabled]="disableClose">
      <mat-icon
        svgIcon="close"
        [class._common-icon--disabled]="disableClose"
        (click)="closeHandler()"></mat-icon>
    </div>
  </div>
</div>
