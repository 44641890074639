<form (ngSubmit)="quickSearch()" class="search-input">
  <app-form-input class="_common-mat-form-field--quick-search"
                  [formControl]="form.get('search')"
                  [autocomplete]="'off'"
                  [placeholder]="placeholder"
                  (inputFocus)="quickSearchInputFocus()"
                  [disabled]="disabled"
                  (inputBlur)="quickSearchInputBlur()">
    <mat-icon class="search-input__bounce-icon" prefix [class.bounce]="(bounce$ | async) && (canBounce$ | async)" svgIcon="search-grey" (click)="quickSearch()"></mat-icon>
    <mat-icon class="search-input__close-icon" suffix *ngIf="!!form.get('search').value" svgIcon="close" (click)="clear()"></mat-icon>
  </app-form-input>
</form>
