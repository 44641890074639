import { CorrespondenceGridCellComponent } from '../../grid/components/correspondence-grid-cell/correspondence-grid-cell.component';
import { EmailContentsGridCellComponent } from '../../grid/components/email-contents-grid-cell/email-contents-grid-cell.component';
import { GridDateCellComponent } from '../../grid/components/grid-date-cell/grid-date-cell.component';
import { GridDateTimeCellComponent } from '../../grid/components/grid-date-time-cell/grid-date-time-cell.component';
import { ServiceDetailsGridCellComponent } from '../../grid/components/service-details-grid-cell/service-details-grid-cell.component';
import { ServiceStatusGridCellComponent } from '../../grid/components/service-status-grid-cell/service-status-grid-cell.component';
import { ServiceTypeGridCellComponent } from '../../grid/components/service-type-grid-cell/service-type-grid-cell.component';
import { GridColumnModel } from '../../grid/models/grid/grid-column.model';
import { ProductIncidentProblemsComponent } from '../components/product-incident-problems/product-incident-problems.component';
import { ProductIncidentStatusComponent } from '../components/product-incident-status/product-incident-status.component';

export class BaseClaimDetailsTablesConfig {
  static serviceActionsColumns = [
    new GridColumnModel('openDate', 'Opened', GridDateTimeCellComponent),
    new GridColumnModel('serviceActionType', 'Service', ServiceTypeGridCellComponent),
    new GridColumnModel('serviceActionStatus', 'Status', ServiceStatusGridCellComponent),
    new GridColumnModel('processStage', 'Process Stage'),
    new GridColumnModel('settlementName', 'Details', ServiceDetailsGridCellComponent),
  ];

  static claimCommunicationLettersColumns = [
    new GridColumnModel('createDate', 'Date', GridDateTimeCellComponent),
    new GridColumnModel('createdBy', 'Created By'),
    new GridColumnModel('subject', 'Subject'),
    new GridColumnModel('size', 'Size'),
    new GridColumnModel('content', 'Contents', EmailContentsGridCellComponent, false, false, true, true),
  ];

  static claimCommunicationColumns = [
    new GridColumnModel('createDate', 'Date', GridDateTimeCellComponent),
    new GridColumnModel('correspondence', 'Correspondence', CorrespondenceGridCellComponent),
    new GridColumnModel('subject', 'Subject'),
    new GridColumnModel('content', 'Contents', EmailContentsGridCellComponent, false, false, true, true),
  ];

  static claimCommunicationPhoneCallsColumns = [
    new GridColumnModel('createDate', 'Date', GridDateTimeCellComponent),
    new GridColumnModel('correspondence', 'Correspondence', CorrespondenceGridCellComponent),
    new GridColumnModel('subject', 'Subject'),
  ];

  static claimCommunicationEmailColumns = [
    new GridColumnModel('createDate', 'Date', GridDateTimeCellComponent),
    new GridColumnModel('correspondence', 'Correspondence', CorrespondenceGridCellComponent),
    new GridColumnModel('subject', 'Subject'),
    new GridColumnModel('size', 'Size'),
    new GridColumnModel('content', 'Contents', EmailContentsGridCellComponent, false, false, true, true),
  ];

  static claimProductsColumns = [
    new GridColumnModel('vendor', 'Manufacturer'),
    new GridColumnModel('name', 'Product Name'),
    new GridColumnModel('sku', 'SKU'),
    new GridColumnModel('status', 'Status', ProductIncidentStatusComponent),
    new GridColumnModel(['reportedProblemType', 'verifiedProblemType'], 'Problem', ProductIncidentProblemsComponent),
    new GridColumnModel(
      ['reportedProblemDescription', 'verifiedProblemDescription'],
      'Description',
      ProductIncidentProblemsComponent,
    ),
    new GridColumnModel(
      ['reportedProblemCause', 'verifiedProblemCause', 'retailerClassificationName'],
      'Cause',
      ProductIncidentProblemsComponent,
    ),
  ];

  static claimWarrantyColumns = [
    new GridColumnModel('receiptNumber', 'Receipt Number'),
    new GridColumnModel('name', 'MFS Consumer Agreement Number'),
    new GridColumnModel('purchaseDate', 'Purchase', GridDateCellComponent),
    new GridColumnModel('deliveryDate', 'Delivery', GridDateCellComponent),
  ];
}
