import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  opened$ = new BehaviorSubject(false);

  constructor(
    private readonly router: Router,
  ) {
    this.router.events.pipe(
      filter(e => e instanceof NavigationStart),
      distinctUntilChanged(),
    ).subscribe(event => {
      this.closeMenu();
    });
  }

  setMenuOpen(isOpened: boolean) {
    this.opened$.next(isOpened);
  }

  openMenu() {
    this.opened$.next(true);
  }

  closeMenu() {
    this.opened$.next(false);
  }
}
