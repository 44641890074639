import { ChangeDetectionStrategy, Component,inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';
import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';


@Component({
  selector: 'app-license-california-dialog',
  standalone: true,
  templateUrl: './license-california-dialog.component.html',
  styleUrls: ['./license-california-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CustomOverlayScrollbarDirective,
    MatIconModule,
    LoadingButtonDirective,
  ],
})
export class LicenseCaliforniaDialogComponent {
  contactEmail = 'salessupport@montagefs.com';
  dialogData: {
    data: {retailerId: number};
  } = inject(MAT_DIALOG_DATA);

  constructor(
    public dialogRef: MatDialogRef<LicenseCaliforniaDialogComponent>,
  ) {}

  close(): void {
    this.dialogRef.close(null);
  }
}
