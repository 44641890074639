<div class="dialog license-california">
  <div appCustomOverlayScrollbar>
    <div class="license-california__close">
      <mat-icon
        class="mat-icon--smaller"
        svgIcon="close"
        (click)="close()"></mat-icon>
    </div>
    <mat-icon
      class="license-california__icon"
      svgIcon="img-licenseexpiringsoon"></mat-icon>
    <p class="license-california__header">California State Licensing.</p>
    <div class="license-california__main-text">
      <p>
        <b>
          The State of California requires retailers selling protection plans to obtain a Service Warranty
          Sales license. It is the responsibility of the retailers to obtain and maintain proper licensing.
          All applications should be submitted directly by the retailer to the California Bureau of Household
          Goods and Services (BHGS) with appropriate fees.
        </b>
      </p>
    </div>
    <div class="license-california__text">
      <p>
        Montage, Inc. assumes no licensing responsibility for a California retailer selling its furniture
        protection plans without proper licensing. If you would like to download a copy of instructions which
        may assist in your Service Warranty Sales application,
        <a
          class="license-california__link"
          href="../../../../../assets/pdf/How_to_California_License.pdf"
          target="_blank">
          click here
        </a>
        .
      </p>
      <br />
      <p>
        Upon receipt of your California License number, please forward the license details to our Sales
        Support team at
        <a
          class="license-florida__mailto"
          [href]="'mailto:' + contactEmail">
          {{ contactEmail }}
        </a>
        for proper consideration.
      </p>
    </div>
  </div>
</div>
