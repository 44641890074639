<ng-container *rxLet="vm$; let vm">
  <div class="advanced-search" [formGroup]="form">
    <app-collapsible-block>
      <div
        class="advanced-search__header"
        header>
        Retailer
      </div>
      <div content>
        <div *ngIf="appConfig.portalType !== TPortalType.MJC">
          <app-regions-select
            *appSecureContent="[feature.ViewRegions]"
            [formControl]="form.get('regionId')"></app-regions-select>
        </div>
        <div>
          <app-retailer-select [formControl]="form.get('retailerId')"></app-retailer-select>
        </div>
      </div>
    </app-collapsible-block>
    <app-collapsible-block>
      <div
        class="advanced-search__header"
        header>
        Date
      </div>
      <div content>
        <div>
          <app-date-range-search
            (dateRangeTypeChange)="dateRangeTypeChanged($event)"
            [dateRange]="form.get('dateRangeType').value"></app-date-range-search>
        </div>
        <div>
          <app-form-select
            [formControl]="form.get('dateKind')"
            [data]="dateKinds"
            [label]="'Search By'"></app-form-select>
        </div>
      </div>
    </app-collapsible-block>
    <app-collapsible-block>
      <div
        class="advanced-search__header"
        header>
        Service
      </div>
      <div content>
        <div *ngFor="let filter of vm.serviceActionFilters">
          <ng-container *appSecureContent="filter.features">
            <mat-checkbox
              [(ngModel)]="filter.checked"
              [ngModelOptions]="{standalone: true}"
              (ngModelChange)="filterChanged(filter)">
              {{ filter.title }}
            </mat-checkbox>
          </ng-container>
        </div>
        <div class="advanced-search__select-block">
          <div>
            <app-form-select
              [formControl]="form.get('claimStatus')"
              [data]="claimStatuses"
              [label]="'Status'"></app-form-select>
          </div>
          <div>
            <app-form-select
              [formControl]="form.get('claimStage')"
              [data]="claimStages"
              [label]="'Stage'"></app-form-select>
          </div>
          <div *ngIf="vm.showProcessStages">
            <app-processing-stage-select
              [items]="processStages"
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="serviceActionTileStage"></app-processing-stage-select>
          </div>
        </div>
      </div>
    </app-collapsible-block>
  </div>
  <div class="search-button">
    <button
      class="_common-button--rounded"
      mat-flat-button
      color="primary"
      [disabled]="!form.valid"
      (click)="search()">
      Search
    </button>
    <button
      class="_common-button--rounded"
      mat-flat-button
      color="primary"
      (click)="clear()">
      Clear
    </button>
  </div>
</ng-container>
