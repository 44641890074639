import { Pipe, PipeTransform } from '@angular/core';

import { TDocumentType } from '@core/enums/document-type';

const dcoTypeToName = {
  [TDocumentType.CustPhoto]: 'Claim File',
  [TDocumentType._ProductPhoto]: 'Product Photo',
  [TDocumentType._DamagePhoto]: 'Damage Photo',
  [TDocumentType.myServPhotos]: 'Claim File',
  [TDocumentType.RetailerPortalUploadedPhoto]: 'Claim File',
  [TDocumentType.Receipt]: 'Receipt',
  [TDocumentType.ServiceRequest]: 'Service Request Form',
  [TDocumentType.TechServicePhoto]: 'Service Photo',
  [TDocumentType.TechProductPhoto]: 'Product Image',
  [TDocumentType.TechTagPhoto]: 'Product Tag',
  [TDocumentType.TechDamagePhoto]: 'Damage Closeup',
  [TDocumentType._UploadedReceipt]: 'Uploaded receipt',
};

@Pipe({
  name: 'fileDoctype',
})
export class FileDoctypePipe implements PipeTransform {
  transform(docType: TDocumentType | string): string {
    if (!docType) {
      return '';
    }

    return dcoTypeToName[docType] || docType.toString();
  }
}
