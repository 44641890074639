<div class="dialog ordering-dialog">
  <app-base-header headerTitle="Authorization, Availability and Ordering" (closeClick)="close()"></app-base-header>
  <p class="ordering-dialog__title help-text help-text--required block__content">Required</p>
  <div appCustomOverlayScrollbar class="ordering-dialog__form-wrapper">
    <div class="ordering-dialog__loader-wrapper" [ngClass]="{'in-progress': loadInProgress}">
      <div class="block__content">
        <p class="block__item">
          <a class="_common-button--rounded"
             mat-flat-button color="primary"
             target="_blank"
             [href]="authorizationLink">VIEW AUTHORIZATION</a>
        </p>
        <p class="block__item">
          <mat-checkbox [formControl]="form.get('authorizationViewed')" name="viewedAuthorizationCheckbox"><span class="help-text--required-right">I have viewed the authorization</span></mat-checkbox>
        </p>
      </div>
      <div class="block__content">
        <h3 class="block__title">{{titleText}}</h3>
        <div class="base-loader" *ngIf="partsLoading$ | async; else partsData">
          <app-loader></app-loader>
        </div>
        <ng-template #partsData>
          <ul class="ordering-dialog__parts-list">
            <li *ngFor="let part of parts$ | async" class="block__item">{{part.name}}</li>
          </ul>
        </ng-template>
      </div>
      <div *ngIf="showConfirmAvailability$ | async">
        <h3 class="block__content help-text--required-right">Confirm Availability</h3>
        <mat-radio-group [formControl]="form.get('areAllPartsAvailable')"
                         (change)="partsAvailabilityChanged()"
                         name="availabilityRadio">
          <div class="block__item">
            <mat-radio-button class="block__subtitle" [value]="true">All {{itemsName}} are available</mat-radio-button>
            <div class="fields-block"
                 [class.hidden]="form.get('areAllPartsAvailable').value === false || form.get('areAllPartsAvailable').value === null">
              <app-form-date class="field field--third"
                             [formControl]="form.get('estimatedDeliveryDate')"
                             [label]="'Delivery Date'"
                             [required]="!!form.get('areAllPartsAvailable').value"
                             [min]="today"></app-form-date>
              <span class="field field--long">If there are multiple products with different estimated delivery dates, please enter the last date.</span>
            </div>
          </div>
          <div class="block__item">
            <mat-radio-button class="block__subtitle" [value]="false">Some {{itemsName}} are not available</mat-radio-button>
            <div class="fields-block"
                 [class.hidden]="form.get('areAllPartsAvailable').value === true || form.get('areAllPartsAvailable').value === null">
              <app-form-textarea class="field"
                                 [formControl]="form.get('notAvailableDescription')"
                                 [required]="!form.get('areAllPartsAvailable').value"
                                 [label]="availableLabel"
                                 [showIsRequiredMessage]="false"
                                 [errorMessage]="'You must specify which ' + itemsName + ' are not available'"></app-form-textarea>
            </div>
          </div>
        </mat-radio-group>
      </div>
      <div *ngIf="loadInProgress" class="ordering-dialog__loader">
        <app-loader class="base-loader"></app-loader>
      </div>
    </div>
  </div>
  <div class="dialog__content inline-buttons inline-buttons--right">
    <button (click)="submitClaimDetailsAuthorizationAvailabilityOrdering()"
            class="inline-buttons__button _common-button--rounded"
            [disabled]="loadInProgress || form.invalid"
            mat-flat-button color="primary">submit</button>
  </div>
</div>
