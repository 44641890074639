import { Component } from '@angular/core';

import { SERVICE_ACTION_STATUS_TO_TITLE } from '@core/constants/claim.constans';

@Component({
  selector: 'app-service-status-grid-cell',
  templateUrl: './service-status-grid-cell.component.html',
  styleUrls: ['./service-status-grid-cell.component.scss'],
})
export class ServiceStatusGridCellComponent {
  data: any;
  fieldName: string;

  serviceStatuses = SERVICE_ACTION_STATUS_TO_TITLE;
}
