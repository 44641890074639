import { AppConstants } from '@core/constants/app.constants';
import { TDocumentType } from '@core/enums/document-type';
import { Files } from '@core/interfaces/claims/files.interface';

export const filterPhotoDialogFiles = (files: Files[]) => files.filter(file => {
  const isSafari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
  const isEdge = navigator.userAgent.indexOf('Edge') !== -1;
  const isTif = file.mimeType.indexOf('image/tif') !== -1;
  if (isTif) {
    if (isSafari || isEdge) {
      return AppConstants.fileTypes.images.includes(file.mimeType);
    }
  } else {
    return AppConstants.fileTypes.images.includes(file.mimeType);
  }
});

export const convertReadOnlyToFile = (readOnlyFile: Files, index: number): Files => ({
  originalFile: null,
  fileName: readOnlyFile.fileName,
  id: readOnlyFile.id,
  index,
  mimeType: readOnlyFile.mimeType,
  thumbnailUrl: readOnlyFile.thumbnailUrl,
  viewerUrl: readOnlyFile.viewerUrl,
  url: readOnlyFile.url,
  thumbnailLoaded: true,
  mainLoaded: true,
  thumbnailBase64: '',
  mainBase64: '',
  isReadOnly: true,
  size: 0,
  // docType: readOnlyFile.docType,
  docType: TDocumentType._UploadedReceipt,
  parentId: null,
});
