import { Observable } from 'rxjs';

import { IAppTableCol } from '@shared/modules/table/interfaces/table-col.interface';
import { IAppTableColConfig } from '@shared/modules/table/interfaces/table-col-config.interface';
import { TAppTableCol } from '@shared/modules/table/types/table-col.enum';

import {
  ITableCellBase,
  ITableCellConfig,
  ITableCellCurrency,
  ITableCellDate,
  ITableCellDescription,
  ITableCellLink,
  ITableCellNumber,
  ITableCellRoute,
} from './interfaces/table-cell.interface';
import { TTableCell } from './types/table-cell.enum';

export class ThTableService {
  static buildCol(id: string, title: string, config: IAppTableColConfig = {}): IAppTableCol {
    return { id, title, mobile: true, titleMobile: title, ...config };
  }

  static buildColHidden(id: string, config: IAppTableColConfig = {}): IAppTableCol {
    return { id, title: '', type: TAppTableCol.Hidden, ...config };
  }

  static buildColAction(id: string, title: string, data: any = null, actionActive$: Observable<boolean>): IAppTableCol {
    return { id, title, mobile: true, titleMobile: title, type: TAppTableCol.Action, data, actionActive$ };
  }

  static buildCell(value: number | string, config?: ITableCellConfig): ITableCellBase {
    return {
      type: TTableCell.Default,
      value,
      ...config,
    };
  }

  static buildCellCustom(data: any = {}, config?: ITableCellConfig): ITableCellBase {
    return {
      type: TTableCell.Custom,
      value: null,
      data,
      ...config,
    };
  }

  static buildCellWithDescription(
    value: number | string,
    description: string,
    config?: ITableCellConfig,
  ): ITableCellDescription {
    return {
      type: TTableCell.WithDescription,
      value,
      description,
      ...config,
    };
  }

  static buildCellInline(value: string): ITableCellBase {
    return {
      type: TTableCell.Inline,
      value,
    };
  }

  static buildCellDesktopInline(value: string): ITableCellBase {
    return {
      type: TTableCell.DesktopInline,
      value,
    };
  }

  static buildCellDate(value: number | string): ITableCellDate {
    return {
      type: TTableCell.Date,
      value,
    };
  }

  static buildCellTime(value: number | string): ITableCellDate {
    return {
      type: TTableCell.Time,
      value,
    };
  }

  static buildCellLink(value: string, ref: string, target: string = '_blank'): ITableCellLink {
    return {
      type: TTableCell.Link,
      value,
      ref,
      target,
    };
  }

  static buildCellRoute(value: string, route: string[], icon?: string): ITableCellRoute {
    return {
      type: TTableCell.Route,
      value,
      route,
      icon,
    };
  }

  static buildCellNumber(value: number, format: string = '1.2-4'): ITableCellNumber {
    return {
      type: TTableCell.Number,
      value,
      format,
    };
  }

  static buildCellCurrency(value: number, format?: string): ITableCellCurrency {
    return {
      type: TTableCell.Currency,
      value,
      format,
    };
  }

  static buildCellEmail(value: string): ITableCellBase {
    return {
      type: TTableCell.Email,
      value,
    };
  }

  static buildCellPhone(value: string): ITableCellBase {
    return {
      type: TTableCell.Phone,
      value,
    };
  }

  static buildCellBoolean(value: boolean): ITableCellBase {
    return {
      type: TTableCell.Boolean,
      value,
    };
  }

  static buildCellPercent(value: number): ITableCellBase {
    return {
      type: TTableCell.Percent,
      value,
    };
  }
}
