import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RxIf } from '@rx-angular/template/if';
import { LetDirective } from '@rx-angular/template/let';
import { map } from 'rxjs/operators';

import { PasswordMatchesModel } from '@core/models/password/password-matches.model';
import { PasswordService } from '@core/services/password.service';

@Component({
  selector: 'app-password-matches',
  standalone: true,
  templateUrl: './password-matches.component.html',
  styleUrls: ['./password-matches.component.scss'],
  imports: [NgIf, NgClass, LetDirective, RxIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordMatchesComponent {
  passwordMatches$ = this.passwordService.getPasswordMatches();
  passwordOptions$ = this.passwordService.getPasswordOptions();
  hasRequiredValidators$ = this.passwordMatches$.pipe(
    map((passwordMatches: PasswordMatchesModel) => {
      let match = false;
      for (const key in passwordMatches) {
        if (passwordMatches.hasOwnProperty(key)) {
          if (passwordMatches[key].required) {
            match = true;
            break;
          }
        }
      }
      return match;
    }),
  );
  notAllCriteriaMet$ = this.passwordMatches$.pipe(
    map((passwordMatches: PasswordMatchesModel) => {
      let criteriaMet = false;
      for (const key in passwordMatches) {
        if (passwordMatches.hasOwnProperty(key)) {
          if (passwordMatches[key].required && !passwordMatches[key].match) {
            criteriaMet = true;
            break;
          }
        }
      }
      return criteriaMet;
    }),
  );

  constructor(private passwordService: PasswordService) {
    this.passwordService.fetchPasswordOptions();
  }
}
