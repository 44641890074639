<div class="dialog">
  <div class="dialog__header">
    <h3 class="dialog__title">Do you wish to mark the Settlement as Completed?</h3>
  </div>
  <div class="dialog__content">
    <div class="add-note">
      <label for="noteInput">Please describe how this cash settlement was resolved below:</label>
      <textarea matInput trim="blur" [(ngModel)]="note" maxlength="4000" id="noteInput"></textarea>
    </div>
    <div class="inline-buttons inline-buttons--right">
      <button type="button" [mat-dialog-close]="false" class="inline-buttons__button _common-button--rounded"
              mat-flat-button color="primary">Cancel</button>
      <button type="button" (click)="processCashSettlement()" class="inline-buttons__button _common-button--rounded"
              mat-flat-button color="primary"
              [disabled]="note.trim().length === 0">Mark as Completed</button>
    </div>
  </div>
</div>
