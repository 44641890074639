import { NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { EmailLinkComponent } from '@shared/components/email-link/email-link.component';
import { RetailerInformationComponent } from '@shared/components/retailer-information/retailer-information.component';
import { PipesModule } from '@shared/pipes/pipes.module';

import { DialogCoreModule } from '../dialog-core/dialog-core.module';
import { CancellationStatusComponent } from './cancellation-status/cancellation-status.component';
import { PlanDialogInformationComponent } from './plan-dialog-information/plan-dialog-information.component';
import { ConsumerPlanStatusGridCellComponent } from './plan-status-grid-cell/consumer-plan-status-grid-cell.component';

const components = [PlanDialogInformationComponent, CancellationStatusComponent, ConsumerPlanStatusGridCellComponent];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [DialogCoreModule, EmailLinkComponent, MatIconModule, NgIf, PipesModule, RetailerInformationComponent],
  providers: [],
})
export class BasePlanDetailsModule {}
