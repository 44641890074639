import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grid-price-cell',
  templateUrl: './grid-price-cell.component.html',
  styleUrls: ['./grid-price-cell.component.scss'],
})
export class GridPriceCellComponent implements OnInit {
  data: any;
  fieldName: string;

  constructor() {}

  ngOnInit() {}
}
