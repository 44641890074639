import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { BaseStore } from '../_base/base.store';
import initialState = StoreState.initialState;
import { StoresApi } from '../../api/stores.api';
import { StoreState } from './retailersList.state';
import IStoreState = StoreState.IStoreState;
import { StoreSearchRequest } from '../../models/search/search-request.model';

@Injectable()
export class RetailersListStore extends BaseStore<IStoreState> {
  constructor(private readonly retailersApi: StoresApi) {
    super(initialState);
  }

  init(pageSize: number = 10): void {
    const searchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...searchRequest,
        pageSize,
      },
    });
  }

  loadData(lazy: boolean = false): void {
    this.updateState({ loading: true });
    const searchRequest = this.get('searchRequest');
    this.retailersApi.all(searchRequest).pipe(
      finalize(() => this.updateState({ loading: false })),
    ).subscribe(({ items, totalCount, page, pageCount }) => {
      this.updateState({
        retailers: lazy ? this.get().retailers.concat(items || []) : items || [],
        pagination: {
          totalCount,
          page,
          pageCount,
        },
      });
    });
  }

  updateSearchRequest(searchRequest: Partial<StoreSearchRequest>): void {
    const storeSearchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...storeSearchRequest,
        ...searchRequest,
      },
    });
  }
}
