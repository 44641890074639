import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SecureContentDirective } from './directives/secure-content/secure-content.directive';

const directives = [SecureContentDirective];

@NgModule({
  declarations: [...directives],
  imports: [CommonModule],
  exports: [...directives],
})
export class AccessControlModule {}
