import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { finalize } from 'rxjs/operators';

import { ServiceActionApi } from '@core/api/service-action-api';
import { APP_CONFIG } from '@core/constants/app-config.constants';
import { ClaimServiceAction } from '@core/interfaces/claims/claimDetails.interface';
import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';


interface IShippingLabelActionCellState {
  showShippingLabel: boolean;
  printPackageLoading: boolean;
  printPackageUrl: string;
}

@Component({
  selector: 'app-shipping-label-action-cell',
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    LetDirective,
    LoadingButtonDirective,
    MatTooltipModule,
  ],
  templateUrl: './shipping-label-action-cell.component.html',
  styleUrls: ['./shipping-label-action-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class ShippingLabelActionCellComponent implements OnInit {
  fieldName = 'shippingLabel';
  data: ClaimServiceAction & {shippingLabelUrl: string; regenerateShippingLabel: boolean; claimNumber: string; showShippingLabel: boolean};

  vm$ = this.state.select();

  appConfig = inject(APP_CONFIG);

  constructor(
    private readonly state: RxState<IShippingLabelActionCellState>,
    private readonly serviceActionApi: ServiceActionApi,
  ) {
    this.state.set({
      showShippingLabel: false,
    });
  }

  ngOnInit(): void {
    this.state.set({
      showShippingLabel: this.data.showShippingLabel,
    });
  }

  printPackage(): void {
    this.state.set({
      printPackageLoading: true,
    });
    this.serviceActionApi.printPackage(this.data.id).pipe(
      finalize(() => {
        this.state.set({
          printPackageLoading: false,
        });
      }),
    ).subscribe(blobResult => {
      // parse to blob
      const blob = new Blob([blobResult.body], {
        type: 'application/pdf',
      });

      //  start downloading file with hidden element
      const tempElement = document.createElement('a');
      if (tempElement.download !== undefined) {
        // pass content of blob
        const url = URL.createObjectURL(blob);

        // set temp attributes and fileName, we can have it file name from server
        // it will require a bit work with HttpResponse<Blob>
        tempElement.setAttribute('href', url);
        tempElement.setAttribute('download', `${this.data.claimNumber}_Package_Insert.pdf`);
        tempElement.setAttribute('target', '_self');

        document.body.appendChild(tempElement);
        tempElement.click();

        // clean up
        document.body.removeChild(tempElement);
      }
    });
  }
}
