import { ChangeDetectionStrategy, Component, Input, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { map } from 'rxjs/operators';

import { BaseFormControlElement } from '@core/base/base-form-control-element';
import { Identity } from '@core/interfaces/util/identity.interface';
import { LookupService } from '@core/services/lookup.service';

interface ILanguageSelectState {
  selectedItem: Identity;
}

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectComponent extends BaseFormControlElement implements OnInit {
  @Input() readonly = false;

  itemsList$ = this.lookupService.getLookup('language');
  selectedItemName$ = this.state.select('selectedItem', 'name');

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private readonly lookupService: LookupService,
    private readonly state: RxState<ILanguageSelectState>,
  ) {
    super(ngControl);
  }

  ngOnInit() {
    super.ngOnInit();
    this.state.connect(
      'selectedItem',
      this.itemsList$.pipe(map(items => items.find(item => item.id === this.formControl.value))),
    );
  }
}
