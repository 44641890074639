import { InjectionToken } from '@angular/core';

import { TConsumerPlanType } from '@core/enums/consumer-plan-type.enum';
import { TDocumentType } from '@core/enums/document-type';
import { ProductStatus } from '@core/enums/product-status.enum';
import { IDropArea } from '@core/interfaces/claims/files.interface';
import { ISideDialogConfig } from '@core/interfaces/side-dialog.interface';
import { TCommonFieldSize } from '@shared/components/form-field/common-field-size.enum';

import { ClaimStages } from '../enums/claim/claim-stages.enum';
import { ClaimStatuses } from '../enums/claim/claim-statuses.enum';
import { TServiceActionTileStage } from '../enums/service-action/service-action-tile-stage.enum';

export const AppConstants = {
  claimStatuses: {
    [ClaimStatuses.Pending]: 'Pending',
    [ClaimStatuses.Open]: 'Open',
    [ClaimStatuses.Closed]: 'Closed',
    // [ClaimStatuses.Cancelled]: 'Cancelled'
  },
  claimTypes: {
    [TConsumerPlanType.MyService]: 'MyS',
    [TConsumerPlanType.ProtectionPlan]: 'PP',
    [TConsumerPlanType.Unsupported]: '-',
  },
  claimStages: {
    [ClaimStages.Pending]: 'Pending',
    [ClaimStages.Intake]: 'Intake',
    [ClaimStages.DocumentationPending]: 'Documentation Pending',
    [ClaimStages.Validation]: 'Validation',
    [ClaimStages.Servicing]: 'Servicing',
    [ClaimStages.NotCovered]: 'Not Covered',
    [ClaimStages.UnderReview]: 'Under Review',
    [ClaimStages.DocumentationReview]: 'Documentation Review',
    [ClaimStages.AgreementValidation]: 'Agreement Validation',
    [ClaimStages.NA]: 'Unknown/Not Applicable',
    [ClaimStages.Complete]: 'Complete',
  },
  claimCommunicationActivityType: {
    1: 'Email',
    2: 'Letter',
    3: 'Phone Call',
    4: 'Fax',
  },
  emailRegEx:
  // eslint-disable-next-line no-control-regex
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i, // eslint-disable-line max-len
  vanityUrlPattern: /^(?![0-9]+$)(?!.*-$)(?!-)[a-zA-Z0-9-]{1,63}$/g,
  VALIDATION_REGEX: {
    url: new RegExp(
      '^(https:\\/\\/)' // protocol
        + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
        + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
        + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
        + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
        + '(\\#[-a-z\\d_]*)?$',
      'i',
    ),
  },
  fileTypes: {
    images: ['image/jpeg', 'image/jpg', 'image/png', 'image/tiff', 'image/tif', 'image/bmp', 'image/svg+xml'],
    documents: [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
  },
  fileFormatToType: {
    '.jpeg': 'image/jpeg',
    '.jpg': 'image/jpg',
    '.png': 'image/png',
    '.tiff': 'image/tiff',
    '.tif': 'image/tif',
    '.bmp': 'image/bmp',
    'svg+xml': 'image/svg',
    '.pdf': 'application/pdf',
    '.doc': 'application/msword',
    '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  maxFilesSize: 50000000,
  minFilesCount: 3,
  maxFilesCount: 4,
  dirtyFormMessage: 'There are unsaved changes. Do you really want to leave the page?',
  minDateDatepicker: new Date(1753, 0, 1),
  serviceActionTileStage: 'serviceActionTileStage',
  serviceActionTileStages: {
    [TServiceActionTileStage.OrderParts]: 'Order Parts',
    [TServiceActionTileStage.OrderReplacements]: 'Confirm Availability',
    [TServiceActionTileStage.EnterDelivery]: 'Enter Delivery/Request Payment',
    [TServiceActionTileStage.ExpiringSoon]: 'POs Expiring within 30 days',
    [TServiceActionTileStage.ConfirmAvailability]: 'Confirm Availability',
    [TServiceActionTileStage.ReadyToShip]: 'Ready to Ship',
    [TServiceActionTileStage.RepairAuthorized]: 'Repair Authorized',
    [TServiceActionTileStage.ReplacementAuthorized]: 'Replacement Authorized',
    [TServiceActionTileStage.ClarificationRequests]: 'Clarification Requests',
    accept: 'to accept',
    schedule: 'to schedule',
    submit: 'to submit',
    invoice: 'invoice to submit',
    submitTechReport: 'to submit tech report',
    prequals: 'prequals to complete',
  },
  fieldNameMaximizedAuthorizedAmount: 'authorizedAmount',
  fieldNameRequestedAmount: 'requestedAmount',
};

export const DIALOG_ROUTE_CONFIG = new InjectionToken<ISideDialogConfig>('Config for route as dialog component');

export const ALLOWED_STATUSES = [
  ProductStatus.Active,
  ProductStatus.PartiallyRefunded,
  ProductStatus.PendingVerification,
];

export const DEFAULT_APP_LOADING_ERROR_MESSAGE =
  'Oops! There was an error contacting the Portal, please click Refresh or press F5 to try again.';

export const RECEIPT_FILES_DROP_AREA: IDropArea = {
  header: 'Receipt',
  labelDescription: 'Drag and drop receipt here',
  labelDescriptionForMobile: 'Select a receipt',
  docType: TDocumentType.Receipt,
  svgIcon: 'img-receipt',
  maxFilesNumber: null,
  sectionSize: TCommonFieldSize.Medium,
};

export const CLAIM_FILES_DROP_AREA: IDropArea = {
  header: 'Claim Files: Photos & Documents',
  labelDescription: 'Drag and drop photos here',
  labelDescriptionForMobile: 'Select photos',
  docType: TDocumentType.CustPhoto,
  svgIcon: 'img-claimphotosdocuments',
  maxFilesNumber: null,
};

export const PRODUCT_FILES_DROP_AREA: IDropArea = {
  header: 'Product Photo(s)',
  labelDescription: 'Drag and drop photos here',
  labelDescriptionForMobile: 'Select photos',
  docType: TDocumentType.RetailerPortalUploadedPhoto,
  svgIcon: 'img-claimphotosdocuments',
  maxFilesNumber: null,
  sectionSize: TCommonFieldSize.Large,
};

export const DEFAULT_FILES_DROP_AREA: IDropArea = {
  header: '',
  labelDescription: 'Drag and drop files here',
  labelDescriptionForMobile: 'Select files',
  docType: null,
  svgIcon: 'picture',
  maxFilesNumber: null,
};

interface TErrorMessageFnObj {
  [key: string]: (errorObj: unknown, { label, dataType }: IErrorMessageFnOptions) => string;
}

export interface IErrorMessageFnOptions {
  label?: string;
  dataType?: string;
}

interface ILengthError {
  requiredLength: number;
}

interface IMaxValueError {
  max: number;
}

interface IMinValueError {
  min: number;
}

interface IServerError {
  serverError: string;
}
interface IFirstLastNameError {
  minNameLength: number;
}

export const DEFAULT_ERROR_MESSAGES: TErrorMessageFnObj = {
  required: (errorObj, { label }) => `${label} is required`,
  pattern: (errorObj, { label }) => `${label} format is invalid`,
  maxlength: (errorObj: ILengthError, { dataType }) =>
    `Max field length is ${errorObj.requiredLength} ${dataType === 'only-number' ? 'digits' : 'symbols'}.`,
  minlength: (errorObj: ILengthError, { dataType }) =>
    `Min field length is ${errorObj.requiredLength} ${dataType === 'only-number' ? 'digits' : 'symbols'}.`,
  exactLength: (errorObj: ILengthError, { dataType }) =>
    `Field should contain ${errorObj.requiredLength} ${dataType === 'only-number' ? 'digits' : 'symbols'}.`,
  max: (errorObj: IMaxValueError) => `Max value is ${errorObj.max}`,
  min: (errorObj: IMinValueError) => `Minimum value is ${errorObj.min}`,
  serverError: (errorObj: IServerError) => errorObj.serverError,
  fieldMustBeVerified: (errorObj, { label }) => `${label} must be verified before use.`,
  firstLastNameInvalid: (errorObj: IFirstLastNameError, { label }) => {
    const letterPlural = errorObj.minNameLength === 1 ? 'letter' : 'letters';
    return `${label} must start with a letter and contain at least ${errorObj.minNameLength} ${letterPlural}`;
  },
};

export const DEFAULT_ERROR_MESSAGE_FN = (): null => null;

export const TIFF_FILE_PLACEHOLDER = '/assets/icons/tiff.svg';
