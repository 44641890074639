import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RxIf } from '@rx-angular/template/if';
import { PushPipe } from '@rx-angular/template/push';

import { AnchorNavigation } from '@core/interfaces/anchor-navigation/anchor-navigation.interface';

@Component({
  selector: 'app-anchor-nav',
  standalone: true,
  templateUrl: './anchor-nav.component.html',
  styleUrls: ['./anchor-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgForOf,
    NgIf,
    AsyncPipe,
    RxIf,
    PushPipe,
  ],
})
export class AnchorNavComponent {
  @Input() navigationList: AnchorNavigation[] = [];
  @Input() currentAnchor: string;
  @Input() displayTitles = false;
  @Input() allowMultirowTitle = false;
  @Output() anchorClick: EventEmitter<string> = new EventEmitter<string>();

  scrollToAnchor(anchor: string): void {
    this.anchorClick.emit(anchor);
  }
}
