export enum DateKindProtectionPlan {
  /// <summary>
  /// Open date
  /// </summary>
  RegistrationDate = 1,

  /// <summary>
  /// Purchase Date
  /// </summary>
  PurchaseDate = 2,

  /// <summary>
  /// Delivery date
  /// </summary>
  DeliveryDate = 3,

  /// <summary>
  /// Invoice date of plan
  /// </summary>
  InvoiceDate = 4,

  /// <summary>
  /// Return date of plan
  /// </summary>
  ReturnDate = 5,
}
