import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appEmptyValue',
})
export class EmptyValuePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (!!value || value === 0) {
      return value;
    } else {
      return '-';
    }
  }
}
