import { Component, OnInit } from '@angular/core';

import { UserRoles } from '@core/enums/user-roles.enum';
import { CoveredProducts } from '@core/interfaces/claims/claimDetails.interface';
import { UserStore } from '@core/store/user/user.store';

@Component({
  selector: 'app-product-problems',
  templateUrl: './product-incident-problems.component.html',
  styleUrls: ['./product-incident-problems.component.scss'],
})
export class ProductIncidentProblemsComponent implements OnInit {
  session = this.userStore.get('session');
  data: CoveredProducts;
  fieldName: string[];
  tooltipText = '';
  labels = ['Reported: ', 'Verified: ', 'Classification: '];
  public userRoles = UserRoles;

  constructor(private readonly userStore: UserStore) {}

  ngOnInit() {
    this.fieldName.forEach((field, i) => {
      if (i !== 2) {
        this.tooltipText += `${this.labels[i]} ${this.data[field] ? this.data[field] : '-'}\n\n`;
      } else {
        if (this.session.showRetailerClassification && this.session.roles.indexOf(this.userRoles.Consumer) === -1) {
          this.tooltipText += `${this.labels[i]} ${this.data[field] ? this.data[field] : '-'}\n\n`;
        }
      }
    });
  }
}
