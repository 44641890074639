import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[appAltTable]',
  standalone: true,
})
export class AltTableDirective {

  @HostBinding('style') get altTableCssConfig(): any {
    return {
      '--th-table-header-background': '#f2f3f8',
      '--th-table-header-border-color': '#e0e0e0',
      '--th-table-border': 'none',
      '--th-table-border-radius': '0',
      '--th-table-row-even-background-color': '#f8f8f9',
      '--th-table-cell-font-weight': '400',
      '--th-table-row-header-font-size': '14px',
      '--th-table-row-header-font-weight': '400',
      '--th-table-cell-color': 'var(--color-text-primary)',
      '--th-table-box-shadow': 'none',
      '--th-table-header-border-top-size': '0',
      '--th-table-header-cell-color': 'var(--color-text-primary)',
    };
  }

}
