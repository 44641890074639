import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Retailer } from '../../../core/interfaces/claims/claimDetails.interface';

@Component({
  selector: 'app-retailer-information',
  standalone: true,
  templateUrl: './retailer-information.component.html',
  styleUrls: ['./retailer-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
  ],
})
export class RetailerInformationComponent implements OnInit {
  @Input() retailer: Retailer;

  constructor() {}

  ngOnInit() {}
}
