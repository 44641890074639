export enum ExportSelections {
  /// <summary>
  /// Export the current page
  /// </summary>
  CurrentPage = 1,
  /// <summary>
  /// Export all pages matching the current filter
  /// </summary>
  AllPages = 2,
  /// <summary>
  /// Export only selected ids
  /// </summary>
  SelectedRows = 3,
  /// <summary>
  /// Export only selected ids
  /// </summary>
  ExportTopN = 4,
}
/// <summary>
/// Grid export kinds
/// </summary>
export enum ExportKinds {
  /// <summary>
  /// Export to XLSX
  /// </summary>
  Excel = 1,
  /// <summary>
  /// Export to CSV
  /// </summary>
  CSV = 2,
}
