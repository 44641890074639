import { HttpStatusCode } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable,Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { NotificationService } from '@shared/modules/notification/services/notification.service';
import { ARGBToRgbaPipe } from '@shared/pipes/ahex-to-rgba.pipe';

import { TenantApi } from '../api/tenant.api';
import { SplashLayouts } from '../enums/layout.enum';
import { SplashLogoAlignments } from '../enums/logo-alignment.enum';
import { Theme, ThemeColors } from '../interfaces/ui/theme.interface';

@Injectable({
  providedIn: 'root',
})
export class ThemingService {
  appConfig = inject(APP_CONFIG);
  theme: Theme;
  themeSubject$: Subject<Theme> = new Subject<Theme>();

  constructor(
    private tenantApi: TenantApi,
    private argbToRgba: ARGBToRgbaPipe,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
  ) {}

  for(tenant: string | number): Observable<any> {
    return this.tenantApi.info(tenant).pipe(
      map(theme => {
        theme = {
          ...theme,
          ...this.parseThemeColors(theme),
        };
        this.setTheme(theme);
        return true;
      }),
      catchError(error => {
        if (error.status === HttpStatusCode.NotFound && tenant !== this.appConfig.commonTenantName) {
          // this.notificationService.next({
          //   message: `Tenant ${tenant} not found`,
          //   type: NotificationType.Error,
          // });
          this.router.navigate([`/${this.appConfig.commonTenantName}/login`]);
        }
        return EMPTY;
      }),
    );
  }

  forClaimTenantInfo(theme: Theme) {
    const parsedTheme = {
      ...theme,
      ...this.parseThemeColors(theme),
    };
    this.setTheme(parsedTheme);
    return true;
  }

  getTheme(): Theme {
    return this.theme;
  }

  setTheme(theme: any) {
    this.theme = this.parseTheme(theme);
    this.themeSubject$.next(this.theme);
  }

  parseTheme(theme: any) {
    return {
      ...theme,
      ...this.parseThemeColors(theme),
      themeClass: this.getTenantThemeClass(theme.splashLayout),
      splashStyle: this.getSplashStyle(theme),
      logoStyles: this.getLogoStyles(theme),
    };
  }

  parseThemeColors(theme: Partial<Theme>): Partial<ThemeColors> {
    const themeColors: Partial<ThemeColors> = {};
    if (theme.accentColorARGB) {
      const hexAccentColor = theme.accentColorARGB.substring(2, theme.accentColorARGB.length);
      themeColors.hexAccentColor = `#${hexAccentColor}`;
      themeColors.rgbaAccentColor = this.argbToRgba.transform(theme.accentColorARGB);
      themeColors.rgbaAccentColor05 = this.argbToRgba.transform(theme.accentColorARGB, '0.05');
      themeColors.rgbaAccentColor38 = this.argbToRgba.transform(theme.accentColorARGB, '0.38');
    }

    if (theme.secondaryAccentColorARGB) {
      const hexSecondaryAccentColor = theme.secondaryAccentColorARGB.substring(
        2,
        theme.secondaryAccentColorARGB.length,
      );
      themeColors.hexSecondaryAccentColor = `#${hexSecondaryAccentColor}`;
    }

    if (theme.bgColorARGB) {
      themeColors.rgbaBgColor = this.argbToRgba.transform(theme.bgColorARGB);
    }
    return themeColors;
  }

  setThemeVariables(theme: Theme, element: HTMLElement) {
    element.style.setProperty('--color-primary', theme.hexAccentColor);
    element.style.setProperty('--color-primary-rgba', theme.rgbaAccentColor);
    element.style.setProperty('--color-primary-rgba-05', theme.rgbaAccentColor05);
    element.style.setProperty('--color-primary-rgba-38', theme.rgbaAccentColor38);
    element.style.setProperty('--color-public-bg-rgba', theme.rgbaBgColor);
    element.style.setProperty('--color-secondary', theme.hexSecondaryAccentColor);
  }

  private getTenantThemeClass(layoutType: SplashLayouts) {
    if (layoutType === SplashLayouts.WideRightJustified) {
      return 'wide-right-justified';
    } else if (layoutType === SplashLayouts.NormalRightJustified) {
      return 'normal-right-justified';
    } else if (layoutType === SplashLayouts.NormalWithRightMargin) {
      return 'normal-with-right-margin';
    } else {
      return '';
    }
  }

  private getLogoStyles(theme: any) {
    const position = this.returnLogoPosition(theme.splashLogoAlignment);
    const useWhiteBg = { backgroundColor: theme.useWhiteBgOnSplash ? '#fff' : 'transparent' };
    return Object.assign(position, useWhiteBg);
  }

  private getSplashStyle(theme: Theme): {backgroundImage: string} {
    let style = null;
    if (theme.splashUrl) {
      style = { backgroundImage: `url(${theme.splashUrl})` };
    } else {
      style = { backgroundImage: 'url(assets/splash/splash-2.jpeg)' };
    }
    return style;
  }

  private returnLogoPosition(logoAlignment: SplashLogoAlignments) {
    switch (logoAlignment) {
      case SplashLogoAlignments.Left:
        return { justifyContent: 'flex-start', alignItems: 'center' };
      case SplashLogoAlignments.Center:
        return { justifyContent: 'center', alignItems: 'center' };
      case SplashLogoAlignments.Right:
        return { justifyContent: 'flex-end', alignItems: 'center' };
      case SplashLogoAlignments.TopLeft:
        return { justifyContent: 'flex-start', alignItems: 'flex-start' };
      case SplashLogoAlignments.TopCenter:
        return { justifyContent: 'center', alignItems: 'flex-start' };
      case SplashLogoAlignments.TopRight:
        return { justifyContent: 'flex-end', alignItems: 'flex-start' };
      default:
        return {};
    }
  }

  public getTextColorBasedOnBackground(bgColor: string) {
    const r = parseInt(bgColor.substring(0, 2), 16);
    const g = parseInt(bgColor.substring(2, 4), 16);
    const b = parseInt(bgColor.substring(4, 6), 16);

    const factor = Math.sqrt(r * r * 0.241 + g * g * 0.691 + b * b * 0.068);

    return factor < 130 ? '#FFFFFF' : '#000000';
  }
}
