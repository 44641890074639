import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, ElementRef, Inject, inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { PushPipe } from '@rx-angular/template/push';
import { Options,Splide } from '@splidejs/splide';
import { filter, Subject } from 'rxjs';
import { delay, finalize, map, take } from 'rxjs/operators';

import { DocumentApi } from '@core/api/document.api';
import { TechWorkOrdersApi } from '@core/api/tech-work-orders.api';
import { DestroyableComponent } from '@core/classes/destroyable-component';
import { AppConstants } from '@core/constants/app.constants';
import { APP_CONFIG } from '@core/constants/app-config.constants';
import { Feature } from '@core/enums/feature.enum';
import { TPage } from '@core/enums/page.enum';
import { TPortalType } from '@core/enums/portal-type.enum';
import { IAppConfig } from '@core/interfaces/app-config.interface';
import { Files } from '@core/interfaces/claims/files.interface';
import { PromptDialogService } from '@core/services/dialog/prompt-dialog.service';
import { filterPhotoDialogFiles } from '@core/utils/file.util';
import { ImageErrorDirective } from '@shared/directives/image-error.directive';
import { CarouselModule } from '@shared/modules/carousel/carousel.module';
import { FilesPreviewComponent } from '@shared/modules/files/components/files-preview/files-preview.component';
import { FileViewerUrlPipe } from '@shared/modules/side-dialog/file-viewer-url.pipe';

interface IPhotoDialogState {
  files: Files[];
  photos: Files[];
  photosLoading: boolean;
  enableDelete: boolean;
  enableDownload: boolean;
  carouselCreated: boolean;
  startPosition: number;
  activeFile: Files;
}

@Component({
  selector: 'app-photo-dialog',
  standalone: true,
  templateUrl: './photo-dialog.component.html',
  styleUrls: ['./photo-dialog.component.scss'],
  providers: [RxState],
  encapsulation: ViewEncapsulation.None,
  imports: [
    LetDirective,
    NgIf,
    AsyncPipe,
    NgClass,
    MatIconModule,
    CarouselModule,
    FileViewerUrlPipe,
    MatProgressSpinnerModule,
    NgForOf,
    PushPipe,
    ImageErrorDirective,
    FilesPreviewComponent,
  ],
})
export class PhotoDialogComponent extends DestroyableComponent implements OnInit, OnDestroy {
  photos$ = this.state.select('photos');
  photosLoading$ = this.state.select('photosLoading');
  enableDelete$ = this.state.select('enableDelete');
  enableDownload$ = this.state.select('enableDownload');
  carouselCreated$ = this.state.select('carouselCreated').pipe(delay(180));
  isActivePhotoNotReadOnly$ = this.state.select('activeFile').pipe(map(activeFile => !activeFile.isReadOnly));
  deleteFileByIndex$ = new Subject<number>();

  Feature = Feature;
  TPortalType = TPortalType;

  fileTypes = AppConstants.fileTypes;
  mainCarousel: Splide;
  carouselOptions: Options = {
    type: 'fade',
    pagination: false,
    keyboard: false,
    arrows: false,
    drag: false,
  };
  mainCarouselCreated$ = new Subject<Splide>();
  photoLoaded$ = new Subject<{id: number}>();
  promptDialogService = inject(PromptDialogService);
  private readonly data: {
    file: Files;
    files: Files[];
    dateRange: any;
    enableDelete: boolean;
  } = inject(MAT_DIALOG_DATA);
  @ViewChild('thumbnailCarousel') thumbnailCarousel: ElementRef;

  private removedIndexes: number[] = [];

  constructor(
    public dialogRef: MatDialogRef<PhotoDialogComponent>,
    private readonly documentsApiService: DocumentApi,
    private readonly techWorkOrdersApi: TechWorkOrdersApi,
    public router: Router,
    private readonly state: RxState<IPhotoDialogState>,
    @Inject(APP_CONFIG) public readonly appConfig: IAppConfig,
  ) {
    super();
    this.state.set({
      files: [],
      photos: [],
      photosLoading: false,
      carouselCreated: false,
      activeFile: null,
    });

    this.state.connect('photos', this.state.select('files').pipe(map(filterPhotoDialogFiles)));

    this.state.connect(
      'startPosition',
      this.state.select('files').pipe(
        filter(files => files.length > 0),
        map(files => filterPhotoDialogFiles(files).findIndex(photo => photo.index === this.data.file.index)),
        take(1),
      ),
    );

    this.state.connect('photos', this.photoLoaded$, (oldState, photoLoaded) => oldState.photos.map(photo => {
      if (photoLoaded.id === photo.id) {
        photo.mainLoaded = true;
      }
      return photo;
    }));

    this.state.connect('files', this.deleteFileByIndex$, (oldState, fileIndex) => {
      this.removedIndexes.push(fileIndex);
      return oldState.files.filter(photo => photo.index !== fileIndex);
    });
  }

  ngOnInit(): void {
    this.state.set({
      files: [...this.data.files],
      enableDelete: this.data.enableDelete,
    });
  }

  ngOnDestroy(): void {
    this.mainCarousel.destroy();
  }

  rotatePhoto() {
    if (!this.isPhotosLoading) {
      const photo = document.getElementById('m' + this.mainCarousel.index) as HTMLImageElement;
      let rotate = 0;
      if (!photo.style.transform) {
        rotate = rotate - 90;
        photo.style.transform = `rotate(${rotate}deg)`;
      } else {
        rotate = +photo.style.transform.replace('rotate(', '').replace('deg)', '') - 90;
        photo.style.transform = `rotate(${rotate}deg)`;
      }
      const even = (rotate / 90) % 2 === 0;
      if (photo.naturalWidth > photo.naturalHeight) {
        if (!even) {
          photo.style.width = photo.clientHeight + 'px';
        } else {
          photo.style.width = 'auto';
        }
      }
    }
  }

  fileLoaded(e: Event, file: Files) {
    this.photoLoaded$.next({
      id: file.id,
    });
  }

  carouselMounted(carousel: Splide) {
    const startPosition = this.state.get('startPosition');
    this.mainCarouselCreated$.pipe(take(1)).subscribe(mainCarousel => {
      this.mainCarousel = mainCarousel;
      this.mainCarousel.sync(carousel).mount();
      this.mainCarousel.go(startPosition);
    });
  }

  carouselCreated(carousel: Splide) {
    const startPosition = this.state.get('startPosition');
    if (startPosition === 0) {
      this.mainCarouselIndexChanged(0);
    }
    this.mainCarouselCreated$.next(carousel);
    this.state.set({
      carouselCreated: true,
    });
  }

  async downloadFile() {
    if (!this.isPhotosLoading) {
      const photos = this.state.get('photos');
      const response = await fetch(photos[this.mainCarousel.index].url);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = photos[this.mainCarousel.index].fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }

  deleteFile() {
    if (!this.isPhotosLoading) {
      this.promptDialogService.openPrompt({
        message: '<span>Are you sure you wish to delete this file?</span>',
        buttonNo: 'No',
        buttonYes: 'Yes',
      }).afterClosed().subscribe(success => {
        if (success) {
          const photos = this.state.get('photos');
          const carouselIndex = this.mainCarousel.index;
          const fileId = photos[carouselIndex].id;
          const fileIndex = photos[carouselIndex].index;
          if (fileId) {
            this.deleteRequest(fileId, fileIndex);
          } else {
            this.deleteFileByIndex$.next(fileIndex);
          }
        }
      });
    }
  }

  updatePhotosAfterDeletion(updatedPhotos: Files[]) {
    if (updatedPhotos.length === 0) {
      this.closeDialog();
    }
  }

  deleteRequest(fileId: number, fileIndex: number) {
    if (fileId) {
      this.state.set({
        photosLoading: true,
      });
      if (this.appConfig.portalType === TPortalType.TechPortal) {
        this.deleteRequestUsingTechWorkOrdersApi(fileId, fileIndex);
      } else {
        this.deleteRequestUsingDocumentsApi(fileId, fileIndex);
      }
    }
  }

  deleteRequestUsingTechWorkOrdersApi(fileId: number, fileIndex: number) {
    let workOrderId: number;
    if (this.router.url.includes(`${TPage.WorkOrders}`) && this.router.url.includes(`${TPage.TechReport}`)) {
      workOrderId = +this.router.url.split(`/${TPage.WorkOrders}/`)[1].split(`/${TPage.TechReport}`)[0];
    }
    this.techWorkOrdersApi
      .deleteTechReportFile(workOrderId, fileId)
      .pipe(
        finalize(() => {
          this.state.set({
            photosLoading: false,
          });
        }),
      )
      .subscribe(() => {
        this.successHandlerForDeleteRequest(fileId, fileIndex);
      });
  }

  deleteRequestUsingDocumentsApi(fileId: number, fileIndex: number) {
    this.documentsApiService
      .deleteFileRequest(fileId)
      .pipe(
        finalize(() => {
          this.state.set({
            photosLoading: false,
          });
        }),
      )
      .subscribe(() => {
        this.successHandlerForDeleteRequest(fileId, fileIndex);
      });
  }

  private successHandlerForDeleteRequest(fileId: number, fileIndex: number) {
    const updatedPhotos = this.state.get('photos').filter(file => file.id !== fileId);
    this.updatePhotosAfterDeletion(updatedPhotos);
    this.deleteFileByIndex$.next(fileIndex);
  }

  closeDialog() {
    this.dialogRef.close({
      removedIndexes: this.removedIndexes,
    });
  }

  get isPhotosLoading(): boolean {
    return this.state.get('photosLoading');
  }

  mainCarouselIndexChanged(index: number) {
    const photos = this.state.get('photos');
    const currentPhoto = photos[index];
    this.state.set({
      enableDownload: !!currentPhoto.id || !!currentPhoto.url,
      activeFile: currentPhoto,
    });
  }
}
