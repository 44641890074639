<div class="option-as-table__option-data">
  <div class="option-as-table__option--{{column.fieldName}}" *ngFor="let column of columns"
       [title]="item['name'] || item['parentPlanName']">
    <ng-container *ngIf="column.fieldName === 'upTo'; else defaultFormatting">
      {{item[column.fieldName] | currency}}
    </ng-container>
    <ng-template #defaultFormatting>
      {{item[column.fieldName]}}
    </ng-template>
  </div>
</div>
