<app-form-select [formControl]="formControl"
                 [data]="problemTypes$ | push"
                 [loading]="loading$ | push"
                 [pagination]="pagination$ | push"
                 [label]="label"
                 [required]="required"
                 [showSearch]="true"
                 [loadOnScroll]="true"
                 (selectSearch)="problemTypeSearch($event)"
                 (itemSelected)="typeSelected($event)"
                 (selectLoadNextPage)="loadNextPage($event)"></app-form-select>
