import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component,inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';

@Component({
  selector: 'app-license-florida-dialog',
  standalone: true,
  templateUrl: './license-florida-dialog.component.html',
  styleUrls: ['./license-florida-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CustomOverlayScrollbarDirective,
    NgIf,
    MatIconModule,
  ],
})
export class LicenseFloridaDialogComponent {
  contactEmail = 'salessupport@montagefs.com';
  dialogData: {
    data: {
      allRetailersDontHaveFloridaLicenses: boolean;
    };
  } = inject(MAT_DIALOG_DATA);

  constructor(
    private readonly dialogRef: MatDialogRef<any>,
  ) {}

  close() {
    this.dialogRef.close(null);
  }
}
