export enum SettlementType {
  /// <summary>
  /// Gift card
  /// </summary>
  GiftCard = 1,
  /// <summary>
  /// A discount
  /// </summary>
  Discount = 2,
  /// <summary>
  /// A check
  /// </summary>
  Check = 3,
}
