<ng-container *ngIf="formControlRef.hasError('required')">
  {{ label }}
  <span *ngIf="showIsRequiredMessage">is required</span>
  .
</ng-container>
<mat-error *ngIf="formControlRef.hasError('maxlength')">
  Max field length is {{ formControlRef.errors.maxlength.requiredLength }}
  {{ dataType === "only-number" ? "digits" : "symbols" }}.
</mat-error>
<mat-error *ngIf="formControlRef.hasError('minlength')">
  Min field length is {{ formControlRef.errors.minlength.requiredLength }}
  {{ dataType === "only-number" ? "digits" : "symbols" }}.
</mat-error>
<mat-error *ngIf="formControlRef.hasError('exactLength')">
  Field should contain {{ formControlRef.errors.exactLength.requiredLength }} symbols.
</mat-error>
<mat-error *ngIf="formControlRef.hasError('minLengthWithoutSpace')">
  You must enter at least five characters in order to search.
</mat-error>
<mat-error *ngIf="formControlRef.hasError('max')">Max value is {{ formControlRef.errors.max.max }}</mat-error>
<mat-error *ngIf="formControlRef.hasError('serverError')">
  {{ formControlRef.errors.serverError }}
</mat-error>
<mat-error *ngIf="formControlRef.hasError('fieldMustBeVerified')">
  {{ label }} must be verified before use.
</mat-error>
