<mat-form-field
  class="form-date"
  [color]="'primary'"
  [class._common-mat-form-field_hide-errors]="!formFieldProvider.showErrors"
  [class._common-theme-field]="!!formControl.value">
  <mat-label *ngIf="label">
    {{ label }}
  </mat-label>
  <input
    matInput
    [matDatepicker]="picker"
    [autocomplete]="autocomplete"
    [max]="max"
    [min]="min"
    [formControl]="formControl"
    [readonly]="readonly"
    [placeholder]="placeholder"
    (click)="reOpenCalendar()"
    (focus)="reOpenCalendar()"
    [matDatepickerFilter]="datePickerFilter" />
  <div
    *ngIf="tooltipText"
    class="tooltip tooltip__datepicker">
    <mat-icon
      class="tooltip__icon _common-icon--theme"
      svgIcon="help_outline"></mat-icon>
    <span class="tooltip__text">{{ tooltipText }}</span>
  </div>
  <div
    *ngIf="tooltipAttentionText"
    class="tooltip tooltip__datepicker">
    <mat-icon
      class="tooltip__icon _common-icon--theme"
      svgIcon="attention"></mat-icon>
    <span class="tooltip__text">{{ tooltipAttentionText }}</span>
  </div>
  <mat-icon
    class="form-date__error-icon"
    [class.form-date__error-icon-shift-left]="tooltipText"
    *ngIf="formControl.status === 'INVALID' && formControl.touched"
    matSuffix
    svgIcon="error-icon"></mat-icon>

  <mat-datepicker-toggle
    *ngIf="appConfig.portalType === TPortalType.TechPortal"
    matPrefix
    [for]="picker">
    <mat-icon
      *ngIf="icon === 'date-range'"
      class="material-icons-outlined"
      matDatepickerToggleIcon>
      date_range
    </mat-icon>
    <mat-icon
      *ngIf="icon === 'schedule'"
      class="form-date__icon"
      matDatepickerToggleIcon>
      today
    </mat-icon>
    <mat-icon
      *ngIf="icon === 'reschedule'"
      svgIcon="img-reschedule"
      class="form-date__icon"
      matDatepickerToggleIcon></mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker-toggle
    class="form-date__icon-toggle"
    *ngIf="appConfig.portalType !== TPortalType.TechPortal"
    matSuffix
    [for]="picker">
    <mat-icon
      svgIcon="calendar-icon"
      class="form-date__icon"
      matDatepickerToggleIcon></mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-hint>{{ hint }}</mat-hint>
  <mat-error>
    <app-form-error
      [formControlRef]="formControl"
      [label]="label"></app-form-error>
  </mat-error>
  <mat-error *ngIf="formControl.hasError('dateIsNotValid')">{{ label }} is invalid.</mat-error>
  <mat-error *ngIf="!formControl.hasError('dateIsNotValid') && formControl.hasError('maxDate')">
    {{ label }} should be before
    {{ formControl.errors.maxDate.maxDate | date : "M/dd/yyyy" | dateDayPlusOne : "MM/DD/YYYY" }}.
  </mat-error>
  <mat-error
    *ngIf="
      !formControl.hasError('dateIsNotValid') &&
      !customMaxDateValidation &&
      formControl.hasError('matDatepickerMax')
    ">
    {{ label }} should be before
    {{
      formControl.errors.matDatepickerMax.max.toDate() | date : "M/dd/yyyy" | dateDayPlusOne : "MM/DD/YYYY"
    }}.
  </mat-error>
  <mat-error
    *ngIf="
      !formControl.hasError('dateIsNotValid') &&
      !customMinDateValidation &&
      formControl.hasError('matDatepickerMin')
    ">
    {{ label }} should be after
    {{
      formControl.errors.matDatepickerMin.min.toDate() | date : "M/dd/yyyy" | dateDayMinusOne : "MM/DD/YYYY"
    }}.
  </mat-error>
  <mat-error
    *ngIf="
      !formControl.hasError('dateIsNotValid') &&
      type === TDateFieldType.MinMaxDate &&
      (formControl.hasError('matDatepickerMin') || formControl.hasError('matDatepickerMax'))
    ">
    {{ label }} should be from {{ min | date : "M/dd/yyyy" }} till {{ max | date : "M/dd/yyyy" }}.
  </mat-error>
  <mat-error *ngIf="type === TDateFieldType.MyPlanPurchaseDate && formControl.hasError('matDatepickerMax')">
    {{ label }} cannot be later than delivery date.
  </mat-error>
  <mat-error *ngIf="type === TDateFieldType.MyPlanDeliveryDate && formControl.hasError('matDatepickerMin')">
    {{ label }} cannot be earlier than purchase date.
  </mat-error>
  <mat-error *ngIf="formControl.hasError('matDatepickerFilter')">Selected date is not allowed.</mat-error>
</mat-form-field>
