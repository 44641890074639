<div class="pagination">
  <ng-container *ngIf="hidePages; else paginationWithPages">
    <div *ngIf="hidePages; else paginationWithPages" class="pagination__controls">
      <div class="pagination__previous">
        <button (click)="first()"
                [disabled]="pagination.page === 1"
                [ngClass]="{'pagination__button--disabled': pagination.page === 1}" class="pagination__button">
          <mat-icon class="_common-icon--theme" svgIcon="arrow-first"></mat-icon>
        </button>
        <button (click)="prev()"
                class="pagination__button"
                [disabled]="pagination.page === 1"
                [ngClass]="{'pagination__button--disabled': pagination.page === 1}">
          <mat-icon class="_common-icon--theme" svgIcon="arrow-prev"></mat-icon>
        </button>
      </div>
      <div class="pagination__next">
        <button (click)="nextWithoutPage()" class="pagination__button">
          <mat-icon class="_common-icon--theme pagination__next-icon" svgIcon="arrow-prev"></mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #paginationWithPages>
  <div class="pagination__controls">
    <div class="pagination__previous">
      <button (click)="first()"
              [disabled]="pagination.page === 1"
              [ngClass]="{'pagination__button--disabled': pagination.page === 1}" class="pagination__button">
        <mat-icon class="_common-icon--theme" svgIcon="arrow-first"></mat-icon>
      </button>
      <button (click)="prev()"
              [disabled]="pagination.page === 1"
              [ngClass]="{'pagination__button--disabled': pagination.page === 1}" class="pagination__button">
        <mat-icon class="_common-icon--theme" svgIcon="arrow-prev"></mat-icon>
      </button>
    </div>
    <div class="pagination__pages">
      <button *ngFor="let page of pagesRange"
              [disabled]="page === '...'"
              (click)="changePage(page)"
              [class.is-active]="pagination.page === page"
              class="pagination__page">{{page}}</button>
    </div>
    <div class="pagination__next">
      <button (click)="next()"
              [disabled]="pagination.page >= pagination.pageCount"
              [ngClass]="{'pagination__button--disabled': pagination.page >= pagination.pageCount}" class="pagination__button">
        <mat-icon class="_common-icon--theme" svgIcon="arrow-next"></mat-icon>
      </button>
      <button (click)="last()"
              [disabled]="pagination.page >= pagination.pageCount"
              [ngClass]="{'pagination__button--disabled': pagination.page >= pagination.pageCount}" class="pagination__button">
        <mat-icon class="_common-icon--theme" svgIcon="arrow-last"></mat-icon>
      </button>
    </div>
  </div>
  <div class="pagination__counter">
    {{counter.from}}-{{counter.to}} of {{pagination.totalCount}}
  </div>
</ng-template>
