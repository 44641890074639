import { Directive, inject } from '@angular/core';
import { MatSortHeader, Sort } from '@angular/material/sort';

import { MultiSortDirective } from '@shared/modules/table/directives/multi-sort.directive';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[mat-sort-header]',
  standalone: true,
})
export class MultiSortHeaderDirective {
  multiSortDirective = inject(MultiSortDirective);
  matSortHeader = inject(MatSortHeader, {
    self: true,
  });

  constructor() {
    this.matSortHeader._isSorted = this._isSorted.bind(this);
    this.matSortHeader._updateArrowDirection = this._updateArrowDirection.bind(this);
  }

  private _isSorted(): boolean {
    if (!this.multiSortDirective.activeSorts) {
      return false;
    }

    return !!this._findActiveSort();
  }

  private _updateArrowDirection(): void {
    if (!this.multiSortDirective.activeSorts) {
      return;
    }
    const activeSort = this._findActiveSort();
    this.matSortHeader._arrowDirection = this._isSorted() ? activeSort.direction : this.matSortHeader.start || 'asc';
  }

  private _findActiveSort(): Sort {
    return this.multiSortDirective.activeSorts.find(activeSort => activeSort.active === this.matSortHeader.id);
  }
}
