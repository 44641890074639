<div class="consumer-information">
  <div class="user__main-info">
    <div class="user__info-block">
      <h2 class="section__title consumer-information__title">Consumer</h2>
      <ul class="user__customer">
        <li class="user__customer-name">
          <span>{{ consumer.firstName }} {{ consumer.lastName }}</span>
        </li>
        <li>Contact Id: {{ consumer.contactId }}</li>
        <li><br /></li>
        <li *ngIf="consumer.homePhone">Home phone: {{ consumer.homePhone | usaPhone }}</li>
        <li *ngIf="consumer.mobilePhone">Mobile phone: {{ consumer.mobilePhone | usaPhone }}</li>
        <li *ngIf="consumer.workPhone">Business phone: {{ consumer.workPhone | usaPhone }}</li>
        <li>
          Email:
          <app-email-link
            *ngIf="consumer.emailAddress"
            [email]="consumer.emailAddress"></app-email-link>
          <span *ngIf="!consumer.emailAddress">-</span>
        </li>
      </ul>
    </div>
    <div class="user__info-block">
      <h2 class="section__title consumer-information__title">Store</h2>
      <app-retailer-information
        *ngIf="retailer"
        [retailer]="retailer"></app-retailer-information>
    </div>
    <div
      *ngIf="claimInfo && showSubmitterAndEscalation"
      class="user__info-block">
      <h2 class="section__title consumer-information__title">Submitter</h2>
      <ul
        *ngIf="claimInfo.submitterEmail"
        class="user__customer">
        <li class="user__customer-name">
          <span>{{ claimInfo.submitterFirstName }} {{ claimInfo.submitterLastName }}</span>
        </li>
        <li><br /></li>
        <li>Phone: {{ claimInfo.submitterPhone | usaPhone }}</li>
        <li>
          Email:
          <app-email-link
            *ngIf="claimInfo.submitterEmail"
            [email]="claimInfo.submitterEmail"></app-email-link>
          <span *ngIf="!claimInfo.submitterEmail">-</span>
        </li>
      </ul>
      <span *ngIf="!claimInfo.submitterEmail">No submitter available</span>
    </div>
  </div>
  <div class="user__main-info">
    <div class="user__info-block">
      <h2 class="section__title consumer-information__title">Consumer Address</h2>
      <ul
        *ngIf="consumer.streetAddress1"
        class="user__customer">
        <li
          class="user__address"
          *ngIf="consumer.streetAddress1">
          {{ consumer.streetAddress1 }}
        </li>
        <li
          class="user__address"
          *ngIf="consumer.streetAddress2">
          {{ consumer.streetAddress2 }}
        </li>
        <li class="user__address">
          {{ consumer.city }}, {{ consumer.stateProvince }} {{ consumer.postalCode }}
        </li>
      </ul>
      <span *ngIf="!consumer.streetAddress1">No consumer address available</span>
    </div>
    <div
      *ngIf="claimInfo && !isSystemDetails"
      class="user__info-block">
      <h2 class="section__title consumer-information__title">Service Address</h2>
      <ul
        class="user__customer"
        *ngIf="claimInfo.furnitureStreetAddress1">
        <li class="user__address">{{ claimInfo.furnitureStreetAddress1 }}</li>
        <li
          *ngIf="claimInfo.furnitureStreetAddress2"
          class="user__address">
          {{ claimInfo.furnitureStreetAddress2 }}
        </li>
        <li class="user__address">
          {{ claimInfo.furnitureCity }}, {{ claimInfo.furnitureProvince }} {{ claimInfo.furniturePostalCode }}
        </li>
      </ul>
      <span *ngIf="!claimInfo.furnitureStreetAddress1">No service address available</span>
    </div>
    <div
      *ngIf="claimInfo && showSubmitterAndEscalation"
      class="user__info-block">
      <h2 class="section__title consumer-information__title">Escalation</h2>
      <app-escalation-info [escalation]="claimInfo.escalation"></app-escalation-info>
    </div>
  </div>
</div>
