import { Injectable } from '@angular/core';
import { fromEvent, Observable, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AppConstants } from '@core/constants/app.constants';
import { TDocumentType } from '@core/enums/document-type';
import { Files, IUploadedFile } from '@core/interfaces/claims/files.interface';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  constructor(private readonly notificationService: NotificationService) {}

  handleFilesInput(
    $event: Event | DragEvent,
    properties: {
      docType: TDocumentType;
      fileControlName?: string;
    },
    acceptFormats: string = '.jpeg, .jpg, .png, .tiff, .tif, .bmp, .pdf, .doc, .docx',
  ): Observable<IUploadedFile[]> {
    const allowedFileTypes = acceptFormats.split(', ').map(format => AppConstants.fileFormatToType[format]);
    let eventFiles: File[];
    let isDragEvent;
    try {
      isDragEvent = $event instanceof DragEvent;
    } catch (e) {
      isDragEvent = false;
    }
    if (isDragEvent) {
      eventFiles = Array.from(($event as DragEvent).dataTransfer.files);
    } else {
      eventFiles = $event.target['files'];
    }
    const files = this._checkFiles(eventFiles, allowedFileTypes);
    return this.readFiles(files, properties).pipe(
      tap(() => {
        if ($event.target && $event.target['value']) {
          $event.target['value'] = null;
        }
      }),
    );
  }

  readFiles(
    files: File[],
    properties: {
      docType: TDocumentType;
      fileControlName?: string;
    },
  ): Observable<IUploadedFile[]> {
    return zip(
      ...files.map(originalFile => {
        const reader = new FileReader();
        reader.readAsDataURL(originalFile);
        return fromEvent(reader, 'loadend').pipe(
          map(() => ({
            originalFile,
            thumbnailUrl: this.getThumbnailUrl(originalFile.type, reader.result),
            parentId: null,
            ...properties,
          })),
        );
      }),
    );
  }

  getThumbnailUrl(fileType: string, readerResult: any): string {
    const isImage = fileType.match(/image\/.+/);
    if (fileType === 'application/pdf') {
      return '/assets/icons/pdf.svg';
    } else if (isImage) {
      const isSafari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
      const isEdge = navigator.userAgent.indexOf('Edge') !== -1;
      const isTif = fileType.indexOf('image/tif') !== -1;
      if (isTif) {
        if (isSafari || isEdge) {
          return readerResult;
        } else {
          return '/assets/icons/tiff.svg';
        }
      } else {
        return readerResult;
      }
    } else if (fileType.match(/application\/vnd\..+/) || fileType === 'application/msword') {
      return '/assets/icons/doc.svg';
    } else {
      return '';
    }
  }

  getFilesSize(files: Files[]): number {
    return files.reduce((size, file) => size + file.size, 0);
  }

  mapFilesToFormFiles(uploadedFile: IUploadedFile, index: number): Files {
    return {
      id: null,
      index,
      fileName: uploadedFile.originalFile.name,
      originalFile: uploadedFile.originalFile,
      thumbnailUrl: uploadedFile.thumbnailUrl,
      viewerUrl: uploadedFile.thumbnailUrl,
      url: uploadedFile.thumbnailUrl,
      mimeType: uploadedFile.originalFile.type,
      dateCreated: new Date().toISOString(),
      thumbnailLoaded: false,
      mainLoaded: false,
      thumbnailBase64: null,
      mainBase64: null,
      size: uploadedFile.originalFile.size,
      docType: uploadedFile.docType || null,
      parentId: uploadedFile.parentId || null,
      fileLocationInForm: uploadedFile.fileLocationInForm || undefined,
    };
  }

  private _checkFiles(files: File[] | FileList, allowedFileTypes: string[] = []): File[] {
    let hasError = false;
    const allowedFiles: File[] = [];
    Array.prototype.forEach.call(files, file => {
      const isJfif = !!file.name.match(/.*(\.jfif)$/);
      if (allowedFileTypes.length === 0 || (allowedFileTypes.includes(file.type) && !isJfif)) {
        allowedFiles.push(file);
      } else {
        hasError = true;
      }
    });
    if (hasError) {
      const message =
        files.length === 1
          ? 'This file type is not allowed'
          : 'Some of your selected files has not allowed type and they were not added';
      this.notificationService.next({
        message,
        type: NotificationType.Error,
        duration: 8000,
      });
    }
    return allowedFiles;
  }
}
