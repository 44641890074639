import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { Pagination } from '../../../../../core/interfaces/ui/pagination.interface';

export interface PageCounter {
  from: number;
  to: number;
}

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() pagination: Pagination;
  @Input() pageSize: number;
  @Input() hidePages = false;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  pagesRange: Array<string | number>;
  counter: PageCounter;

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    this.pagesRange = this.pageNumbers(this.pagination.pageCount, this.pagination.page);
    this.counter = {
      from:
        this.pagination.totalCount === 0 ? this.pagination.totalCount : (this.pagination.page - 1) * this.pageSize + 1,
      to:
        this.pagination.page * this.pageSize > this.pagination.totalCount
          ? this.pagination.totalCount
          : this.pagination.page * this.pageSize,
    };
  }

  next() {
    if (this.pagination.page < this.pagination.pageCount) {
      this.pageChange.emit(this.pagination.page + 1);
    }
  }

  prev() {
    if (this.pagination.page > 1) {
      this.pageChange.emit(this.pagination.page - 1);
    }
  }

  nextWithoutPage() {
    this.pageChange.emit(this.pagination.page + 1);
  }

  first() {
    this.pageChange.emit(1);
  }

  last() {
    this.pageChange.emit(this.pagination.pageCount);
  }

  changePage(page: number) {
    if (page !== this.pagination.page) {
      this.pageChange.emit(page);
    }
  }

  private pageNumbers(count: number, current: number) {
    const delta = 5,
      left = delta,
      right = count - delta + 1,
      range = [],
      rangeWithDots = [];
    let l;

    for (let i = 1; i <= count; i++) {
      if (
        i === 1
        || i === count
        || (current + delta - 1 > count && i >= count - delta + 1)
        || (current - delta < 0 && i <= delta)
        || (current >= left && current <= right && i >= current - 2 && i <= current + 2)
      ) {
        range.push(i);
      }
    }

    for (const i of range) {
      if (l) {
        if (i - l > 1) {
          rangeWithDots.push('...');
          rangeWithDots.push(i);
        } else {
          rangeWithDots.push(i);
        }
      } else {
        rangeWithDots.push(i);
      }
      l = i;
    }

    return rangeWithDots;
  }
}
