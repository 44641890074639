import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import moment from 'moment-timezone';

import { AnchorNavigationClass } from '@core/classes/anchor-navigation';
import { DIALOG_CONFIG_CUSTOM_ANIMATION } from '@core/constants/dialog.constants';
import { QuickSearchConsumer } from '@core/interfaces/quick-search/search-results.interface';
import {
  EditConsumerComponent,
} from '@shared/components/edit-consumer/edit-consumer.component';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';
import { PipesModule } from '@shared/pipes/pipes.module';

@Component({
  selector: 'app-quick-search-consumer',
  standalone: true,
  templateUrl: './quick-search-consumer.component.html',
  styleUrls: ['./quick-search-consumer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    PipesModule,
    MatIconModule,
    MatButtonModule,
  ],
})
export class QuickSearchConsumerComponent {
  @Input() consumer: QuickSearchConsumer;
  @Input() allowDetailedView = true;
  @Input() allowEditView = false;
  @Input() resetStyles = false;

  @Output() openConsumerDetails = new EventEmitter<void>();
  @Output() openConsumerEdit = new EventEmitter<void>();

  anchorNavigation: AnchorNavigationClass;
  moment = moment;

  constructor(
    private readonly modalDialogService: ModalDialogService,
    private readonly notification: NotificationService,
  ) {}


  openDetailed(): void {
    if (this.allowDetailedView) {
      this.openConsumerDetails.emit();
    }
  }

  openEdit(): void {
    if (this.allowEditView) {
      this.modalDialogService.open({
        data: {
          consumer: this.consumer,
        },
      }, EditConsumerComponent, DIALOG_CONFIG_CUSTOM_ANIMATION).afterClosed().subscribe(response => {
        if (response.edited) {
          this.notification.next({
            type: NotificationType.Success,
            message:
              'The consumer record has been updated, it may take a few minutes for the new values to show in the portal.',
            duration: 6000,
          });
        }
      });
    }
  }
}
