import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NavigationInterface } from '../navigation/navigation.component';

@Component({
  selector: 'app-sub-nav',
  templateUrl: './sub-nav.component.html',
  styleUrls: ['./sub-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubNavComponent implements OnInit {
  @Input() navigationList: NavigationInterface[];
  @Input() exact = false;

  @Output() navAction = new EventEmitter<string>();
  @Output() menuClicked = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}
}
