<h2 *ngIf="readonly">Consumer</h2>
<div class="fields-block" [class.fields-block--readonly]="readonly" [class.blocked]="blocked">
  <app-form-input class="field"
                  [class.field--third]="!readonly"
                  [class.field--short]="readonly"
                  [autocomplete]="'section-consumer given-name'"
                  [formControl]="consumerForm.get('firstName')"
                  [readonly]="readonly"
                  [required]="true"
                  [label]="'First Name'"></app-form-input>
  <app-form-input class="field"
                  [class.field--third]="!readonly"
                  [class.field--short]="readonly"
                  [autocomplete]="'section-consumer family-name'"
                  [formControl]="consumerForm.get('lastName')"
                  [readonly]="readonly"
                  [required]="true"
                  [label]="'Last Name'"></app-form-input>

  <app-form-input class="field _common-editable-field"
                  [class.field--third]="!readonly"
                  [class.field--short]="readonly"
                  [floatLabel]="readonly ? 'always' : 'auto'"
                  [autocomplete]="'section-consumer email'"
                  [formControl]="consumerForm.get('emailAddress')"
                  [type]="'email'"
                  [required]="emailRequired"
                  [label]="'Email'"></app-form-input>
</div>

<div class="consumer-form__phones">
  <h3 *ngIf="readonly; else phonesTitle">Phones</h3>
  <ng-template #phonesTitle>
    <h3 class="section__title">Phones</h3>
    <p class="help-text help-text--required">{{tooltipPhones}}</p>
  </ng-template>
  <div class="fields-block" [class.fields-block--readonly]="readonly" [class.blocked]="blocked">
    <app-form-input class="field"
                    [class.field--third]="!readonly"
                    [class.field--short]="readonly"
                    [autocomplete]="'section-consumer tel'"
                    [formControl]="consumerForm.get('mobilePhone')"
                    [readonly]="readonly"
                    [type]="'tel'"
                    [label]="'Mobile Phone #'"></app-form-input>
    <app-form-input class="field"
                    [class.field--third]="!readonly"
                    [class.field--short]="readonly"
                    [autocomplete]="'section-consumer tel'"
                    [formControl]="consumerForm.get('homePhone')"
                    [readonly]="readonly"
                    [type]="'tel'"
                    [label]="'Home Phone #'"></app-form-input>
    <app-form-input class="field"
                    [class.field--third]="!readonly"
                    [class.field--short]="readonly"
                    [autocomplete]="'section-consumer tel'"
                    [formControl]="consumerForm.get('workPhone')"
                    [readonly]="readonly"
                    [type]="'tel'"
                    [label]="'Business Phone #'"></app-form-input>
  </div>
</div>
<div *ngIf="hiddenBlocks.indexOf('contactPreferences') === -1">
  <h3>Contact Preferences</h3>
  <div class="fields-block" [class.blocked]="blocked"
       [class.fields-block--readonly]="readonly">
    <app-language-select class="field"
                         [class.field--third]="!readonly"
                         [class.field--short]="readonly"
                         [readonly]="readonly"
                         [formControl]="consumerForm.get('preferredContactLanguage')"></app-language-select>
    <app-contact-method-select class="field field--third"
                               [class.field--third]="!readonly"
                               [class.field--short]="readonly"
                               [readonly]="readonly"
                               [formControl]="consumerForm.get('preferredContactMethod')"></app-contact-method-select>
    <app-contact-time-select class="field field--third"
                             [class.field--third]="!readonly"
                             [class.field--short]="readonly"
                             [readonly]="readonly"
                             [formControl]="consumerForm.get('preferredContactTime')"></app-contact-time-select>
  </div>
</div>
