<div class="sms-history-message"
     [class.sms-history-message--inbound]="!message.isOutbound"
     [class.sms-history-message--outbound]="message.isOutbound">
  <div class="sms-history-message__body">
    {{message.messageBody}}
  </div>
  <div class="sms-history-message__sender-details">
    {{message.messageTime | appUserTimezone:'MM/DD/YY'}} at {{message.messageTime | appUserTimezone:'hh:mm a'}} • {{smsHistoryStatusToText[message.status]}}
  </div>
</div>
