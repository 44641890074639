import { NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { BaseHeaderComponent } from './base-header/base-header.component';

@NgModule({
  declarations: [BaseHeaderComponent],
  imports: [MatButtonModule, MatIconModule, NgIf],
  exports: [BaseHeaderComponent],
})
export class DialogCoreModule {}
