import { AsyncPipe, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PushPipe } from '@rx-angular/template/push';

import { FormSelectComponent } from '@shared/components/form-select/form-select.component';
import { CauseSelectComponent } from '@shared/modules/claim-controls/components/cause-select/cause-select.component';
import { PipesModule } from '@shared/pipes/pipes.module';

import { ContactMethodSelectComponent } from './components/contact-method-select/contact-method-select.component';
import { ContactTimeSelectComponent } from './components/contact-time-select/contact-time-select.component';
import { EscalationSelectComponent } from './components/escalation-select/escalation-select.component';
import { LanguageSelectComponent } from './components/language-select/language-select.component';
import { ManufacturerSelectComponent } from './components/manufacturer-select/manufacturer-select.component';
import { PartsAvailabilitySelectComponent } from './components/parts-availability-select/parts-availability-select.component';
import { ProblemTypeSubCategorySelectComponent } from './components/problem-type-sub-category-select/problem-type-sub-category-select.component';
import { ProductAvailabilitySelectComponent } from './components/product-availability-select/product-availability-select.component';
import { ProductClassificationSelectComponent } from './components/product-classification-select/product-classification-select.component';
import { ProductTypeSelectComponent } from './components/product-type-select/product-type-select.component';
import { RetailerClassificationComponent } from './components/retailer-classification/retailer-classification.component';
import { SurfaceTypeSelectComponent } from './components/surface-type-select/surface-type-select.component';

@NgModule({
  declarations: [
    CauseSelectComponent,
    ContactMethodSelectComponent,
    ContactTimeSelectComponent,
    EscalationSelectComponent,
    LanguageSelectComponent,
    ManufacturerSelectComponent,
    PartsAvailabilitySelectComponent,
    ProblemTypeSubCategorySelectComponent,
    ProductAvailabilitySelectComponent,
    ProductClassificationSelectComponent,
    ProductTypeSelectComponent,
    RetailerClassificationComponent,
    SurfaceTypeSelectComponent,
  ],
  imports: [AsyncPipe, FormSelectComponent, NgIf, PipesModule, PushPipe, ReactiveFormsModule],
  exports: [
    CauseSelectComponent,
    ContactMethodSelectComponent,
    ContactTimeSelectComponent,
    EscalationSelectComponent,
    LanguageSelectComponent,
    ManufacturerSelectComponent,
    PartsAvailabilitySelectComponent,
    ProblemTypeSubCategorySelectComponent,
    ProductAvailabilitySelectComponent,
    ProductClassificationSelectComponent,
    ProductTypeSelectComponent,
    RetailerClassificationComponent,
    SurfaceTypeSelectComponent,
  ],
})
export class ClaimControlsModule {}
