<app-side-dialog-animation *rxLet="vm$; let vm">
  <div class="dialog__header">
    <app-side-dialog-header
      [dialogTitleTemplate]="planDialogTitleTemplate"
      [disableClose]="planService.cancellationInProgress$ | async"
      (closeDialog)="onClose()">
      <ng-container controls>
        <button
          *ngIf="isAllowedCancelPlan$ | async"
          mat-flat-button
          color="primary"
          class="plan-details__create-new-claim"
          appLoadingButton
          [isLoading]="(planService.cancellationInProgress$ | async) || (loading$ | async)"
          [disabled]="planService.cancellationInProgress$ | async"
          (click)="cancelPlan(vm.plan)">
          Cancel Plan
        </button>
        <app-item-details-pagination
          *ngIf="(singleView$ | async) === false"
          [showCount]="false"
          [itemsTitle]="'Plans'"
          [pagination]="plansPagination$ | async"
          [currentItemIndex]="currentPlanIndex$ | async"
          (setPage)="setPage($event)"
          (changeItemIndex)="changeItemIndex($event)"></app-item-details-pagination>
        <div class="plan-details__pdf">
          <a
            *ngIf="vm.pdfLink && !vm.loading && !vm.planProcessing"
            [href]="vm.pdfLink"
            target="_blank">
            <mat-icon
              class="_common-icon--theme"
              svgIcon="pdf"
              title="View Plan"></mat-icon>
          </a>
        </div>
      </ng-container>
    </app-side-dialog-header>
  </div>
  <app-side-dialog-container>
    <app-side-dialog-nav>
      <app-anchor-nav
        [navigationList]="anchorNavigation.list"
        [currentAnchor]="anchorNavigation.currentAnchor"
        (anchorClick)="anchorNavigation.scrollToAnchor($event)"></app-anchor-nav>
    </app-side-dialog-nav>
    <app-side-dialog-content *ngIf="vm.plan">
      <app-cancellation-status
        *ngIf="
          vm.plan.planInfo.cancellationStatus &&
          (vm.plan.planInfo.protectionPlanStatus !== planStatuses.Cancelling ||
            vm.plan.planInfo.protectionPlanStatus === planStatuses.Cancelled)
        "
        [cancellationStatus]="vm.plan.planInfo.cancellationStatus"
        [refundAmount]="vm.plan.planInfo.refundAmount"></app-cancellation-status>
      <div
        id="Consumer"
        class="section">
        <app-plan-dialog-information [plan]="vm.plan"></app-plan-dialog-information>
      </div>
      <div
        id="PlanInformation"
        class="section">
        <app-collapsible-data-block
          headingTitle="Protection Plan"
          [grid]="{ source: [vm.plan.planInfo], columns: planColumns }"></app-collapsible-data-block>
      </div>
      <div
        id="CoveredProducts"
        class="section">
        <app-collapsible-data-block
          headingTitle="Covered Products"
          [grid]="{
            source: vm.plan.coveredProducts,
            columns: coveredProductColumns
          }"></app-collapsible-data-block>
      </div>
      <div
        id="PlanDocuments"
        class="section">
        <div class="files-slider">
          <app-collapsible-data-block headingTitle="Photos/Documents">
            <ng-container *ngIf="!vm.filesLoading">
              <ng-container *ngIf="!vm.files.length">
                <span>No files</span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="vm.filesLoading">
              <mat-spinner
                color="primary"
                [diameter]="50"></mat-spinner>
            </ng-container>
            <app-files-preview
              *ngIf="vm.files.length"
              [files]="vm.files"
              [showRemove]="false"
              [disableControls]="filesLoading$ | async"
              (allFilesLoaded)="allFilesLoaded()"></app-files-preview>
            <div
              class="plan-details__upload-files"
              *ngIf="vm.dropAreas.length > 0">
              <app-wizard-form-section>
                <th-form-field-block>
                  <th-form-field
                    *ngFor="let dropArea of vm.dropAreas"
                    [size]="dropArea.sectionSize">
                    <app-wizard-form-section [sectionTitle]="dropArea.header">
                      <app-form-files-drop-area
                        [formFilesArray]="filesForm"
                        [labelDescription]="dropArea.labelDescription"
                        [labelDescriptionForMobile]="dropArea.labelDescriptionForMobile"
                        [multiple]="dropArea.maxFilesNumber === null"
                        [pictureIcon]="dropArea.svgIcon"
                        [maxFilesNumber]="dropArea.maxFilesNumber"
                        [docType]="dropArea.docType"
                        [oneFileErrorMessage]="dropArea.errorMessage"></app-form-files-drop-area>
                    </app-wizard-form-section>
                  </th-form-field>
                </th-form-field-block>
              </app-wizard-form-section>
              <app-wizard-form-section>
                <app-files-carousel
                  *ngIf="filesForm.value.length > 0"
                  [files]="filesForm.value"
                  [showRemove]="true"
                  [showDocumentName]="true"
                  (removeFileByIndex)="removeFileIndex(filesForm, $event)"></app-files-carousel>
              </app-wizard-form-section>
              <button
                mat-flat-button
                color="primary"
                [disabled]="filesForm.invalid"
                (click)="uploadFiles()">
                Upload Files
              </button>
            </div>
          </app-collapsible-data-block>
        </div>
        <app-files-for-print [files]="vm.files"></app-files-for-print>
      </div>
    </app-side-dialog-content>
    <app-side-dialog-loader *ngIf="vm.loading"></app-side-dialog-loader>
    <div
      *ngIf="vm.planProcessing"
      class="plan-details__placeholder"></div>
  </app-side-dialog-container>
  <ng-template #planDialogTitleTemplate>
    <div class="plan-details__dialog-title">
      <h2>{{ vm.dialogTitle }}</h2>
    </div>
  </ng-template>
</app-side-dialog-animation>
