import { Directive, EventEmitter, Input, Output, Self } from '@angular/core';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[matSort]',
  standalone: true,
})
export class MultiSortDirective {
  @Input() activeSorts: Sort[] = [];

  @Output() readonly multipleSortChange = new EventEmitter<Sort[]>();

  constructor(@Self() private readonly matSort: MatSort) {
    this.matSort.sort = this.sort.bind(this);
  }

  sort(sortable: MatSortable): void {
    this._multipleSort(sortable);
    this.matSort.sortChange.emit();
  }

  private _multipleSort(sortable: MatSortable): void {
    const activeSortIndex = this.activeSorts.findIndex(activeSort => activeSort.active === sortable.id);

    if (activeSortIndex > -1) {
      this.matSort.direction = this.activeSorts[activeSortIndex].direction;
      const nextDirection = this.matSort.getNextSortDirection(sortable);
      if (nextDirection) {
        this.activeSorts[activeSortIndex].direction = nextDirection;
      } else {
        this.activeSorts.splice(activeSortIndex, 1);
      }
    } else {
      this.activeSorts.push({
        active: sortable.id,
        direction: sortable.start ? sortable.start : 'asc',
      });
    }
    this.multipleSortChange.emit(this.activeSorts);
  }
}
