import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-files-total-size-error',
  templateUrl: './files-total-size-error.component.html',
  styleUrls: ['./files-total-size-error.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LetDirective,
    NgIf,
  ],
})
export class FilesTotalSizeErrorComponent extends RxState<{ maxFilesSizeError: { maxFilesSize: number; totalFilesSize: number } }>{
  vm$ = this.select();

  @Input() set filesForm(filesForm: AbstractControl) {
    this.connect('maxFilesSizeError', filesForm.statusChanges.pipe(
      startWith(filesForm.status),
      map(status => {
        if (status === 'VALID') {
          return null;
        } else {
          return filesForm.getError('sizeError') || null;
        }
      }),
    ));
  }

  constructor() {
    super();
    this.set({
      maxFilesSizeError: null,
    });
  }

}
