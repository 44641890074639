import { AsyncPipe, NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA, MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { ClaimServiceAction } from '@core/interfaces/claims/claimDetails.interface';
import { ClaimItemStore } from '@core/store/claim-item/claim-item.store';
import { LoaderModule } from '@shared/modules/loader/loader.module';

@Component({
  selector: 'app-prompt',
  standalone: true,
  templateUrl: 'close-exchange-dialog.component.html',
  styleUrls: ['./close-exchange-dialog.component.scss'],
  imports: [
    MatLegacyInputModule,
    NgxTrimDirectiveModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    AsyncPipe,
    LoaderModule,
    NgIf,
  ],
})
export class CloseExchangeDialogComponent implements OnInit {
  note = '';
  specialHandling: boolean;
  loading$ = this.claimItemStore.select$('loading');
  dialogData: {
    data: ClaimServiceAction;
  } = inject(MAT_DIALOG_DATA);

  constructor(
    private readonly claimItemStore: ClaimItemStore,
    public dialogRef: MatDialogRef<any>,
  ) {}

  ngOnInit(): void {
    this.specialHandling = this.dialogData.data.specialHandling;
  }

  processExchange(): void {
    this.claimItemStore.processExchange(this.dialogData.data.id, this.note).subscribe(() => {
      this.dialogRef.close();
    });
  }
}
