import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'app-carousel-slide',
  template: '<ng-content></ng-content>',
  styleUrls: ['./carousel-slide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselSlideComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
