import { ChangeDetectionStrategy, Component } from '@angular/core';

import { LoaderModule } from '@shared/modules/loader/loader.module';

@Component({
  selector: 'app-side-dialog-loader',
  standalone: true,
  templateUrl: './side-dialog-loader.component.html',
  styleUrls: ['./side-dialog-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LoaderModule,
  ],
})
export class SideDialogLoaderComponent {}
