<div
  class="qs-consumer"
  [class.qs-consumer_reset]="resetStyles"
  [attr.id]="'consumer-' + consumer.id">
  <div class="qs-consumer__header">
    <p class="qs-consumer__consumer" [class.qs-consumer__consumer_no-click]="!allowDetailedView"
       (click)="openDetailed()"
    >
      {{ consumer.firstName }} {{ consumer.lastName }}
    </p>
    <button *ngIf="allowEditView" mat-icon-button (click)="openEdit()">
      <mat-icon svgIcon="edit-icon"></mat-icon>
    </button>
  </div>
  <div class="qs-consumer__contacts">
    <p *ngIf="consumer.streetAddress1">{{ consumer.streetAddress1 }}</p>
    <p *ngIf="consumer.streetAddress2">{{ consumer.streetAddress2 }}</p>
    <p *ngIf="consumer.city">{{ consumer.city }}, {{ consumer.stateProvince }} {{ consumer.postalCode }}</p>
    <p *ngIf="consumer.homePhone || consumer.mobilePhone || consumer.workPhone">
      {{
      (consumer.homePhone
          ? consumer.homePhone
          : consumer.mobilePhone
            ? consumer.mobilePhone
            : consumer.workPhone
              ? consumer.workPhone
              : ""
      ) | usaPhone
      }}
    </p>
  </div>
</div>
