import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-wizard-form-section',
  standalone: true,
  imports: [
    NgIf,
  ],
  templateUrl: './wizard-form-section.component.html',
  styleUrls: ['./wizard-form-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class WizardFormSectionComponent {
  @Input() sectionTitle: string;

  @HostBinding('class.wizard-form-section') wizardSectionClass = true;
}
