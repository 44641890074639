import { NgForOf, NgIf } from '@angular/common';
import { Component,inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA, MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { INotifyDialogData } from '@core/interfaces/prompt-dialog-data.interface';
import {
  SideDialogHeaderComponent,
} from '@shared/modules/side-dialog/components/side-dialog-header/side-dialog-header.component';
import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';

@Component({
  selector: 'app-notify',
  standalone: true,
  templateUrl: 'notify-dialog.component.html',
  styleUrls: ['./notify-dialog.component.scss'],
  imports: [
    SideDialogHeaderComponent,
    MatIconModule,
    MatDialogModule,
    CustomOverlayScrollbarDirective,
    MatButtonModule,
    NgForOf,
    NgIf,
  ],
})
export class NotifyDialogComponent {
  multiLinesMode = false;
  messages: string[] = [];
  dialogData: {
    data: INotifyDialogData;
  } = inject(MAT_DIALOG_DATA);

  constructor(private readonly dialogRef: MatDialogRef<any>) {
    if (this.dialogData.data.message && this.dialogData.data.message.length && Array.isArray(this.dialogData.data.message)) {
      this.messages = (<string[]>this.dialogData.data.message).map(message => message + '  ');
      this.multiLinesMode = true;
    }
  }

  close() {
    this.dialogRef.close(null);
  }
}
