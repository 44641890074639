<div class="sub-nav">
  <ng-container *ngFor="let nav of navigationList">
    <a *ngIf="nav.route"
       [routerLink]="nav.route"
       (click)="menuClicked.emit(nav)"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: exact}"
       class="sub-nav__item">
      <span class="sub-nav__title">{{nav.name}}</span>
    </a>
    <div *ngIf="nav.action"
         (click)="navAction.emit(nav.action)"
         [ngClass] = "{'active': nav.active && nav.active()}"
         class="sub-nav__item">
      <span class="sub-nav__title">{{nav.name}}</span>
    </div>
    <div *ngIf="!nav.action && !nav.route"
         [ngClass] = "{'active': nav.active && nav.active()}"
         class="sub-nav__item">
      <span class="sub-nav__title">{{nav.name}}</span>
    </div>
  </ng-container>
</div>
