<div *ngIf="showLoader$ | push" class="portal-loader">
  <div class="portal-loader__logo-wrapper">
    <div>
      <img class="portal-loader__logo" src="../../assets/montage-logos/MFS-One80-White.svg" alt="">
    </div>
  </div>
  <div *rxLet="errorMessage$; let errorMessage" class="portal-loader__message">
    <span *ngIf="!errorMessage">Loading Portal...</span>
    <span class="portal-loader__error" *ngIf="errorMessage">{{errorMessage}}</span>
  </div>
</div>
