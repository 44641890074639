export enum TTableCell {
  Default,
  Custom,
  Date,
  Time,
  Number,
  Currency,
  Email,
  Phone,
  Link,
  Route,
  Inline,
  DesktopInline,
  Boolean,
  WithDescription,
  Percent,
}
