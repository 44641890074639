export enum ServiceStatus {
  /// <summary>
  /// Open
  /// </summary>
  Open = 1,

  /// <summary>
  /// Cancelled
  /// </summary>
  Cancelled = 2,

  /// <summary>
  /// Technician Assigned,
  /// </summary>
  TechnicianAssigned = 3,

  /// <summary>
  /// Pending
  /// </summary>
  Pending = 4,

  /// <summary>
  /// Waiver Pending
  /// </summary>
  WaiverPending = 5,

  /// <summary>
  /// Technician Rejected
  /// </summary>
  TechnicianRejected = 6,
  /// <summary>
  /// Technician Scheduled
  /// </summary>
  TechnicianScheduled = 7,
  /// <summary>
  /// Technician Completed
  /// </summary>
  TechnicianCompleted = 8,
  /// <summary>
  /// Waived
  /// </summary>
  Waived = 9,
  /// <summary>
  /// Canceled After Assignment
  /// </summary>
  CancelledAfterAssignment = 10,
  /// <summary>
  /// Re Assigned
  /// </summary>
  ReAssigned = 11,
  /// <summary>
  /// Authorized for Service
  /// </summary>
  AuthorizedForService = 12,
  /// <summary>
  /// Closed
  /// </summary>
  Closed = 99,
  /// <summary>
  /// Completed
  /// </summary>
  Completed = 100,
}
