import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators } from '@angular/forms';

import { AppConstants } from '@core/constants/app.constants';

@Directive({
  selector: '[appEmailValidation][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EmailValidationDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class EmailValidationDirective implements Validator {
  validate = Validators.pattern(AppConstants.emailRegEx);

}
