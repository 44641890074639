import { Injectable } from '@angular/core';

import { ServiceStatus } from '../enums/claim/service-status.enum';
import { TServiceActionType } from '../enums/service-action/service-action-type.enum';
import { ClaimServiceAction } from '../interfaces/claims/claimDetails.interface';

export interface SimpleCondition {
  key: string;
  value: any;
}

export interface GetterCondition {
  conditionFn(object: any): boolean;
}

export interface ServiceActionConditions {
  [key: number]: SimpleCondition[] | GetterCondition[];
}

const serviceActionButtonConditions = {
  [TServiceActionType.CashSettlement]: [
    { key: 'serviceActionStatus', value: ServiceStatus.Open },
    { key: 'processStage', value: 'Pending Authorization (Myservice)' },
    { key: 'isHandled', value: false },
  ],
  [TServiceActionType.Replacement]: [
    { key: 'serviceActionStatus', value: ServiceStatus.Open },
    { key: 'processStage', value: 'Pending Retailer' },
    { key: 'isHandled', value: false },
  ],
  [TServiceActionType.Complaint]: [
    { key: 'processStage', value: 'Capture Information' },
    { key: 'isHandled', value: false },
  ],
  [TServiceActionType.PartOrder]: [
    { key: 'serviceActionStatus', value: ServiceStatus.Open },
    { key: 'isHandled', value: false },
  ],
  [TServiceActionType.TechWorkOrder]: [],
};

@Injectable()
export class ServiceActionService {

  static serviceActionButtonText = {
    [TServiceActionType.CashSettlement]: 'Mark as Completed',
    [TServiceActionType.Replacement]: 'Mark as Completed',
    [TServiceActionType.Complaint]: 'Review Complaint',
    [TServiceActionType.TechWorkOrder]: 'View',
  };

  static buttonGetterConditions: ServiceActionConditions = {
    [TServiceActionType.TechWorkOrder]: [
      { conditionFn: (serviceAction: ClaimServiceAction) => serviceAction.techReportDate !== null },
    ],
  };

  static serviceActionConditions: ServiceActionConditions = {
    [TServiceActionType.CashSettlement]: serviceActionButtonConditions[TServiceActionType.CashSettlement],
    [TServiceActionType.Replacement]: serviceActionButtonConditions[TServiceActionType.Replacement],
    [TServiceActionType.Complaint]: [{ key: 'isHandled', value: false }],
    [TServiceActionType.PartOrder]: serviceActionButtonConditions[TServiceActionType.PartOrder],
    [TServiceActionType.TechWorkOrder]: serviceActionButtonConditions[TServiceActionType.TechWorkOrder],
  };

  static serviceActionGetterConditions: ServiceActionConditions = {
    [TServiceActionType.Complaint]: [
      {
        conditionFn: (serviceAction: ClaimServiceAction) =>
          serviceAction.processStage === null || serviceAction.processStage === 'Capture Information',
      },
    ],
  };

  constructor() {}

  static returnButtonText(serviceAction: ClaimServiceAction): string {
    const buttonText = this.serviceActionButtonText[serviceAction.serviceActionType];
    const getterConditions = this.buttonGetterConditions[serviceAction.serviceActionType] || null;
    if (
      buttonText
      && this.meetsConditions(
        serviceAction,
        this.serviceActionConditions[serviceAction.serviceActionType],
        getterConditions,
      )
    ) {
      if (
        serviceAction.serviceActionType === TServiceActionType.TechWorkOrder
        && serviceAction.processStage === 'Technician Work Order Complete'
      ) {
        return 'Tech Report';
      } else {
        return buttonText;
      }
    } else {
      return null;
    }
  }

  static meetsConditions(
    serviceAction: ClaimServiceAction,
    conditions: SimpleCondition[] | GetterCondition[],
    getterConditions: SimpleCondition[] | GetterCondition[] = null,
  ): boolean {
    if (conditions) {
      let meetsCondition = true;

      conditions.forEach(condition => {
        if (!this.compareConditions(condition, serviceAction)) {
          meetsCondition = false;
        }
      });

      getterConditions = getterConditions || this.serviceActionGetterConditions[serviceAction.serviceActionType] || [];

      getterConditions.forEach(getterCondition => {
        if (!this.compareConditions(getterCondition, serviceAction)) {
          meetsCondition = this.compareConditions(getterCondition, serviceAction);
        }
      });

      return meetsCondition;
    } else {
      return false;
    }
  }

  static isInvoice(serviceAction: ClaimServiceAction): boolean {
    return (
      serviceAction.serviceActionType === TServiceActionType.TechWorkOrder
      && serviceAction.processStage === 'Technician Work Order Complete'
    );
  }

  private static compareConditions(
    condition: SimpleCondition & GetterCondition,
    serviceAction: ClaimServiceAction,
  ): boolean {
    if (condition.hasOwnProperty('value')) {
      return serviceAction[condition.key] === condition.value;
    } else if (condition.hasOwnProperty('conditionFn')) {
      return condition.conditionFn(serviceAction);
    }
  }
}
