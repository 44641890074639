import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, ElementRef, inject, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { PushPipe } from '@rx-angular/template/push';
import { Options,Splide } from '@splidejs/splide';
import { filter, Observable, Subject } from 'rxjs';
import { delay, map, take, tap } from 'rxjs/operators';

import { DestroyableComponent } from '@core/classes/destroyable-component';
import { AppConstants } from '@core/constants/app.constants';
import { Feature } from '@core/enums/feature.enum';
import { TPortalType } from '@core/enums/portal-type.enum';
import { Files } from '@core/interfaces/claims/files.interface';
import { PromptDialogService } from '@core/services/dialog/prompt-dialog.service';
import { filterPhotoDialogFiles } from '@core/utils/file.util';
import { CarouselModule } from '@shared/modules/carousel/carousel.module';
import { FilesPreviewComponent } from '@shared/modules/files/components/files-preview/files-preview.component';
import { FileViewerUrlPipe } from '@shared/modules/side-dialog/file-viewer-url.pipe';

interface IPhotoDialogState {
  files: Files[];
  photos: Files[];
  photosLoading: boolean;
  enableDelete: boolean;
  enableDownload: boolean;
  carouselCreated: boolean;
  startPosition: number;
  activeFile: Files;
}

@Component({
  selector: 'app-photo-dialog',
  standalone: true,
  templateUrl: './new-photo-dialog.component.html',
  styleUrls: ['./new-photo-dialog.component.scss'],
  providers: [RxState],
  encapsulation: ViewEncapsulation.None,
  imports: [
    LetDirective,
    NgIf,
    MatIconModule,
    NgClass,
    AsyncPipe,
    CarouselModule,
    PushPipe,
    NgForOf,
    MatProgressSpinnerModule,
    FileViewerUrlPipe,
    FilesPreviewComponent,
  ],
})
export class NewPhotoDialogComponent extends DestroyableComponent implements OnDestroy {
  photos$ = this.state.select('photos');
  photosLoading$ = this.state.select('photosLoading');
  enableDelete$ = this.state.select('enableDelete');
  enableDownload$ = this.state.select('enableDownload');
  carouselCreated$ = this.state.select('carouselCreated').pipe(delay(180));
  isActivePhotoNotReadOnly$ = this.state.select('activeFile').pipe(map(activeFile => !activeFile.isReadOnly));
  deleteFileByIndex$ = new Subject<number>();
  deleteFileById$$ = new Subject<number>();
  deleteFileById$ = this.deleteFileById$$.asObservable();

  Feature = Feature;
  TPortalType = TPortalType;

  fileTypes = AppConstants.fileTypes;
  mainCarousel: Splide;
  carouselOptions: Options = {
    type: 'fade',
    pagination: false,
    keyboard: false,
    arrows: false,
    drag: false,
  };
  mainCarouselCreated$ = new Subject<Splide>();
  photoLoaded$ = new Subject<{id: number}>();
  promptDialogService = inject(PromptDialogService);

  private readonly data: {
    file: Files;
    files$: Observable<Files[]>;
    dateRange: any;
    enableDelete: boolean;
  } = inject(MAT_DIALOG_DATA);
  @ViewChild('thumbnailCarousel') thumbnailCarousel: ElementRef;

  private removedIndexes: number[] = [];

  constructor(
    private readonly dialogRef: MatDialogRef<NewPhotoDialogComponent>,
    private readonly state: RxState<IPhotoDialogState>,
  ) {
    super();
    this.state.set({
      files: [],
      photos: [],
      photosLoading: false,
      carouselCreated: false,
      activeFile: null,
      enableDelete: this.data.enableDelete,
    });

    this.state.connect('files', this.data.files$);

    this.state.connect('photos', this.state.select('files').pipe(
      map(filterPhotoDialogFiles),
      tap(photos => {
        if (photos.length === 0) {
          this.closeDialog();
        }
      }),
    ));

    this.state.connect(
      'startPosition',
      this.state.select('files').pipe(
        filter(files => files.length > 0),
        map(files => filterPhotoDialogFiles(files).findIndex(photo => photo.index === this.data.file.index)),
        take(1),
      ),
    );

    this.state.connect('photos', this.photoLoaded$, (oldState, photoLoaded) => oldState.photos.map(photo => {
      if (photoLoaded.id === photo.id) {
        photo.mainLoaded = true;
      }
      return photo;
    }));

    this.state.connect('files', this.deleteFileByIndex$, (oldState, fileIndex) => {
      this.removedIndexes.push(fileIndex);
      return oldState.files.filter(photo => photo.index !== fileIndex);
    });
  }

  ngOnDestroy(): void {
    this.mainCarousel.destroy();
  }

  rotatePhoto() {
    if (!this.isPhotosLoading) {
      const photo = document.getElementById('m' + this.mainCarousel.index) as HTMLImageElement;
      let rotate = 0;
      if (!photo.style.transform) {
        rotate = rotate - 90;
        photo.style.transform = `rotate(${rotate}deg)`;
      } else {
        rotate = +photo.style.transform.replace('rotate(', '').replace('deg)', '') - 90;
        photo.style.transform = `rotate(${rotate}deg)`;
      }
      const even = (rotate / 90) % 2 === 0;
      if (photo.naturalWidth > photo.naturalHeight) {
        if (!even) {
          photo.style.width = photo.clientHeight + 'px';
        } else {
          photo.style.width = 'auto';
        }
      }
    }
  }

  fileLoaded(e: Event, file: Files) {
    this.photoLoaded$.next({
      id: file.id,
    });
  }

  carouselMounted(carousel: Splide) {
    const startPosition = this.state.get('startPosition');
    this.mainCarouselCreated$.pipe(take(1)).subscribe(mainCarousel => {
      this.mainCarousel = mainCarousel;
      this.mainCarousel.sync(carousel).mount();
      this.mainCarousel.go(startPosition);
    });
  }

  carouselCreated(carousel: Splide) {
    const startPosition = this.state.get('startPosition');
    if (startPosition === 0) {
      this.mainCarouselIndexChanged(0);
    }
    this.mainCarouselCreated$.next(carousel);
    this.state.set({
      carouselCreated: true,
    });
  }

  async downloadFile() {
    if (!this.isPhotosLoading) {
      const photos = this.state.get('photos');
      const response = await fetch(photos[this.mainCarousel.index].url);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = photos[this.mainCarousel.index].fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }

  deleteFile() {
    if (!this.isPhotosLoading) {
      const photos = this.state.get('photos');
      const carouselIndex = this.mainCarousel.index;
      const fileId = photos[carouselIndex].id;
      const fileIndex = photos[carouselIndex].index;
      if (fileId) {
        this.promptDialogService.openPrompt({
          message: '<span>Are you sure you wish to delete this file?</span>',
          buttonNo: 'No',
          buttonYes: 'Yes',
        }).afterClosed().subscribe(success => {
          if (success) {
            this.deleteFileById$$.next(fileId);
          }
        });
      } else {
        this.deleteFileByIndex$.next(fileIndex);
      }
    }
  }

  closeDialog() {
    this.dialogRef.close({
      removedIndexes: this.removedIndexes,
    });
  }

  get isPhotosLoading(): boolean {
    return this.state.get('photosLoading');
  }

  mainCarouselIndexChanged(index: number) {
    const photos = this.state.get('photos');
    const currentPhoto = photos[index];
    this.state.set({
      enableDownload: !!currentPhoto.id || !!currentPhoto.url,
      activeFile: currentPhoto,
    });
  }
}
