<div class="grid">
  <div appCustomOverlayScrollbar>
    <div class="grid__scroll-wrapper">
      <div class="grid__header customHeader">
        <div class="grid__row">
          <div
            *ngIf="selectable"
            class="grid__cell grid__cell--checkbox">
            <mat-checkbox
              *ngIf="(itemsLength$ | async) > 0"
              [ngModel]="allChecked$ | async"
              (change)="checkAll()"></mat-checkbox>
          </div>
          <div
            class="grid__cell grid__cell--{{ column.fieldName }}"
            [class.grid__cell--sortable]="column.sortable"
            *ngFor="let column of columns | filterItems : 'show'"
            (click)="changeOrderBy(column.fieldName, column.sortable)">
            <span class="grid__cell-text">
              {{ column.title }}
              <span
                class="grid__sort-icon"
                [class.grid__sort-icon--asc]="orderBy.ascending"
                [class.grid__sort-icon--none]="orderBy.ascending === undefined"
                *ngIf="orderBy && orderBy.fieldName === column.fieldName && column.sortable"></span>
            </span>
          </div>
          <div
            *ngIf="expandableCellTemplate"
            class="grid__cell"></div>
        </div>
      </div>
      <div class="grid__body">
        <ng-container *ngFor="let item of items$ | async; let i = index; trackBy: trackByRow">
          <div
            class="grid__row"
            [class.grid__row_clickable]="rowClickable"
            [class.grid__row--selected]="item.isChecked"
            (click)="rowClicked.emit(item)">
            <div
              *ngIf="selectable"
              class="grid__cell grid__cell--checkbox">
              <mat-checkbox
                [(ngModel)]="item.isChecked"
                (ngModelChange)="itemChecked(item)"></mat-checkbox>
            </div>
            <div
              class="grid__cell grid__cell--{{ column.fieldName }}"
              *ngFor="let column of columns | filterItems : 'show'; trackBy: trackByFnColumn">
              <span
                *ngIf="!column.component"
                [matTooltip]="item[column.fieldName] ? item[column.fieldName] : ''"
                matTooltipShowDelay="1000">
                {{ item[column.fieldName] | appEmptyValue }}
              </span>
              <app-dynamic-grid-cell
                *ngIf="column.component"
                [component]="column.component"
                [fieldName]="column.fieldName"
                [data]="item"></app-dynamic-grid-cell>
            </div>
            <div
              *ngIf="expandableCellTemplate"
              class="grid__cell grid__cell_expandable">
              <ng-container
                *ngTemplateOutlet="expandableCellTemplate; context: { $implicit: item }"></ng-container>
            </div>
          </div>
          <ng-container *ngIf="expandableRowTemplate">
            <div
              class="grid__row grid__row_expandable"
              [class.grid__row_expanded]="item.expanded">
              <td colspan="6">
                <ng-container
                  *ngTemplateOutlet="expandableRowTemplate; context: { $implicit: item }"></ng-container>
              </td>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div
    *ngIf="loading"
    class="grid__loader"
    [class.grid__loader--fixed]="loadingFixed">
    <app-loader class="base-loader"></app-loader>
  </div>
  <div
    *ngIf="!loading && (items$ | async).length === 0"
    class="no-results">
    No results found
  </div>
  <div
    class="grid__no-data-loader"
    *ngIf="(items$ | async).length === 0 && loading"></div>
  <div
    *ngIf="(items$ | async).length > 0 && pagination"
    class="grid__footer">
    <app-pagination
      [hidePages]="hidePages"
      [pagination]="pagination"
      [pageSize]="pageSize"
      (pageChange)="changePage.emit($event)"></app-pagination>
  </div>
</div>
