import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grid-amount-cell',
  templateUrl: './grid-amount-cell.component.html',
  styleUrls: ['./grid-amount-cell.component.scss'],
})
export class GridAmountCellComponent implements OnInit {
  data: any;
  fieldName: string;

  constructor() {}

  ngOnInit() {}
}
