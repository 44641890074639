import { AsyncPipe, NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import {
  MatLegacySelect as MatSelect,
  MatLegacySelectChange as MatSelectChange,
  MatLegacySelectModule,
} from '@angular/material/legacy-select';
import { RxState } from '@rx-angular/state';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { Pagination } from '@core/interfaces/ui/pagination.interface';
import { APP_FORM_FIELD_PROVIDER } from '@core/providers/form-field.provider';
import { FormErrorComponent } from '@shared/components/form-error/form-error.component';
import { OptionAsTableComponent } from '@shared/components/option-as-table/option-as-table.component';
import { MatSelectInfiniteScrollDirective } from '@shared/directives/mat-select-infinite-scroll.directive';
import { ScrollLoadingOverlayDirective } from '@shared/directives/scroll-loading-overlay.directive';
import { ThBaseFormFieldControlElement } from '@shared/form/base-form-field-control-element.directive';
import { GridColumnModel } from '@shared/modules/grid/models/grid/grid-column.model';
import { LoaderModule } from '@shared/modules/loader/loader.module';

interface ISelectState {
  data: any[];
  selectedItem: any;
  showSelectedOutOfListItem: boolean;
}

type TOptionTemplateType = 'default' | 'tree' | 'table';

@Component({
  selector: 'app-form-select',
  standalone: true,
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
  providers: [RxState],
  imports: [
    NgIf,
    LoaderModule,
    MatLegacySelectModule,
    MatSelectInfiniteScrollDirective,
    ScrollLoadingOverlayDirective,
    MatLegacyInputModule,
    MatIconModule,
    NgForOf,
    AsyncPipe,
    NgTemplateOutlet,
    NgSwitchCase,
    NgClass,
    FormErrorComponent,
    NgSwitch,
    FormsModule,
    ReactiveFormsModule,
    OptionAsTableComponent,
    NgxTrimDirectiveModule,
  ],
})
export class FormSelectComponent<V, T = any> extends ThBaseFormFieldControlElement<V> implements OnInit {
  @Input() set data(data: T[]) {
    this.state.set({
      data,
    });
  }

  @Input() templateType: TOptionTemplateType = 'default';

  @Input() optionColumns: GridColumnModel[];

  @Input() pagination: Pagination;

  @Input() emptyLabelPrefix = 'Choose';

  @Input() optionsLabel: string;

  @Input() showEmptyOption = true;

  @Input() multiple = false;

  @Input() readonly: boolean;

  @Input() titleField = 'name';

  @Input() subTitleField = null;

  @Input() valueField = 'id';

  @Input() loading = false;

  @Input() showSearch = false;

  @Input() loadOnScroll = false;

  @Input() showOutOfScopeValue = false;

  @Input() set runClearSearch(value: boolean) {
    if (value) {
      this.clearSearch();
    }
  }

  @Input() enableDiscardingSearchResultsOnBlur = true;

  @Input() set selectedItem(item: T) {
    this.state.set({
      selectedItem: item,
    });
  }

  @Output() itemSelected = new EventEmitter<T>();

  @Output() selectSearch = new EventEmitter<string>();

  @Output() selectLoadNextPage = new EventEmitter<number>();

  data$ = this.state.select('data');
  dataLength$ = this.state.select('data').pipe(map(data => (data ? data.length : 0)));
  outOfScopeValue$ = this.state.select('data').pipe(
    filter(data => !!data && data.length > 0),
    map(data => {
      const availableValues = data.map(dataItem => dataItem[this.valueField]);
      if (availableValues.includes(this.formControl.value)) {
        return null;
      } else {
        return this.formControl.value;
      }
    }),
  );

  selectedItem$ = this.state.select('selectedItem');
  showSelectedOutOfListItem$ = this.state.select('showSelectedOutOfListItem');

  searchString = '';

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('matSelectInstance') matSelectInstance: MatSelect;

  constructor(
    private readonly state: RxState<ISelectState>,
    @Inject(APP_FORM_FIELD_PROVIDER) public readonly formFieldProvider: {showErrors: boolean},
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.state.connect('selectedItem', this.formControl.valueChanges,
      (oldState, value) => (oldState.data || []).find(item => item[this.valueField] === value),
    );
    this.state.connect(
      'showSelectedOutOfListItem',
      this.state.select('data').pipe(
        withLatestFrom(this.state.select('selectedItem').pipe(
          filter(selectedItem => !!selectedItem && !!selectedItem[this.valueField]),
        )),
        map(([data, selectedItem]) => !data.map(item => item[this.valueField]).includes(selectedItem[this.valueField])),
      ),
    );
  }

  /**
   * Emits a whole item object on select
   */
  itemSelect($event: MatSelectChange) {
    const item = this._findItem($event.value);
    this.itemSelected.emit(item);
  }

  /**
   * Closes the dropdown on click outside
   * Note: Fixes issue with multiple dropdowns opened on mobile
   */
  onClickedOutside($event: any, matSelect: MatSelect) {
    matSelect.close();
  }

  searchEvent(event: Event) {
    event.stopPropagation();
    const searchString = this.searchString.trim();
    this.search(searchString);
    this.searchString = searchString;
  }

  clearSearch() {
    this.searchString = '';
    this.search(this.searchString);
  }

  search(searchString: string) {
    if (this.matSelectInstance && this.matSelectInstance.panel) {
      this.matSelectInstance.panel.nativeElement.scrollTop = 0;
    }
    this.selectSearch.emit(searchString);
  }

  onScrollDown() {
    this.loadNextPage();
  }

  loadNextPage() {
    if (this.pagination.page < this.pagination.pageCount) {
      this.selectLoadNextPage.emit(++this.pagination.page);
    }
  }

  /**
   * Finds item in data list with a corresponding value
   * @param value Lookup value
   */
  private _findItem(value: string): any {
    const data = this.state.get('data') || [];
    return data.find(selectItem => selectItem[this.valueField] === value);
  }

  openedHandler(isOpened: boolean) {
    if (this.searchString.length && !isOpened && this.enableDiscardingSearchResultsOnBlur) {
      this.clearSearch();
    }
  }

  modelChanged(data: any) {
    if (!data.length && this.enableDiscardingSearchResultsOnBlur) {
      this.clearSearch();
    }
  }
}
