import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { TPortalType } from '@core/enums/portal-type.enum';
import { IEnhancement } from '@core/interfaces/enhancement/enhancement.interface';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import {
  ConfirmationDialogComponent,
} from '@shared/modules/side-dialog/components/confirmation-dialog/confirmation-dialog.component';
import { NewsDialogComponent } from '@shared/modules/side-dialog/components/news-dialog/news-dialog.component';

export interface IDialogConfig {
  title?: string;
  contentText?: string;
  additionalText?: string;
  buttonYes?: string;
  buttonNo?: string;
  buttonClose?: string;
}

export interface IDialogNews extends IDialogConfig {
  closeButtonIsAvailable?: boolean;
}

@Injectable({ providedIn: 'root' })
export class DialogService {
  appConfig = inject(APP_CONFIG);
  constructor(private readonly modalDialogService: ModalDialogService) {}

  confirmLeaveObservable(
    options: {title?: string; contentText?: string; buttonYes?: string; buttonNo?: string; disableNo?: boolean} = {},
  ): Observable<boolean> {
    const dialogRef = this.modalDialogService.open({
      title: options.title || 'Confirm dialog',
      data: {
        title: options.title || null,
        contentText: options.title || null,
        buttonYes: options.title || null,
        buttonNo: options.title || null,
        disableNo: options.disableNo || false,
      },
    }, ConfirmationDialogComponent, {
      disableClose: true,
      width: this.appConfig.portalType === TPortalType.ConsumerMobile ? 'auto' : '600px',
    });

    return dialogRef.afterClosed();
  }

  canLeaveDefaultConfirmationObservable(form: FormGroup, formIsDirty?: boolean): Observable<boolean> {
    if ((form && form.dirty) || formIsDirty) {
      return this.confirmLeaveObservable({
        buttonYes: '',
        buttonNo: 'CONTINUE EDITING',
      }).pipe(
        switchMap(discardChanges => {
          if (discardChanges) {
            return of(true);
          } else {
            return of(false);
          }
        }),
      );
    } else {
      return of(true);
    }
  }

  openNewsDialog(enhancementsList: IEnhancement[], _config?: Partial<IDialogNews>) {
    const defaultConfig: IDialogNews = {
      title: 'Welcome Back, ',
      contentText: 'We’ve introduced some new features we thought you might want to know about.',
      buttonClose: 'Close',
      closeButtonIsAvailable: false,
    };
    const dialogConfig: IDialogConfig = { ...defaultConfig, ..._config };
    return this.modalDialogService.open({
      title: dialogConfig.title,
      data: {
        dialogConfig,
        enhancementsList,
      },
    }, NewsDialogComponent, {
      disableClose: true,
      autoFocus: false,
      width: 'auto',
    });
  }
}
