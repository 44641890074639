import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RxState } from '@rx-angular/state';
import { sortBy } from 'lodash-es';
import moment from 'moment';
import { filter, map } from 'rxjs/operators';

import { ClaimDetails, IServerSmsCommunication, IServerSmsMessage } from '@core/interfaces/claims/claimDetails.interface';
import { ClaimItemStore } from '@core/store/claim-item/claim-item.store';
import { UserStore } from '@core/store/user/user.store';
import { DialogCoreModule } from '@shared/modules/dialog-core/dialog-core.module';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';

import { SmsHistoryMessageComponent } from '../../components/sms-history-message/sms-history-message.component';

interface IViewMessageHistoryDialogState {
  loading: boolean;
  claim: ClaimDetails;
  activeHistory: IServerSmsCommunication;
}

@Component({
  selector: 'app-view-message-history-dialog',
  standalone: true,
  templateUrl: './view-message-history-dialog.component.html',
  styleUrls: ['./view-message-history-dialog.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SmsHistoryMessageComponent,
    PipesModule,
    DialogCoreModule,
    AsyncPipe,
    CustomOverlayScrollbarDirective,
    MatButtonModule,
    MatIconModule,
    LoaderModule,
    NgIf,
    NgForOf,
  ],
})
export class ViewMessageHistoryDialogComponent implements OnInit {
  loading$ = this.state.select('loading');
  claimNumber$ = this.state.select('claim').pipe(map(claim => claim.claimInfo.claimNumber));

  smsHistoryList$ = this.claimItemStore.select$('smsHistoryList');
  activeHistoryId$ = this.state.select('activeHistory').pipe(
    filter(activeHistory => !!activeHistory),
    map(activeHistory => activeHistory.activityId),
  );
  activeHistoryGroupedMessages$ = this.state.select('activeHistory').pipe(
    filter(activeHistory => !!activeHistory),
    map(activeHistory => {
      const messages = <IServerSmsMessage[]>activeHistory.messages;
      return sortBy(messages, 'messageTime').reduce((previousValue, message) => {
        const previousMessageGroup = previousValue[previousValue.length - 1];

        if (
          !!previousMessageGroup
          && moment(previousMessageGroup.groupTime).isSame(moment(message.messageTime), 'day')
        ) {
          previousMessageGroup.messages.push(message);
        } else {
          previousValue.push({
            groupTime: message.messageTime,
            messages: [message],
          });
        }
        return previousValue;
      }, <{groupTime: string; messages: IServerSmsMessage[]}[]>[]);
    }),
  );

  disableClose = false;

  constructor(
    public dialogRef: MatDialogRef<ViewMessageHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {claim: ClaimDetails} | any,
    private readonly state: RxState<IViewMessageHistoryDialogState>,
    private readonly userStore: UserStore,
    private readonly claimItemStore: ClaimItemStore,
  ) {
    this.state.connect('loading', this.claimItemStore.select$('smsHistoryLoading'));
    this.state.connect(
      'activeHistory',
      this.claimItemStore.select$('smsHistoryList').pipe(map(smsHistoryList => smsHistoryList[0])),
    );
    if (this.data.claim) {
      this.state.set({
        claim: { ...this.data.claim },
      });
    }
  }

  ngOnInit() {
    this.claimItemStore.getSmsHistoryForClaim();
  }

  close() {
    this.dialogRef.close(null);
  }

  setActiveSmsHistory(activeHistory: IServerSmsCommunication) {
    this.state.set({
      activeHistory,
    });
  }
}
