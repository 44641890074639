import { Pipe, PipeTransform } from '@angular/core';

import { Files } from '@core/interfaces/claims/files.interface';

@Pipe({
  name: 'appFileViewerUrl',
  standalone: true,
})
export class FileViewerUrlPipe implements PipeTransform {
  transform(file: Files, mainSlide: HTMLDivElement): string {
    if (file.viewerUrl) {
      return file.viewerUrl;
    } else {
      return file.thumbnailUrl.indexOf('data:image') === -1
        ? file.thumbnailUrl + '&width=' + mainSlide.offsetWidth + '&height=' + mainSlide.offsetHeight
        : file.thumbnailUrl;
    }
  }
}
