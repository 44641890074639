import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appOnlyNumber]',
  standalone: true,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: OnlyNumberDirective, multi: true }],
})
export class OnlyNumberDirective implements ControlValueAccessor {
  @Input() appOnlyNumber = true;
  private _value: string | null;
  onChange: (_: string | null) => void = () => {};
  onTouched: () => void = () => {};

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}

  get value(): string | null {
    return this._value;
  }

  @HostListener('input', ['$event.target.value'])
  currencyInput(value: string) {
    if (this.appOnlyNumber) {
      const numbersMatcher = value.trim().match(/[0-9]+/g);
      value = numbersMatcher ? numbersMatcher.join('') : '';
    }

    this.writeValue(value);
    this.onChange(value);
  }

  @HostListener('blur')
  currencyBlur() {
    this.onTouched();
  }

  writeValue(value: string): void {
    const normalizedValue = value === null ? '' : value.toString();
    this.setProperty('value', normalizedValue);
  }

  registerOnChange(fn: (_: number | string | null) => void): void {
    this.onChange = value => {
      fn(value === '' ? null : value);
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  protected setProperty(key: string, value: string): void {
    this._renderer.setProperty(this._elementRef.nativeElement, key, value);
  }
}
