<button
  class="address-suggest__verify-button"
  [disabled]="!addressForm.valid || blocked"
  (click)="verifyAddress()"
  type="button"
  mat-flat-button
  color="primary"
  appLoadingButton
  [isLoading]="verificationInProgress$ | async">
  Verify address
</button>
<div #addressSuggestWrapper>
  <ng-container [ngSwitch]="validationType$ | async">
    <div
      *ngSwitchCase="validationTypes.ValidAndDifferent"
      class="address-suggest">
      <h3 class="address-suggest__title">We have found a matching address:</h3>
      <div class="address-suggest__content">
        <ul>
          <li>{{ addressSuggest.streetAddress1 }}</li>
          <li>
            {{ addressSuggest.city }}, {{ addressSuggest.stateProvince }} {{ addressSuggest.postalCode }}
          </li>
        </ul>
      </div>
      <div>
        <div class="address-suggest__button">
          <button
            (click)="chooseAddress()"
            class="_common-button--rounded"
            mat-flat-button
            color="primary">
            Use this address
          </button>
        </div>
        <a
          (click)="clearAddressSuggest()"
          class="address-suggest__button">
          Cancel
        </a>
      </div>
    </div>
    <div
      *ngSwitchCase="validationTypes.Valid"
      class="address-suggest">
      <h3 class="address-suggest__title">The address was successfully validated</h3>
      <div class="address-suggest__button">
        <button
          (click)="clearAddressSuggest()"
          class="_common-button--rounded"
          mat-flat-button
          color="primary">
          OK
        </button>
      </div>
    </div>
    <div
      *ngSwitchCase="validationTypes.Invalid"
      class="address-suggest">
      <h3 class="address-suggest__title">
        No matching address could be found, please double check your entry
      </h3>
      <div class="address-suggest__button">
        <button
          (click)="clearAddressSuggest()"
          class="_common-button--rounded"
          mat-flat-button
          color="primary">
          OK
        </button>
      </div>
    </div>
  </ng-container>
</div>
<div
  *ngIf="(validationType$ | async) !== validationTypes.Hide"
  class="disable-around-form"></div>
