import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterItems',
})
export class FilterItemsPipe implements PipeTransform {
  transform<T, K extends keyof T>(items: T[], field: K): T[] {
    if (!field) {
      return items;
    } else {
      return items.filter(item => item[field]);
    }
  }
}
