import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { SubNavComponent } from './components/sub-nav/sub-nav.component';
import { WizardNavigationComponent } from './components/wizard-navigation/wizard-navigation.component';

const components = [SubNavComponent, WizardNavigationComponent];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, MatIconModule, RouterModule],
})
export class NavigationModule {}
