<div [formGroup]="form">
  <div class="fields-block" [class.blocked]="blocked">
    <app-form-input class="field field--long"
                    [autocomplete]="'section-address consumer address-line1'"
                    (input)="inputChanged.emit()"
                    [formControl]="form.get('streetAddress1')"
                    [label]="'Address'"
                    [required]="true"></app-form-input>
    <app-form-input class="field field--third"
                    [autocomplete]="'section-address consumer address-line2'"
                    (input)="inputChanged.emit()"
                    [formControl]="form.get('streetAddress2')"
                    [label]="'Address 2'"
                    [required]="false"></app-form-input>
  </div>
  <div class="fields-block" [class.blocked]="blocked">
    <app-form-input class="field field--third"
                    [autocomplete]="'section-address consumer address-level2'"
                    (input)="inputChanged.emit()"
                    [formControl]="form.get('city')"
                    [label]="'City'"
                    [required]="true"></app-form-input>
    <app-select-state class="field field--third"
                      [formControl]="form.get('stateProvince')"
                      [showOutOfScopeValue]="true"
                      [label]="'State'"
                      [required]="true"
                      (itemSelected)="inputChanged.emit()"></app-select-state>
    <app-form-input class="field field--third"
                    [autocomplete]="'section-address consumer postal-code'"
                    (input)="inputChanged.emit()"
                    [formControl]="form.get('postalCode')"
                    [label]="'Zip'"
                    [required]="true"></app-form-input>
  </div>
</div>
