<table class="data-grid" *ngIf="_source">
  <thead>
  <tr>
    <th class="data-grid__head-cell"
        *ngFor="let column of columns"
        [class.hide-print]="column.hideOnPrint">{{column.title}}</th>
  </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of _source" class="grid__row">
      <td *ngFor="let column of columns" class="grid__cell"
          [class.hide-print]="column.hideOnPrint">
        <app-dynamic-grid-cell *ngIf="column.component"
                               [component]="column.component"
                               [fieldName]="column.fieldName"
                               [data]="item"></app-dynamic-grid-cell>
        <ng-container *ngIf="!column.component">{{item[column.fieldName] | appEmptyValue}}</ng-container>
    </tr>
  </tbody>
</table>
