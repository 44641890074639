<div class="dialog">
  <div class="dialog__header">
    <h3 class="dialog__title">Review Complaint</h3>
  </div>
  <div class="dialog__content">
    <div class="mat-typography">{{ dialogData.data.complaint }}</div>
    <div class="add-note">
      <label for="noteInput">
        You can enter a comment below and it will be added as a claim note when the complaint is marked as
        reviewed
      </label>
      <textarea
        matInput
        trim="blur"
        [(ngModel)]="note"
        maxlength="4000"
        id="noteInput"></textarea>
    </div>
    <div class="inline-buttons inline-buttons--right">
      <button
        [mat-dialog-close]="false"
        type="button"
        class="inline-buttons__button _common-button--rounded"
        [disabled]="loading$ | async"
        mat-flat-button
        color="primary">
        Cancel
      </button>
      <button
        (click)="processComplaint()"
        type="button"
        class="inline-buttons__button _common-button--rounded"
        mat-flat-button
        color="primary"
        [disabled]="note.trim().length === 0 || (loading$ | async)">
        Mark as Reviewed
      </button>
    </div>
    <div
      class="modal-loader"
      *ngIf="loading$ | async">
      <app-loader class="base-loader"></app-loader>
    </div>
  </div>
</div>
