import { Injectable } from '@angular/core';
import { ClaimDetailsComponent } from '@th-common-retailer/dialogs/claim-details/claim-details.component';

import { DIALOG_CONFIG_CUSTOM_ANIMATION } from '@core/constants/dialog.constants';
import {
  IQuickSearchClaim,
  PurchaseClaim,
  QuickSearchConsumer,
} from '@core/interfaces/quick-search/search-results.interface';
import { EditConsumerComponent } from '@shared/components/edit-consumer/edit-consumer.component';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

@Injectable()
export class DetailedQuickSearchService {
  constructor(
    private readonly notification: NotificationService,
    private readonly modalDialogService: ModalDialogService,
  ) {}

  openClaim(claim: PurchaseClaim | IQuickSearchClaim): void {
    this.modalDialogService.open({
      title: 'Claim Details',
      data: {
        claimInfo: claim,
        singleView: true,
      },
    }, ClaimDetailsComponent, DIALOG_CONFIG_CUSTOM_ANIMATION);
  }

  openEditConsumer(consumer: QuickSearchConsumer) {
    const ref = this.modalDialogService.open({
      data: {
        consumer,
      },
    }, EditConsumerComponent, DIALOG_CONFIG_CUSTOM_ANIMATION);

    ref.afterClosed().subscribe(response => {
      if (response.edited) {
        this.notification.next({
          type: NotificationType.Success,
          message:
            'The consumer record has been updated, it may take a few minutes for the new values to show in the portal.',
          duration: 6000,
        });
      }
    });

    return ref;
  }
}
