<app-side-dialog-animation *rxLet="vm$; let vm">
  <ng-container *ngIf="vm.claim">
    <div class="dialog__header">
      <app-side-dialog-header
        [dialogTitle]="'Claim Details for ' + vm.claim.claimInfo.claimNumber"
        [showReturnArrow]="
          appConfig.portalType === TPortalType.MJC
            ? false
            : (previousClaimsIdsLength$ | async) > 0 || vm.singleView
        "
        (returnToItem)="returnToCurrentClaim()"
        (closeDialog)="onClose()">
        <app-claim-details-header
          title-header
          [claimStatus]="vm.claim.claimInfo.claimStatus"
          [claimStage]="vm.claim.claimInfo.claimStage"
          [dateOpen]="vm.claim.claimInfo.dateOpen"
          [dateClosed]="vm.claim.claimInfo.dateClosed"></app-claim-details-header>
        <ng-container controls>
          <button
            *ngIf="vm.showCompleteRepairBtn"
            class="claims-details__mark-complete-btn"
            mat-flat-button
            (click)="completeRepair()"
            appLoadingButton
            [isLoading]="vm.markCompleteLoading">
            Complete
          </button>
          <app-item-details-pagination
            *ngIf="(previousClaimsIdsLength$ | async) === 0 && !vm.singleView"
            [itemsTitle]="'Claims'"
            [pagination]="claimsPagination$ | async"
            [currentItemIndex]="vm.currentClaimIndex"
            (setPage)="setPage($event)"
            (changeItemIndex)="changeItemIndex($event)"></app-item-details-pagination>
          <div class="claims-details__review">
            <div
              *ngIf="vm.showClaimReviewBtn && !vm.claim.claimInfo.flagCleared"
              class="claims-details__flag"
              (click)="unsetFlag()"
              [class.allowed]="vm.allowClaimReview">
              <mat-icon svgIcon="flag"></mat-icon>
            </div>
            <div
              class="claims-details__checkmark"
              *ngIf="vm.claim.claimInfo.flagCleared">
              <mat-icon
                svgIcon="checkmark"
                class="mat-icon--checkmark"></mat-icon>
            </div>
          </div>
          <div [title]="(filesLoading$ | async) ? 'The claim data is loading' : ''">
            <mat-icon
              class="_common-icon--theme claims-details__print-button"
              [class._common-icon--disabled]="filesLoading$ | async"
              svgIcon="print"
              (click)="print()"></mat-icon>
          </div>
        </ng-container>
      </app-side-dialog-header>
    </div>
    <app-side-dialog-container>
      <app-side-dialog-nav>
        <div class="timeline-switcher">
          <span>Classic</span>
          <app-switch
            type="checkbox"
            [(ngModel)]="timelineViewToggle"
            (ngModelChange)="timelineToggled()"></app-switch>
          <span>Timeline</span>
        </div>
        <app-anchor-nav
          [navigationList]="anchorNavigation.list"
          [currentAnchor]="anchorNavigation.currentAnchor"
          (anchorClick)="anchorNavigation.scrollToAnchor($event)">
          <div
            class="sub-nav__item"
            *ngIf="!anchorNavigation.list.length && !vm.loading">
            <span
              class="sub-nav__title invisible"
              [class.claims-details__visible]="visibleTimelineNoData">
              No Events
            </span>
          </div>
        </app-anchor-nav>
      </app-side-dialog-nav>
      <app-side-dialog-content>
        <div
          id="CustomerInformation"
          class="section">
          <app-consumer-information
            [consumer]="vm.claim.consumer"
            [claimInfo]="vm.claim.claimInfo"
            [retailer]="vm.claim.retailer"
            [claimDetailsType]="claimDetailsTypes.Tenant"></app-consumer-information>
        </div>
        <ng-container *ngIf="!timelineViewToggle">
          <div
            id="Warranty"
            class="section">
            <app-collapsible-data-block
              headingTitle="Consumer Agreement"
              [grid]="{
                source: [vm.claim.consumerPlanDetailed],
                columns: claimWarrantyColumns
              }"></app-collapsible-data-block>
          </div>

          <div
            id="ClaimProduct"
            class="section">
            <app-collapsible-data-block
              headingTitle="Claim Incidents"
              [grid]="{
                source: vm.claim.productInformation,
                columns: claimProductsColumns
              }"></app-collapsible-data-block>
          </div>

          <div
            id="ServicesTaken"
            class="section">
            <app-collapsible-data-block headingTitle="Service Actions on Claim">
              <app-service-action-grid
                headingTitle="Service Actions on Claim"
                [claim]="vm.claim"
                [source]="vm.claim.serviceActions"></app-service-action-grid>
            </app-collapsible-data-block>
          </div>

          <ng-container *ngIf="vm.showRepairActions">
            <div
              id="RepairActions"
              class="section claims-details__repair-actions-table">
              <app-collapsible-data-block headingTitle="Repair Actions">
                <app-repair-actions-table [repairActions]="vm.claim.repairActions"></app-repair-actions-table>
              </app-collapsible-data-block>
            </div>
            <div class="claims-details__repair-actions-print">
              <app-collapsible-data-block headingTitle="Repair Actions">
                <div
                  class="claims-details__repair-actions-print-item"
                  *ngFor="let serviceAction of vm.claim.repairActions">
                  <th-form-field-block>
                    <th-form-field [size]="4">
                      <app-label-value-item
                        label="Repair Type Category"
                        [value]="serviceAction.repairTypeCategory"></app-label-value-item>
                    </th-form-field>
                    <th-form-field [size]="4">
                      <app-label-value-item
                        label="Repair Type"
                        [value]="serviceAction.repairType"></app-label-value-item>
                    </th-form-field>
                    <th-form-field [size]="12">
                      <app-label-value-item
                        label="Repair Instructions"
                        [value]="serviceAction.repairInstructions | appEmptyValue"></app-label-value-item>
                    </th-form-field>
                    <th-form-field [size]="4">
                      <app-label-value-item
                        label="Authorization Status"
                        [value]="serviceAction.repairAuthorizationStatus"></app-label-value-item>
                    </th-form-field>
                    <th-form-field [size]="4">
                      <app-label-value-item
                        label="Servicer Type"
                        [value]="serviceAction.repairServicingType"></app-label-value-item>
                    </th-form-field>
                  </th-form-field-block>
                  <th-form-field-block>
                    <th-form-field [size]="4">
                      <app-label-value-item
                        label="Estimate"
                        [value]="serviceAction.repairEstimate | currency"></app-label-value-item>
                    </th-form-field>
                    <th-form-field [size]="4">
                      <app-label-value-item
                        label="Amount"
                        [value]="serviceAction.reimbursementAmount | currency"></app-label-value-item>
                    </th-form-field>
                    <th-form-field [size]="4">
                      <app-label-value-item
                        label="Quantity"
                        [value]="serviceAction.quantity"></app-label-value-item>
                    </th-form-field>
                  </th-form-field-block>
                </div>
              </app-collapsible-data-block>
            </div>
          </ng-container>

          <div
            id="ClaimCommunications"
            class="claim-details-block section">
            <app-collapsible-block>
              <h2 header>Claim Communications</h2>
              <div content>
                <div
                  *appSecureContent="[features.ViewSmsHistory]"
                  class="claims-details__communication-section section">
                  <button
                    class="_common-button--rounded"
                    mat-flat-button
                    color="primary"
                    appLoadingButton
                    [isLoading]="smsHistoryLoading$ | async"
                    [disabled]="smsHistoryLoading$ | async"
                    (click)="openMessageHistory(vm.claim)">
                    VIEW SMS / Text Message history
                  </button>
                </div>

                <ng-container
                  *ngIf="
                    vm.claim.communication.letters.length === 0 &&
                      vm.claim.communication.emails.length === 0 &&
                      vm.claim.communication.phoneCalls.length === 0 &&
                      vm.claim.communication.faxCalls.length === 0;
                    else haveCommunications
                  ">
                  No Claim Communications
                </ng-container>
                <ng-template #haveCommunications>
                  <div
                    *ngIf="vm.claim.communication.letters.length > 0"
                    class="claims-details__communication-section">
                    <h4>Letters:</h4>
                    <app-data-grid
                      [columns]="claimCommunicationLettersColumns"
                      [source]="vm.claim.communication.letters"></app-data-grid>
                  </div>
                  <div
                    *ngIf="vm.claim.communication.emails.length > 0"
                    class="claims-details__communication-section">
                    <h4>Emails:</h4>
                    <app-data-grid
                      [columns]="claimCommunicationEmailColumns"
                      [source]="vm.claim.communication.emails"></app-data-grid>
                  </div>
                  <div
                    *ngIf="vm.claim.communication.phoneCalls.length > 0"
                    class="claims-details__communication-section">
                    <h4>Phone calls:</h4>
                    <app-data-grid
                      [columns]="claimCommunicationPhoneCallsColumns"
                      [source]="vm.claim.communication.phoneCalls"></app-data-grid>
                  </div>
                  <div
                    *ngIf="vm.claim.communication.faxCalls.length > 0"
                    class="claims-details__communication-section">
                    <h4>Fax:</h4>
                    <app-data-grid
                      [columns]="claimCommunicationColumns"
                      [source]="vm.claim.communication.faxCalls"></app-data-grid>
                  </div>
                </ng-template>
              </div>
            </app-collapsible-block>
          </div>

          <div
            id="Notes"
            class="section">
            <app-collapsible-data-block
              headingTitle="Claim Notes"
              [grid]="{
                source: vm.claim.notes | orderBy: 'createdOn',
                columns: claimNoteColumns
              }"></app-collapsible-data-block>
          </div>
          <div
            *appSecureContent="[features.AddClaimNotes]"
            class="escalation-information-form section"
            id="AddNotes">
            <app-collapsible-data-block headingTitle="Add Note">
              <label for="noteTextArea">Place notes in the box provided below and click Save Note</label>
              <form
                class="note-form"
                [formGroup]="form">
                <textarea
                  [cdkTextareaAutosize]="true"
                  trim="blur"
                  id="noteTextArea"
                  formControlName="note"></textarea>
                <div class="note-form__error">
                  <span *ngIf="form.get('note').hasError('maxlength')">
                    Max note length is {{ form.get("note").errors.maxlength.requiredLength }} symbols
                  </span>
                </div>
                <div class="note-form__button">
                  <button
                    type="button"
                    mat-flat-button
                    color="primary"
                    (click)="createNote()"
                    [disabled]="!form.valid">
                    Save Note
                  </button>
                </div>
              </form>
            </app-collapsible-data-block>
          </div>
          <div
            id="Files"
            class="section">
            <app-collapsible-data-block headingTitle="Photos/Documents">
              <div class="files-slider">
                <ng-container *ngIf="(filesLoading$ | async) === false">
                  <ng-container *ngIf="(filesLength$ | async) === 0">
                    <span>No files</span>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="vm.showDocumentFilter">
                  <app-documents-filter
                    *ngIf="(filesLength$ | async) > 0"
                    [files]="files$ | async"
                    (docTypeFilterChanged)="docTypeFilterChanged($event)"></app-documents-filter>
                  <ng-container *ngIf="documentsFilterLoading$ | async">
                    <mat-spinner
                      [diameter]="50"
                      color="primary"></mat-spinner>
                  </ng-container>
                </ng-container>
                <app-files-preview-new
                  *ngIf="(filesLength$ | async) > 0"
                  [files]="files$ | async | filterDocumentsByTypes: docTypeFilter"
                  [sectionTitle]="null"
                  [showCaptions]="false"
                  [showRemove]="false"
                  [showDocumentName]="true"
                  (allFilesLoaded)="allFilesLoaded()"></app-files-preview-new>
              </div>
              <app-grouped-claim-files
                *ngIf="(filesLength$ | async) > 0"
                [files]="files$ | async"></app-grouped-claim-files>
            </app-collapsible-data-block>
          </div>
          <div
            *appSecureContent="[features.UploadClaimFiles]"
            id="AddFiles"
            class="section">
            <app-collapsible-data-block headingTitle="Add Photos/Documents">
              <div
                class="files-drop-area__drop-area-list"
                [class.files-drop-area__drop-area-list_two-columns]="dropAreasListTwoCol$ | async"
                [class.files-drop-area__drop-area-list_three-columns]="dropAreasListThreeCol$ | async">
                <div
                  class="files-drop-area__drop-area"
                  *rxFor="let dropArea of dropAreasList$">
                  <p class="files-drop-area__drop-area-header">{{ dropArea.header }}</p>
                  <app-form-files-drop-area
                    [formFilesArray]="filesForm"
                    [labelDescription]="dropArea.labelDescription"
                    [labelDescriptionForMobile]="dropArea.labelDescriptionForMobile"
                    [multiple]="dropArea.maxFilesNumber === null"
                    [pictureIcon]="dropArea.svgIcon"
                    [maxFilesNumber]="dropArea.maxFilesNumber"
                    [docType]="dropArea.docType"></app-form-files-drop-area>
                </div>
              </div>
              <app-files-preview-new
                [files]="filesForm.value"
                [showRemove]="true"
                [showDocumentName]="true"
                [disableControls]="filesLoading$ | async"
                (removeFileByIndex)="removeFiles($event)"></app-files-preview-new>

              <app-files-total-size-error [filesForm]="filesForm"></app-files-total-size-error>
              <button
                mat-flat-button
                color="primary"
                (click)="uploadFiles()"
                [disabled]="filesForm.invalid">
                Upload Files
              </button>
            </app-collapsible-data-block>
          </div>
          <div
            id="OtherClaims"
            class="section">
            <app-collapsible-data-block headingTitle="Other Claims">
              <ng-container *ngIf="(otherClaimsLength$ | async) === 0">
                <p>No Other Claims</p>
              </ng-container>
              <ng-container *ngIf="(otherClaimsLength$ | async) > 0">
                <p class="section__title">Listed below are other claims from the same consumer.</p>
                <app-data-grid
                  [columns]="otherClaimsColumns"
                  [source]="otherClaims$ | async"></app-data-grid>
              </ng-container>
            </app-collapsible-data-block>
          </div>
        </ng-container>
        <ng-container *ngIf="timelineViewToggle">
          <app-timeline (allFilesLoaded)="allFilesLoaded()"></app-timeline>
        </ng-container>
      </app-side-dialog-content>
    </app-side-dialog-container>
  </ng-container>
  <app-side-dialog-loader *ngIf="vm.loading"></app-side-dialog-loader>
</app-side-dialog-animation>
