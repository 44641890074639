import { Pipe, PipeTransform } from '@angular/core';

import { Feature } from '../../core/enums/feature.enum';
import { FeatureService } from '../../core/services/feature.service';

@Pipe({
  name: 'appAllowed',
})
export class AllowedPipe implements PipeTransform {
  constructor(private readonly featureService: FeatureService) {}

  transform(features: Feature[], options: {some: boolean} = { some: false }) {
    if (options.some) {
      return this.featureService.allowedSome(features);
    } else {
      return this.featureService.allowedMany(features);
    }
  }
}
