import { AsyncPipe } from '@angular/common';
import { Component, OnInit, Optional, Self } from '@angular/core';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { map } from 'rxjs/operators';

import { BaseFormControlElement } from '@core/base/base-form-control-element';
import { TreeService } from '@core/services/tree.service';
import { RegionsStore } from '@core/store/regions/regions.store';
import { FormSelectComponent } from '@shared/components/form-select/form-select.component';

@Component({
  selector: 'app-regions-select',
  standalone: true,
  templateUrl: './regions-select.component.html',
  styleUrls: ['./regions-select.component.scss'],
  imports: [
    FormSelectComponent,
    AsyncPipe,
    ReactiveFormsModule,
  ],
})
export class RegionsSelectComponent extends BaseFormControlElement implements OnInit {
  readonly regions$ = this.regionsStore.select$('regions').pipe(
    map(regions => this.treeService.buildTree(regions)),
  );

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private readonly regionsStore: RegionsStore,
    private readonly treeService: TreeService,
  ) {
    super(ngControl);
  }

  ngOnInit() {
    super.ngOnInit();
    this.regionsStore.loadData();
  }
}
