<div>
  <mat-dialog-content class="confirmation-dialog__content">
    <div class="confirmation-dialog__image-section">
      <mat-icon class="confirmation-dialog__image-unsavedchanges _common-icon--theme" svgIcon="img-unsavedchanges"></mat-icon>
    </div>
    <div class="confirmation-dialog__head-text">
      <p>{{title}}</p>
    </div>
    <div class="confirmation-dialog__content-text">{{contentText}}</div>
    <div class="confirmation-dialog__additional-text">Unsaved progress will be lost.</div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="confirmation-dialog__actions">
      <button [mat-dialog-close]="true"
              mat-stroked-button
              color="primary">{{buttonYes}}</button>
      <button [mat-dialog-close]="false"
              [disabled]="disableNo"
              mat-flat-button
              color="primary">{{buttonNo}}</button>
    </div>
  </mat-dialog-actions>
</div>
