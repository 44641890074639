import { CurrencyPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { GridColumnModel } from '@shared/modules/grid/models/grid/grid-column.model';

@Component({
  selector: 'app-option-as-table',
  standalone: true,
  templateUrl: './option-as-table.component.html',
  styleUrls: ['./option-as-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CurrencyPipe,
    NgIf,
    NgForOf,
  ],
})
export class OptionAsTableComponent {
  @Input()
    item: any;

  @Input()
    columns: GridColumnModel[];
}
