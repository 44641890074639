<div class="fields-block fields-block--readonly">
  <div class="field field--short">
    <div class="field__label">Address</div>
    <div class="field__text">{{address.streetAddress1 | appEmptyValue}}</div>
  </div>
  <div class="field field--short">
    <div class="field__label">Address 2</div>
    <div class="field__text">{{address.streetAddress2 | appEmptyValue}}</div>
  </div>
</div>
<div class="fields-block fields-block--readonly">
  <div class="field field--short">
    <div class="field__label">City</div>
    <div class="field__text">{{address.city | appEmptyValue}}</div>
  </div>
  <div class="field field--short">
    <div class="field__label">State</div>
    <div class="field__text">{{address.stateProvince | appEmptyValue}}</div>
  </div>
  <div class="field field--short">
    <div class="field__label">Zip</div>
    <div class="field__text">{{address.postalCode | appEmptyValue}}</div>
  </div>
</div>
