<p class="correspondence-block" *ngIf="data.callTo">
  Call To:
  <span class="correspondence-block__sub-text">{{data.callTo}}</span>
</p>
<p class="correspondence-block" *ngIf="data.callFrom">
  Call From:
  <span class="correspondence-block__sub-text">{{data.callFrom}}</span>
</p>
<p class="correspondence-block">
  Direction
  <span class="correspondence-block__sub-text">{{data.direction}}</span>
</p>
