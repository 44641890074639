import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { GridOrderByModel } from '@shared/modules/grid/models/grid/grid-order-by.model';

import { RetailerPlanApiService } from '../../api/retailer-plan-api.service';
import { ExportKinds, ExportSelections } from '../../enums/claim/claim-export.enum';
import { ProtectionPlanRequest } from '../../models/search/search-request.model';
import { AdvancedSearchService } from '../../services/advanced-search.service';
import { BaseStore } from '../_base/base.store';
import { StoreState } from './plans.state';
import IStoreState = StoreState.IStoreState;
import initialState = StoreState.initialState;

@Injectable({
  providedIn: 'root',
})
export class PlansStore extends BaseStore<IStoreState> {
  constructor(
    private readonly retailerPlanApiService: RetailerPlanApiService,
    private readonly advancedSearchService: AdvancedSearchService,
  ) {
    super(initialState);
  }

  filterAdvancedSearch(searchRequest: Partial<ProtectionPlanRequest>) {
    this.updateSearchRequest(searchRequest);
    this.loadData();
  }

  resetAdvancedSearch() {
    const searchRequest = initialState.searchRequest;
    this.updateState({
      searchRequest: {
        ...searchRequest,
        // noCount: true,
      },
    });
  }

  deactivateAdvancedSearch() {
    this.advancedSearchService.deactivate();
    this.resetAdvancedSearch();
    this.loadData();
  }

  setNewProtectionPlanStatus(protectionPlanStatus: number, planId: number) {
    if (protectionPlanStatus) {
      const plans = this.get('plans');
      plans.forEach(plan => {
        if (plan.id === planId) {
          plan.protectionPlanStatus = protectionPlanStatus;
        }
      });
      this.updateState({ plans: [...plans] });
    }
  }

  loadData(): void {
    const searchRequest = this.get('searchRequest');
    this.updateState({ loading: true });
    this.retailerPlanApiService
      .all(searchRequest)
      .pipe(finalize(() => this.updateState({ loading: false })))
      .subscribe(({ items, totalCount, page, pageCount }) => {
        if (page > 1 && items.length === 0) {
          this.updateState({
            searchRequest: {
              ...searchRequest,
              page: searchRequest.page - 1,
            },
          });
        } else {
          this.updateState({
            plans: items || [],
            pagination: {
              totalCount,
              page,
              pageCount,
            },
          });
        }
      });
  }

  updatePagination(page: number, pageSize?: number) {
    const searchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...searchRequest,
        page,
        pageSize: pageSize || searchRequest.pageSize,
      },
    });
    this.loadData();
  }

  updatePageSize(pageSize: number) {
    const searchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...searchRequest,
        page: 1,
        pageSize,
      },
    });
    this.loadData();
  }

  updateSearches(searchString: string) {
    const searchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...searchRequest,
        page: 1,
        searches: [searchString],
      },
    });
    this.loadData();
  }

  updateOrderBy(orderBy: GridOrderByModel[]) {
    const storeSearchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...storeSearchRequest,
        orderBy,
      },
    });
    this.loadData();
  }

  updateSearchRequest(searchRequest: Partial<ProtectionPlanRequest>) {
    const storeSearchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...storeSearchRequest,
        ...searchRequest,
        page: 1,
      },
    });
  }

  checkAll(isChecked: boolean) {
    const plans = this.get('plans');
    plans.forEach(item => (item.isChecked = isChecked));
    this.updateState({
      plans: [...plans],
    });
  }

  checkItem(checkedItem: any) {
    const plans = this.get('plans');
    plans.forEach(item => {
      if (checkedItem.id === item.id) {
        item.isChecked = checkedItem.isChecked;
      }
    });
    this.updateState({
      plans: [...plans],
    });
  }

  clearSearchRequest() {
    this.updateSearchRequest(initialState.searchRequest);
  }

  export(selections: ExportSelections, maxRows?: number) {
    this.updateState({ exportInProgress: true });
    const searchRequest = this.get('searchRequest');
    searchRequest.exportOptions = {
      ids: [],
      selections,
      exportKind: ExportKinds.Excel,
    };

    if (maxRows) {
      searchRequest.exportOptions.exportTopN = maxRows;
    }
    this.retailerPlanApiService.exportPlans(searchRequest).subscribe(
      (blobResult: HttpResponse<Blob>) => {
        // parse to blob
        const blob = new Blob([blobResult.body], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        //  start downloading file with hidden element
        const tempElement = document.createElement('a');
        if (tempElement.download !== undefined) {
          // pass content of blob
          const url = URL.createObjectURL(blob);

          // set temp attributes and fileName, we can have it file name from server
          // it will require a bit work with HttpResponse<Blob>
          tempElement.setAttribute('href', url);
          tempElement.setAttribute('download', 'download.xlsx');
          tempElement.setAttribute('target', '_self');

          document.body.appendChild(tempElement);
          tempElement.click();

          // clean up
          document.body.removeChild(tempElement);
        }
        this.updateState({ exportInProgress: false });
      },
      error => {
        this.updateState({ exportInProgress: false });
      },
    );
  }
}
