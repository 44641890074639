export enum TTileType {
  Action,
  Stat,
  Composite
}

export enum TTileId {
  ClaimsToReview,
  SettlementsToApprove,
  ComplaintsToReview,
  ExchangesToReview,
  OpenClaims,
  OpenPartOrders,
  RecentTechReports,
  PPClaimsToReview,
  PPOpenPartOrders,
  PPEvenExchanges,
  PPOpenReselections,
  MjcRepairs,
  MjcReplacements,
}
