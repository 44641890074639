import { AsyncPipe, DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';

import { AppConstants } from '@core/constants/app.constants';
import { Timeline } from '@core/interfaces/claims/timeline.interface';
import { ClaimItemStore } from '@core/store/claim-item/claim-item.store';
import {
  CollapsibleDataBlockComponent,
} from '@shared/modules/collapsible/components/collapsible-data-block/collapsible-data-block.component';
import { FilesForPrintComponent } from '@shared/modules/files/components/files-for-print/files-for-print.component';
import { FilesPreviewComponent } from '@shared/modules/files/components/files-preview/files-preview.component';
import { ServiceStatusGridCellComponent } from '@shared/modules/grid/components/service-status-grid-cell/service-status-grid-cell.component';
import { ServiceTypeGridCellComponent } from '@shared/modules/grid/components/service-type-grid-cell/service-type-grid-cell.component';
import { GridModule } from '@shared/modules/grid/grid.module';
import { GridColumnModel } from '@shared/modules/grid/models/grid/grid-column.model';
import { PipesModule } from '@shared/pipes/pipes.module';

@Component({
  selector: 'app-timeline',
  standalone: true,
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    AsyncPipe,
    PipesModule,
    GridModule,
    CollapsibleDataBlockComponent,
    NgForOf,
    NgClass,
    DatePipe,
    FilesPreviewComponent,
    FilesForPrintComponent,
  ],
})
export class TimelineComponent {
  timeline$ = this.claimItemStore.select$('timeline');
  timelineLength$ = this.claimItemStore.select$('timeline').pipe(map(timeline => Object.keys(timeline || {}).length));
  public claimCommunicationActivityType = Object.keys(AppConstants.claimCommunicationActivityType).map(
    e => AppConstants.claimCommunicationActivityType[e],
  );
  public timelineClaimNoteColumns = [
    new GridColumnModel('enteredBy', 'Entered By:'),
    new GridColumnModel('description', 'Note:'),
  ];
  public timelineClaimLettersColumns = [
    new GridColumnModel('direction', 'Direction:'),
    new GridColumnModel('subject', ''),
  ];
  public timelineClaimEmailColumns = [
    new GridColumnModel('emailTo', 'Sent To:'),
    new GridColumnModel('emailFrom', 'Sent From:'),
    new GridColumnModel('direction', 'Direction:'),
    new GridColumnModel('subject', ''),
  ];
  public timelineClaimActivityColumns = [
    new GridColumnModel('serviceActionType', 'Service:', ServiceTypeGridCellComponent),
    new GridColumnModel('serviceActionStatus', 'Status:', ServiceStatusGridCellComponent),
  ];

  public filesLoaded = 0;

  @Output() allFilesLoaded = new EventEmitter();

  @ViewChild('claimsContent') claimsContent;

  constructor(private readonly claimItemStore: ClaimItemStore) {}

  itemFilesLoaded(timeline: Timeline[]) {
    this.filesLoaded++;
    let fileItems = 0;
    timeline.forEach(time => {
      time.items.forEach(item => {
        if (item.type === 'Files') {
          if (item.files.length > 0) {
            fileItems++;
          }
        }
      });
    });
    if (this.filesLoaded === fileItems) {
      this.allFilesLoaded.emit();
    }
  }
}
