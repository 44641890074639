export class GridColumnModel {
  constructor(
    public fieldName: string | string[],
    public title: string,
    public component?: any,
    public hideable: boolean = true,
    public sortable: boolean = true,
    public show: boolean = true,
    public hideOnPrint: boolean = false,
  ) {}
}
