<ng-container *rxLet="photosLoading$; let photosLoading">
  <div class="photo-dialog-container" [style.color]="'#fff'">
    <div *ngIf="carouselCreated$ | async" class="dialog-controls">
      <div class="control-icon close" [ngClass]="{disabled: photosLoading}" (click)="closeDialog()">
        <mat-icon svgIcon="close"></mat-icon>
      </div>
      <div class="control-icon rotate" [ngClass]="{disabled: photosLoading}" (click)="rotatePhoto()">
        <mat-icon class="_common-icon--white" svgIcon="rotate"></mat-icon>
      </div>
      <div *ngIf="enableDownload$ | async" class="control-icon download" [ngClass]="{disabled: photosLoading}">
      <span (click)="downloadFile()">
        <mat-icon class="_common-icon--white" svgIcon="download"></mat-icon>
      </span>
      </div>
      <ng-container *ngIf="(enableDelete$ | async) && (isActivePhotoNotReadOnly$ | async)">
        <div class="control-icon delete" [ngClass]="{disabled: photosLoading}"
             (click)="deleteFile()">
          <mat-icon svgIcon="delete"></mat-icon>
        </div>
      </ng-container>
    </div>
    <div class="carousel-main">
      <app-carousel [options]="carouselOptions"
                    [customOptions]="{arrowsPosition: 'outside', arrowsBackground: false}"
                    (carouselCreated)="carouselCreated($event)"
                    (indexChanged)="mainCarouselIndexChanged($event)">
        <div class="splide__slide" *ngFor="let slide of photos$ | push; index as i">
          <app-carousel-slide>
            <div class="main-slide" #mainSlide>
              <img appImageError
                   [attr.id]="'m' + i"
                   [src]="slide | appFileViewerUrl:mainSlide"
                   [class.hidden]="!slide.mainLoaded"
                   [class.is-loading]="photosLoading"
                   (load)="fileLoaded($event, slide)">
              <mat-spinner color="primary"
                           [diameter]="50"
                           [ngClass]="{'is-loading': photosLoading}"
                           *ngIf="!slide.mainLoaded || (photosLoading)"></mat-spinner>
            </div>
          </app-carousel-slide>
        </div>
      </app-carousel>
    </div>
    <div class="carousel-thumbnails">
      <app-files-preview type="images"
                         [customCarouselOptions]="{arrowsPosition: 'outside', arrowsBackground: false}"
                         [isNavigation]="true"
                         [isPhotoDialog]="true"
                         [files]="photos$ | async"
                         [disableControls]="photosLoading"
                         (carouselMounted)="carouselMounted($event)"></app-files-preview>
    </div>
  </div>
</ng-container>
