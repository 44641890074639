import {
  AfterViewInit,
  Directive,
  Input, Self,
} from '@angular/core';
import { MatLegacySelect } from '@angular/material/legacy-select';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appScrollLoadingOverlay]',
  standalone: true,
})
export class ScrollLoadingOverlayDirective implements AfterViewInit {
  @Input() set loading(loading: boolean) {
    this.updateOverlayHeight();
  }

  private panel!: HTMLElement;

  private destroyed$ = new Subject<boolean>();

  constructor(@Self() private matSelect: MatLegacySelect) {}

  ngAfterViewInit() {
    this.matSelect.openedChange.pipe(
      takeUntil(this.destroyed$),
    ).subscribe((opened) => {
      if (opened) {
        this.panel = this.matSelect.panel.nativeElement;
        this.updateOverlayHeight();
      }
    });
  }

  updateOverlayHeight() {
    if (this.panel) {
      this.panel.style.setProperty('--scroll-loading-overlay-height', `${this.panel.scrollHeight}px`);
    }
  }

}
