import { ServiceRetailerActionComponent } from '@th-common-retailer/dialogs/claim-details/containers/service-retailer-action-cell/service-retailer-action.component';

import { BaseClaimDetailsTablesConfig } from '@shared/modules/claim-details/classes/base-claim-details-tables-config';

import { ClaimStatusCellComponent } from '../../claim-grid/components/claim-status-cell/claim-status-cell.component';
import { CorrespondencePhoneCallsGridCellComponent } from '../../grid/components/correspondence-phone-calls-grid-cell/correspondence-phone-calls-grid-cell.component';
import { EmailContentsGridCellComponent } from '../../grid/components/email-contents-grid-cell/email-contents-grid-cell.component';
import { GridAmountCellComponent } from '../../grid/components/grid-amount-cell/grid-amount-cell.component';
import { GridDateCellComponent } from '../../grid/components/grid-date-cell/grid-date-cell.component';
import { GridDateTimeCellComponent } from '../../grid/components/grid-date-time-cell/grid-date-time-cell.component';
import { PreviousClaimCellComponent } from '../../grid/components/previous-claim-cell/previous-claim-cell.component';
import { GridColumnModel } from '../../grid/models/grid/grid-column.model';

export class DealerClaimDetailsTablesConfig {
  static serviceActionsColumnsDealer = [
    ...BaseClaimDetailsTablesConfig.serviceActionsColumns,
    new GridColumnModel('authorizedAmount', 'Auth. Amount', GridAmountCellComponent),
  ];

  static serviceActionColumnsWithActions = [
    ...DealerClaimDetailsTablesConfig.serviceActionsColumnsDealer,
    new GridColumnModel('isHandled', 'Retailer Action', ServiceRetailerActionComponent, false, false, true, true),
  ];

  static serviceActionInvoiceColumnsWithActions = [
    ...DealerClaimDetailsTablesConfig.serviceActionsColumnsDealer,
    new GridColumnModel('paidAmount', 'Paid Amount', GridAmountCellComponent),
    new GridColumnModel('isHandled', 'Retailer Action', ServiceRetailerActionComponent, false, false, true, true),
  ];

  static claimCommunicationPhoneCallsColumns = [
    new GridColumnModel('createDate', 'Date', GridDateTimeCellComponent),
    new GridColumnModel('correspondence', 'Correspondence', CorrespondencePhoneCallsGridCellComponent),
    new GridColumnModel('subject', 'Subject'),
    new GridColumnModel('content', 'Contents', EmailContentsGridCellComponent, false, false, true, true),
  ];

  static otherClaimsColumns = [
    new GridColumnModel('claimNumber', 'Claim #', PreviousClaimCellComponent),
    new GridColumnModel('claimStatus', 'Status', ClaimStatusCellComponent),
    new GridColumnModel('dateOpen', 'Opened', GridDateCellComponent),
    new GridColumnModel('dateClosed', 'Closed', GridDateCellComponent),
    new GridColumnModel('products', 'Product(s)'),
  ];

  static claimNoteColumns = [
    new GridColumnModel('createdOn', 'Date', GridDateTimeCellComponent),
    new GridColumnModel('enteredBy', 'Entered By'),
    new GridColumnModel('description', 'Note'),
  ];
}
