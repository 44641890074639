import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'dateDayMinusOne',
})
export class DateDayMinusOne implements PipeTransform {
  public transform(value: string, format: string = ''): string {
    return moment(value).add(-1, 'days').format(format);
  }
}
