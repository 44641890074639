<div class="consumer-search">
  <div class="quick-search">
    <ng-container *ngIf="currentSubStep === preSteps.Select">
      <div
        class="quick-search__consumer-component"
        *ngFor="let consumer of searchResults">
        <app-quick-search-consumer
          [consumer]="consumer"
          (openConsumerDetails)="onConsumerClicked(consumer)"></app-quick-search-consumer>
      </div>
    </ng-container>
    <div
      class="quick-search__no-results consumer-search__no-results"
      [class.consumer-search__no-results--searched]="!(searched && !searchResults.length && !loading)"
      *ngIf="currentSubStep === preSteps.Search && !(searchResults && searchResults.length)">
      <div class="quick-search__no-results-content">
        <h3 *ngIf="searched && !searchResults.length && !loading">
          No records were found that match your search.
          <br />
          Please try a different search.
        </h3>
        <div class="quick-search__no-results-input">
          <app-search-input
            [canBounce]="false"
            (search)="quickSearch($event)"
            (clearSearch)="clearSearch()"
            [disabled]="loading"></app-search-input>
        </div>
        <p>You can search for:</p>
        <ul>
          <li>
            A
            <b>consumer</b>
            (by specifying first name and last name, email, any phone number, or contact ID)
          </li>
        </ul>
      </div>

      <ng-container *ngIf="searched && !searchResults.length && !loading">
        <div class="quick-search__no-results-separator">
          <p></p>
          <span>Or</span>
        </div>

        <div class="quick-search__no-results-add-claim">
          <app-add-button-small
            (click)="goToRegistrationStep.emit()"
            buttonText="START A PROTECTION PLAN FOR A NEW CONSUMER"></app-add-button-small>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="currentSubStep === preSteps.Select">
      <div
        class="quick-search__consumer-button"
        *ngIf="searchResults.length">
        <div class="quick-search-add-claim">
          <div>
            <p>If this is not the consumer you were looking for you can start a new Protection Plan here</p>
            <app-add-button-small
              (click)="goToRegistrationStep.emit()"
              buttonText="START A PROTECTION PLAN FOR A NEW CONSUMER"></app-add-button-small>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div
    *ngIf="loading"
    class="side-dialog__loader">
    <app-loader class="base-loader"></app-loader>
  </div>
</div>
