import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PlanDetails } from '@core/interfaces/plan/plan.interface';
import { GridColumnModel } from '@shared/modules/grid/models/grid/grid-column.model';

@Injectable()
export abstract class PlanDetailsDialogService {
  abstract planColumns: GridColumnModel[];
  abstract coveredProductColumns: GridColumnModel[];
  abstract showCancelPlanButton(plan: PlanDetails): Observable<boolean>;
  abstract parsePlanDetails(plan: PlanDetails): PlanDetails;
  abstract getCoveredProductColumns(planDetails: PlanDetails): GridColumnModel[];
}
