<app-form-select [formControl]="formControl"
                 [data]="retailers$ | push"
                 [loading]="loading$ | push"
                 [pagination]="pagination$ | push"
                 [label]="label"
                 [selectedItem]="selectedRetailer"
                 [valueField]="valueField"
                 [required]="required"
                 [disabled]="disabled"
                 [showSearch]="true"
                 [loadOnScroll]="true"
                 [subTitleField]="'retailerLocationId'"
                 (selectSearch)="retailersSearch($event)"
                 (itemSelected)="retailerSelected($event)"
                 (selectLoadNextPage)="loadNextPage($event)"></app-form-select>





