<div class="dialog news-dialog">
  <div class="news-dialog__background-section">
    <mat-icon
      class="_common-icon--theme news-dialog__image-background news-dialog__image-background--left"
      svgIcon="triangle-3"></mat-icon>
    <mat-icon
      class="_common-icon--theme news-dialog__image-background news-dialog__image-background--left-inside"
      svgIcon="triangle-4"></mat-icon>
    <mat-icon
      class="_common-icon--theme news-dialog__image-background news-dialog__image-background--right-inside"
      svgIcon="triangle-5"></mat-icon>
    <mat-icon
      class="_common-icon--theme news-dialog__image-background news-dialog__image-background--right"
      svgIcon="triangle-6"></mat-icon>
  </div>
  <div class="dialog__content news-dialog__content">
    <div class="block__content news-dialog__image-section">
      <mat-icon
        class="news-dialog__image-unsavedchanges _common-icon--theme"
        svgIcon="img-unboxing"></mat-icon>
    </div>
    <div class="news-dialog__head-text">
      <p>{{ title }} {{ (session$ | async).userFirstName }} {{ (session$ | async).userLastName }}!</p>
    </div>
    <ng-container *ngIf="enhancementsList.length > 0; else noData">
      <div class="news-dialog__description">
        <span>{{ contentText }}</span>
      </div>
      <div class="news-dialog__enhancement-section">
        <div appCustomOverlayScrollbar>
          <div
            class="_revert-reset-styles news-dialog__enhancement-content"
            *ngIf="enhancementCurrent">
            <div class="news-dialog__enhancement-title">{{ enhancementCurrent.title }}</div>
            <div
              class="ql-editor news-dialog__enhancement-description"
              [innerHTML]="
                enhancementCurrent.description ? (enhancementCurrent.description | safe : 'html') : 'No data.'
              "></div>
          </div>
        </div>
      </div>
      <div
        *ngIf="pagination"
        class="news-dialog__footer">
        <div class="news-dialog__pagination-wrapper">
          <!--             [class.news-dialog__pagination-wrapper&#45;&#45;without-close]="!closeButtonIsAvailable">-->
          <app-pagination
            [hidePages]="hidePages"
            class="_pagination-without-first-last-arrows"
            [pagination]="pagination"
            [pageSize]="pageSize"
            (pageChange)="changePage($event)"></app-pagination>
        </div>
        <div class="inline-buttons news-dialog__footer-actions">
          <button
            (click)="closeHandler()"
            class="inline-buttons__button _common-button--rounded"
            [disabled]="!closeButtonIsAvailable"
            mat-flat-button
            color="primary">
            Close
          </button>
        </div>
      </div>
    </ng-container>

    <ng-template #noData>
      <div class="block news-dialog__description">
        <p>There’s no enhancements to view yet.</p>
      </div>
      <div class="inline-buttons">
        <button
          [mat-dialog-close]="true"
          class="inline-buttons__button _common-button--rounded"
          mat-flat-button
          color="primary">
          Close
        </button>
      </div>
    </ng-template>
  </div>
</div>
