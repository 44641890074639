<div class="advanced-search" [formGroup]="form">
  <app-collapsible-block>
    <div class="advanced-search__header" header>Retailer</div>
    <div content>
      <div *ngIf="appConfig.portalType !== TPortalType.MJC">
        <app-regions-select *appSecureContent="[feature.ViewRegions]"
                            [formControl]="form.get('regionId')"></app-regions-select>
      </div>
      <div>
        <app-retailer-select [formControl]="form.get('retailerId')"></app-retailer-select>
      </div>
    </div>
  </app-collapsible-block>
  <app-collapsible-block>
    <div class="advanced-search__header" header>Date</div>
    <div content>
      <div>
        <app-date-range-search (dateRangeTypeChange)="dateRange = $event"
                               [useAdditionalDay]="false"
                               [dateRange]="dateRange"></app-date-range-search>
      </div>
      <div>
        <app-form-select [formControl]="form.get('dateKind')"
                         [data]="dateKinds$ | async"
                         [label]="'Search By'"></app-form-select>
      </div>
    </div>
  </app-collapsible-block>
</div>
<div class="search-button">
<button class="_common-button--rounded"
        mat-flat-button color="primary"
        [disabled]="!form.valid" (click)="search()">Search</button>
<button class="_common-button--rounded"
        mat-flat-button color="primary"
        (click)="clear()">Clear</button>
</div>
