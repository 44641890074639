import { Injectable } from '@angular/core';

import { Tree } from '../interfaces/regions/regions.interface';

@Injectable({
  providedIn: 'root',
})
export class TreeService {
  constructor() {}

  buildTree(items: any[]) {
    const tree = this.getNestedChildren(items, null, true);
    return this.countNested(tree);
  }

  /*
   * keepChildren: add the children to the list that does not has parents.
   * */
  public getNestedChildren(items: any[], parentId: number, keepChildren: boolean) {
    const out = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].parentId === parentId) {
        items[i].children = this.getNestedChildren(items, items[i].id, keepChildren);
        out.push(items[i]);
      } else if (items.findIndex(it => it.id === items[i].parentId) === -1 && parentId === null && keepChildren) {
        items[i].children = this.getNestedChildren(items, items[i].id, keepChildren);
        out.push(items[i]);
      }
    }
    return out;
  }

  public countNested(array: any[]): any[] {
    const countNested = arr => {
      let elCount = arr.length;
      arr.forEach((tree: Tree) => {
        let nestedCount = 0;
        if (tree.children && tree.children.length) {
          nestedCount = 0;
          nestedCount += countNested(tree.children);
        }
        tree.nestedCount = nestedCount;
        elCount += nestedCount;
      });
      return elCount;
    };
    countNested(array);

    return array;
  }
}
