<div
  class="th-table-col"
  [class.th-table-col_nowrap]="item.nowrap"
  [ngSwitch]="item?.type">
  <ng-container *ngSwitchCase="TAppTableCol.Action">
    <span
      class="th-table-col__link"
      (click)="headerColClick($event)"
      *ngIf="item.actionActive$ | async; else inactiveAction">
      {{ item.title }}
    </span>
    <ng-template #inactiveAction>
      <span>{{ item.title }}</span>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchDefault>
    {{ isMobile ? item.titleMobile : item.title }}
  </ng-container>
</div>
