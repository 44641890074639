import { Component, Input, OnInit } from '@angular/core';

import { PlanDetails } from '../../../../core/interfaces/plan/plan.interface';

@Component({
  selector: 'app-plan-dialog-information',
  templateUrl: './plan-dialog-information.component.html',
  styleUrls: ['./plan-dialog-information.component.scss'],
})
export class PlanDialogInformationComponent implements OnInit {
  @Input() plan: PlanDetails;

  constructor() {}

  ngOnInit() {}
}
