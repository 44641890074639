<div>
  <div *ngIf="!dialogData.data.title; else titleTemplate"
       class="prompt-close">
    <button
      (click)="close()"
      mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <p class="prompt-title" *ngIf="!multiLinesMode; else multiLinesTemplate">{{dialogData.data.message}}</p>
  </mat-dialog-content>
  <div mat-dialog-actions class="prompt-actions">
    <button [mat-dialog-close]="true"
            class="inline-buttons__button _common-button--rounded"
            mat-flat-button color="primary">OK</button>
  </div>
</div>
<ng-template #titleTemplate>
  <div class="dialog__header">
    <app-side-dialog-header [dialogTitle]="dialogData.data.title" (closeDialog)="close()"></app-side-dialog-header>
  </div>
</ng-template>
<ng-template #multiLinesTemplate>
  <p class="prompt-title" *ngFor="let message of messages">{{message}}</p>
</ng-template>
