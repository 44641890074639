import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'argbToHex',
})
export class ArgbToHexPipe implements PipeTransform {
  transform(argb: string): string {
    if (!argb || !argb.length) {
      return '#f00';
    }
    return `#${argb.substring(2, argb.length)}`;
  }
}
