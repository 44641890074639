<form class="container" [formGroup]="form">
  <div class="fields-block protection-plan-form__first-block">
    <app-form-select class="field field--half"
                     formControlName="planId"
                     [data]="plans$ | push"
                     [loading]="loading$ | push"
                     [pagination]="pagination$ | push"
                     [label]="'Plan'"
                     [templateType]="'table'"
                     [optionColumns]="planColumns"
                     [required]="true"
                     [showSearch]="true"
                     [loadOnScroll]="true"
                     [runClearSearch]="clearSearch"
                     (selectSearch)="planSearch($event)"
                     (itemSelected)="planSelected($event)"
                     (selectLoadNextPage)="loadNextPage($event)"></app-form-select>
    <div class="field field--half">
      <div class="protection-plan-form__alert-message">
        <app-alert-message [title]="'NOTE:'"
                           [message]="deliveryDateWarningMessage$ | async"
                           [type]="'warning'"></app-alert-message>
      </div>
      <app-form-date formControlName="deliveryDate"
                     [label]="'Estimated Furniture Delivery Date'"
                     [required]="true"
                     [type]="TDateFieldType.MinMaxDate"
                     [min]="minDeliveryDate"
                     [max]="maxDeliveryDate"></app-form-date>
    </div>
  </div>
  <div class="fields-block">
    <app-form-input class="field field--half"
                    [formControl]="form.get('planPrice')"
                    [label]="'Plan Price'"
                    [autocomplete]="'section-plan plan-price'"
                    [dataType]="'price'"
                    [required]="true"></app-form-input>
    <app-form-input class="field field--half"
                    [formControl]="form.get('totalCoverageAmount')"
                    [label]="'Total Price Of Furniture Covered By This Plan'"
                    [autocomplete]="'section-plan total-furniture-price'"
                    [dataType]="'price'"
                    [required]="true"></app-form-input>
  </div>
</form>
