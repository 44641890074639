import { ChangeDetectionStrategy, Component, EventEmitter,Input, Optional, Output, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LookupApiService } from '../../../../../core/api/lookup-api.service';
import { BaseFormControlElement } from '../../../../../core/base/base-form-control-element';
import { IState } from '../../../../../core/interfaces/state.interface';

interface ISelectStateState {
  items: IState[];
  loading: boolean;
}

@Component({
  selector: 'app-select-state',
  templateUrl: './select-state.component.html',
  styleUrls: ['./select-state.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectStateComponent extends BaseFormControlElement {
  @Input() label: string;
  @Input() showOutOfScopeValue = false;
  @Input() set formUpdated(formUpdated$: Observable<void>) {
    this.state.connect('items', formUpdated$, ({ items }) => [...items]);
  }

  @Output()
    itemSelected = new EventEmitter<IState>();

  items$ = this.state.select('items');
  loading$ = this.state.select('loading');

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private readonly lookupApiService: LookupApiService,
    private readonly state: RxState<ISelectStateState>,
  ) {
    super(ngControl);
    this.state.set({
      loading: true,
      items: [],
    });
    this.lookupApiService
      .lookupStates()
      .pipe(
        finalize(() => {
          this.state.set({
            loading: false,
          });
        }),
      )
      .subscribe(states => {
        this.state.set({
          items: states,
        });
      });
  }
}
