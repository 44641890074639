<div class="cancellation-status">
  <div class="cancellation-status__message" *ngIf="cancellationStatus === 'Pending Documents' || cancellationStatus === 'Qualification'">
    <span class="cancellation-status__icon">
      <mat-icon svgIcon="help_outline" class="_common-icon--theme"></mat-icon>
    </span>
    <span>A cancellation/refund has been requested for this plan and no claims may be started for it. <br> The status of the cancellation/refund is currently <b>pending review</b>.</span>
  </div>
  <div class="cancellation-status__message"  *ngIf="cancellationStatus === 'Accounting Review'">
    <span class="cancellation-status__icon">
      <mat-icon svgIcon="help_outline" class="_common-icon--theme"></mat-icon>
    </span>
    <span>A cancellation/refund has been requested for this plan and no claims may be started for it. <br> The status of the cancellation/refund is currently <b>calculating refund amount</b>.</span>
  </div>
  <div class="cancellation-status__message"  *ngIf="cancellationStatus === 'Refund Complete'">
    <span class="cancellation-status__icon success"><mat-icon svgIcon="checkmark" class="mat-icon--checkmark"></mat-icon></span>
    <span>Your cancellation/refund has been <b>Approved</b> and the refund amount is <span class="amount">${{refundAmount}}</span>.</span>
  </div>
  <div class="cancellation-status__message"  *ngIf="cancellationStatus === 'Denied'">
    <span class="cancellation-status__icon denied"><mat-icon svgIcon="close"></mat-icon></span>
    <span>Your cancellation/refund has been <b>denied</b> due to <b>insufficient funds</b>.</span>
  </div>
</div>
