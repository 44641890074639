import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-correspondence-phone-calls-grid-cell',
  templateUrl: './correspondence-phone-calls-grid-cell.component.html',
  styleUrls: ['./correspondence-phone-calls-grid-cell.component.scss'],
})
export class CorrespondencePhoneCallsGridCellComponent implements OnInit {
  data: any;
  constructor() {}

  ngOnInit() {}
}
