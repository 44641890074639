import { inject, Injectable } from '@angular/core';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { StorageItem } from '@core/enums/storage-item.enum';
import { ISession } from '@core/interfaces/session/session-data.interface';
import { AppStorage } from '@core/utils/storage';

import { MyProtectionPlanStatus } from '../enums/plan/my-protection-plan-status.enum';

@Injectable({
  providedIn: 'root',
})
export class PlanUtil {
  appConfig = inject(APP_CONFIG);

  static statusesObject(): {[key: number]: string} {
    return {
      [MyProtectionPlanStatus.Registered]: 'Registered',
      [MyProtectionPlanStatus.Expired]: 'Expired',
      [MyProtectionPlanStatus.Cancelled]: 'Cancelled',
      [MyProtectionPlanStatus.Cancelling]: 'Cancelling',
    };
  }

  getPlanPdfLink(planId: number, filename: 'retailerplan' | 'myplan'): string {
    const session: ISession = AppStorage.get(StorageItem.Session);
    const pdfUrl = new URL(
      `${this.appConfig.apiBaseUrl}/api/${filename}/${planId}/${filename === 'retailerplan' ? 'plan' : filename}.pdf`,
    );
    pdfUrl.searchParams.append('token', session.token);
    if (session.XTenantId) {
      pdfUrl.searchParams.append('X-TenantId', session.XTenantId.toString());
    }
    return pdfUrl.href;
  }
}
