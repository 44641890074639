export enum TReplacementType {
  /// <summary>
  /// Reselection
  /// </summary>
  Reselection = 1,
  /// <summary>
  /// Event Exchange
  /// </summary>
  EvenExchange = 2,
  /// <summary>
  /// Unknown
  /// </summary>
  Unknown = 99,
}
