import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ITileSearch } from '@core/interfaces/claims/tile-search.interface';

import { SidenavService } from '../../../../th-tech/app/core/services/sidenav.service';

@Injectable({
  providedIn: 'root',
})
export class AdvancedSearchService {
  searchToggled$ = new BehaviorSubject<string>(null);
  tileSearch: ITileSearch = {
    tileType: null,
    tileId: '',
  };

  constructor(
    private readonly sidenavService: SidenavService,
  ) {}

  activate(menu: string, tileSearch?: ITileSearch) {
    if (tileSearch) {
      this.tileSearch = tileSearch;
    }
    this.searchToggled$.next(menu);
    this.sidenavService.openMenu();
  }

  deactivate() {
    this.resetTileSearch();
    this.searchToggled$.next(null);
    this.sidenavService.closeMenu();
  }

  resetTileSearch() {
    this.tileSearch = {
      tileType: null,
      tileId: '',
    };
  }
}
