import { ServiceStatus } from '@core/enums/claim/service-status.enum';
import { TTileId, TTileType } from '@core/enums/claim/tile-types.enum';
import { TServiceActionTileStage } from '@core/enums/service-action/service-action-tile-stage.enum';
import { TServiceActionType } from '@core/enums/service-action/service-action-type.enum';
import { ClaimServiceAction } from '@core/interfaces/claims/claimDetails.interface';
import { IServiceActionFilter } from '@core/interfaces/service-action-filter.interface';
import { ClaimSearchRequest } from '@core/models/search/search-request.model';

import { TServicerType } from '../enums/servicing-type.enum';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export class MjcTileFilter {
  static getRepairsStageReadyToShipFilters(): Partial<ClaimSearchRequest> {
    return {
      serviceActionFilters: [
        {
          serviceActionType: TServiceActionType.Repair,
          serviceActionStatus: ServiceStatus.AuthorizedForService,
          servicerTypes: [TServicerType.AssignedServicingRetailer, TServicerType.ThirdPartyRepairDepot],
        },
      ],
    };
  }
  static getRepairsAuthorizedFilters(orCondition: boolean = false): Partial<ClaimSearchRequest> {
    return {
      serviceActionFilters: [
        {
          serviceActionType: TServiceActionType.Repair,
          serviceActionStatus: ServiceStatus.AuthorizedForService,
          orCondition,
        },
      ],
    };
  }

  static getReplacementAuthorizedStageFilters(orCondition: boolean = false): Partial<ClaimSearchRequest> {
    return {
      serviceActionFilters: [
        {
          serviceActionType: TServiceActionType.Replacement,
          serviceActionStatus: ServiceStatus.AuthorizedForService,
          orCondition,
        },
      ],
    };
  }

  static getReplacementInvoicingStageFilters(): Partial<ClaimSearchRequest> {
    return {
      serviceActionFilters: [
        {
          isHandled: false,
        },
      ],
    };
  }

  static getActionTypeTileStageKey(
    serviceActionType: TServiceActionType,
    serviceActionTileStage?: TServiceActionTileStage,
  ): string {
    return `${serviceActionType}${serviceActionTileStage || ''}`;
  }

  static mergeSearchRequests(...searchRequests: Partial<ClaimSearchRequest>[]): Partial<ClaimSearchRequest> {
    return searchRequests.reduce(
      (mergedSearchRequest, currentSearchRequest) => {
        const { serviceActionFilters, ...searchRequestProps } = currentSearchRequest;
        return {
          ...mergedSearchRequest,
          ...searchRequestProps,
          serviceActionFilters: mergedSearchRequest.serviceActionFilters.concat(serviceActionFilters),
        };
      },
      {
        serviceActionFilters: [],
      } as Partial<ClaimSearchRequest>,
    );
  }

  static matchServiceActionWithSearchRequest(claimServiceAction: ClaimServiceAction, searchRequest: Partial<ClaimSearchRequest>): boolean {
    return searchRequest.serviceActionFilters.reduce((previousConditionsMatch, serviceActionFilter) => {
      const { orCondition, ...actionFilterProps } = serviceActionFilter;
      let currentConditionsMatch = true;

      const filterEntries = Object.entries(actionFilterProps) as Entries<IServiceActionFilter>;
      for (const [key, value] of filterEntries) {
        if (claimServiceAction[key] !== value) {
          currentConditionsMatch = false;
        }
      }

      return currentConditionsMatch || previousConditionsMatch;
    }, false);
  }
}

export const COMPOSITE_TILE_SEARCH_REQUESTS: {[key: string]: Partial<ClaimSearchRequest>} = {
  // Repairs
  [MjcTileFilter.getActionTypeTileStageKey(
    TServiceActionType.Repairs,
  )]: MjcTileFilter.mergeSearchRequests(
    MjcTileFilter.getRepairsAuthorizedFilters(),
    // MjcTileFilter.getRepairsStageReadyToShipFilters(),
  ),
  [MjcTileFilter.getActionTypeTileStageKey(
    TServiceActionType.Repairs,
    TServiceActionTileStage.RepairAuthorized,
  )]: MjcTileFilter.getRepairsAuthorizedFilters(),
  [MjcTileFilter.getActionTypeTileStageKey(
    TServiceActionType.Repairs,
    TServiceActionTileStage.ReadyToShip,
  )]: MjcTileFilter.getRepairsStageReadyToShipFilters(),

  // Replacements
  [MjcTileFilter.getActionTypeTileStageKey(
    TServiceActionType.Replacement,
  )]: MjcTileFilter.mergeSearchRequests(
    MjcTileFilter.getReplacementAuthorizedStageFilters(true),
  ),
  [MjcTileFilter.getActionTypeTileStageKey(
    TServiceActionType.Replacement,
    TServiceActionTileStage.ReplacementAuthorized,
  )]: MjcTileFilter.getReplacementAuthorizedStageFilters(),
  // [MjcTileFilter.getActionTypeTileStageKey(
  //   TServiceActionType.Replacement,
  //   TServiceActionTileStage.Invoicing,
  // )]: MjcTileFilter.getReplacementInvoicingStageFilters(),
};

export const ACTION_TILE_SEARCH_REQUEST: {[key: string]: Partial<ClaimSearchRequest>} = {
  [TTileId.ClaimsToReview]: {
    isFlaggedForClientReview: true,
  },
};

export const STAT_TILE_SEARCH_REQUEST: {[key: string]: Partial<ClaimSearchRequest>} = {
  [TTileId.MjcRepairs]: {
    serviceActionFilters: [
      {
        serviceActionType: TServiceActionType.Repair,
        serviceActionStatus: ServiceStatus.Open,
        orCondition: true,
      },
      {
        serviceActionType: TServiceActionType.Repair,
        serviceActionStatus: ServiceStatus.Pending,
        orCondition: true,
      },
      {
        serviceActionType: TServiceActionType.Repair,
        serviceActionStatus: ServiceStatus.AuthorizedForService,
        orCondition: true,
      },
      {
        serviceActionType: TServiceActionType.Repair,
        serviceActionStatus: ServiceStatus.Completed,
        orCondition: true,
      },
    ],
  },
  [TTileId.MjcReplacements]: {
    serviceActionFilters: [
      {
        serviceActionType: TServiceActionType.Replacement,
        serviceActionStatus: ServiceStatus.Open,
        orCondition: true,
      },
      {
        serviceActionType: TServiceActionType.Replacement,
        serviceActionStatus: ServiceStatus.Pending,
        orCondition: true,
      },
      {
        serviceActionType: TServiceActionType.Replacement,
        serviceActionStatus: ServiceStatus.Completed,
        orCondition: true,
      },
    ],
  },
};

export function getTileSearchRequest(tileType: TTileType, tileId: TTileId | string): Partial<ClaimSearchRequest> {
  switch (tileType) {
    case TTileType.Action:
      return ACTION_TILE_SEARCH_REQUEST[tileId];
    case TTileType.Stat:
      return STAT_TILE_SEARCH_REQUEST[tileId];
    case TTileType.Composite:
      return COMPOSITE_TILE_SEARCH_REQUESTS[tileId];
    default:
      return {};
  }
}
