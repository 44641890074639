import { Injectable } from '@angular/core';
import { filter, finalize, map, switchMap } from 'rxjs/operators';

import { ClaimApiService } from '../../api/claim-api.service';
import { MainHub } from '../../services/hubs/main.hub';
import { BaseStore } from '../_base/base.store';
import { StoreState } from './claims.state';
import IStoreState = StoreState.IStoreState;
import initialState = StoreState.initialState;
import { HttpResponse } from '@angular/common/http';

import { THubMethods } from '@core/models/hub-event.model';
import { GridOrderByModel } from '@shared/modules/grid/models/grid/grid-order-by.model';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

import { ExportSelections } from '../../enums/claim/claim-export.enum';
import { TConsumerPlanType } from '../../enums/consumer-plan-type.enum';
import { ClaimsExportOptionsModel } from '../../models/claims-export/claims-export-options.model';
import { ClaimSearchRequest } from '../../models/search/search-request.model';
import { AdvancedSearchService } from '../../services/advanced-search.service';
import { UserStore } from '../user/user.store';

@Injectable({
  providedIn: 'root',
})
export class ClaimsStore extends BaseStore<IStoreState> {
  constructor(
    private readonly claimApiService: ClaimApiService,
    private readonly mainHub: MainHub,
    private readonly notification: NotificationService,
    private readonly advancedSearchService: AdvancedSearchService,
    private readonly userStore: UserStore,
  ) {
    super(initialState);
    const session = this.userStore.get('session');
    this.mainHub.baseHub.eventBus$
      .pipe(
        filter(({ name }) => name === THubMethods.ClaimStatusChange),
        filter(({ data }) => data.tenantId === session.tenantId),
        switchMap(({ data }) =>
          this.select$('claims').pipe(
            map(claims => {
              const changedClaimIndex = claims.findIndex(item => item.id === data.claimId);
              if (changedClaimIndex !== -1) {
                claims[changedClaimIndex].claimStage = data.stage;
                claims[changedClaimIndex].claimStatus = data.status;
              }
              return claims;
            }),
          ),
        ),
      )
      .subscribe(claims => {
        this.updateState({ claims });
      });
  }

  resetAdvancedSearch() {
    const searchRequest = initialState.searchRequest;
    const consumerPlanType = this.get('searchRequest', 'consumerPlanType');
    this.updateState({
      searchRequest: {
        ...searchRequest,
        consumerPlanType,
      },
    });
  }

  deactivateAdvancedSearch() {
    this.advancedSearchService.deactivate();
    this.resetAdvancedSearch();
    this.loadData();
  }

  clearClaims(): void {
    this.updateState({ claims: [] });
  }

  resetSearchRequest(): void {
    this.updateState({
      searchRequest: initialState.searchRequest,
    });
  }

  getInitialSearchRequest(): Partial<ClaimSearchRequest> {
    return initialState.searchRequest;
  }

  clearFlag(claimId: number) {
    const claims = this.get('claims');
    claims.forEach(claim => {
      if (claim.id === claimId) {
        claim.isFlaggedForRetailerReview = false;
      }
    });
    this.updateState({ claims: [...claims] });
  }

  loadData() {
    this.updateState({ loading: true });
    const searchRequest = this.get('searchRequest');
    this.claimApiService
      .all(searchRequest)
      .pipe(finalize(() => this.updateState({ loading: false })))
      .subscribe(({ items, totalCount, page, pageCount }) => {
        this.updateState({
          claims: items || [],
          pagination: {
            totalCount,
            page,
            pageCount,
          },
        });
      });
  }

  export(selections: any) {
    this.updateState({ exportInProgress: true });
    let ids = this.get('claims')
      .filter(item => item.isChecked)
      .map(item => item.id);
    let allPages = {};
    if (selections === ExportSelections.AllPages) {
      allPages = {
        page: 0,
        pageSize: 1,
      };
    }
    if (selections === ExportSelections.SelectedRows && ids.length === 0) {
      this.updateState({ exportInProgress: false });
      this.notification.next({
        message: 'No rows selected.',
        type: NotificationType.Error,
      });
      return;
    }
    if (selections !== ExportSelections.SelectedRows) {
      ids = [];
    }
    const exportOptions = new ClaimsExportOptionsModel(ids, selections);
    const request = {
      ...this.get('searchRequest'),
      exportOptions,
      ...allPages,
    };
    this.claimApiService.exportClaims(request).subscribe(
      (blobResult: HttpResponse<Blob>) => {
        // parse to blob
        const blob = new Blob([blobResult.body], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        //  start downloading file with hidden element
        const tempElement = document.createElement('a');
        if (tempElement.download !== undefined) {
          // pass content of blob
          const url = URL.createObjectURL(blob);

          // set temp attributes and fileName, we can have it file name from server
          // it will require a bit work with HttpResponse<Blob>
          tempElement.setAttribute('href', url);
          tempElement.setAttribute('download', 'download.xlsx');
          tempElement.setAttribute('target', '_self');

          document.body.appendChild(tempElement);
          tempElement.click();

          // clean up
          document.body.removeChild(tempElement);
        }
        this.updateState({ exportInProgress: false });
      },
      error => {
        this.updateState({ exportInProgress: false });
      },
    );
  }

  updatePagination(page: number, pageSize?: number) {
    const searchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...searchRequest,
        page,
        pageSize: pageSize || searchRequest.pageSize,
      },
    });
    this.loadData();
  }

  updatePageSize(pageSize: number) {
    const searchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...searchRequest,
        pageSize,
      },
    });
    this.loadData();
  }

  updateSearches(searchString: string) {
    const searchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...searchRequest,
        page: 1,
        searches: [searchString],
      },
    });
    this.loadData();
  }

  updateOrderBy(orderBy: GridOrderByModel[]) {
    const storeSearchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...storeSearchRequest,
        orderBy,
      },
    });
    this.loadData();
  }

  updateSearchRequest(searchRequest: Partial<ClaimSearchRequest>) {
    const storeSearchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...storeSearchRequest,
        ...searchRequest,
        page: 1,
      },
    });
  }

  filterAdvancedSearch(searchRequest: Partial<ClaimSearchRequest>) {
    const { dateRangeType, ...searchRequestResult } = searchRequest;
    this.updateSearchRequest(searchRequestResult);
    this.loadData();
  }

  checkAll(isChecked: boolean) {
    const claims = this.get('claims');
    claims.forEach(item => (item.isChecked = isChecked));
    this.updateState({
      claims: [...claims],
    });
  }

  checkItem(checkedItem: any) {
    const claims = this.get('claims');
    claims.forEach(item => {
      if (checkedItem.id === item.id) {
        item.isChecked = checkedItem.isChecked;
      }
    });
    this.updateState({
      claims: [...claims],
    });
  }

  loadClaimsForClaimType(claimType: TConsumerPlanType) {
    const pageSize = this.get('searchRequest', 'pageSize');
    this.updateState({
      searchRequest: {
        ...initialState.searchRequest,
        pageSize,
        searches: [],
        consumerPlanType: claimType,
      },
    });
    this.loadData();
  }
}
