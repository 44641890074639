<div class="add-button" [class.add-button--disabled]="disabled">
  <button type="button"
          class="_common-button--circle-sm"
          mat-flat-button
          color="primary"
          [disabled]="disabled">
    <mat-icon class="_common-icon--white" svgIcon="add-icon"></mat-icon>
  </button>
  <div class="add-button__wrapper-text">
    <span *ngIf="buttonText"
          class="add-button__text"
          [class.add-button__text--primary]="color === 'primary'"
          [class.add-button__text--bold]="bold">{{buttonText}}</span>
  </div>
</div>
