import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseConsumer } from '../interfaces/claims/claimDetails.interface';
import { CrudApiBaseService } from './crud-api.base';

@Injectable({
  providedIn: 'root',
})
export class ConsumerApiService extends CrudApiBaseService<BaseConsumer> {
  endpoint = 'consumer';

  documentUploadEmail(body: {
    claimNumber: string;
    emailAddress: string;
    message?: string;
  }): Observable<{message: string}> {
    return this.httpClient.post<{message: string}>(this.getUrl('document-upload-email'), body);
  }
}
