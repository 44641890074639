import { ExportKinds } from '../../enums/claim/claim-export.enum';
import { ClaimsExportOptions } from '../../interfaces/claims/claims-export-options.interface';

export class ClaimsExportOptionsModel implements ClaimsExportOptions {
  ids = [];
  selections = null;
  exportKind = ExportKinds.Excel;

  constructor(ids: (string | number)[], selections: any) {
    this.ids = ids;
    this.selections = selections;
  }
}
