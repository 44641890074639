import { Directive, ElementRef, HostListener, inject, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { TPortalType } from '@core/enums/portal-type.enum';

@Directive({
  selector: '[appCurrency]',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CurrencyDirective,
      multi: true,
    },
  ],
})
export class CurrencyDirective implements ControlValueAccessor {
  appConfig = inject(APP_CONFIG);

  validCurrencyRegExp = /^[0-9]+\.[0-9]{1,2}/g;
  onlyDigits = /^[0-9]+$/g;
  lastDot = /\.$/g;

  onChange: (_: string | null) => void = () => {};
  onTouched: () => void = () => {};

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}

  @HostListener('input', ['$event.target.value'])
  currencyInput(value: string) {
    value = value.trim();
    const currencyMatcher = value.match(/^[0-9]+\.?[0-9]{0,2}/g);
    value = currencyMatcher ? currencyMatcher.join('') : '';

    this.writeValue(value);
    this.onChange(value);
  }

  @HostListener('blur', ['$event.target.value'])
  currencyDot(value: string) {
    const currencyValue = this.parseCurrency(value);
    this.writeValue(currencyValue);
    this.onChange(currencyValue);
    this.onTouched();
  }

  @HostListener('focus', ['$event.target'])
  selectValueOnFocus(target: HTMLInputElement) {
    target.select();
  }

  writeValue(value: string): void {
    const normalizedValue = value === null ? '' : value.toString();
    this.setProperty('value', normalizedValue);
  }

  registerOnChange(fn: (_: number | string | null) => void): void {
    this.onChange = value => {
      fn(this.normalizedCurrencyValue(value));
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  protected setProperty(key: string, value: string): void {
    this._renderer.setProperty(this._elementRef.nativeElement, key, value);
  }

  private parseCurrency(value: string | null): string {
    if (!value) {
      return '';
    }

    const validCurrency = value.match(this.validCurrencyRegExp);
    const onlyDigits = value.match(this.onlyDigits);
    const lastDot = value.match(this.lastDot);
    if (validCurrency) {
      return value;
    } else if (onlyDigits) {
      return `${value}.00`;
    } else if (lastDot) {
      return `${value}00`;
    } else {
      return `${value}0`;
    }
  }

  private normalizedCurrencyValue(value: string) {
    if (value.match(this.validCurrencyRegExp) || value.match(this.onlyDigits) || value.match(this.lastDot)) {
      return Number(value);
    } else if (this.appConfig.portalType === TPortalType.TechPortal) {
      return -1;
    } else {
      return null;
    }
  }
}
