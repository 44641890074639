import moment from 'moment';

import { ClaimStatuses } from '@core/enums/claim/claim-statuses.enum';
import { DateKind } from '@core/enums/claim/date-kind.enum';
import { TServiceActionStage } from '@core/enums/claim/service-action-stage.enum';
import { ServiceStatus } from '@core/enums/claim/service-status.enum';
import { TTileId, TTileType } from '@core/enums/claim/tile-types.enum';
import { TConsumerPlanType } from '@core/enums/consumer-plan-type.enum';
import { TReplacementType } from '@core/enums/replacement-type.enum';
import { TServiceActionTileStage } from '@core/enums/service-action/service-action-tile-stage.enum';
import { TServiceActionType } from '@core/enums/service-action/service-action-type.enum';
import { ClaimServiceAction } from '@core/interfaces/claims/claimDetails.interface';
import { IServiceActionFilter } from '@core/interfaces/service-action-filter.interface';
import { ClaimSearchRequest } from '@core/models/search/search-request.model';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export class RetailerTileFilter {
  static getSettlementsToApproveFilters(): Partial<ClaimSearchRequest> {
    return {
      serviceActionFilters: [{
        serviceActionType: TServiceActionType.CashSettlement,
        serviceActionStatus: ServiceStatus.Open,
        serviceActionStage: TServiceActionStage.RetailerPending,
        isHandled: false,
      }],
    };
  }

  static getComplaintsToReviewFilters(): Partial<ClaimSearchRequest> {
    return {
      serviceActionFilters: [
        {
          serviceActionType: TServiceActionType.Complaint,
          serviceActionStage: TServiceActionStage.Servicing,
          isHandled: false,
          orCondition: true,
        },
        {
          serviceActionType: TServiceActionType.Complaint,
          processStage: null,
          isHandled: false,
          orCondition: true,
        },
      ],
    };
  }

  static getOpenPartOrdersFilters(): Partial<ClaimSearchRequest> {
    return {
      serviceActionFilters: [{
        serviceActionType: TServiceActionType.PartOrder,
        serviceActionStatus: ServiceStatus.Open,
        isHandled: false,
      }],
      consumerPlanType: TConsumerPlanType.MyService,
    };
  }

  static getPPOpenPartOrdersFilters(): Partial<ClaimSearchRequest> {
    return {
      serviceActionFilters: [{
        serviceActionType: TServiceActionType.PartOrder,
        serviceActionStatus: ServiceStatus.Open,
        isHandled: false,
      }],
      consumerPlanType: TConsumerPlanType.ProtectionPlan,
    };
  }

  static getExchangesToReviewFilters(): Partial<ClaimSearchRequest> {
    return {
      serviceActionFilters: [{
        serviceActionType: TServiceActionType.Replacement,
        serviceActionStatus: ServiceStatus.Open,
        serviceActionStage: TServiceActionStage.RetailerPending,
        isHandled: false,
      }],
    };
  }

  static getRecentTechReportsFilters(): Partial<ClaimSearchRequest> {
    return {
      serviceActionFilters: [{
        serviceActionType: TServiceActionType.TechWorkOrder,
        // TODO: ProcessStage is not null
      }],
      claimStatus: ClaimStatuses.Open,
      dateKind: DateKind.TechCompletionDate,
      get fromDate() {
        return moment().utc().subtract(6, 'days').format();
      },
      get toDate() {
        return moment().utc().format();
      },
    };
  }

  // COMPOSITE TILES
  static getPartOrderOrderPartsStageFilters(orCondition: boolean = false): Partial<ClaimSearchRequest> {
    return {
      consumerPlanType: TConsumerPlanType.ProtectionPlan,
      serviceActionFilters: [
        {
          serviceActionType: TServiceActionType.PartOrder,
          serviceActionStatus: ServiceStatus.Open,
          serviceActionStage: TServiceActionStage.RetailerPending,
          partOrderTypeIds: [223940000],
          isHandled: false,
          orCondition,
        },
      ],
    };
  }

  static getPartOrderEnterDeliveryStageFilters(): Partial<ClaimSearchRequest> {
    return {
      consumerPlanType: TConsumerPlanType.ProtectionPlan,
      serviceActionFilters: [
        {
          serviceActionType: TServiceActionType.PartOrder,
          serviceActionStatus: ServiceStatus.Open,
          serviceActionStage: TServiceActionStage.Servicing,
          partOrderTypeIds: [223940000],
          isHandled: false,
          orCondition: true,
        },
        {
          serviceActionType: TServiceActionType.PartOrder,
          serviceActionStatus: ServiceStatus.Open,
          serviceActionStage: TServiceActionStage.Servicing,
          partOrderTypeIds: [223940001],
          processStage: 'Parts Shipped/In Transit (Direct)',
          isHandled: false,
          orCondition: true,
        },
      ],
    };
  }

  static getReplacementOrderStageFilters(orCondition: boolean = false): Partial<ClaimSearchRequest> {
    return {
      consumerPlanType: TConsumerPlanType.ProtectionPlan,
      serviceActionFilters: [
        {
          serviceActionType: TServiceActionType.Replacement,
          serviceActionStage: TServiceActionStage.RetailerPending,
          serviceActionStatus: ServiceStatus.Open,
          isHandled: false,
          orCondition,
        },
      ],
    };
  }

  static getReplacementEnterDeliveryStageFilters(orCondition: boolean = false): Partial<ClaimSearchRequest> {
    return {
      consumerPlanType: TConsumerPlanType.ProtectionPlan,
      serviceActionFilters: [
        {
          serviceActionType: TServiceActionType.Replacement,
          serviceActionStage: TServiceActionStage.Servicing,
          serviceActionStatus: ServiceStatus.Open,
          isHandled: false,
          orCondition,
        },
      ],
    };
  }

  static getActionTypeTileStageKey(serviceActionType: TServiceActionType, serviceActionTileStage?: TServiceActionTileStage): string {
    return `${serviceActionType}${serviceActionTileStage || ''}`;
  }

  static mergeSearchRequests(...searchRequests: Partial<ClaimSearchRequest>[]): Partial<ClaimSearchRequest> {
    return searchRequests.reduce((mergedSearchRequest, currentSearchRequest) => {
      const { serviceActionFilters, ...searchRequestProps } = currentSearchRequest;
      return {
        ...mergedSearchRequest,
        ...searchRequestProps,
        serviceActionFilters: mergedSearchRequest.serviceActionFilters.concat(serviceActionFilters),
      };
    }, {
      serviceActionFilters: [],
    } as Partial<ClaimSearchRequest>);
  }

  static matchServiceActionWithSearchRequest(claimServiceAction: ClaimServiceAction, searchRequest: Partial<ClaimSearchRequest>): boolean {
    return searchRequest.serviceActionFilters.reduce((previousConditionsMatch, serviceActionFilter) => {
      const { orCondition, excludeManagedParts, ...actionFilterProps } = serviceActionFilter;
      let currentConditionsMatch = true;

      const filterEntries = Object.entries(actionFilterProps) as Entries<IServiceActionFilter>;
      for (const [key, value] of filterEntries) {
        if (key === 'partOrderTypeIds') {
          if (!value.includes(claimServiceAction['partOrderTypeId'])) {
            currentConditionsMatch = false;
          }
        } else if (claimServiceAction[key] !== value) {
          currentConditionsMatch = false;
        }
      }

      return currentConditionsMatch || previousConditionsMatch;
    }, false);
  }
}

export const COMPOSITE_TILE_SEARCH_REQUESTS: {[key: string]: Partial<ClaimSearchRequest>} = {
  [RetailerTileFilter.getActionTypeTileStageKey(
    TServiceActionType.PartOrder,
  )]: RetailerTileFilter.mergeSearchRequests(
    RetailerTileFilter.getPartOrderOrderPartsStageFilters(true),
    RetailerTileFilter.getPartOrderEnterDeliveryStageFilters(),
  ),
  [RetailerTileFilter.getActionTypeTileStageKey(
    TServiceActionType.PartOrder, TServiceActionTileStage.OrderParts,
  )]: RetailerTileFilter.getPartOrderOrderPartsStageFilters(),
  [RetailerTileFilter.getActionTypeTileStageKey(
    TServiceActionType.PartOrder, TServiceActionTileStage.EnterDelivery,
  )]: RetailerTileFilter.getPartOrderEnterDeliveryStageFilters(),
  [RetailerTileFilter.getActionTypeTileStageKey(TServiceActionType.PartOrder, TServiceActionTileStage.ExpiringSoon)]: {
    dateKind: DateKind.RetailerExpirationDate,
    get fromDate() {
      return moment().utc(false).startOf('day').format();
    },
    get toDate() {
      return moment().utc(false).add(30, 'days').startOf('day').format();
    },
    ...RetailerTileFilter.mergeSearchRequests(
      RetailerTileFilter.getPartOrderOrderPartsStageFilters(true),
      RetailerTileFilter.getPartOrderEnterDeliveryStageFilters(),
    ),
  },
  [RetailerTileFilter.getActionTypeTileStageKey(
    TServiceActionType.Replacement,
  )]: RetailerTileFilter.mergeSearchRequests(
    RetailerTileFilter.getReplacementOrderStageFilters(true),
    RetailerTileFilter.getReplacementEnterDeliveryStageFilters(true),
  ),
  [RetailerTileFilter.getActionTypeTileStageKey(
    TServiceActionType.Replacement, TServiceActionTileStage.OrderReplacements,
  )]: RetailerTileFilter.getReplacementOrderStageFilters(),
  [RetailerTileFilter.getActionTypeTileStageKey(
    TServiceActionType.Replacement, TServiceActionTileStage.EnterDelivery,
  )]: RetailerTileFilter.getReplacementEnterDeliveryStageFilters(),
  [RetailerTileFilter.getActionTypeTileStageKey(TServiceActionType.Replacement, TServiceActionTileStage.ExpiringSoon)]: {
    dateKind: DateKind.RetailerExpirationDate,
    get fromDate() {
      return moment().utc(false).startOf('day').format();
    },
    get toDate() {
      return moment().utc(false).add(30, 'days').startOf('day').format();
    },
    ...RetailerTileFilter.mergeSearchRequests(
      RetailerTileFilter.getReplacementOrderStageFilters(true),
      RetailerTileFilter.getReplacementEnterDeliveryStageFilters(true),
    ),
  },
};

export const ACTION_TILE_SEARCH_REQUEST: {[key: string]: Partial<ClaimSearchRequest>} = {
  [TTileId.ClaimsToReview]: {
    isFlaggedForClientReview: true,
  },
  [TTileId.SettlementsToApprove]: RetailerTileFilter.getSettlementsToApproveFilters(),
  [TTileId.ComplaintsToReview]: RetailerTileFilter.getComplaintsToReviewFilters(),
  [TTileId.ExchangesToReview]: RetailerTileFilter.getExchangesToReviewFilters(),
  [TTileId.PPClaimsToReview]: {
    isFlaggedForClientReview: true,
  },
};

export const STAT_TILE_SEARCH_REQUEST: {[key: string]: Partial<ClaimSearchRequest>} = {
  [TTileId.OpenClaims]: {
    claimStatus: ClaimStatuses.Open,
  },
  [TTileId.OpenPartOrders]: RetailerTileFilter.getOpenPartOrdersFilters(),
  [TTileId.RecentTechReports]: RetailerTileFilter.getRecentTechReportsFilters(),
  [TTileId.PPOpenPartOrders]: RetailerTileFilter.getPPOpenPartOrdersFilters(),
  [TTileId.PPEvenExchanges]: {
    serviceActionFilters: [
      {
        serviceActionType: TServiceActionType.Replacement,
        serviceActionStatus: ServiceStatus.Open,
        serviceActionReplacementType: TReplacementType.EvenExchange,
      },
    ],
  },
  [TTileId.PPOpenReselections]: {
    serviceActionFilters: [
      {
        serviceActionType: TServiceActionType.Replacement,
        serviceActionStatus: ServiceStatus.Open,
        serviceActionReplacementType: TReplacementType.Reselection,
      },
    ],
  },
};

export function getTileSearchRequest(tileType: TTileType, tileId: TTileId | string): Partial<ClaimSearchRequest> {
  switch (tileType) {
    case TTileType.Action:
      return ACTION_TILE_SEARCH_REQUEST[tileId];
    case TTileType.Stat:
      return STAT_TILE_SEARCH_REQUEST[tileId];
    case TTileType.Composite:
      return COMPOSITE_TILE_SEARCH_REQUESTS[tileId];
    default:
      return {};
  }
}
