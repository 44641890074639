import { Pipe, PipeTransform } from '@angular/core';

import { TDocumentType } from '../../core/enums/document-type';
import { Files } from '../../core/interfaces/claims/files.interface';

export interface ClaimDocument {
  id: number;
  url: string;
  dateCreated: string;
  fileName: string;
  mimeType: string;
  thumbnailUrl: string;
  viewerUrl: string;
  docType: TDocumentType;
}

@Pipe({
  name: 'filterDocumentsByTypes',
})
export class FilterDocumentsByTypesPipe implements PipeTransform {
  transform(items: Files[], documentTypes: TDocumentType[]): any {
    if (!documentTypes) {
      return items;
    } else {
      return items.filter(item => documentTypes.includes(item.docType));
    }
  }
}
