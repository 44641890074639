import { Directive,OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { DestroyableComponent } from './destroyable-component';

export interface WizardNavigation {
  [key: string]: WizardNavigationItem;
}

export interface WizardNavigationItem {
  name: string;
  step?: number;
  nextStep: string;
  prevStep: string;
  formGroupName: string;
  readonly: boolean;
  child?: WizardNavigationChild[];
  hideChildrenInNavbar?: boolean;
  extraValidation?(step: any, form?: any): boolean;
}

export interface WizardNavigationChild {
  name: string;
  subStep: number;
  readonly: boolean;
}

@Directive()
export abstract class BaseWizard<T extends WizardNavigation> extends DestroyableComponent implements OnInit {
  form: UntypedFormGroup;
  submitInProgress = false;

  navigationItems: T;

  navigationList: Array<string>;
  currentStep: WizardNavigationItem;
  currentSubStep = 0;
  nextButtonDisabled = false;

  abstract initWizard();

  protected constructor() {
    super();
  }

  ngOnInit(): void {
    this.initWizard();
  }

  goToStep(stepName: string) {
    this.setStep(stepName);
  }

  nextStep() {
    if (!this.currentStep.nextStep) {
      return;
    } else if (this.currentStep.child && this.currentSubStep < this.currentStep.child.length - 1) {
      this.setSubStep(this.currentSubStep + 1);
    } else {
      this.setStep(this.currentStep.nextStep);
    }
  }

  prevStep() {
    if (this.submitInProgress) {
      return;
    } else if (this.currentStep.child && this.currentSubStep > 0) {
      this.setSubStep(this.currentSubStep - 1);
    } else if (this.getStepByName(this.currentStep.prevStep).child) {
      this.setStep(this.currentStep.prevStep, this.getStepByName(this.currentStep.prevStep).child.length - 1);
    } else {
      this.setStep(this.currentStep.prevStep);
    }
  }

  protected setStep(stepName: string, subStep: number = 0) {
    if (!stepName) {
      return;
    }
    this.setSubStep(subStep);
    this.currentStep = this.getStepByName(stepName);
  }

  protected setSubStep(subStep: number) {
    this.currentSubStep = subStep;
  }

  private getStepByName(stepName: string) {
    return this.navigationItems[stepName];
  }
}
