import { animate, style, transition, trigger } from '@angular/animations';
import { Component, inject } from '@angular/core';

import { DIALOG_ROUTE_CONFIG } from '@core/constants/app.constants';
import { SideDialogStateService } from '@core/services/dialog/side-dialog-state.service';
import {RxIf} from '@rx-angular/template/if';

@Component({
  selector: 'app-side-dialog-animation',
  template: `
    <div *rxIf="isVisible$"
          [@slideInOut]="{value: null, params: {animationTime: dialogRouteConfig.animationTime}}"
         class="side-dialog-animation">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./side-dialog-animation.component.scss'],
  standalone: true,
  imports: [
    RxIf,
  ],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('{{animationTime}}ms ease-in-out', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('{{animationTime}}ms ease-in-out', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class SideDialogAnimationComponent {
  isVisible$ = this.sideDialogStateService.isVisible$;
  dialogRouteConfig = inject(DIALOG_ROUTE_CONFIG);

  constructor(
    private readonly sideDialogStateService: SideDialogStateService,
  ) {}
}
