import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

import { UserStore } from '../../core/store/user/user.store';

@Pipe({
  name: 'appUserTimezone',
})
export class UserTimezonePipe implements PipeTransform {
  constructor(private readonly userStore: UserStore) {}

  transform(date: string | Date, format: string) {
    const session = this.userStore.getSessionStorage();
    return moment(date).tz(session.timeZone.ianaName).format(format);
  }
}
