import { AsyncPipe, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, ElementRef, EventEmitter, inject,Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { RxState } from '@rx-angular/state';
import { distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';

import { UtilityApiService } from '@core/api/utility-api.service';
import { DestroyableComponent } from '@core/classes/destroyable-component';
import { Address, ValidatedAddress } from '@core/interfaces/address.interface';
import { ClaimUtil } from '@core/utils/claim.util';
import { ScrollUtil } from '@core/utils/scroll.util';
import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';

import { ADDRESS_SUGGEST_SCROLL_SELECTOR } from './address-suggest.token';

enum AddressValidationType {
  Hide,
  Valid,
  ValidAndDifferent,
  Invalid,
}

interface IAddressSuggestState {
  verificationInProgress: boolean;
  validationType: AddressValidationType;
}

@Component({
  selector: 'app-address-suggest',
  standalone: true,
  templateUrl: './address-suggest.component.html',
  styleUrls: ['./address-suggest.component.scss'],
  providers: [RxState],
  imports: [
    MatButtonModule,
    LoadingButtonDirective,
    NgSwitch,
    NgSwitchCase,
    AsyncPipe,
    NgIf,
  ],
})
export class AddressSuggestComponent extends DestroyableComponent implements OnInit {
  @Input() addressForm: AbstractControl;
  @Input() isServiceAddressSame: boolean;
  @Input() blocked: boolean;
  @Output() isInProgress = new EventEmitter<boolean>();

  @ViewChild('addressSuggestWrapper', { static: true }) addressSuggestWrapper: ElementRef;

  addressSuggest: ValidatedAddress = null;
  verificationInProgress$ = this.state.select('verificationInProgress');
  validationType$ = this.state.select('validationType');
  validationTypes = AddressValidationType;
  validatedAddress: ValidatedAddress = null;
  addressSuggestScrollSelector = inject(ADDRESS_SUGGEST_SCROLL_SELECTOR, {
    optional: true,
  });

  constructor(private utilityApiService: UtilityApiService, private readonly state: RxState<IAddressSuggestState>) {
    super();
    this.state.set({
      verificationInProgress: false,
      validationType: AddressValidationType.Hide,
    });
  }

  ngOnInit(): void {
    this.addressForm.valueChanges
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe(address => {
        this.setAddressValidationFlags(address);
      });

    if (this.isServiceAddressSame) {
      if (!this.addressForm.value.isValidated && !this.addressForm.value.addressValidationAttempted) {
        this.validatedAddress = null;
      } else if (!this.addressForm.value.isValidated && this.addressForm.value.addressValidationAttempted) {
        this.validatedAddress = undefined;
      } else {
        this.validatedAddress = this.addressForm.value;
      }
    }
  }

  setAddressValidationFlags(address: Address): void {
    if (this.validatedAddress === null) {
      this.addressForm.patchValue({
        isValidated: false,
        addressValidationAttempted: false,
      });
    } else if (this.validatedAddress === undefined) {
      this.addressForm.patchValue({
        isValidated: false,
        addressValidationAttempted: true,
      });
    } else {
      const isCustomerAddressValidated = ClaimUtil.compareAddresses(address, this.validatedAddress);
      this.addressForm.patchValue({
        isValidated: isCustomerAddressValidated,
        addressValidationAttempted: isCustomerAddressValidated,
      });
    }
  }

  verifyAddress(): void {
    const addressFormValue = this.addressForm.value;
    if (
      addressFormValue.streetAddress1
      && addressFormValue.city
      && addressFormValue.stateProvince
      && addressFormValue.postalCode
    ) {
      this.state.set({
        verificationInProgress: true,
      });
      this.isInProgress.emit(true);
      this.utilityApiService
        .validateAddress(addressFormValue)
        .pipe(
          finalize(() => {
            this.state.set({
              verificationInProgress: false,
            });
            this.isInProgress.emit(false);
          }),
        )
        .subscribe(response => {
          const isAddressesEqual = ClaimUtil.compareAddresses(addressFormValue, response);
          const isAddressValid = response.addressValidated;
          this.validatedAddress = response;
          if (isAddressValid) {
            if (isAddressesEqual) {
              this.state.set({
                validationType: AddressValidationType.Valid,
              });
              this.validatedAddress = response;
            } else {
              this.state.set({
                validationType: AddressValidationType.ValidAndDifferent,
              });
              this.addressSuggest = response;
              this.validatedAddress = response;
            }
          } else {
            this.state.set({
              validationType: AddressValidationType.Invalid,
            });
            this.validatedAddress = undefined;
          }
          this.setAddressValidationFlags(addressFormValue);
          this.blockScroll(true);
        });
    } else {
      this.state.set({
        validationType: AddressValidationType.Hide,
      });
    }
  }

  chooseAddress(): void {
    this.addressForm.patchValue(this.addressSuggest);
    this.clearAddressSuggest();
    this.validatedAddress = this.addressSuggest;
  }

  clearAddressSuggest(): void {
    this.state.set({
      validationType: AddressValidationType.Hide,
    });
    this.blockScroll(false);
  }

  blockScroll(block: boolean): void {
    const dialogContent = document.querySelector(this.addressSuggestScrollSelector || '.side-dialog__content-scroll') as HTMLElement;
    const addressSuggest = this.addressSuggestWrapper.nativeElement as HTMLElement;
    if (addressSuggest && block) {
      setTimeout(() => {
        ScrollUtil.scrollTo(dialogContent, addressSuggest.offsetTop);
      }, 100);
    }

    if (block) {
      setTimeout(() => {
        dialogContent.style.overflow = 'hidden';
      }, 600);
    } else {
      dialogContent.style.overflow = 'auto';
    }
  }
}
