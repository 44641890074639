import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { parseZone } from 'moment';
import moment from 'moment-timezone';

import { SettlementType } from '@core/enums/claim/service-settlement-type.enum';
import { TServiceActionType } from '@core/enums/service-action/service-action-type.enum';
import { ClaimServiceAction } from '@core/interfaces/claims/claimDetails.interface';
import { UserStore } from '@core/store/user/user.store';

@Component({
  selector: 'app-service-details-grid-cell',
  standalone: true,
  templateUrl: './service-details-grid-cell.component.html',
  styleUrls: ['./service-details-grid-cell.component.scss'],
  imports: [
    NgIf,
    MatTooltipModule,
  ],
})
export class ServiceDetailsGridCellComponent implements OnInit {
  session = this.userStore.get('session');
  fieldText: string;
  tooltipText: string;
  data: ClaimServiceAction;
  fieldName: string;
  hasTooltip = false;
  table: ClaimServiceAction;
  html: string;

  serviceStatuses = {};
  serviceType = TServiceActionType;

  constructor(private readonly userStore: UserStore) {
    this.serviceStatuses[SettlementType.GiftCard] = 'Gift Card';
    this.serviceStatuses[SettlementType.Discount] = 'Discount';
    this.serviceStatuses[SettlementType.Check] = 'Check';
  }

  ngOnInit() {
    if (this.data.serviceActionType === this.serviceType.PartOrder) {
      this.table = this.data;
      this.hasTooltip = true;
      // this.fieldText = this.data.partsOrdered + '...';
      this.fieldText = 'See more';
      this.tooltipText = `
Parts Ordered: ${this.data.partsOrdered || '-'}\n
Authorization sent date: ${this.data.authSentDate ? parseZone(this.data.authSentDate).format('MM/DD/YYYY') : '-'}\n
PO additional comments: ${this.data.poAdditionalComments || '-'}\n\n
CC processed amount: ${this.data.ccProcessedAmount || '-'}\n
CC authorization date: ${this.data.ccAuthorizationDate ? parseZone(this.data.ccAuthorizationDate).format('MM/DD/YYYY') : '-'}\n
`;
    }

    if (this.data.serviceActionType === this.serviceType.CashSettlement) {
      this.fieldText = this.data[this.fieldName];
    }

    if (this.data.serviceActionType === this.serviceType.Complaint) {
      this.hasTooltip = true;
      this.tooltipText = this.data.complaint;
      const threeDots = this.data.complaint && this.data.complaint.length > 80 ? '...' : '';
      this.fieldText = `${this.data.complaint ? this.data.complaint.substring(0, 80) + threeDots : ''}`;
    }

    if (this.data.serviceActionType === this.serviceType.Replacement) {
      this.hasTooltip = true;
      this.tooltipText = this.data.poAdditionalComments;
      const threeDots = this.data.poAdditionalComments && this.data.poAdditionalComments.length > 80 ? '...' : '';
      const poAdditionalComments = `${
        this.data.poAdditionalComments ? this.data.poAdditionalComments.substring(0, 80) + threeDots : ''
      }`;
      const additionalText = `${this.data.replacementTypeName ? this.data.replacementTypeName : ''}${
        this.data.specialHandling ? ', Special Handling' : ''
      }`;
      const br = this.data.replacementTypeName || this.data.specialHandling ? '<br>' : '';
      this.fieldText = `${additionalText}${br}${poAdditionalComments}`;
    }
    if (this.data.serviceActionType === this.serviceType.TechWorkOrder) {
      this.fieldText = `Tech Assigned: ${
        this.data.techAssignedDate
          ? moment(this.data.techAssignedDate).tz(this.session.timeZone.ianaName).format('MM/DD/YYYY')
          : '-'
      }<br>
        Service Scheduled: ${
  this.data.serviceScheduledDate
    ? moment(this.data.serviceScheduledDate).tz(this.session.timeZone.ianaName).format('MM/DD/YYYY')
    : '-'
}<br>
        Tech Completed: ${
  this.data.techCompletionDate
    ? moment(this.data.techCompletionDate).tz(this.session.timeZone.ianaName).format('MM/DD/YYYY')
    : '-'
}
        `;
    }
  }
}
