import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IParentPlan } from '@core/interfaces/plan/parent-plan.interface';

import { IDataSearch } from '../interfaces/data-search.interface';
import { IIdentityString } from '../interfaces/util/identity.interface';
import { BaseSearchRequest, ProtectionPlanRequest } from '../models/search/search-request.model';
import { CrudApiBaseService } from './crud-api.base';

export interface ParentPlanSearchRequest extends BaseSearchRequest {
  retailerId: number;
}

export interface ParentPlanIdsSearchRequest extends ParentPlanSearchRequest {
  retailerPlanIds: string[];
}

@Injectable({
  providedIn: 'root',
})
export class ParentPlanApiService extends CrudApiBaseService<IIdentityString> {
  endpoint = 'parentplan';

  all(searchRequest: ProtectionPlanRequest): Observable<IDataSearch<IParentPlan>> {
    return this.httpClient.post<IDataSearch<IParentPlan>>(this.getUrl('all'), searchRequest);
  }

  comparePlans(searchRequest: ParentPlanIdsSearchRequest): Observable<string[]> {
    return this.httpClient.post<string[]>(this.getUrl('compareplans'), searchRequest);
  }
}
